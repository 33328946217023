import { AutoSuggestionService } from "../../http";

/*ACTION TYPES*/
const GET_SEARCH_SUGGESTION_DATA = "GET_SEARCH_SUGGESTION_DATA";

/*ACTION CREATORS*/
export const getSuggestionData = (payload) => ({
  type: GET_SEARCH_SUGGESTION_DATA,
  payload: payload,
});

/*THUNK*/
export const getSuggestionDataThunk = (params) => async (dispatch) => {
  try {
    const { query } = params;
    const response = await AutoSuggestionService.getData(query);
    dispatch(getSuggestionData(response.data));
  } catch (error) {
    dispatch(getSuggestionData([]));
  }
};

const initialState = {
  suggestionData: []
};

/*REDUCER*/
function autoSuggestionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SEARCH_SUGGESTION_DATA:
      return {
        ...state,
        suggestionData: action.payload,
      };
    default:
      return state;
  }
}

export default autoSuggestionReducer;