import React from "react";
import "./EVRecommendation.css";
import { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import LoadingMore from '../common/LoadingMore/LoadingMore';
import EVItem from "../common/EVItem/EVItem";

const EVRecommendation = ({ title, items }) => {
  const [infiniteArray, setInfiniteArray] = useState(items.slice(0, 3));
  const [isMore, setIsMore] = useState(true);
  const [dataLength, setDataLength] = useState(items.length);
  const [start, setStart] = useState(3);
  const [busy, setBusy] = useState(false)
  const limit = 3;

  useEffect(() => {
    setInfiniteArray(() => {
      return items.slice(0, 3);
    })
    setIsMore(true)
    setDataLength(items.length)
    setStart(3)
  }, [items])

  const addMoreItems = () => {
    const end = (dataLength < start + limit) ? dataLength : start + limit;
    setInfiniteArray((prev) => {
      return [
        ...prev,
        ...items.slice(start, end),
      ];
    })
    setStart(start + 3)
  }

  const trottle = () => {
    if (dataLength < start) {
      setIsMore(false)
      return;
    }
    if (busy) {
      return;
    }

    setBusy(true);
    setTimeout(() => {
      addMoreItems();
      setBusy(false);
    }, 1000);
  };

  return (<>
    <div className="other-recommendation-title title-large mt-3">
      <span className="icon-icon-double-arrow mr-2"></span>
      {title} Lainnya
    </div>
    <div>
      <InfiniteScroll
        loadMore={trottle}
        hasMore={true || false}
        className="other-recommendation__wrapper"
      >
        {infiniteArray && infiniteArray.map((item, index) => {
          return (
            <EVItem item={item} key={`other-recommendation__item-${index}`} />
          )
        })}
      </InfiniteScroll>
      {(isMore) ? (
        <LoadingMore />
      ) : (null)}
    </div>
  </>)
};

export default EVRecommendation;