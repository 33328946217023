import React from "react";
import "./PopularToday.css";
// import { useSelector } from "react-redux";
import PopularTodayItem from "../../components/PopularToday/PopularTodayItem";

function PopularToday({ title, articles }) {
  // const otherData = useSelector((state) => state.homepageReducer.other);

  return (
    <>
      <div className="popular-today__container">
        <div className="popular-today__title">{title}</div>
        <div className="popular-today__list">
          {articles &&
            articles.map((item, index) => (
              <PopularTodayItem key={index} num={index + 1} article={item} />
            ))}
        </div>
      </div>
    </>
  );
}

export default PopularToday;
