import React from "react";
import "./AboutUs.css";
import StaticSidebar from "@components/Static/Sidebar/StaticSidebar";
// import {Link} from 'react-router-dom';

function AboutUs() {
    return (
        <>
            <div className="about-us-section__container">
                <div className="static-sidebar">
                    <StaticSidebar cls="show-desktop has-title" id="" active="eula" />
                </div>
                <div className="static-content">
                    <div className="title">
                        <h2 style={{ marginBottom: "10px" }}>Visi dan Misi JalanTikus.com</h2>
                        <span>Mengenal tim dan proses kreatif di balik redaksi JalanTikus.com</span>
                    </div>
                    <div className="content">
                        <p>
                            JalanTikus.com adalah portal teknologi online berbahasa Indonesia yang sudah ada sejak tahun 2012. Dengan tema "One-Stop Shop untuk Tren Teknologi", kami berkomitmen untuk memberikan solusi terkait teknologi, terutama bagi pengguna handphone iOS, Android, dan laptop.
                        </p>
                        <p>
                            Kami membahas berbagai topik teknologi terkini seperti cryptocurrency, fintech, perangkat kesehatan, kendaraan dan motor listrik, sehingga membantu pembaca kami untuk lebih memahami tren teknologi yang sedang populer.
                        </p>
                        <p>
                            Pembaca dapat mengakses JalanTikus.com melalui web browser dan mobile web browser. Tak hanya menyajikan konten teks dan foto, JalanTikus.com juga menampilkan infografis dan video melalui akun Facebook <a href="https://www.facebook.com/JalanTikus/" target="_blank" rel="noopener noreferrer">Jalan Tikus</a> dan Instagram <a href="https://www.instagram.com/jalantikusid/" target="_blank" rel="noopener noreferrer">@jalantikusid</a>.
                        </p>
                        <p>
                            Untuk menghubungi redaksi JalanTikus, Anda bisa mengirimkan email ke <a href="mailto:info@jalantikus.com">info@jalantikus.com</a> atau mengunjungi <a href="https://jalantikus.com/contact/">https://jalantikus.com/contact/</a>.
                        </p>
                    </div>
                    <div className="title" id="redaksi">
                        <h2>REDAKSI JalanTikus.com</h2>
                    </div>
                    <div className="content">
                        <p className="sub-title">
                            Pemimpin Redaksi / Project Manager
                        </p>
                        <p>
                            Laila Karomah
                        </p>
                        <p className="sub-title">
                            Managing Editor
                        </p>
                        <p>
                            Tanri Raafani Haidi
                        </p>
                        <p className="sub-title">
                            Content strategist & Data Wizard
                        </p>
                        <p>
                            Ayesha Agustien<br />
                            Amien Rais<br />
                        </p>
                        <p className="sub-title">
                            Editor
                        </p>
                        <p>
                            Ayu Dewi<br />
                            Kiki Kristika<br />
                        </p>
                        <p className="sub-title">
                            Reporter
                        </p>
                        <p>
                            Kevin Rizky<br />
                            Azhar Ilyas<br />
                            {/* Siti Aisyah (News Reporter)<br />
                            Ayu Pratiwi (News Reporter)<br />
                            Anggi Oktaviani (Kesehatan)<br />
                            Kevin Chandra (Hiburan)<br />
                            Michelle Lim (Tekno)<br />
                            Fitria Handayani (Finansial)<br />
                            Puput Hadiningrat (Ragam)<br />
                            Muhammad Reza (Sport)<br />
                            Asep Budiawan (Otomotif)<br />
                            Bayu Setiawan (Crypto & Trading)<br />
                            Rizki Ramadhan (Kesehatan)<br />
                            Dwi Yulianto (Ragam)<br /> */}
                        </p>
                        <p className="sub-title">
                            IT
                        </p>
                        <p>
                            Jodi Kardo<br />
                            Thanh Nguyen<br />
                            Trung Nguyen<br />
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUs;
