import React, { useEffect, useState } from "react";
import "./VerticalBanner.css";
// import verticalBanner from "@assets/images/banners/vertical-banner.svg";
import { AdSlot } from 'react-dfp';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function VerticalBanner() {
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(width < 768)
  }, [width])

  return (
    <>
    {!isMobile && 
      <div className="" style={{minWidth:'120px',minHeight: '240px'}}>
        <AdSlot 
          dfpNetworkId="21702367101" 
          adUnit="JT_120x600_desktop" 
          sizes={[[120, 600], [120, 240]]}
          targetingArguments={{ jt_ads: ['revamp_test'] }}
        />
      </div>
    }
    </>
  )
}

export default VerticalBanner;
