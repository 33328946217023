import React, { useRef } from "react";
// import Dropdown from "react-dropdown";
// import "react-dropdown/style.css";
import "./BreadcrumbDropdown.css";

function BreadcrumbDropdown({
  breadcrumbDropdownInfo,
  isDropdownOpen,
  useOutsideAlerter,
  showDropdown,
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      {isDropdownOpen && showDropdown && (
        <div className="breadcrumb-dropdown__outer">
          <ul ref={wrapperRef} className="breadcrumb-dropdown__wrapper">
            {breadcrumbDropdownInfo?.map((item, index) => {
              return (
                <li
                  className="breadcrumb-dropdown__item"
                  key={`breadcrumb-dropdown__item-${index}`}
                >
                  <a href={item.url}
                    className="breadcrumb-dropdown__link category-small"
                  >
                    {item.linkName}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export default BreadcrumbDropdown;
