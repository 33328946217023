import React from "react";
import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
// import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import Error from  '../components/Error/Error'
import MetaTag from '../components/MetaTag/MetaTag';
import { useEffect } from "react";
import { googleAnalytics } from "../helpers";

function ErrorPage() {
    const renderMeta = () => {
        const title = 'Halaman Tidak Ditemukan | JalanTikus';
        const ogTitle = title;
        const props = {title, ogTitle};
        return <MetaTag {...props} />
    }
    useEffect(() => {
        // send analytics
        googleAnalytics('Halaman Tidak Ditemukan | JalanTikus')
        // eslint-disable-next-line
    },[])
    return(
        <main className="container-common__wrapper">
            {renderMeta()}
            {/* Left vertical banner */}
            <div className="container-common__vertical-banner">
                <VerticalBanner />
            </div>

            <div className="container-common">
                <Error code="404"/>
            </div>

            {/* Right vertical banner */}
            <div className="container-common__vertical-banner">
                <VerticalBanner />
            </div>
        </main>
    )
}

export default ErrorPage