import React from "react"
import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import { useEffect } from "react";
import { googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody } from '../helpers'
import HandlerExternalLink from "../components/common/HandlerExternalLink/HandlerExternalLink";
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import { breadcrumbDropdownMenuArticle } from "../constants";
import MetaTag from '../components/MetaTag/MetaTag';
import AppsFAQ from '../components/FAQ/FAQ'
import AppsSection from "../components/AppsSection/AppsSection";
import { useDispatch, useSelector } from "react-redux";
import { getBestGamesThunk } from "../store/ducks/downloadPageReducer";
import { getOtherDataThunk } from "../store/ducks/homepageReducer";
import EditorsPick from "../components/EditorsPick/EditorsPick";
import { useLocation } from "react-router-dom";

const bestAPKGamesDescription = "Download APK terbaik secara gratis untuk kebutuhanmu sehari-hari di bawah ini. Kecepatan downloadnya ciamik dan aman dari virus, lo!";

function HandlerPage() {
    const dispatch = useDispatch();

    const bestGamesWindows = useSelector(
        (state) => state.downloadPageReducer.bestGamesWindows
    );
    const bestGamesAndroid = useSelector(
        (state) => state.downloadPageReducer.bestGamesAndroid
    );
    const populerData = useSelector((state) => state.homepageReducer.other);

    const gamesBestGamesWindows =
        bestGamesWindows &&
        bestGamesWindows.code === 200 &&
        bestGamesWindows.response.appList.hits;
    const gamesBestGamesAndroid =
        bestGamesAndroid &&
        bestGamesAndroid.code === 200 &&
        bestGamesAndroid.response.appList.hits;

    useEffect(() => {
        const getData = () => {
            dispatch(getBestGamesThunk('android'));
            dispatch(getBestGamesThunk('windows'));
            // dispatch(getPopulerDataThunk());
            dispatch(getOtherDataThunk());
        };
        getData();
        googleAnalytics()
        loadAds()
        // eslint-disable-next-line
    }, [])

    const loadAds = () => {
        const isMobile = window.innerWidth <= 768 ? true : false;
        const $currpage = document.getElementById(`handlerPage`);

        var _scriptTagHead = document.createElement("script");
        _scriptTagHead.async = true;
        _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

        let slots = []
        if (isMobile) {
            // available slot in this page
            slots = [{
                slot: "JT_320x100", // define slot
                id: "adSlotMobTop-category",
                wrapperId: "JT_320x100",
                defaultSize: "320x100"
            }, {
                slot: "JT_320x100",
                id: "adSlotMob_above_download_link",
                wrapperId: "JT_320x100_above_download_link",
                defaultSize: "320x100"
            }, {
                slot: "JT_320x200",
                id: "adSlotMob_above_apk_games",
                wrapperId: "JT_320x200_above_apk_games",
                defaultSize: "320x200"
            }, {
                slot: "JT_320x100", // define slot
                id: "adSlotMobSticky-category",
                wrapperId: "JT_320x100-sticky-bottom",
                defaultSize: "320x100"
            },{
                slot: "JT_320x100_top", // adunit path
                id: "JT_320x100_top_inner", // whatever
                wrapperId: "JT_320x100_top",
                defaultSize: "320x100"
              }]
        } else {
            // available slot in this page
            slots = [{
                slot: "JT_970x90/JT_970x90_top",
                id: "adSlotDesktop0",
                wrapperId: "JT_970x90_top",
                defaultSize: "970x90"
            }, {
                slot: "JT_970x90/JT_970x90_above_download_link",
                id: "adSlotDesktop1",
                wrapperId: "JT_970x90_above_download_link",
                defaultSize: "970x90"
            }, {
                slot: "JT_970x90/JT_970x90_above_apk_games",
                id: "adSlotDesktop2",
                wrapperId: "JT_970x90_above_apk_games",
                defaultSize: "970x90"
            }, {
                slot: "JT_970x90/JT_970x90_below_apk_games",
                id: "adSlotDesktop3",
                wrapperId: "JT_970x90_below_apk_games",
                defaultSize: "970x90"
            }]
        }
        // create ad unit tag head
        for (let i = 0; i < slots.length; i++) {
            _scriptTagHead.text += generateAdUnitTagHead(slots[i])
        }
        $currpage && $currpage.appendChild(_scriptTagHead)

        // create ad unit tag body
        for (let i = 0; i < slots.length; i++) {
            generateAdUnitTagBody(slots[i])
        }
    }

    let winLocation = useLocation()

    const renderMeta = () => {
        const title = 'Halaman handler download aplikasi';
        const ogTitle = title;
        const description = 'Halaman ini akan membantumu mengundurh aplikasi atau terbaru pilihanmu'
        const robots = "noindex, nofollow"
        const amphtmlUrl = 'amphandler' + winLocation.pathname + winLocation.search
        const props = { title, ogTitle, description, robots, amphtmlUrl }
        return <MetaTag {...props} />
    }

    return (
        <main className="container-common__wrapper" id="handlerPage">
            {renderMeta()}

            {/* Left vertical banner */}
            <div className="container-common__vertical-banner">
                <VerticalBanner />
            </div>

            <div className="container-common">
                <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{ minHeight: '66px' }} />
                <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{ minHeight: '66px' }} />
                <div className="video-category__breadcrumbs">
                    <Breadcrumbs
                        categName={`Handler`}
                        categSlug={`Handler`}
                        className="article-top-info__breadcrumbs"
                        breadcrumbDropdownInfo={breadcrumbDropdownMenuArticle}
                        hasDropdown
                    />
                </div>
                <AdWrapper id={`JT_970x90_above_download_link`} cls={`desktop`} customStyle={{ minHeight: '66px' }} />
                <AdWrapper id={`JT_320x100_above_download_link`} cls={`mobile`} customStyle={{ minHeight: '66px' }} />
                <HandlerExternalLink />
                <AdWrapper id={`JT_970x90_above_apk_games`} cls={`desktop`} customStyle={{ minHeight: '66px' }} />
                <AdWrapper id={`JT_320x200_above_apk_games`} cls={`mobile`} customStyle={{ minHeight: '66px' }} />
                {gamesBestGamesWindows && gamesBestGamesAndroid && (
                    <AppsSection
                        tab
                        description={bestAPKGamesDescription}
                        innerHtmlDesc
                        windowApps={gamesBestGamesWindows.slice(0, 6)}
                        androidApps={gamesBestGamesAndroid.slice(0, 6)}
                        title="Best APK & Games"
                        seeAllUrl="/games/category/"
                        type="nft"
                    ></AppsSection>
                )}
                <AdWrapper id={`JT_970x90_below_apk_games`} cls={`desktop`} customStyle={{ minHeight: '66px' }} />
                <hr />
                {populerData && (
                    <EditorsPick
                        articles={
                            populerData &&
                            populerData.response.populerHariIni.slice(0, 6)
                        }
                    />
                )}
                <hr />
                <AppsFAQ />
                <DesktopHorizontalBanner />
            </div>

            {/* Right vertical banner */}
            <div className="container-common__vertical-banner">
                <VerticalBanner />
            </div>
            <MobileStickyBottom />
        </main>
    )
}

export default HandlerPage
