import React from "react";
import "./BannerMobile.css";
// import bannerMobileRed from "../../../assets/images/banners/banner-mobile-red.svg";
import { AdSlot } from 'react-dfp';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function BannerMobile() {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768 ? true : false 

  return (
    <>
    {isMobile &&
      <div className="banner-mobile__wrapper" style={{minWidth:'168px',minHeight: '28px'}}>
        <AdSlot 
          dfpNetworkId="21702367101" 
          adUnit="JT_320x100" 
          sizes={[[234, 60], [300, 50], [320, 100], [216, 54], [468, 60], [168, 28], [300, 75], [300, 100], [220, 90], [320, 50], [168, 42], [216, 36], 'fluid']}
          targetingArguments={{ jt_ads: ['revamp_test'] }}
        />
      </div>
    }
    </>
  );
}

export default BannerMobile;
