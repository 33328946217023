import React from 'react';
import PostInfo from "../common/PostInfo/PostInfo";
// import { Link } from "react-router-dom";
import 'lazysizes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { onErrorSrcImg } from '../../helpers';

function CategoryBlockRight({
  title,
  thumbnail_url,
  url,
  date,
  categoryUrl,
  categoryName,
  hasThirdPartyServiceUrl,
  videoDetailPageLink
}) {
  return (
    <>
      <div className="category-block-right">
        {hasThirdPartyServiceUrl ? (
          <a href={videoDetailPageLink} className="category-block-right__img-wrapper">
            <img
              className="category-block-right__img lazyload"
              data-src={thumbnail_url}
              alt={title}
            />
          </a>
        ) : (
          <a href={`/${url}/`}
            className="category-block-right__img-wrapper"
          >
            {/* <img
              className="category-block-right__img lazyload"
              data-src={thumbnail_url}
              alt={title}
            /> */}
            <LazyLoadImage
              onError={onErrorSrcImg} // try different version
              alt={title}
              src={thumbnail_url} 
              className="category-block-right__img"
            />
          </a>
        )}

        <div className="category-block-right__info">
          <p className="category-block-right__text">
            {hasThirdPartyServiceUrl ? (
              <a href={`${videoDetailPageLink}`} className="category-block-right__link">
                {title}
              </a>
            ) : (
              <a href={`/${url}/`} className="category-block-right__link">
                {title}
              </a>
            )}
          </p>
          <PostInfo
            categoryName={categoryName}
            categoryUrl={categoryUrl}
            date={date}
            thirdPartyResource={hasThirdPartyServiceUrl}
            subTitleText=""
          />
        </div>
      </div>
    </>
  );
}

export default CategoryBlockRight;
