import React from "react";
import Slider from "react-slick";
import "./PostsSlider.css";
// import sliderImg from "@assets/images/test-slider-img.png";
// import PostInfoWithBreadcrumb from "@components/common/PostInfoWithBreadcrumb/PostInfoWithBreadcrumb";
import PostsSliderOneSlide from "../PostsSlider/PostsSliderOneSlide";
// import { assetsUrl } from "../../../../constants";

function PostsSlider({ slideNumber, sliderData, arrow = true, page, videoItemsCategory }) {
  var settings = {
    arrows: arrow,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slideNumber || 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };
  // ("sliderData in most read", sliderData);
  return (
    <div className="posts-slider_wrapper">
      <Slider {...settings}>
        {
        // eslint-disable-next-line
        sliderData !== null && sliderData != [] && sliderData !== undefined && Object.values(sliderData).map((item, index) => (
          <PostsSliderOneSlide
            videoItemsCategory={videoItemsCategory}
            page={page}
            key={index}
            article={item}
            title={item.title}
            date={item.published || item.created_time * 1000}
          />
        ))}
      </Slider>
    </div>
  );
}

export default PostsSlider;
