import React from "react";
import "./LazyLoader.css";

const LazyLoader = (props) => (
  <div className="lazy-loader__wrapper">
    <div className="skeleton-item">
      <div className="skeleton-box skeleton-square"></div>
      <div className="skeleton-rows">
        <p className="skeleton-box skeleton-row"></p>
        <p className="skeleton-box skeleton-row"></p>
        <p className="skeleton-box skeleton-row"></p>
      </div>
    </div>
  </div>
);

export default LazyLoader;
