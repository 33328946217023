import React from "react";
import "./PostInfo.css";
import Moment from "react-moment";
import "moment/locale/id";

function PostInfo({ categoryName, categoryUrl, date, thirdPartyResource,absoluteDate, writer, authorLink }) {
  // sanitize incoming url
  const filteredCategoryUrl = categoryUrl?.slice(-1) === '/' ? categoryUrl.slice(0,-1) : categoryUrl
  return (
    <>
      <div className="post-info__wrapper publish-time-text">
        {thirdPartyResource ? (
          <a href={categoryUrl} style={{ color: '#ce1818', cursor: 'pointer', fontSize: '14px', textTransform: 'capitalize'}} className="post-info__category-link" target="_blank" rel="noreferrer">
            {categoryName}
          </a>
        ) : (
          <a href={writer ? "https://jalantikus.com/author"+authorLink : `/${filteredCategoryUrl}/`} style={{ color: '#ce1818', cursor: 'pointer', fontSize: '14px', textTransform: 'capitalize'}} className="post-info__category-link">
            {categoryName}
          </a>
        )}

        <p className="post-info__publish-date" style={{ color: '#636c77', marginLeft: '20px',position: 'relative', fontSize: '14px' }}>

          {absoluteDate ? (
            <>
            <Moment locale="id" format="dddd, D MMM YYYY, HH:mm">
              {date}
            </Moment> WIB
            </>
          ) : (
            <Moment locale="id" fromNow>
              {date}
            </Moment>
          )}
        </p>
      </div>
    </>
  );
}

export default PostInfo;
