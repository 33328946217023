import React from "react";
import "./Footer.css";
import Logo from "../common/Logo/Logo";
import FooterSocialIcons from "../Footer/FooterSocialIcons/FooterSocialIcons";
import { footerLinks } from "../../constants";

var curYear = new Date().getFullYear();

function Footer() {
  return (
    <>
      <footer>
        <div className="footer-container">
          {/* One footer column */}
          <div className="footer-column footer-column__logo">
            <Logo cname="footer-logo" />
            <p className="footer-column__text caption-text">
              Solusi Lengkap Download Aplikasi dan Games, Informasi Teknologi,
              Lifestyle dan Gadget
            </p>
          </div>

          {/* One footer column */}
          <div className="footer-column footer-column__redaksi">
            <p className="footer-column__title title-small">
              Redaksi JalanTikus
            </p>
            {/* <div className="footer-column__socials-text">
              <p>or</p>
              <p>
              <a
              className="footer-column__link"
              href="mailto:info@jalantikus.com"
              >
              info@jalantikus@.com
              </a>
              </p>
            </div> */}
            <p className="footer-column__text caption-text">Gandaria 8 Office Tower</p>
            <p className="footer-column__text caption-text">Jl. Arteri Pd. Indah No. 10,</p>
            <p className="footer-column__text caption-text">Kec. Kby. Lama, Jakarta Selatan,</p>
            <p className="footer-column__text caption-text">DKI Jakarta, 12240</p>
            <p className="footer-column__text caption-text">Lantai 22, Unit C</p>
            <p className="footer-column__text caption-text">
              <a
                className="footer-column__link"
                href="mailto:info@jalantikus.com"
              >
                info@jalantikus.com
              </a>
            </p>
            <div className="footer-column__socials">
              <FooterSocialIcons />
            </div>
          </div>

          {/* One footer column */}
          <div className="footer-column footer-column__nav">
            <p className="footer-column__title title-small">
              Tentang Jalantikus
            </p>
            <ul>
              {footerLinks[0].map((item, index) => {
                return (
                  <li key={index} className="footer-column__link-wrapper">
                    <a
                      className="footer-column__link caption-text"
                      href={item.url}
                    >
                      {item.linkName}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* One footer column */}
          <div className="footer-column footer-column__nav">
            <p className="footer-column__title title-small">
              Kategori Unggulan
            </p>
            <ul>
              {footerLinks[1].map((item, index) => {
                return (
                  <li key={index} className="footer-column__link-wrapper">
                    <a
                      className="footer-column__link caption-text"
                      href={item.url}
                    >
                      {item.linkName}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="footer-copyright__mob sub-title">
            {curYear} © JalanTikus.com All right reserved.
          </div>
        </div>
        {/* end of footer-container */}
        <div>
          <div className="footer-copyright__desk sub-title">
            {curYear} © JalanTikus.com All right reserved.
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
