import React from "react";
import "./Pedoman.css";
import StaticSidebar from "@components/Static/Sidebar/StaticSidebar";

function Pedoman() {
   return (
      <>
         <div className="pedoman-section__container">
            <div className="static-sidebar">
               <StaticSidebar cls="show-desktop has-title" id="" active="pedoman" />
            </div>
            <div className="static-content">
               <div className="title">
                  <h1>PEDOMAN MEDIA SIBER</h1>
                  <span>Kemerdekaan berpendapat, kemerdekaan berekspresi, dan kemerdekaan pers adalah hak asasi manusia yang dilindungi Pancasila, Undang-Undang Dasar 1945, dan Deklarasi Universal Hak Asasi Manusia PBB. Keberadaan media siber di Indonesia juga merupakan bagian dari kemerdekaan berpendapat, kemerdekaan berekspresi, dan kemerdekaan pers.</span>
                  <span>Media siber memiliki karakter khusus sehingga memerlukan pedoman agar pengelolaannya dapat dilaksanakan secara profesional, memenuhi fungsi, hak, dan kewajibannya sesuai Undang-Undang Nomor 40 Tahun 1999 tentang Pers dan Kode Etik Jurnalistik. Untuk itu Dewan Pers bersama organisasi pers, pengelola media siber, dan masyarakat menyusun Pedoman Pemberitaan Media Siber sebagai berikut:</span>
               </div>
               <div className="content">
                  <h3 className="color black">1. Ruang Lingkup</h3>
                  <ul>
                     <li>
                        Isi Buatan Pengguna (User Generated Content) adalah segala isi yang dibuat dan atau dipublikasikan oleh pengguna media siber, antara lain, artikel, gambar, komentar, suara, video dan berbagai bentuk unggahan yang melekat pada media siber, seperti blog, forum, komentar pembaca atau pemirsa, dan bentuk lain.
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black">
                     2. Verifikasi dan Keberimbangan Berita
                  </h3>
                  <ul>
                     <li>
                        Pada prinsipnya setiap berita harus melalui verifikasi.
                     </li>
                     <li>
                        Berita yang dapat merugikan pihak lain memerlukan verifikasi pada berita yang sama untuk memenuhi prinsip akurasi dan keberimbangan.
                     </li>
                     <li>
                        Ketentuan dalam butir (1) di atas dikecualikan, dengan syarat:
                        <ul>
                           <li className="sub-list">
                              Berita benar-benar mengandung kepentingan publik yang bersifat mendesak;
                           </li>
                           <li className="sub-list">
                              Sumber berita yang pertama adalah sumber yang jelas disebutkan identitasnya, kredibel dan kompeten;
                           </li>
                           <li className="sub-list">
                              Subyek berita yang harus dikonfirmasi tidak diketahui keberadaannya dan atau tidak dapat diwawancarai;
                           </li>
                           <li className="sub-list">
                              Media memberikan penjelasan kepada pembaca bahwa berita tersebut masih memerlukan verifikasi lebih lanjut yang diupayakan dalam waktu secepatnya. Penjelasan dimuat pada bagian akhir dari berita yang sama, di dalam kurung dan menggunakan huruf miring.
                           </li>
                        </ul>
                     </li>
                     <li>
                        Setelah memuat berita sesuai dengan butir (3), media wajib meneruskan upaya verifikasi, dan setelah verifikasi didapatkan, hasil verifikasi dicantumkan pada berita pemutakhiran (update) dengan tautan pada berita yang belum terverifikasi.
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black">3. Isi Buatan Pengguna (User Generated Content)</h3>
                  <ul>
                     <li>
                        Media siber wajib mencantumkan syarat dan ketentuan mengenai Isi Buatan Pengguna yang tidak bertentangan dengan Undang-Undang No. 40 tahun 1999 tentang Pers dan Kode Etik Jurnalistik, yang ditempatkan secara terang dan jelas.
                     </li>
                     <li>
                        Media siber mewajibkan setiap pengguna untuk melakukan registrasi keanggotaan dan melakukan proses log-in terlebih dahulu untuk dapat mempublikasikan semua bentuk Isi Buatan Pengguna. Ketentuan mengenai log-in akan diatur lebih lanjut.
                     </li>
                     <li>
                        Dalam registrasi tersebut, media siber mewajibkan pengguna memberi persetujuan tertulis bahwa Isi Buatan Pengguna yang dipublikasikan:
                        <ul>
                           <li className="sub-list">
                              Tidak memuat isi bohong, fitnah, sadis dan cabul;
                           </li>
                           <li className="sub-list">
                              Tidak memuat isi yang mengandung prasangka dan kebencian terkait dengan suku, agama, ras, dan antargolongan (SARA), serta menganjurkan tindakan kekerasan;
                           </li>
                           <li className="sub-list">
                              Tidak memuat isi diskriminatif atas dasar perbedaan jenis kelamin dan bahasa, serta tidak merendahkan martabat orang lemah, miskin, sakit, cacat jiwa, atau cacat jasmani.
                           </li>
                        </ul>
                     </li>
                     <li>
                        Media siber memiliki kewenangan mutlak untuk mengedit atau menghapus Isi Buatan Pengguna yang bertentangan dengan butir (3).
                     </li>
                     <li>
                        Media siber wajib menyediakan mekanisme pengaduan Isi Buatan Pengguna yang dinilai melanggar ketentuan pada butir (3). Mekanisme tersebut harus disediakan di tempat yang dengan mudah dapat diakses pengguna.
                     </li>
                     <li>
                        Media siber wajib menyunting, menghapus, dan melakukan tindakan koreksi setiap Isi Buatan Pengguna yang dilaporkan dan melanggar ketentuan butir (3), sesegera mungkin secara proporsional selambat-lambatnya 2 x 24 jam setelah pengaduan diterima.
                     </li>
                     <li>
                        Media siber yang telah memenuhi ketentuan pada butir (1), (2), (3), dan (6) tidak dibebani tanggung jawab atas masalah yang ditimbulkan akibat pemuatan isi yang melanggar ketentuan pada butir (3).
                     </li>
                     <li>
                        Media siber bertanggung jawab atas Isi Buatan Pengguna yang dilaporkan bila tidak mengambil tindakan koreksi setelah batas waktu sebagaimana tersebut pada butir (6).
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black">4. Ralat, Koreksi, dan Hak Jawab</h3>
                  <ul>
                     <li>
                        Ralat, koreksi, dan hak jawab mengacu pada Undang-Undang Pers, Kode Etik Jurnalistik, dan Pedoman Hak Jawab yang ditetapkan Dewan Pers.
                     </li>
                     <li>
                        Ralat, koreksi dan atau hak jawab wajib ditautkan pada berita yang diralat, dikoreksi atau yang diberi hak jawab.
                     </li>
                     <li>
                        Di setiap berita ralat, koreksi, dan hak jawab wajib dicantumkan waktu pemuatan ralat, koreksi, dan atau hak jawab tersebut.
                     </li>
                     <li>
                        Bila suatu berita media siber tertentu disebarluaskan media siber lain, maka:
                        <ul>
                           <li className="sub-list">
                              Tanggung jawab media siber pembuat berita terbatas pada berita yang dipublikasikan di media siber tersebut atau media siber yang berada di bawah otoritas teknisnya;
                           </li>
                           <li className="sub-list">
                              Koreksi berita yang dilakukan oleh sebuah media siber, juga harus dilakukan oleh media siber lain yang mengutip berita dari media siber yang dikoreksi itu;
                           </li>
                           <li className="sub-list">
                              Media yang menyebarluaskan berita dari sebuah media siber dan tidak melakukan koreksi atas berita sesuai yang dilakukan oleh media siber pemilik dan atau pembuat berita tersebut, bertanggung jawab penuh atas semua akibat hukum dari berita yang tidak dikoreksinya itu.
                           </li>
                        </ul>
                     </li>
                     <li>
                        Sesuai dengan Undang-Undang Pers, media siber yang tidak melayani hak jawab dapat dijatuhi sanksi hukum pidana denda paling banyak Rp500.000.000 (Lima ratus juta rupiah).
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black">5. Pencabutan Berita</h3>
                  <ul>
                     <li>
                        Berita yang sudah dipublikasikan tidak dapat dicabut karena alasan penyensoran dari pihak luar redaksi, kecuali terkait masalah SARA, kesusilaan, masa depan anak, pengalaman traumatik korban atau berdasarkan pertimbangan khusus lain yang ditetapkan Dewan Pers.
                     </li>
                     <li>
                        Media siber lain wajib mengikuti pencabutan kutipan berita dari media asal yang telah dicabut.
                     </li>
                     <li>
                        Pencabutan berita wajib disertai dengan alasan pencabutan dan diumumkan kepada publik.
                     </li>
                  </ul>

                  <br />
                  <h3 className="color black">6. Iklan</h3>
                  <ul>
                     <li>
                        Beberapa pengiklan di situs Jalantikus.com mungkin menggunakan cookie dan web beacon. Setiap mitra periklanan Jalantikus.com memiliki Kebijakan Privasi sendiri terkait data pengguna.
                     </li>
                     <li>
                        Setiap berita/artikel/isi yang merupakan iklan dan atau isi berbayar wajib mencantumkan keterangan ”advertorial”, ”iklan”, ”ads”, ”sponsored”, atau kata lain yang menjelaskan bahwa berita/artikel/isi tersebut adalah iklan.
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black">7. Hak Cipta</h3>
                  <p>Media siber wajib menghormati hak cipta sebagaimana diatur dalam peraturan perundang-undangan yang berlaku.</p>
                  <br />
                  <h3 className="color black">
                     8. Pencantuman Pedoman
                  </h3>
                  <p>
                     Media siber wajib mencantumkan Pedoman Pemberitaan Media Siber ini di medianya secara terang dan jelas.
                  </p>
                  <h3 className="color black">
                     9. Sengketa
                  </h3>
                  <p>
                     Penilaian akhir atas sengketa mengenai pelaksanaan Pedoman Pemberitaan Media Siber ini diselesaikan oleh Dewan Pers.
                  </p>

                  <br/>
                  <p>
                  Jakarta, 3 Februari 2012
                  </p>
                  <p>
                  (Pedoman ini ditandatangani oleh Dewan Pers dan komunitas pers di Jakarta, 3 Februari 2012).
                  </p>
                  <p>
                  Sumber: https://dewanpers.or.id/
                  </p>
               </div>
            </div>
         </div>
      </>
   );
}

export default Pedoman;