import React from "react";
import "./SubTitle.css";

function SubTitle({ subTitleText, isLowercase, isWhite }) {
  return (
    <>
      <div className="sub-title__wrapper">
      <span className="icon-icon-double-arrow"></span>
        <h2
          className={`sub-title__text title-large 
            ${isLowercase ? "lowercase" : null}
            ${isWhite ? "whiteText" : null}
          `}
        >
          {subTitleText}
        </h2>
      </div>
    </>
  );
}

export default SubTitle;
