import React from "react";
import './AppsItem.css'
import { imgSrc, onErrorSrc} from "../../../helpers";
import { placeholder } from '../../../constants/links'

function AppsItem({app, type}){
    const url = app ? app.links && app.links.detail && `/${app.links.detail}/` : `#`;
    const img = (app && app.image) ? imgSrc(app.image,40,40) : placeholder;
    const alt = (app && app.title) ? app.title : "Default Image";
    const company = (app && app.company && app.company.title);
    const companyUrl = app ? app.company && app.company.links && app.company.links.detail : `#`;
    const title =  (app && app.title)
    return(
        <>
            <div className={type === "nft" ? "apps-item-nft apps-item " : "apps-item"}>
                <div className="apps-item__img-wrapper">
                    <picture>
                        {(app && app.image) &&
                        <source
                        type="image/webp" 
                        srcSet={img + ".webp"}
                        data-srcset={img + ".webp"}
                        />  
                        }
                        <img
                            onError={onErrorSrc}
                            src= {img} 
                            data-src={img}
                            className=""
                            alt={alt}  
                        />
                    </picture>
                </div>
                <div className="apps-item__info-wrapper">
                    <a href={url} className="apps-item__name">
                        {title}
                    </a>
                    <a href={companyUrl} className="apps-item__category">
                        {company}
                    </a>
                </div>
                <a href={url} className="btn-download-apps">
                    Download
                </a>
            </div> 
        </>
    )
}

export default AppsItem