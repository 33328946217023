import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import AppsFAQ from '../components/FAQ/FAQ'
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import DownloadStatusBox from "../components/common/DownloadStatusBox/DownloadStatusBox";
import { useParams,useLocation, Redirect } from "react-router-dom";
import { breadcrumbDropdownMenuDownload } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import Error from  '../components/Error/Error';

import {
    getDetailIdVersionThunk,
} from "../store/ducks/downloadDetailPageReducer";
import {googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody} from '../helpers'
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';
import MetaTag from '../components/MetaTag/MetaTag';

function DownloadHandlerPage(){
    const dispatch = useDispatch();

    const location = useLocation();
    const pathname = location.pathname;
    const pathArray = pathname.split("/");
    const type = pathArray[1];
    const params = useParams();
    
    const detailIdVersion = useSelector((state) => state.downloadDetailPageReducer.detailIdVersion)
    useEffect(() => {
        dispatch(getDetailIdVersionThunk(type,params.slug, params.version))
        googleAnalytics()
        loadAds()
        // eslint-disable-next-line
    }, [params]);
    
    const appData = detailIdVersion && detailIdVersion.code === 200 && detailIdVersion.response

    const loadAds = () => {
        const isMobile = window.innerWidth <= 768 ? true : false;
        const $currpage = document.getElementById(`downloadHandlerPage`);
    
        var _scriptTagHead = document.createElement("script");
        _scriptTagHead.async = true;
        _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`
        
        let slots = []
        if(isMobile){
            // available slot in this page
            slots = [{
                slot: "JT_320x100", // define slot
                id: "adSlotMobTop-category",
                wrapperId: "JT_320x100",
                defaultSize: "320x100"
            },{
                slot: "JT_320x100", // define slot
                id: "adSlotMobSticky-category",
                wrapperId: "JT_320x100-sticky-bottom",
                defaultSize: "320x100"
            }]
        }else{
            // available slot in this page
            slots = [{
                slot: "JT_970x90/JT_970x90_top",
                id: "adSlotDesktop0",
                wrapperId: "JT_970x90_top",
                defaultSize: "970x90"
            }]
        }
        // create ad unit tag head
        for (let i = 0; i < slots.length; i++) {
            _scriptTagHead.text += generateAdUnitTagHead(slots[i])
        }
        $currpage && $currpage.appendChild(_scriptTagHead)    
        
        // create ad unit tag body
        for (let i = 0; i < slots.length; i++) {
            generateAdUnitTagBody(slots[i])
        }
    }

    const renderMeta = () => {
        const title = 'Halaman Handler | JalanTikus';
        const ogTitle = title;
        const props = {title, ogTitle};
        return <MetaTag {...props} />
    }

    console.log("Type: " + type)
    console.log(detailIdVersion)
    // console.log("Title: " + appData.title)
    return(
        <main className="container-common__wrapper" id="downloadHandlerPage">
            {renderMeta()}
        
            {/* Left vertical banner */}
            <div className="container-common__vertical-banner">
                <VerticalBanner />
            </div>

            <div className="container-common">
                <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{minHeight:'100px'}} />
                <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{minHeight:'100px'}} />
                {detailIdVersion && 
                    (detailIdVersion.code === 200 && 
                        (detailIdVersion.response.code === 200 &&
                        <div className="video-category__breadcrumbs">
                            <Breadcrumbs
                                categName={type}
                                articleSlug={appData && appData.title}
                                categSlug={`${type}/category`}
                                className="article-top-info__breadcrumbs"
                                hasDropdown
                                breadcrumbDropdownInfo={breadcrumbDropdownMenuDownload}
                            />
                        </div>
                    )
                )
                }
                {detailIdVersion && (
                    detailIdVersion.code !== 200 ? 
                    detailIdVersion.code === 301 ? <Redirect to={`/${detailIdVersion.response.redirect}`} /> :  
                    <div className="container-common">
                        <Error code={detailIdVersion.code ? detailIdVersion.code : '503'} />
                    </div>
                    : <DownloadStatusBox data={detailIdVersion} />)
                }
                <DesktopHorizontalBanner />
                <AppsFAQ />
            </div>

            {/* Right vertical banner */}
            <div className="container-common__vertical-banner">
                <VerticalBanner />
            </div>
            <MobileStickyBottom />
        </main>
    )
}

export default DownloadHandlerPage 