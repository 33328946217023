import React, { useEffect, useState } from "react";
import "./TextInputSearch.css";

import { useParams } from "react-router-dom";

function TextInputSearch({
  dataList,
  page,
  placeholder,
  onKeyDownCallback,
  suggestionList,
  onClickSuggestion,
  onClickSearchIcon,
  outerInputData = "",
}) {
  const [inputData, setInputData] = useState(outerInputData);
  const [showSuggestion, setShowSuggestion] = useState(false);

  useEffect(() => {
    if (outerInputData) {
      setInputData(outerInputData);
    }
    // eslint-disable-next-line
  }, [outerInputData]);

  const inputHandler = (e) => {
    setInputData(e.target.value);
    if (!e.target.value) {
      return;
    }
  };
  const params = useParams();
  // const onBlurHandler = () => {
  //   setShowSuggestion(false);
  // };
  const onFocusHandler = () => {
    setShowSuggestion(true);
  };

  useEffect(() => {
    if (page === "gadget") {
      setInputData("");
    }
    // eslint-disable-next-line
  }, [params]);

  return (
    <>
      <div className="filter-wrapper">
        <div className="filter-wrapper__inner">
          <input
            onChange={inputHandler}
            value={inputData}
            type="text"
            name=""
            id=""
            className="filter-input"
            placeholder={placeholder}
            onKeyUp={onKeyDownCallback}
            // onBlur={onBlurHandler}
            onFocus={onFocusHandler}
          />
          <p
            className="input__search-icon"
            onClick={(e) => onClickSearchIcon(inputData)}
          >
            <span className="icon-icon-zoom"></span>
          </p>
        </div>
        {suggestionList && suggestionList.code === 200 && showSuggestion && (
          <ul className={`suggestion-wrapper`}>
            {suggestionList.response.autocomplete.map((item, index) => {
              return (
                <li key={`suggestion-item-${index}`}>
                  <div
                    className="suggestion-item"
                    onClick={() => onClickSuggestion(item.query, item.url)}
                    dangerouslySetInnerHTML={{ __html: item.highlight }}
                  ></div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
}

export default TextInputSearch;
