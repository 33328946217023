import React from "react";
import { imgVehicleSrc, imgSrc } from "../../../helpers";
import './EVItem.css';

function EVItem({ item }) {
  return (
    <div className="tab-content-item__wrapper ev-item__wrapper">
      <a href={`/${item?.links?.detail}`}>
        <div className="ev-item-img__wrapper">
          <picture>
            <source
              type="image/webp" data-srcset={imgVehicleSrc(item.image, 0, 200)}
              srcSet={imgVehicleSrc(item.image, 0, 200)}
            />
            <img
              src={imgSrc(item.image, 0, 200)}
              alt={item.title}
              data-src={imgSrc(item.image, 0, 200)}
            />
          </picture>
        </div>
        <div className="ev-item-title sub-title">
          <div>{item?.company?.title}</div>
          <div>{item?.model}</div>
        </div>
      </a>
    </div>
  )
}

export default EVItem