import React from "react";
import './AppsTopInfo.css'
import { imgSrc} from "../../helpers";
import { placeholder } from '../../constants/links'
import ShareWithFriends from '../common/ShareWithFriends/ShareWithFriends'
import Rating from '../common/Rating/Rating'

function AppsTopInfo({app}){
    const title = app && app.title
    const img = (app && app.image) ? imgSrc(app.image,40,40) : placeholder;
    const summary = (app && app.summary)

    const getSummaryDetail = () => {
        if (app) {
            const content = app.content;
            return content;
        }
        return false;
    }

    const platform = app && app.platform.title
    // const platform_id = app && app.platform.id
    const company = app && app.company.title
    const category = app && app.category.title
    const version = app && app.version.title
    const released = app && app.published_locale
    const license = app && app.license.title
    
    // const downloadUrl = app ? app.links.download && `/${app.links.download}` : `#`;
    // let ironsource=  true;
    let dlUri = '/#';
    let dlTarget = "_self";
    if (app){
        // if(app.id === "54") ironsource=  false;
        // if ((app.links.external_url || app.version.links.external_url) !== ""){
        //     dlUri = `/${app.category.slug}/handler/${app.id}/${app.version.id}/`
        //     dlTarget = "_blank";
        // } else {
        //     dlUri = "/"+ app.version.links.download || app.links.download
        //     dlUri = dlUri.slice(-1) !== "/" ? dlUri + "/" : dlUri;
        //     if (!ironsource) {
        //       dlUri += "?no-downloader";
        //     } else {
        //       if (parseInt(platform_id) > 3) {
        //         dlUri += "?no-downloader";
        //       } else {
        //         dlUri += "";
        //       }
        //     }
        //   }
        // check new version for download apps
        if (app.other_version[0]) {
            const other_version = app.other_version[0]
            dlUri = `/handler/download/?u=${other_version?.links?.external_url ? other_version.links.external_url : ''}`;
        } else {
            dlUri = `/handler/download/?u=${app.version?.links?.external_url ? app.version.links.external_url : ''}`;
        }
        dlTarget = "_blank";
    }

    const websiteUrl = app ? app.company.links.detail ? app.company.links.detail : `#` : `#`;
    const links = app && app.links.detail
    const rating = app && app.rating.score
    return(
        <div className="apps-top-info__wrapper">
            <div className="apps-flex-item info">
                <div className="apps-info__wrapper">
                    <div className="apps-icon__wrapper">
                        <picture>
                            {(app && app.image) &&
                            <source
                                type="image/webp" 
                                srcSet={img + ".webp"}
                                data-srcset={img + ".webp"}
                            />  
                            }
                            <img 
                                data-src={img} 
                                width="48" 
                                height="48" 
                                alt="" 
                                src={img}
                            />
                        </picture>
                    </div>
                    <div className="apps-name__wrapper">
                        <h1 className="apps-name__title">
                            {title} <span className="apps-payment-type">({license})</span>
                        </h1>
                        <div className="apps-name__editor-rating">
                            Editor’s Rating 
                            <Rating rating={rating} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="apps-flex-item summary">
                <div className="apps-summary__wrapper">
                    <div className="apps-summary" >
                        {summary}
                    </div>
                    <div className="apps-summary-detail" dangerouslySetInnerHTML={{ __html: getSummaryDetail()}}>
                    </div>
                </div>

                <div className="apps-spec__wrapper">
                    <div className="apps-spec__item">
                        <div className="apps-spec__type">
                            OS
                        </div>
                        <div className="apps-spec__name">
                            {platform}
                        </div>
                    </div>
                    <div className="apps-spec__item">
                        <div className="apps-spec__type">
                            Developer
                        </div>
                        <div className="apps-spec__name">
                            {company}
                        </div>
                    </div>
                    <div className="apps-spec__item">
                        <div className="apps-spec__type">
                            Category
                        </div>
                        <div className="apps-spec__name">
                            {category}
                        </div>
                    </div>
                    <div className="apps-spec__item">
                        <div className="apps-spec__type">
                            Version
                        </div>
                        <div className="apps-spec__name">
                            {version}
                        </div>
                    </div>
                    <div className="apps-spec__item">
                        <div className="apps-spec__type">
                            Release
                        </div>
                        <div className="apps-spec__name">
                            {released}
                        </div>
                    </div>
                    <div className="apps-spec__item">
                        <div className="apps-spec__type">
                            License
                        </div>
                        <div className="apps-spec__name">
                            {license}
                        </div>
                    </div>
                </div>
            </div>

            <div className="apps-flex-item action">
                <div className="apps-actions__wrapper">
                    <a href={dlUri} target={dlTarget} className="btn-download-apps-red">
                        Download
                    </a>
                    <a href={websiteUrl} className="btn-grey">
                        Official Website
                    </a>
                </div>
                
                <ShareWithFriends link={links} title={title}/>
            </div>
        </div>
    )
}

export default AppsTopInfo