import React from "react";
import { useLocation, Redirect } from 'react-router-dom'
import '../assets/css/adsFormPage.css'
import Logo from "@components/common/Logo/LogoWhite";
import adsBgMask from '../assets/images/adsform-top-bg.jpg'
import adsBgSec2 from '../assets/images/adsform-sec2-bg.jpg'
import adsBgSec3 from '../assets/images/adsform-sec3-bg.jpg'
import adsBgSec6 from '../assets/images/adsform-sec6-bg.png'
import adsBgSec2Big from '../assets/images/adsform-sec2-bg-big.jpg'
import adsBgSec3Big from '../assets/images/adsform-sec3-bg-big.jpg'
import adsBgSec6Big from '../assets/images/adsform-sec6-bg-big.png'
import adsWeb1 from '../assets/images/ads-web-1.gif'
import adsWeb2 from '../assets/images/ads-web-2.gif'
import adsWeb3 from '../assets/images/ads-web-3.gif'
import adsWeb4 from '../assets/images/ads-web-4.gif'
import adsMobile1 from '../assets/images/ads-mobile-1.gif'
import adsMobile2 from '../assets/images/ads-mobile-2.gif'
import partnershipBrand from '../assets/images/partnership-brand.png'
import backgroundPartnershipRed from '../assets/images/background-partnership-red.png'
import samsung from '../assets/images/brand/samsung.png'
import asus from '../assets/images/brand/asus.png'
import lenovo from '../assets/images/brand/lenovo.png'

import { PartnershipPageService } from "../http"
import MetaTag from '../components/MetaTag/MetaTag';
import { useEffect } from "react";
import { googleAnalytics } from "../helpers";
import axios from "axios";
import qs from "querystring";

import ReCAPTCHA from 'react-google-recaptcha';

function AdsFormPage() {
    var captchaToken = "";
    const [webCount, setWebCount] = React.useState(0);

    function verifyCaptcha(value) {
        captchaToken = value;
    }

    const isValidForm = () => {
        if (captchaToken.length === 0) {
            alert("Wront Captcha!");
            return false;
        } else {
            const $name = document.getElementById("name");
            const $nameError = document.getElementById("name-error");
            const $email = document.getElementById("email");
            const $emailError = document.getElementById("email-error");
            const $company = document.getElementById("company");
            const $companyError = document.getElementById("company-error");
            const $mobile = document.getElementById("mobile");
            const $mobileError = document.getElementById("mobile-error");
            const $category = document.getElementById("category");
            const $categoryError = document.getElementById("category-error");
            const $campaign = document.getElementById("campaign");
            const $campaignError = document.getElementById("campaign-error");
            const $brief = document.getElementById("brief");
            const $briefError = document.getElementById("brief-error");
            const name = $name.value;
            const email = $email.value;
            const company = $company.value;
            const mobile = $mobile.value;
            const category = $category.value;
            const campaign = $campaign.value;
            const brief = $brief.value;

            let isError = false;

            if (name.trim() === "") {
                $nameError.style.display = "block";
                isError = true;
            } else $nameError.style.display = "none";

            if (email.trim() === "") {
                $emailError.innerHTML = "<strong> Email harus diisi </strong>";
                $emailError.style.display = "block";
                isError = true;
            } else {
                if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)) {
                    $emailError.innerHTML = "<strong> Format email tidak valid </strong>";
                    $emailError.style.display = "block";
                    isError = true;
                } else $emailError.style.display = "none";
            }

            if (company.trim() === "") {
                $companyError.style.display = "block";
                isError = true;
            } else $companyError.style.display = "none";
            if (mobile.trim() === "") {
                $mobileError.style.display = "block";
                isError = true;
            } else $mobileError.style.display = "none";
            if (category.trim() === "") {
                $categoryError.style.display = "block";
                isError = true;
            } else $categoryError.style.display = "none";
            if (campaign.trim() === "") {
                $campaignError.style.display = "block";
                isError = true;
            } else $campaignError.style.display = "none";
            if (brief.trim() === "") {
                $briefError.style.display = "block";
                isError = true;
            } else $briefError.style.display = "none";

            return (!isError);
        }
    }

    const submitForm = async (e) => {
        e.preventDefault()

        if (isValidForm()) {
            const $name = document.getElementById("name");
            const $email = document.getElementById("email");
            const $company = document.getElementById("company");
            const $mobile = document.getElementById("mobile");
            const $category = document.getElementById("category");
            const $categoryOther = document.getElementById("category-form");
            const $categoryLabel = document.getElementById("category-label");
            const $campaign = document.getElementById("campaign");
            const $brief = document.getElementById("brief");
            const name = $name.value;
            const email = $email.value;
            const company = $company.value;
            const mobile = $mobile.value;
            const category = $category.value;
            const categoryOther = $categoryOther.value;
            const campaign = $campaign.value;
            const brief = $brief.value;
            if (category === "Others") {
                var dataGF = qs.stringify({
                    'entry.1165796065': name,
                    'entry.1012646603': company,
                    'entry.765178851': email,
                    'entry.1525074477': mobile,
                    'entry.541835707': "__other_option__",
                    'entry.35718994': campaign,
                    'entry.1051008138': brief,
                    'entry.541835707.other_option_response': categoryOther
                });
            } else {
                 // eslint-disable-next-line
                var dataGF = qs.stringify({
                    'entry.1165796065': name,
                    'entry.1012646603': company,
                    'entry.765178851': email,
                    'entry.1525074477': mobile,
                    'entry.541835707': category,
                    'entry.35718994': campaign,
                    'entry.1051008138': brief
                });
            }
            $name.value = '';
            $name.disabled = true;
            $email.value = '';
            $email.disabled = true;
            $company.value = '';
            $company.disabled = true;
            $mobile.value = '';
            $mobile.disabled = true;
            $category.value = '';
            $category.disabled = true;
            $campaign.value = '';
            $campaign.disabled = true;
            $brief.value = '';
            $brief.disabled = true;

            var configGF = {
                method: 'post',
                url: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfOVf8aUdbI8J5UcU3IDGKBHDi8NexKihbgIsaKB4katPbmDw/formResponse',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: dataGF
            };

            try {
                await axios(configGF).then(function (response) {
                    alert("Permintaan anda telah terkirim! Tim kami akan menghubungi Anda secepatnya!")
                }).catch(function (error) {
                    //Alert in this error because Google Form keep rejecting because of CORS policy, but the form data sent to Google successfully!
                    alert("Permintaan anda telah terkirim! Tim kami akan menghubungi Anda secepatnya!");
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }

            $name.disabled = false;
            $email.disabled = false;
            $company.disabled = false;
            $mobile.disabled = false;
            $category.disabled = false;
            $category.style.marginBottom = '58px';
            $categoryOther.disabled = false;
            $categoryOther.style.display = 'none';
            $categoryLabel.style.display = 'none';
            $campaign.disabled = false;
            $brief.disabled = false;
        }
    }
    const location = useLocation();
    const currentPathName = location.pathname;

    useEffect(() => {
        googleAnalytics('Halaman Form | JalanTikus')
        // eslint-disable-next-line
    }, [])

    if (currentPathName.substr(-1) !== "/") {
        return <Redirect to={`${currentPathName}/`} />
    }

    const renderMeta = () => {
        const title = 'Form Page | JalanTikus';
        const ogTitle = title;
        const description = 'Do you wish to see your products become more renowned and advanced in sales? JalanTikus offers you an advertising partnership to attain maximum result.';
        const url = currentPathName.slice(1);
        const props = { title, description, url, ogTitle };
        return <MetaTag {...props} />
    }

    const brands = [
        { icon: samsung, testimoni: '“JalanTikus help our product to reach more GenZ audience”' },
        { icon: asus, testimoni: '“JalanTikus help our product to reach more GenZ audience”' },
        // {icon: huawei},
        { icon: lenovo, testimoni: '“JalanTikus help our product to reach more GenZ audience”' },
        // {icon: evercross},
        // {icon: meizu},
        // {icon: vivo},
        // {icon: advan},
    ]

    const categoryOptions = [
        { value: 'Lifestyle / Entertainment', text: 'Lifestyle/Entertainment' },
        { value: 'Apps / Gadgets', text: 'Apps/Gadgets' },
        { value: 'Technology / Hacks', text: 'Technology/Hacks' },
        { value: 'Gaming / E-Sports', text: 'Gaming/E-Sports' },
        { value: 'Web3', text: 'Web3' },
        { value: 'Finance', text: 'Finance' },
        { value: 'Others', text: 'Others' },
    ];
    const handleChange = event => {
        var catForm = document.getElementById('category-form');
        var catSelect = document.getElementById('category');
        var catLabel = document.getElementById('category-label');
        if (event.target.value === 'Others') {
            catForm.style.display = 'inline';
            catSelect.style.marginBottom = '14px';
            catLabel.style.display = 'block';
        } else {
            catForm.style.display = 'none';
            catSelect.style.marginBottom = '58px';
            catLabel.style.display = 'none';
        }
    };

    const webBanner = [
        <div className="ads-slide-body">
            <div className="ads-slide-banner">
                <img src={adsWeb1} alt="" />
            </div>
            <div className="ads-slide-text">
                <strong>Banner Ads</strong>
                Promoting your brands or products on the header and the body of our website. Get a chance to gathering thousands of clicks with million impressions. Available in billboard, leaderboard, rectangle and skyscraper for desktop and mobile
            </div>
        </div>,
        <div className="ads-slide-body">
            <div className="ads-slide-banner">
                <img src={adsWeb2} alt="" />
            </div>
            <div className="ads-slide-text">
                <strong>Banner Ads</strong>
                Promoting your brands or products on the header and the body of our website. Get a chance to gathering thousands of clicks with million impressions. Available in billboard, leaderboard, rectangle and skyscraper for desktop and mobile
            </div>
        </div>,
        <div className="ads-slide-body">
            <div className="ads-slide-banner">
                <img src={adsWeb3} alt="" />
            </div>
            <div className="ads-slide-text">
                <strong>Banner Ads</strong>
                Promoting your brands or products on the header and the body of our website. Get a chance to gathering thousands of clicks with million impressions. Available in billboard, leaderboard, rectangle and skyscraper for desktop and mobile
            </div>
        </div>,
        <div className="ads-slide-body">
            <div className="ads-slide-banner">
                <img src={adsWeb4} alt="" />
            </div>
            <div className="ads-slide-text">
                <strong>Banner Ads</strong>
                Promoting your brands or products on the header and the body of our website. Get a chance to gathering thousands of clicks with million impressions. Available in billboard, leaderboard, rectangle and skyscraper for desktop and mobile
            </div>
        </div>,
        <div className="ads-slide-body">
        <div>
            <img className="ads-slide-banner-mobile" src={adsMobile1} alt="" />
        </div>
        <div className="ads-slide-text">
            <strong>Banner Ads</strong>
            Promoting your brands or products on the header and the body of our website. Get a chance to gathering thousands of clicks with million impressions. Available in billboard, leaderboard, rectangle and skyscraper for desktop and mobile
        </div>
    </div>,
    <div className="ads-slide-body">
        <div>
            <img className="ads-slide-banner-mobile" src={adsMobile2} alt="" />
        </div>
        <div className="ads-slide-text">
            <strong>Banner Ads</strong>
            Promoting your brands or products on the header and the body of our website. Get a chance to gathering thousands of clicks with million impressions. Available in billboard, leaderboard, rectangle and skyscraper for desktop and mobile
        </div>
    </div>
    ];

    const submitForm2 = async (e) => {
        e.preventDefault()
        if (isValidForm()) {
            const $type = document.getElementById("type");
            const $name = document.getElementById("name-bottom");
            const $email = document.getElementById("email-bottom");
            const $content = document.getElementById("content-bottom");
            const $subject = document.getElementById("subject-bottom");
            const type = $type.value;
            const name = $name.value;
            const email = $email.value;
            const content = $content.value;
            const subject = $subject.value;

            var bodyFormData = new FormData()
            bodyFormData.append('type', type);
            bodyFormData.append('name', name);
            bodyFormData.append('email', email);
            bodyFormData.append('content', content);
            bodyFormData.append('subject', subject);
            $name.value = '';
            $name.disabled = true;
            $email.value = '';
            $email.disabled = true;
            $content.value = '';
            $content.disabled = true;
            $subject.value = '';
            $subject.disabled = true;

            try {
                const response = await PartnershipPageService.sendForm(bodyFormData);
                if (response.status === 200) {
                    alert('Pesan anda telah dikirim, kami akan segera membalas pesan anda.')
                }
            } catch (error) {
                // console.log(error);
            }

            $name.disabled = false;
            $email.disabled = false;
            $content.disabled = false;
            $subject.disabled = false;
        }
    }

    return (
        <main>
            <div className="adsform-top">
                {renderMeta()}
                <div className="adsform-image">
                    <div className="adsform-image__wrapper desktop">
                        <img src={adsBgMask} alt="" />
                    </div>
                </div>
                <div className="ads-container-full-width description">
                    <h1 id="ads-h1">Form Page</h1>
                    <div className="row">
                        <div className="ads-section-one__wrapper col-sm-6 mx-auto">
                            <div className="adsform-logo__wrapper">
                                <Logo />
                            </div>
                            <div className="adsform-subtitle">
                                The Best Informative Website to Leverage Your Brand Campaign
                            </div>
                        </div>
                        <div className="ads-section-two__wrapper col-sm-6 mx-auto">
                            <div className="form-card-header text-center align-middle d-flex align-items-center">
                                <span className="card-header-text">Fill out this form and our team will contact you for your advertising needs.</span>
                            </div>
                            <div className="form-card-body">
                                <form className="adsform-form__wrapper">
                                    <div className="adsform-input__wrapper">
                                        <input type="text" name="name" id="name" className="ads-jt-form" required="required" />
                                        <label for="name">Name</label>
                                        <div className="ads-input-info-error" id="name-error" style={{ display: 'none' }}><strong>Nama harus diisi</strong></div>
                                    </div>
                                    <div className="adsform-input__wrapper">
                                        <input type="text" name="company" id="company" className="ads-jt-form" required="required" />
                                        <label for="company">Company</label>
                                        <div className="ads-input-info-error" id="company-error" style={{ display: 'none' }}><strong>Company harus diisi</strong></div>
                                    </div>
                                    <div className="adsform-input__wrapper">
                                        <input type="text" name="email" id="email" className="ads-jt-form" required="required" />
                                        <label for="email">Email</label>
                                        <div className="ads-input-info-error" id="email-error" style={{ display: 'none' }}><strong>Email harus diisi</strong></div>
                                    </div>
                                    <div className="adsform-input__wrapper">
                                        <input type="number" name="mobile" id="mobile" className="ads-jt-form" required="required" />
                                        <label for="mobile">Mobile Number</label>
                                        <div className="ads-input-info-error" id="mobile-error" style={{ display: 'none' }}><strong>Nomor HP harus diisi</strong></div>
                                    </div>
                                    <div className="adsform-input__wrapper">
                                        <select name="category" id="category" required="required" className="ads-jt-form"
                                            onChange={handleChange}>
                                            <option value="" disabled selected> Product Category</option>
                                            {categoryOptions.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))}
                                        </select>
                                        <input type="text" name="category" id="category-form" style={{ display: 'none' }} className="ads-jt-form" />
                                        <label for="category" id="category-label" style={{ display: 'none' }}>Please input other category</label>
                                        <div className="ads-input-info-error" id="category-error" style={{ display: 'none' }}><strong>Category harus diisi</strong></div>
                                    </div>
                                    <div className="adsform-input__wrapper">
                                        <select name="campaign" id="campaign" required="required" className="ads-jt-form">
                                            <option className="select-placeholder" value="" disabled selected>Campaign Type</option>
                                            <option value="Advertorial">Advertorial</option>
                                            <option value="Banner Ads">Banner Ads</option>
                                            <option value="Social Media">Social Media</option>
                                            <option value="KOL/Creators">KOL/Creators</option>
                                            <option value="Events">Events</option>
                                        </select>
                                        <div className="ads-input-info-error" id="campaign-error" style={{ display: 'none' }}><strong>Tipe campaign harus diisi</strong></div>
                                    </div>
                                    <div className="adsform-input__wrapper">
                                        <input type="text" name="brief" id="brief" className="ads-jt-form" required="required" />
                                        <label for="brief">Brief Description</label>
                                        <div className="ads-input-info-error" id="brief-error" style={{ display: 'none' }}><strong>Penjelasan iklan harus diisi</strong></div>
                                    </div>
                                    <p class="ads-tnc">By submitting this form, you confirm that you agree to the storing and processing of your personal data by JalanTikus as described in the Privacy Statement.</p>
                                    <div className="parntership-input__wrapper">
                                        <ReCAPTCHA
                                            sitekey="6Le7964iAAAAAKZdNywPKSPNXLQF09AMj6lydosn"
                                            onChange={verifyCaptcha}
                                        />
                                        <button id="ads-submit" className="ads-btn-send-msg" onClick={(e) => submitForm(e)}>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="adsform-section2">
                <div className="adsform-image-section2">
                    <div className="adsform-image__wrapper desktop">
                        <img src={adsBgSec2} alt="" />
                    </div>
                    <div className="adsform-image__wrapper bigger">
                        <img src={adsBgSec2Big} alt="" />
                    </div>
                </div>
                <div className="ads-container-full-width services">
                    <div className="text-center">
                        <h2 className="ads-title-sec2">Our Services</h2>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-sm-4 mb-2">
                            <div className="service-bg">
                                <div className="service-title">
                                    Advertorial
                                </div>
                                <div className="service-list">
                                    <p>Craft and publish your SEO friendly articles with us in a form of press release, review, tutorial, recommendation, as well as live report</p>
                                    <li className="ads-list-text">
                                        Press Release
                                    </li>
                                    <li className="ads-list-text">
                                        Guest Posting
                                    </li>
                                    <div className="ads-list-title">
                                        <strong className="ads-list-title">Advertorial Article</strong>
                                    </div>
                                    <li className="ads-list-text">
                                        Native Article
                                    </li>
                                    <li className="ads-list-text">
                                        Featured Article
                                    </li>
                                    <li className="ads-list-text">
                                        Additional Listicle Content
                                    </li>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 mb-2">
                            <div className="service-bg">
                                <div className="service-title">
                                    Banner Ads
                                </div>
                                <div className="service-list">
                                    <div className="ads-list-title">
                                        <strong>Desktop</strong>
                                    </div>
                                    <li className="ads-list-text">
                                        Billboard (970x250)
                                    </li>
                                    <li className="ads-list-text">
                                        Leaderboard (728x90)
                                    </li>
                                    <li className="ads-list-text">
                                        Skyscrapper (300x600)
                                    </li>
                                    <li className="ads-list-text">
                                        Rectangle (300x250)
                                    </li>
                                    <div className="ads-list-title">
                                        <strong className="ads-list-title">Mobile</strong>
                                    </div>
                                    <li className="ads-list-text">
                                        Leaderboard (320x100)
                                    </li>
                                    <li className="ads-list-text">
                                        Rectangle (300x250)
                                    </li>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 mb-2">
                            <div className="service-bg">
                                <div className="service-title">
                                    Social Media
                                </div>
                                <div className="service-list">
                                    <div className="ads-list-title">
                                        <strong>Instagram</strong>
                                    </div>
                                    <li className="ads-list-text">
                                        Post
                                    </li>
                                    <li className="ads-list-text">
                                        Reels
                                    </li>
                                    <li className="ads-list-text">
                                        Instastory
                                    </li>
                                    <li className="ads-list-text">
                                        IGTV
                                    </li>
                                    <div className="ads-list-title">
                                        <strong className="ads-list-title">Facebook</strong>
                                    </div>
                                    <li className="ads-list-text">
                                        Post
                                    </li>
                                    <li className="ads-list-text">
                                        Video
                                    </li>
                                    <div className="ads-list-title">
                                        <strong className="ads-list-title">YouTube</strong><br />
                                    </div>
                                    <li className="ads-list-text">
                                        Pre-roll & Bumper in/out up to 3 mins
                                    </li>
                                    <li className="ads-list-text">
                                        Shorts 1 mins
                                    </li>
                                    <li className="ads-list-text">
                                        Integrated video
                                    </li>
                                    <li className="ads-list-text">
                                        Dedicated video
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-center">
                        <button className="ads-btn-rate">Request Rate Card</button>
                    </div> */}
                </div>
            </div>
            <div className="adsform-section3">
                <div className="adsform-image">
                    <div className="adsform-image__wrapper desktop">
                        <img src={adsBgSec3} alt="" />
                    </div>
                    <div className="adsform-image__wrapper bigger">
                        <img src={adsBgSec3Big} alt="" />
                    </div>
                </div>
                <div className="ads-container-full-width format">
                    <div className="text-center">
                        <h2 className="ads-title-sec2">Ads Format</h2>
                    </div>
                    <div className="ads-slide__wrapper" id="web-ads" style={{ display: 'block' }}>
                        <div className="ads-sub-title">
                            Banner Leaderboard
                        </div>
                        <div className="ads-slide-body__wrapper">
                            <div className="ads-slide-icon-left">
                                {webCount > 0 && <button className="ads-btn-pagination" onClick={() => setWebCount(webCount - 1)}><span class="icon-icon-arrow-left"></span></button>}
                            </div>
                            {webBanner[webCount]}
                            <div className="ads-slide-icon-right">
                                {webCount < webBanner.length - 1 && <button className="ads-btn-pagination" onClick={() => setWebCount(webCount + 1)}><span class="icon-icon-arrow-sm"></span></button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="adsform-red-bg" id="container-full-width-statistic">
                <div className="adsform-bg-img desktop">
                    <img src={backgroundPartnershipRed} alt="" width="100%" height="100%" />
                </div>
                <div className="ads-container-full-width statistic" >
                    <div className="ads-big-title text-center">
                        Why Advertise with US?
                    </div>
                    <div className="ads-statistic__container">
                        <div className="ads-statistic-first-col">
                            <div className="ads-statistic__wrapper">
                                <div className="statistic-title">
                                    Avg. Pageviews/Month
                                </div>
                                <div className="ads-statistic-number">
                                    15M
                                </div>
                            </div>
                            <div className="ads-statistic__wrapper">
                                <div className="statistic-title">
                                    Daily Active Users
                                </div>
                                <div className="ads-statistic-number">
                                    500K
                                </div>
                            </div>
                            <div className="ads-statistic__wrapper">
                                <div className="statistic-title">
                                    Monthly Active Users
                                </div>
                                <div className="ads-statistic-number">
                                    10M
                                </div>
                            </div>
                            <div className="ads-statistic__wrapper">
                                <div className="statistic-title">
                                    Active Users/Minute
                                </div>
                                <div className="ads-statistic-number">
                                    3.2K
                                </div>
                            </div>
                        </div>
                        <div className="ads-statistic-second-col">
                            <div className="ads-statistic__wrapper">
                                <div className="statistic-title">
                                    Avg. Pageviews/Month
                                </div>
                                <div className="ads-statistic-number">
                                    15M
                                </div>
                            </div>
                            <div className="ads-statistic__wrapper">
                                <div className="statistic-title">
                                    Daily Active Users
                                </div>
                                <div className="ads-statistic-number">
                                    500K
                                </div>
                            </div>
                            <div className="ads-statistic__wrapper">
                                <div className="statistic-title">
                                    Monthly Active Users
                                </div>
                                <div className="ads-statistic-number">
                                    10M
                                </div>
                            </div>
                            <div className="ads-statistic__wrapper">
                                <div className="statistic-title">
                                    Active Users/Minute
                                </div>
                                <div className="ads-statistic-number">
                                    3.2K
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ads-container-full-width brand">
                <div className="adsform-brand__image-wrapper">
                    <img src={partnershipBrand} alt="" />
                </div>
                <div className="brand-description__container">
                    <div className="brand-section__title">
                        What Our Clients Say
                    </div>
                    <div className="brand-section__subtitle">
                        We believe our works speak for themselves. Browse our most recent projects below and enjoy our handmade done with love for every detail.
                    </div>
                    <div className="brand__wrapper">
                        {brands.map(brand => (
                            <div className="ads-brand-sub">
                                <a className="brand__icon" href="/#" key={brand.icon}>
                                    <img src={brand.icon} alt="" />
                                </a>
                                <p className="ads-brand-testimoni">{brand.testimoni}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="adsform-section6">
                <div className="adsform-image-section6">
                    <div className="adsform-image__wrapper desktop">
                        <img src={adsBgSec6} alt="" />
                    </div>
                    <div className="adsform-image__wrapper bigger">
                        <img src={adsBgSec6Big} alt="" />
                    </div>
                </div>
                <div className="ads-container-full-width work-together">
                    <div className="work-together__wrapper">
                        <div className="work-together__item">
                            <div>
                                <h2 className="ads-work-together__title">More Questions?</h2>
                            </div>
                            <div className="work-together__subtitle">
                                Let’s find out how we can work together to create something meaningful and valuable<br />
                                or <br />
                                <strong>Email us at</strong><br />
                                <a href="mailto:sales@jalantikus.com" className="ads-work-together__email-wrapper">
                                    sales@jalantikus.com
                                </a>
                            </div>
                            <div className="work-together__social">
                                <a className="ads-work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-social-fb white"></span>
                                </a>
                                <a className="ads-work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-instagram white"></span>
                                </a>
                                <a className="ads-work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-youtube white"></span>
                                </a>
                                <a className="ads-work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-twitter white"></span>
                                </a>
                                <a className="ads-work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-social-email white"></span>
                                </a>
                            </div>
                        </div>
                        <div className="work-together__item">
                            <form className="adsform-form__wrapper" id="formadsform">
                                <div className="adsform-input__wrapper">
                                    <label className="ads-jt-form-label">
                                        YOUR NAME
                                    </label>
                                    <input type="hidden" name="type" id="type" value="1" />
                                    <input type="text" name="name-bottom" id="name-bottom" className="ads-jt-form" required="required" />
                                    <div className="ads-input-info-error" id="name-bottom-error" style={{ display: 'none' }}><strong>Nama harus diisi</strong></div>
                                </div>
                                <div className="adsform-input__wrapper">
                                    <label className="ads-jt-form-label">
                                        YOUR EMAIL
                                    </label>
                                    <input type="email" name="email-bottom" id="email-bottom" className="ads-jt-form" />
                                    <div className="ads-input-info-error" id="email-bottom-error" style={{ display: 'none' }}><strong>Email harus diisi</strong></div>
                                </div>
                                <div className="adsform-input__wrapper">
                                    <label className="ads-jt-form-label">
                                        YOUR SUBJECT
                                    </label>
                                    <input type="text" name="subject-bottom" className="ads-jt-form" />
                                </div>
                                <div className="adsform-input__wrapper">
                                    <label className="ads-jt-form-label">
                                        YOUR MESSAGE
                                    </label>
                                    <input type="text" name="content-bottom" id="content-bottom" className="ads-jt-form" />
                                    <div className="ads-input-info-error" id="content-bottom-error" style={{ display: 'none' }}><strong>Pesan harus diisi</strong></div>
                                </div>
                                <div className="ads-input__wrapper">
                                    <button className="ads-btn-send-msg-bottom" onClick={(e) => submitForm2(e)}>
                                        SEND MESSAGE
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div id="initGlia" style={{marginTop:'300px'}}></div>
        </main>
    )
}

export default AdsFormPage;