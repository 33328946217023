import { GameItemPageService } from "../../http";

/* ACTION TYPES */
const GET_APP_DATA = "GET_APP_DATA";

/*ACTION CREATORS*/
export const getAppData = (payload) => ({
  type: GET_APP_DATA,
  payload: payload,
});

/*THUNK*/
export const getAppsByCategoryThunk = (category, platform) => async (dispatch) => {
  try {
    const response = await GameItemPageService.getAppsByCategory(category, platform);
    dispatch(getAppData(response.data));
  } catch (error) {
    dispatch(getAppData(error));
  }
}

export const getGameItemPageDataThunk = () => async (dispatch) => {
  try {
    await dispatch(getAppsByCategoryThunk('video-audio', 'windows'));
  } catch (error) {
  }
};
/*INITIAL STATE*/
const initialState = {
  gameItemData: null
};

/*REDUCER*/
function gameItemPageReducer(state = initialState, action) {
  switch (action.type) {
    /*Get data for category page*/
      case GET_APP_DATA:
        return {
          ...state,
          gameItemData: action.payload,
        };
    default:
      return state;
  }
}

export default gameItemPageReducer;
