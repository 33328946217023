import React from 'react';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import FollowUs from "@components/FollowUs/FollowUs";
import Error from "../components/Error/Error";
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import { getAuthorDataThunk } from "../store/ducks/authorPageReducer";
import RecentPosts from "../components/RecentPosts/RecentPosts";
import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import SidebarBanner from "../components/Banners/SidebarBanner/SidebarBanner";
import { getOtherDataThunk } from "../store/ducks/homepageReducer";
import PopularToday from "@components/PopularToday/PopularToday";
import { useState } from "react";
import { AuthorPageService } from "../http/AuthorPageService";
import InfiniteScroll from "react-infinite-scroller";
import PostBlockWithBreadcrumb from "../components/PostBlockWithBreadcrumb/PostBlockWithBreadcrumb";
import MobileBetweenPosts from "../components/Banners/MobileBetweenPosts/MobileBetweenPosts";
import DeskstopBetweenPosts from "../components/Banners/DesktopBetweenPosts/DesktopBetweenPosts";
import '../assets/css/authorPage.css'
import { assetsUrl } from "../constants";
import { generateURL, imgSrc } from "../helpers";
import MetaTag from "../components/MetaTag/MetaTag";

let page = 2;
let isBusy = false;
const maxAds = 3;
const limit = 10;
const halfSize = Math.round(limit/2);
function AuthorPage() {
	const dispatch = useDispatch();
	const {userName} = useParams();
	const authorData = useSelector(
		(state) => state.authorPageReducer.authorData
	)
  const otherData = useSelector(
    (state) => state.homepageReducer.other
  );

  // handling infinite scroll
  const [cacheArray, setCacheArray] = useState([]);
  const [infiniteArray, setInfiniteArray] = useState([]);
	useEffect(() => {
    dispatch(getOtherDataThunk())
		dispatch(getAuthorDataThunk(userName));
    
	}, [dispatch, userName])

	const renderMeta = (authorData) => {
    const url = authorData.response.links.profile.replace('user', 'author');
    const authorName = authorData.response.name
    const title = `Profil ${authorName}, Penulis dan jurnalis JalanTikus.com`
    const description = authorData.response.profile;
    const image=imgSrc(authorData.response && authorData.response.profile_picture, 769, 330)
    const ogTitle = title;
    const robots = 'index, follow, max-image-preview:large, max-snippet:-1'
    const schemaContent = [{
      "@context": "https://schema.org",
      "@type":"Person",
      "@id": generateURL(url),
      "@url": generateURL(url),
      "name": authorName,
      "image": {
        "@type": "ImageObject",
        "@id": image,
        "@url": image,
        "caption": authorName + ", Penulis dan Jurnalist JalanTikus"
      },
      "jobTitle": "Writer",
      "worksFor": {
        "@type": "Organization",
        "@url": "https://jalantikus.com/",
        "name": "JalanTikus",
        "Facebook": "https://www.facebook.com/JalanTikus",
        "Instagram": "https://instagram.com/jalantikusid/",
        "YouTube": "https://www.youtube.com/user/JalanTikusOfficial",
        "Twitter": "https://twitter.com/jalantikuscom",
        "logo": "https://assets.jalantikus.com/assets/cache/0/0/userfiles/2015/04/20/jtlogo.png",
      }
    }]
    const props = {url, title, description, image, robots, ogTitle, schemaContent};
    return <MetaTag {...props}/>
	}

  const addMorePost = async () => {
    try {
      if (cacheArray.length) {
        setInfiniteArray((prev) => [...prev, ...cacheArray])
        setCacheArray([])
        return;
      }
      let response = await AuthorPageService.getData(userName, page);
      let hits = response.data.response.article_list.hits
      if (hits) {
        // split half to cache
        setInfiniteArray((prev) => [...prev, ...hits.splice(0, halfSize)])
        setCacheArray(hits.splice(halfSize));
      }
      page++
    } catch (e) {
      console.log(e)
    }
  }

  const throttle = () => {
    if (isBusy) {
      return;
    }
    isBusy = true;
    setTimeout(async () => {
      await addMorePost();
      isBusy = false;
    }, 100);
  }

	return (
		<>
			<main className="main-container" id="authorpage">
				{ authorData && authorData.code === 200 && renderMeta(authorData)}
				{ authorData && authorData.code !== 200 ? (
					<div className="container-common">
						<Error code={'404'}/>
					</div>
				): (
					<>
						<div className="container-full-width">
              <div className="author-page__breadcrumbs">
                <Breadcrumbs 
                  categName={'Reporters'}
                  categSlug={'/#'}
                  className="article-top-info__breadcrumbs"
                  hasSecondCategory={true}
                  secondCategoryName={authorData?.response?.name ?? ''}
                  secondCategoryLink={'/#'}
                />
              </div>
            </div>
            <div className="container-divided" >
              <section className="container-divided_section container-divided__author-section" style={{marginBottom: "10px"}}>
                <div id="author-profile">
                  <div
                    style={{backgroundImage:
                      `url("${assetsUrl}${authorData?.response?.profile_picture?.base_url}200/200/${authorData?.response?.profile_picture?.file}")`}}
                    className="author-page__profile-picture"></div>
                  <div><span style={{fontWeight: "bold", fontSize:"20px"}}>{authorData?.response?.name}</span></div>
                  <p style={{textAlign: "center", fontSize: "14px"}}>{authorData?.response?.profile}</p>
                  <div className="author-page__profile-link-container">
                    <a
                      className="author-page__profile-link"
                      href={`https://twitter.com/${authorData?.response.links.twitter}`}
                      target="_blank" rel='noreferrer'
                    >
                      <span className="icon-twitter author-page-icon"></span>
                      <span style={{color: "rgb(206, 24, 24)"}}>{authorData?.response.name}</span>
                    </a>
                    <a
                      className="author-page__profile-link"
                      href={`https://facebook.com/${authorData?.response.links.facebook}`}
                      target="_blank" rel='noreferrer'
                    >
                      <span className="icon-facebook author-page-icon"></span>
                      <span style={{color: "rgb(206, 24, 24)"}}>{authorData?.response.name}</span>
                    </a>
                    <a
                      className="author-page__profile-link"
                      href={`https://instagram.com/${authorData?.response.links.instagram}`}
                      target="_blank" rel='noreferrer'
                    >
                      <span className="icon-instagram author-page-icon"></span>
                      <span style={{color: "rgb(206, 24, 24)"}}>{authorData?.response.name}</span>
                    </a>
                  </div>
                </div>
              </section>
              <aside className="container-divided_aside">
                <FollowUs />
              </aside>
            </div>
            <div className="container-divided container-divided__author-page">
              <section className="container-divided_section">
                <div className="author-page__recent-posts">
                  {authorData && (
                    <RecentPosts
                      hasBreadcrumbs
                      containerData={authorData.response.article_list}
                      title={`Recent articles by ${authorData.response.name}`}
                    />
                  )}
                </div>
                <div className="author-page__horizontal-banner1">
                  <DesktopHorizontalBanner />
                </div>
              </section>
              <aside className="container-divided_aside">
                <SidebarBanner />
                <PopularToday
                  title="Artikel Terpopuler"
                  articles={
                    otherData && otherData.response.populerHariIni.slice(5, 10)
                  }/>
              </aside>
            </div>
            <div className="container-divided">
              <section className="container-divided_section">
                <InfiniteScroll
                  pageStart={0}
                  loadMore={throttle}
                  hasMore={true}
                  className="infinite-scroll-block"
                  id="infinite-scroll-block"
                >
                {infiniteArray.map((item, index) => {
                  if ((index + 1) % 10 === 0 && index !== 0) {
                    return (
                      <div key={index}>
                        <PostBlockWithBreadcrumb data={item} />
                        { ((index + 1) < maxAds * 10) && 
                          <>
                          <MobileBetweenPosts idName={`infinite-recent-${index}`} parentId={`infinite-scroll-block`} currentAdIndex={(index + 1) / 10 + 1}/>
                          <DeskstopBetweenPosts idName={`infinite-recent-${index}`} parentId={`infinite-scroll-block`} currentAdIndex={(index + 1) / 10 + 1}/>
                          </>
                        }
                      </div>
                    );
                  } else {
                    return <PostBlockWithBreadcrumb key={index} data={item} />;
                  }
                  })}
                </InfiniteScroll>
              </section>
            </div>
					</>
				)}
			</main>
		
		</>
	)
}

export default AuthorPage;