import { javantApi } from "../constants";

export const ElectricVehiclePageService = {
    getArticle(){
        return javantApi.get(`component/article/kendaraan-listrik`); 
    },
    // http://dev.api.jalantikus.com/v1/
    // getHomeData(){
    //     return javantApi.get(`component/vehicle/home/`);
    // },
    getDetailData(vehicleSlug){
        return javantApi.get(`content/vehicle/detail/${vehicleSlug}`);
    },
    getVehicleSearch(type, merk="", q=""){
        return javantApi.get(`component/vehicle/${type}?merek=${merk}&q=${q}&page=1`);
    },
    getSuggestionData(q="", item="", type="vehicles"){
        return javantApi.get(`search/suggest?q=${q}&item=${item}&type=${type}`);
    },

    getInfiniteVehicleData(type, page, keyword) {
        return javantApi.get(`component/vehicle/${type}?q=${keyword}&page=${page}`);
    },
    getInfiniteVehicleDataByMerk(type, merk, page){
        return javantApi.get(`component/vehicle/${type}?merek=${merk}&page=${page}`);
    }
}