import { DownloadPageService } from "../../http";

// ACTION TYPES
const GET_SELECTED_CATEGORY_WINDOWS = "GET_SELECTED_CATEGORY_WINDOWS"; 
const GET_SELECTED_CATEGORY_ANDROID = "GET_SELECTED_CATEGORY_ANDROID";
const GET_SELECTED_CATEGORY_META = "GET_SELECTED_CATEGORY_META";
const GET_INFINITE_DOWNLOAD_DATA = "GET_INFINITE_DOWNLOAD_DATA";
const CLEAR_SEARCH_DOWNLOAD_DATA = "CLEAR_SEARCH_DOWNLOAD_DATA";

// ACTION CREATORS
export const getSelectedCategoryWindows = (payload) => ({
  type: GET_SELECTED_CATEGORY_WINDOWS,
  payload: payload,
})
export const getSelectedCategoryAndroid = (payload) => ({
  type: GET_SELECTED_CATEGORY_ANDROID,
  payload: payload,
})
export const getSelectedCategoryMeta = (payload) => ({
  type: GET_SELECTED_CATEGORY_META,
  payload: payload,
})

export const getInfiniteDownloadData = (payload) => ({
  type: GET_INFINITE_DOWNLOAD_DATA,
  payload: payload,
});

export const clearSearchDownloadData = (payload) => ({
  type: CLEAR_SEARCH_DOWNLOAD_DATA,
  payload: payload,
});

// THUNK
export const getSelectedCategoryThunk = (type, category, platform, order, license) => async (dispatch) => {
  try {
    const response = await DownloadPageService.getDownloadByParam(type, category, platform, order, license);
    if(platform === "windows"){
      dispatch(getSelectedCategoryWindows(response.data));
    }else if(platform === "android"){
      dispatch(getSelectedCategoryAndroid(response.data));
    }
  }catch(error){
    // console.log(error)
    dispatch(getSelectedCategoryWindows(error));
  }
}

export const getSelectedCategoryMetaThunk = (type, category) => async (dispatch) => {
  try{
    const response = await DownloadPageService.getMetaByCategory(type, category);
    dispatch(getSelectedCategoryMeta(response.data));
  }catch(error){
    // console.log(error)
  }
}

export const getAllDownloadCategoryPageDataThunk = (params) => async (dispatch) => {
  try{
    const { type, category, order, license } = params;
    await dispatch(getSelectedCategoryThunk(type, category, 'windows', order, license));
    await dispatch(getSelectedCategoryThunk(type, category, 'android', order, license));
    await dispatch(getSelectedCategoryMetaThunk(type, category));
  } catch(error) {
    // console.log(error)
  }
}


// INITIAL STATE
const initialState = {
  selectedCategoryWindows: null,
  selectedCategoryAndroid: null,
  selectedCategoryMeta: null
}

// REDUCER
function downloadCategoryPageReducer(state = initialState, action){
  switch(action.type){
    case GET_SELECTED_CATEGORY_WINDOWS:
      return{
        ...state,
        selectedCategoryWindows: action.payload,
      }
    case GET_SELECTED_CATEGORY_ANDROID:
      return{
        ...state,
        selectedCategoryAndroid: action.payload,
      }
    case GET_SELECTED_CATEGORY_META:
      return{
        ...state,
        selectedCategoryMeta: action.payload,
      }
    default:
      return state;
  }
}

export default downloadCategoryPageReducer;