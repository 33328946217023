import React from "react";
import SubTitle from "@components/common/Titles/SubTitle";
import PostsSlider from "../../components/common/Sliders/PostsSlider/PostsSlider";
import "./MostReadPosts.css";

function MostReadPosts({
  articles,
  title,
  page,
  isLowercase,
  videoItemsCategory,
}) {
  const sliderData = articles;

  return (
    <>
      <div className="most-read-posts_wrapper">
        <SubTitle subTitleText={title} isLowercase={isLowercase} isWhite />
        <PostsSlider
          videoItemsCategory={videoItemsCategory}
          page={page}
          sliderData={sliderData}
        />
      </div>
    </>
  );
}

export default MostReadPosts;
