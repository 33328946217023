import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
// import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import Filter from "../components/common/Filter/Filter";
import AppsFAQ from '../components/FAQ/FAQ'
import { useParams } from "react-router-dom";
import AppsSection from '../components/AppsSection/AppsSection';
import { useLocation, useHistory, Redirect } from "react-router-dom";
import Error from  '../components/Error/Error';
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import { breadcrumbDropdownMenuDownload } from "../constants";
import {
    getAllDownloadCategoryPageDataThunk
} from "../store/ducks/downloadCategoryPageReducer";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { insertParam } from "../helpers";
import useSearch from "../hooks/useSearch";
import {googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody} from '../helpers'
import { getDownloadCategoryParams } from '../shared/params'
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';
import MetaTag from '../components/MetaTag/MetaTag';
function DownloadCategoryPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const parameter = new URLSearchParams(location.search);
    const pathname = location.pathname;
    const pathArray = pathname.split("/");
    const type = pathArray[1];
    const params = useParams();
    const history = useHistory();
    
    const [licenseSelectValue, setLicenseSelectValue] = useState("");
    const [popularitySelectValue, setPopularitySelectValue] = useState("");
    const [leftSelectedValue, setLeftSelectedValue] = useState("")
    const [rightSelectedValue, setRightSelectedValue] = useState("")
    const selectedCategoryWindows = useSelector((state) => state.downloadCategoryPageReducer.selectedCategoryWindows );
    const selectedCategoryAndroid = useSelector((state) => state.downloadCategoryPageReducer.selectedCategoryAndroid );
    const selectedCategoryMeta = useSelector((state) => state.downloadCategoryPageReducer.selectedCategoryMeta );
    useEffect(() => {
        const p = getDownloadCategoryParams(params, `${location.pathname}${location.search}`);
        const parameters = { ...p, type };
        
        dispatch(getAllDownloadCategoryPageDataThunk(parameters));
        if(parameter.get('order')) {
            setLeftSelectedValue('')
        } else {
            setLeftSelectedValue('default')
        }
        if(parameter.get('license')) {
            setRightSelectedValue('')
        } else {
            setRightSelectedValue('default')
        }
        // eslint-disable-next-line
    }, [params]);
    const appsSelectedCategoryWindows = selectedCategoryWindows?.response?.appList?.hits
    const appsSelectedCategoryAndroid = selectedCategoryAndroid?.response?.appList?.hits
    const menuData = selectedCategoryMeta?.response?.menu_data
    const popularitySelectHandler = (e) => {
        setPopularitySelectValue(e.value);
    };
    const licenseSelectHandler = (e) => {
        setLicenseSelectValue(e.value);
    };
    useEffect(() => {
        let params;
        params = insertParam("order", popularitySelectValue);
        history.push({ search: params });
        params = insertParam("license", licenseSelectValue);
        history.push({ search: params });
        // eslint-disable-next-line
    }, [popularitySelectValue, licenseSelectValue]);
    const { onClickSearchIcon, onKeyDownCallback } = useSearch({
        category: "download",
    });
    useEffect(() => {
        loadAds()
        // send to google analytics
        googleAnalytics()
        // eslint-disable-next-line
    },[])
    const loadAds = () => {
        const isMobile = window.innerWidth <= 768 ? true : false;
        const $currpage = document.getElementById(`downloadCategoryPage`);
    
        var _scriptTagHead = document.createElement("script");
        _scriptTagHead.async = true;
        _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`
        let slots = []
        if(isMobile){
            // available slot in this page
            slots = [{
                slot: "JT_320x100", // define slot
                id: "adSlotMobTop-category",
                wrapperId: "JT_320x100",
                defaultSize: "320x100"
            },{
                slot: "JT_320x100", // define slot
                id: "adSlotMobSticky-category",
                wrapperId: "JT_320x100-sticky-bottom",
                defaultSize: "320x100"
            }]
        }else{
            // available slot in this page
            slots = [{
                slot: "JT_970x90/JT_970x90_top",
                id: "adSlotDesktop0",
                wrapperId: "JT_970x90_top",
                defaultSize: "970x90"
            }]
        }
        // create ad unit tag head
        for (let i = 0; i < slots.length; i++) {
            _scriptTagHead.text += generateAdUnitTagHead(slots[i])
        }
        $currpage && $currpage.appendChild(_scriptTagHead)    
        
        // create ad unit tag body
        for (let i = 0; i < slots.length; i++) {
            generateAdUnitTagBody(slots[i])
        }
    }
    const renderMeta = () => {
        const title = menuData?.meta_title;
        const ogTitle = title;
        const description = menuData?.meta_description;
        const url = pathname.slice(1);
        const schemaContent = '';
        const props = {title, description, url, schemaContent, ogTitle};
        return <MetaTag {...props} />
      }
    return(
        <main className="container-common__wrapper" id="downloadCategoryPage">
            { renderMeta() }
            {/* Left vertical banner */}
            <div className="container-common__vertical-banner">
                <VerticalBanner />
            </div>
            <div className="container-common">
                <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{minHeight:'100px'}} />
                <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{minHeight:'100px'}} />
                
                <div className="video-category__breadcrumbs">
                    <Breadcrumbs
                        categName={type}
                        articleSlug={params?.categoryName}
                        categSlug={`${type}/category`}
                        className="article-top-info__breadcrumbs"
                        hasDropdown
                        breadcrumbDropdownInfo={breadcrumbDropdownMenuDownload}
                    />
                </div>
                <Filter 
                    page="download" 
                    typeDownload={type}
                    inputPlaceholder={`Search ${type}..`}
                    onChangeCallbackLeft={popularitySelectHandler}
                    onChangeCallbackRight={licenseSelectHandler}
                    onKeyDownCallback={onKeyDownCallback}
                    onClickSearchIcon={onClickSearchIcon}
                    selected={params.categoryName ? params.categoryName : "all"}
                    optionLeftDefault={leftSelectedValue}
                    optionRightDefault={rightSelectedValue}
                />
                {selectedCategoryWindows && selectedCategoryWindows.code !== 200 ? (
                    selectedCategoryWindows.code === 301 ? <Redirect to={`/${selectedCategoryWindows.response.redirect}`} /> :  
                    <div className="container-common">
                        <Error code={selectedCategoryWindows.code} />
                    </div>
                ) : (
                    <AppsSection
                        tab
                        description={menuData?.meta_top}
                        innerHtmlDesc
                        windowApps={appsSelectedCategoryWindows}
                        androidApps={appsSelectedCategoryAndroid}
                        title={params?.categoryName}
                        infiniteScroll={false}
                    />
                )}
                <AppsFAQ />
            </div>
            {/* Right vertical banner */}
            <div className="container-common__vertical-banner">
                <VerticalBanner />
            </div>
            <MobileStickyBottom />
        </main>   
    )
}
export default DownloadCategoryPage