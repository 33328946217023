import React from "react";
import './Rating.css'

function Rating({rating}){
    const result = () => {
        let tmp='';
        for (let index = 0; index < 5; index++) {
            if(index<rating-1){
                tmp += '<span class="fa fa-star checked"></span>'
            }else{
                tmp+= '<span class="fa fa-star"></span>'
            }
        }
        return tmp
    }

    return(
        <>
        <div className="rating__wrapper" dangerouslySetInnerHTML={{ __html: result()}}>

        </div>
        </>
    )
}

export default Rating