import React from "react";
import './AppsHeadlineSliderItem.css'
import { imgSrc} from "../../../../helpers";
import { placeholder } from '../../../../constants/links'

function AppsHeadlineSliderItem({article}) {
    const url = article ? (article.links.detail && `/${article.links.detail}/`) : `#`;
    const img = (article && article.banner_image) ? imgSrc(article.banner_image,0,400) : placeholder;
    const alt = (article && article.title) ? article.title : "Default Image";
    const category = (article) && (article.category ? article.category.slug : article.links.detail.split("/")[0]);
    const title =  (article && article.title)

    return (
        <div className="apps-headline__item" >
            <a href={url}>
                <div className="apps-image__wrapper">
                    <picture>
                    {(article && article.banner_image) &&
                    <source
                        type="image/webp" 
                        srcSet={img + ".webp"}
                        data-srcset={img + ".webp"}
                    />  
                    }
                    <img
                        src= {img} 
                        data-src={img}
                        className=""
                        alt={alt}  
                    />
                    </picture>
                </div>
            </a>
            <div className="apps-headline__info">
                <a href={`/${category}`}>
                    <div className="apps-headline__category">
                        {category}
                    </div>
                </a>
                <a href={url}>
                    <div className="apps-headline__title">
                        {title}
                    </div>
                </a>
            </div>
        </div >
    )
}

export default AppsHeadlineSliderItem