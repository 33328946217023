import { ElectricVehiclePageService } from "../../http/ElectricVehiclePageService";

/* ACTION TYPES */
const GET_ELECTRIC_VEHICLE_ARTICLE_DATA = "GET_ELECTRIC_VEHICLE_ARTICLE_DATA";
const GET_VEHICLE_SEARCH_DATA = "GET_VEHICLE_SEARCH_DATA";
const GET_VEHICLE_SUGGESSTION_DATA = "GET_VEHICLE_SUGGESSTION_DATA";
const GET_VEHICLE_DETAIL_DATA = "GET_VEHICLE_DETAIL_DATA";

const GET_INFINITE_VEHICLE_DATA = "GET_INFINITE_VEHICLE_DATA";
const CLEAR_INFINITE_SEARCH_VEHICLE_DATA = "CLEAR_INFINITE_SEARCH_VEHICLE_DATA";

/*ACTION CREATORS*/
export const getCategoryPageData = (payload) => ({
  type: GET_ELECTRIC_VEHICLE_ARTICLE_DATA,
  payload: payload,
});

export const getVehicleSearchData = (payload) => ({
  type: GET_VEHICLE_SEARCH_DATA,
  payload: payload,
});

export const getSuggestionData = (payload) => ({
  type: GET_VEHICLE_SUGGESSTION_DATA,
  payload: payload,
});

export const getDetailData = (payload) => ({
  type: GET_VEHICLE_DETAIL_DATA,
  payload: payload,
});

export const getInfiniteVehicleData = (payload) => ({
  type: GET_INFINITE_VEHICLE_DATA,
  payload: payload,
});

export const clearInfiniteSearchVehicleData = (payload) => ({
  type: CLEAR_INFINITE_SEARCH_VEHICLE_DATA,
  payload: payload,
});

/*THUNK*/
export const getElectricVehicleArticles = () => async (dispatch) => {
  try {
    const response = await ElectricVehiclePageService.getArticle();
    dispatch(getCategoryPageData(response.data));
  } catch (error) {
    dispatch(getCategoryPageData(error));
  }
};

export const getVehicleSearchDataThunk = (params) => async (dispatch) => {
  try {
    const { type, merk, q } = params;
    const response = await ElectricVehiclePageService.getVehicleSearch(type, merk, q);
    dispatch(getVehicleSearchData(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getVehicleSearchData(error));
  }
};

export const getVehicleSuggestionDataThunk = (q, item, type) => async (dispatch) => {
  try {
    const response = await ElectricVehiclePageService.getSuggestionData(q, item, type);
    dispatch(getSuggestionData(response.data));
  } catch (error) {
    // console.log(error);
  }
};

export const getVehicleDetailDataThunk = (params) => async (dispatch) => {
  try {
    const { slug } = params;
    const response = await ElectricVehiclePageService.getDetailData(slug);
    dispatch(getDetailData(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getDetailData(error));
  }
};

export const getInfiniteVehicleDataThunk =
  (type, page, keyword) => async (dispatch) => {
    try {
      const response = await ElectricVehiclePageService.getInfiniteVehicleData(
        type,
        page,
        keyword
      );
      dispatch(
        getInfiniteVehicleData({
          list: response.data.response.vehicleList.vehicleList.hits,
          total: response.data.response.vehicleList.vehicleList.size,
          hasMore: (response.data.response.vehicleList.vehicleList.page.curr < response.data.response.vehicleList.vehicleList.page.last) ? true : false
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

/*INITIAL STATE*/
const initialState = {
  electricVehicleCategoryData: null,
  homeData: null,
  vehicleDetail: null,
  searchVehicleData: null,
  vehicleSuggestionData: null,
  searchInfiniteVehicleData: null,
  totalVehicleData: null,
  isHasMoreVehicle: true,
};

/*REDUCER*/
function eletricVehiclePageReducer(state = initialState, action) {
  switch (action.type) {
    /*Get data for category page*/
    case GET_ELECTRIC_VEHICLE_ARTICLE_DATA:
      return {
        ...state,
        electricVehicleCategoryData: action.payload,
      };

    /*Get homedata for vehicle*/
    // case GET_HOME_DATA:
    //   return {
    //     ...state,
    //     homeData: action.payload,
    //   };
    /* Get vehicle detail data */
    case GET_VEHICLE_DETAIL_DATA:
      return {
        ...state,
        vehicleDetail: action.payload,
      };
    case GET_VEHICLE_SEARCH_DATA:
      return {
        ...state,
        searchVehicleData: action.payload,
      };

    case GET_INFINITE_VEHICLE_DATA:
      return {
        ...state,
        searchInfiniteVehicleData: [
          ...(state.searchInfiniteVehicleData || []),
          ...(action.payload.list || []),
        ],
        totalVehicleData: action.payload.total,
        isHasMoreVehicle: action.payload.hasMore
      };

    case CLEAR_INFINITE_SEARCH_VEHICLE_DATA:
      return {
        ...state,
        searchInfiniteVehicleData: [],
      };

    case GET_VEHICLE_SUGGESSTION_DATA:
      return {
        ...state,
        vehicleSuggestionData: action.payload,
      };
    default:
      return state;
  }
}

export default eletricVehiclePageReducer;
