import React from "react";
import "./PostsSlider.css";
// import sliderImg from "@assets/images/test-slider-img.png";
import PostInfo from "@components/common/PostInfo/PostInfo";
// import { assetsUrl } from "../../../../constants/api";
import { categories } from "../../../../constants";
import { imgSrc, onErrorSrc} from "../../../../helpers";
import 'lazysizes';
import slugify from 'react-slugify';

function PostsSliderOneSlide({
  article,
  title,
  date,
  page,
  videoItemsCategory,
}) {
  let categoryName;
  let categoryUrl;
  let linkToVideo;
  let imgUrl;
  // Video Detail page
  if (page === "videoDetail") {
    categoryName = categories[videoItemsCategory];
    categoryUrl = `category/${videoItemsCategory}`;
    linkToVideo = `/video/${videoItemsCategory}/${article.id}/${slugify(article.title)}/`;
    imgUrl = article.thumbnail_url;

    // Category Page
  } else if (page === "categoryPage") {
    imgUrl = imgSrc(article.banner_image,0,400);
    categoryUrl = article.links.detail.split("/")[0];
    categoryName = article.links.detail.split("/")[0];
    linkToVideo = `/${article.links.detail}/`;

    // Homepage
  } else if (page === "homepage") {
    imgUrl = imgSrc(article.banner_image,0,200);
    categoryUrl = article.category.slug;
    categoryName = article.category.title;
    linkToVideo = `/${article.links.detail}/`;
  }

  return (
    <div className="post-slider__one-slide">
      {page === "videoDetail" ? (
        <a href={linkToVideo}>
          <div className="post-slider__one-slide-img-wrapper">
            <picture>
              <img 
                className="post-slider__one-slide-img lazyload"
                data-src={imgUrl}
                alt={title}
                height="200" 
              />
            </picture>
          </div>
          <p className="post-slider__one-slide-title title-small">{title}</p>
        </a>
      ):(
        <a href={linkToVideo}>
          <div className="post-slider__one-slide-img-wrapper">
            <picture>
              <source
                type="image/webp" 
                // srcSet={imgUrl + ".webp"}
                data-srcset={imgUrl + ".webp"}
              />
              <img 
                onError={onErrorSrc}
                className="post-slider__one-slide-img lazyload"
                data-src={imgUrl}
                alt={title}
                height="200" 
              />
            </picture>
          </div>
          <p className="post-slider__one-slide-title title-small">{title}</p>
        </a>
      )}
      <div>
        <PostInfo
          subTitleText=""
          categoryName={categoryName}
          categoryUrl={categoryUrl}
          date={date}
        />
      </div>
    </div>
  );
}

export default PostsSliderOneSlide;
