import React from "react";
import PostsSlider from '../common/Sliders/PostsSlider/PostsSlider';
import './AppsArticle.css'
import SubTitle from "@components/common/Titles/SubTitle";
function AppsArticle({articles}){

    return(
        <div className="apps-article_wrapper">
            <SubTitle subTitleText="Artikel Terkait" isLowercase isWhite />
            <PostsSlider sliderData={articles} page="categoryPage" />
        </div>
    )
}

export default AppsArticle