import React from "react";
import "./FAQ.css";
import {faqDownload} from '../../constants'

function FAQ() {
   return (
      <>
         <div className="faq-section__container">
            <div className="faq-section__wrapper">
               <div className="faq-section__title">Frequently Asked Questions</div>
               <div className="faq-section__content">
                  <ul className="accordion css-accordion">
                     {faqDownload.map((item, index) => {
                        return(
                           <li className="accordion-item" key={index}>
                              <input className="accordion-item-input" type="checkbox" name="accordion" id={`item-${index}`} />
                              <label htmlFor={`item-${index}`} className="accordion-item-hd">
                                 {item.question}
                                 <span className="accordion-item-hd-cta">
                                    <span className="icon-icon-arrow-down"></span>
                                 </span>
                              </label>
                              <div className="accordion-item-bd" dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                           </li>
                        )
                     })}
                  </ul>
               </div>
            </div>
         </div>
      </>
   );
}

export default FAQ;
