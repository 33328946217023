import { VideoCategoryPageService } from "../../http";

/*ACTION TYPES*/
const GET_ACTIVE_CATEGORY_VIDEO_LIST = "GET_ACTIVE_CATEGORY_VIDEO_LIST";
const GET_ALL_CATEGORY_VIDEO_LIST = "GET_ALL_CATEGORY_VIDEO_LIST";
const CLEAR_ACTIVE_CATEGORY_VIDEO_LIST = "CLEAR_ACTIVE_CATEGORY_VIDEO_LIST";
const CLEAR_ALL_CATEGORY_VIDEO_LIST = "CLEAR_ALL_CATEGORY_VIDEO_LIST";
const GET_SEARCH_VIDEO_LIST = "GET_SEARCH_VIDEO_LIST";
const CLEAR_SEARCH_VIDEO_LIST = "CLEAR_SEARCH_VIDEO_LIST";
const GET_INFINITE_VIDEO_DATA = "GET_INFINITE_VIDEO_DATA";
const GET_FILTERED_VIDEO_LIST = "GET_FILTERED_VIDEO_LIST";
const ADD_FILTERED_VIDEO_LIST = "ADD_FILTERED_VIDEO_LIST";
const GET_INFINITE_ACTIVE_CATEGORY_VIDEO_DATA =
  "GET_INFINITE_ACTIVE_CATEGORY_VIDEO_DATA";
const CLEAR_HAS_MORE = "CLEAR_HAS_MORE";
const GET_INFINITE_ALL_CATEGORY_VIDEO_DATA =
  "GET_INFINITE_ALL_CATEGORY_VIDEO_DATA";

/*ACTION CREATORS*/
export const getActiveCategoryVideoList = (payload) => ({
  type: GET_ACTIVE_CATEGORY_VIDEO_LIST,
  payload: payload,
});

export const getAllCategoryVideoList = (payload) => ({
  type: GET_ALL_CATEGORY_VIDEO_LIST,
  payload: payload,
});

export const clearActiveCategoryVideoList = () => ({
  type: CLEAR_ACTIVE_CATEGORY_VIDEO_LIST,
});

export const clearAllCategoryVideoList = () => ({
  type: CLEAR_ALL_CATEGORY_VIDEO_LIST,
});

export const clearSearchVideoList = () => ({
  type: CLEAR_SEARCH_VIDEO_LIST,
});

export const getSearchVideoList = (payload) => {
  return {
    type: GET_SEARCH_VIDEO_LIST,
    payload: payload,
  };
};

export const getInfiniteVideoData = (payload) => ({
  type: GET_INFINITE_VIDEO_DATA,
  payload: payload,
});

export const getFilteredVideoList = (payload) => ({
  type: GET_FILTERED_VIDEO_LIST,
  payload: payload,
});

export const addFilteredVideoList = (payload) => ({
  type: ADD_FILTERED_VIDEO_LIST,
  payload: payload,
});

export const getInfiniteActiveCategoryVideoData = (payload) => ({
  type: GET_INFINITE_ACTIVE_CATEGORY_VIDEO_DATA,
  payload: payload,
});

export const getInfiniteAllCategoryVideoData = (payload) => ({
  type: GET_INFINITE_ALL_CATEGORY_VIDEO_DATA,
  payload: payload,
});

export const clearHasMore = () => ({
  type: CLEAR_HAS_MORE,
});

/*THUNK*/
export const getActiveCategoryVideoListThunk =
  (categoryName) => async (dispatch) => {
    try {
      const response =
        await VideoCategoryPageService.getActiveCategoryVideoList(categoryName);
      dispatch(getActiveCategoryVideoList(response.data.list));
      return response.data.has_more;
    } catch (error) {
      // console.log(error);
      dispatch(getActiveCategoryVideoList(error.response.data));
    }
  };

export const getAllCategoryVideoListThunk = () => async (dispatch) => {
  try {
    const response = await VideoCategoryPageService.getAllCategoryVideoList();
    dispatch(getAllCategoryVideoList(response.data.list));
  } catch (error) {
    // console.log(error);
  }
};

export const getSearchVideoListThunk = (query) => async (dispatch) => {
  try {
    const response = await VideoCategoryPageService.getSearchVideoList(query);
    dispatch(getSearchVideoList(response.data.list));
  } catch (error) {
    // console.log(error);
  }
};

export const getInfiniteVideoDataThunk =
  (page, keyword, isBusy) => async (dispatch) => {
    try {
      const response = await VideoCategoryPageService.getInfiniteVideoData(
        page,
        keyword
      );
      dispatch(
        getInfiniteVideoData({
          list: response.data.list,
          has_more: response.data.has_more,
          total: response.data.total,
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

export const getFilteredVideoListThunk =
  (category, query, page) => async (dispatch) => {
    try {
      const response = await VideoCategoryPageService.getFilteredVideoList(
        category,
        query,
        page
      );
      dispatch(
        getFilteredVideoList({
          list: response.data.list,
          has_more: response.data.has_more,
          total: response.data.total,
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

export const addFilteredVideoListThunk =
  (category, query, page) => async (dispatch) => {
    try {
      const response = await VideoCategoryPageService.getFilteredVideoList(
        category,
        query,
        page
      );
      dispatch(
        addFilteredVideoList({
          list: response.data.list,
          has_more: response.data.has_more,
          total: response.data.total,
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

export const getInfiniteActiveCategoryVideoDataThunk =
  (page, categoryName) => async (dispatch) => {
    try {
      const response =
        await VideoCategoryPageService.getInfiniteActiveCategoryVideoData(
          page,
          categoryName
        );
      dispatch(
        getInfiniteActiveCategoryVideoData({
          list: response.data.list,
          has_more: response.data.has_more,
          total: response.data.total,
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

export const getInfiniteAllCategoryVideoDataThunk =
  (page, categoryName) => async (dispatch) => {
    try {
      const response =
        await VideoCategoryPageService.getInfiniteAllCategoryVideoData(
          page,
          categoryName
        );
      dispatch(
        getInfiniteAllCategoryVideoData({
          list: response.data.list,
          has_more: response.data.has_more,
          total: response.data.total,
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

export const getAllVideoCategoryPageDataThunk = (params) => async (dispatch) => {
  try {
    if (params?.categoryName === "all") {
      await dispatch(getAllCategoryVideoListThunk());
      await dispatch(clearActiveCategoryVideoList());
    } else {
      await dispatch(getActiveCategoryVideoListThunk(params?.categoryName));
      await dispatch(clearAllCategoryVideoList());
    }
  } catch (error) {
    // console.log(error);
  }
};

/*INITIAL STATE*/
const initialState = {
  activeCategoryVideoList: [],
  // allCategoryVideoList: null,
  searchVideoList: [],
  infiniteVideoListHasMore: true,
  filteredVideoList: [],
  total: 0,
  activeCategoryHasMore: true,
};

/*REDUCER*/
function videoCategoryPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_CATEGORY_VIDEO_LIST:
      return {
        ...state,
        activeCategoryVideoList: action.payload,
      };
    case GET_ALL_CATEGORY_VIDEO_LIST:
      return {
        ...state,
        activeCategoryVideoList: action.payload,
      };
    case CLEAR_ACTIVE_CATEGORY_VIDEO_LIST:
      return {
        ...state,
        activeCategoryVideoList: null,
      };
    case CLEAR_ALL_CATEGORY_VIDEO_LIST:
      return {
        ...state,
        allCategoryVideoList: null,
      };
    case GET_SEARCH_VIDEO_LIST:
      return {
        ...state,
        searchVideoList: action.payload,
      };
    case CLEAR_SEARCH_VIDEO_LIST:
      return {
        ...state,
        searchVideoList: [],
      };

    case GET_FILTERED_VIDEO_LIST:
      return {
        ...state,
        filteredVideoList: action.payload.list,
      };

    case GET_INFINITE_VIDEO_DATA:
      return {
        ...state,
        searchVideoList: [
          ...(state.searchVideoList || []),
          ...(action.payload.list || []),
        ],
        infiniteVideoListHasMore: action.payload.has_more,
        total: action.payload.total,
      };

    case ADD_FILTERED_VIDEO_LIST:
      return {
        ...state,
        filteredVideoList: [
          ...(state.filteredVideoList || []),
          ...(action.payload.list || []),
        ],
        filteredHasMore: action.payload.has_more,
      };

    case GET_INFINITE_ACTIVE_CATEGORY_VIDEO_DATA:
      return {
        ...state,
        activeCategoryVideoList: [
          ...(state.activeCategoryVideoList || []),
          ...(action.payload.list || []),
        ],
        activeCategoryHasMore: action.payload.has_more,
      };

    case GET_INFINITE_ALL_CATEGORY_VIDEO_DATA:
      return {
        ...state,
        activeCategoryVideoList: [
          ...(state.activeCategoryVideoList || []),
          ...(action.payload.list || []),
        ],
        activeCategoryHasMore: action.payload.has_more,
      };

    case CLEAR_HAS_MORE:
      return {
        ...state,
        activeCategoryHasMore: true,
      };
    default:
      return state;
  }
}

export default videoCategoryPageReducer;
