import { javantApi } from "../constants";

export const DownloadPageService = {
    // type = apps/games; 
    getAppsData(){
        return javantApi.get(`component/app/apps/`); 
    },
    getGamesData(){
        return javantApi.get(`component/app/games/`); 
    },
    getAppsByCategory(category, platform){
        return javantApi.get(`component/app/apps?category=${category}&platform=${platform}`); 
    },
    getBestGamesData(platform){
        return javantApi.get(`component/app/games?platform=${platform}&order=version`);
    },
    getMetaByCategory(type, category){
        return javantApi.get(`component/header/data?slug=${type}/category/${category}/`); 
    },
    getDownloadByParam(type, category = "", platform="windows", order="newest", license="", page=1){
        return javantApi.get(`component/app/${type}?category=${category}&platform=${platform}&order=${order}&license=${license}&page=${page}`)
    },
    getSingleAppData(type, slug){
        return javantApi.get(`content/app/${type}/detail/${slug}`);
    },
    getSingleAppComponentData(type, slug){
        return javantApi.get(`content/app/${type}/detail/${slug}/component/1`);
    }
}