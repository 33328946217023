import React from "react";
import slugify from 'react-slugify';
import SubTitle from "@components/common/Titles/SubTitle";
import "./PostsContainer.css";
import PostBlockLarge from "@components/PostBlockLarge/PostBlockLarge";
import PostBlockWithCategory from "../../components/PostBlockWithCategory/PostBlockWithCategory";
import { assetsUrl } from "../../constants";
import { categoryUrlObj } from "../../constants/index";

function PostsContainer({
  containerData,
  title,
  hasVideoIcon,
  hasBreadcrumbs,
  hasThirdPartyServiceUrl,
  wordsToRemove
}) {
  // Get first post data for PostBlockLarge component
  let firstPostData;
  let postsContainerRightData;
  if(wordsToRemove){
    if (containerData.list) {
      firstPostData = containerData.list.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })[0];
      postsContainerRightData = containerData.list.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      }).slice(1);
    } else if (containerData.hits) {
      firstPostData = containerData.hits.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })[0];
      postsContainerRightData = containerData.hits.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      }).slice(1, 4);
    }
  }else{
    if (containerData.list) {
      firstPostData = containerData.list[0];
      postsContainerRightData = containerData.list.slice(1);
    } else if (containerData.hits) {
      firstPostData = containerData.hits[0];
      postsContainerRightData = containerData.hits.slice(1, 4);
    }
  }

  return (
    <div
      className={`posts-container__outer ${
        hasVideoIcon ? "posts-container__outer-video-icons" : ""
      }`}
    >
      <SubTitle subTitleText={title} />
      <div className="posts-container__wrapper">
        <div className="posts-container__left">
          {firstPostData && <PostBlockLarge
            hasBreadcrumbs={hasBreadcrumbs}
            firstPostData={firstPostData}
          />}
        </div>
        <div className="posts-container__right">
          {postsContainerRightData.map((item, index) => {
            // get thumbnail url
            let thumbnail_url =
              item.thumbnail_url ||
              assetsUrl +
                item.banner_image.base_url +
                "184/94/" +
                item.banner_image.file;

            // get post date
            let date = item.created_time * 1000 || item.published;

            // get postDetail url(post img and title are wrapped in link with this url)
            let url = item.url || item.links.detail;

            // Boolean variable wich shows if we have third patry service inside Post link or not
            // get category url for PostInfo block
            let categoryUrl = item?.category?.slug || item["owner.url"];

            // get category name for PostInfo block
            let categoryName =
              item?.category?.title || item["owner.screenname"];
            return (
              <PostBlockWithCategory
                key={index}
                thumbnail_url={thumbnail_url}
                date={date}
                title={item.title}
                url={url}
                hasThirdPartyServiceUrl={hasThirdPartyServiceUrl}
                categoryUrl={categoryUrl}
                categoryName={categoryName}
                videoDetailPageLink={`/video/${categoryUrlObj[item["channel.id"]]}/${item.id}/${slugify(item.title)}/`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PostsContainer;
