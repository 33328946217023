import React, { useEffect } from "react";
// import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import AppsTopInfo from '../components/AppsTopInfo/AppsTopInfo'
import { useParams, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppsSection from '../components/AppsSection/AppsSection';
import AppsFAQ from '../components/FAQ/FAQ'
import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
// import Filter from "../components/common/Filter/Filter";
import Error from  '../components/Error/Error';
import {
    getSingleAppDataThunk,
    getSingleAppComponentDataThunk,
    getAppsDataThunk
} from "../store/ducks/downloadPageReducer";

import Breadcrumbs from "../components/common/Breadcrumbs/Breadcrumbs";
import { breadcrumbDropdownMenuDownload } from "../constants";
import useSearch from "../hooks/useSearch";
import {googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody, imgSrc} from '../helpers'
import TextInputSearch from "@components/common/TextInputSearch/TextInputSearch";
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AlertCopyClip from "../components/ShareSocials/AlertCopyClip";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';
import MetaTag from '../components/MetaTag/MetaTag';

function DownloadDetailPage(){
    const history = useParams();
    const dispatch = useDispatch();
    
    const { type } = history;
    
    const singleAppData =  useSelector((state) => state.downloadPageReducer.singleAppData);
    const singleComponentData = useSelector((state) => state.downloadPageReducer.singleAppComponentData);
    
    const appData = singleAppData && singleAppData.code === 200 && singleAppData.response
    const componentData = singleComponentData && singleComponentData.code === 200 && singleComponentData.response
    
    // const appsData = useSelector((state) => state.downloadPageReducer.appsData );
    // const filter = appsData && appsData.response.filter
    
    useEffect(() => {
        dispatch(getAppsDataThunk())
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(getSingleAppDataThunk(history))
        dispatch(getSingleAppComponentDataThunk(history))
        googleAnalytics()
        loadAds()
        // eslint-disable-next-line
    }, [history]);

    const { onClickSearchIcon, onKeyDownCallback } = useSearch({
        category: "download",
    });

      
    const renderMeta = (singleAppData) => {
        const url = singleAppData.response.links.detail.replace(/\/?$/, '/');
        const title = singleAppData.response.title + ' | JalanTikus';
        const ogTitle = title;
        const description = singleAppData.response.summary;
        const image = imgSrc(singleAppData.response && singleAppData.response?.banner_image, 769, 330);
        const amphtmlUrl = `amp/${url}`;
        const props = {url, title, description, image, amphtmlUrl, ogTitle};
        return <MetaTag {...props} />
    }

    const loadAds = () => {
        const isMobile = window.innerWidth <= 768 ? true : false;
        const $currpage = document.getElementById(`downloadDetailPage`);
    
        var _scriptTagHead = document.createElement("script");
        _scriptTagHead.async = true;
        _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

        let slots = []
        if(isMobile){
            // available slot in this page
            slots = [{
                slot: "JT_320x100", // define slot
                id: "adSlotMobTop-category",
                wrapperId: "JT_320x100",
                defaultSize: "320x100"
            },{
                slot: "JT_320x100", // define slot
                id: "adSlotMobSticky-category",
                wrapperId: "JT_320x100-sticky-bottom",
                defaultSize: "320x100"
            }]
        }else{
            // available slot in this page
            slots = [{
                slot: "JT_970x90/JT_970x90_top",
                id: "adSlotDesktop0",
                wrapperId: "JT_970x90_top",
                defaultSize: "970x90"
            }]
        }
        // create ad unit tag head
        for (let i = 0; i < slots.length; i++) {
            _scriptTagHead.text += generateAdUnitTagHead(slots[i])
        }
        $currpage && $currpage.appendChild(_scriptTagHead)    
        
        // create ad unit tag body
        for (let i = 0; i < slots.length; i++) {
            generateAdUnitTagBody(slots[i])
        }
    }

    return(
        <>
            <main className="container-common__wrapper" id="downloadDetailPage">
                { singleAppData && singleAppData.code === 200 && renderMeta(singleAppData) }

                {/* Left vertical banner */}
                <div className="container-common__vertical-banner">
                    <VerticalBanner />
                </div>

                <div className="container-common">
                    <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{minHeight:'100px'}} />
                    <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{minHeight:'100px'}} />
                    <div className="video-category__breadcrumbs">
                        <Breadcrumbs
                            categName={type}
                            articleSlug={appData && appData.title}
                            categSlug={`${type}/category`}
                            className="article-top-info__breadcrumbs"
                            hasDropdown
                            breadcrumbDropdownInfo={breadcrumbDropdownMenuDownload}
                            primaryTag={appData?.tags?.primary}
                            primaryTagLink={`/${type}/category/`}
                        />
                    </div>
                    <TextInputSearch
                        page="download"
                        onKeyDownCallback={onKeyDownCallback}
                        onClickSearchIcon={onClickSearchIcon}
                        placeholder={"Search Apps..."}
                    />

                    {singleAppData && singleAppData.code !== 200 ? (
                        singleAppData.code === 301 ? <Redirect to={`/${singleAppData.response.redirect}`} /> :  
                        <div className="container-common">
                            <Error code={singleAppData.code ? singleAppData.code : '503'} />
                        </div>
                    ) : (
                        <AppsTopInfo app={appData} />
                    )}
                    {
                        singleComponentData?.code === 200 && (
                            <AppsSection 
                                apps={componentData && componentData.rekomendasiLainnya.slice(0,6)} 
                                title="Rekomendasi Lainnya"
                            />
                        )
                    }
                    <AppsFAQ />
                </div>

                {/* Right vertical banner */}
                <div className="container-common__vertical-banner">
                    <VerticalBanner />
                </div>
                <AlertCopyClip />
                <MobileStickyBottom />
            </main>
        </>
    )
}

export default DownloadDetailPage 