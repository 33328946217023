import React from "react";
import MetaTag from '../MetaTag/MetaTag';
import Header from "../Header/Header";
// import ResponsiveHeader from "../Header/ResponsiveHeader";
import Footer from "../Footer/Footer";
import ExternalPopup from "../ExternalPopup/ExternalPopup";
import '../../assets/css/libs/slick-carousel/1.6.0/slick-theme.min.css';
import '../../assets/css/libs/slick-carousel/1.6.0/slick.min.css';
import TopAdWrapper from "../Banners/AdWrapper/TopAdWrapper";

function Layout({ InnerComponent }) {
  return (
    <>
      <MetaTag />
      <TopAdWrapper />
      <Header />
      <InnerComponent/>
      <ExternalPopup />
      <Footer />
    </>
  );
}

export default Layout;
