import { AuthorPageService } from "../../http/AuthorPageService"


// ACTION TYPE
const GET_AUTHOR_DATA = "GET_AUTHOR_DATA"


// ACTION CREATOR
export const getAuthorData = (payload) => ({
    type: GET_AUTHOR_DATA,
    payload: payload
})

//THUNK
export const getAuthorDataThunk = (userName) => async(dispatch) => {
    try {
        const response = await AuthorPageService.getData(userName)
        dispatch(getAuthorData(response.data))
    } catch (error) {
        dispatch(getAuthorData(error))
    }
}

// INITIAL STATE
const initialState = {
    authorData: null
}

// REDUCER
function authorPageReducer(state= initialState, action) {
    switch (action.type) {
        case GET_AUTHOR_DATA:
            return {
                ...state,
                authorData: action.payload
            }
        default:
            return state
    }
}

export default authorPageReducer;