import React from "react";
import "./ArticleTopInfo.css";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";
import PostInfo from "../common/PostInfo/PostInfo";
import { breadcrumbDropdownMenuArticle } from "../../constants";

function ArticleTopInfo({
  title,
  categName,
  categSlug,
  articleSlug,
  ownerScreenname,
  ownerUrl,
  date,
  primaryTag,
  authorLink
}) {
  let writerName = ownerScreenname;
  // let writerName;
  let writerLink = authorLink;
  // let writerLink;
  // if(categSlug==="apps" || categSlug==="games"){
  //   writerName = "Siti Aisyah"
  //   writerLink = "siti.aisyah"
  // }else if(categSlug==="tips" || categSlug==="news"){
  //   writerName = "Ayu Pratiwi"
  //   writerLink = "ayu.pratiwi"
  // }else if(categSlug==="gadgets" || categSlug==="gokil"){
  //   writerName = "Anggi Oktaviani"
  //   writerLink = "anggi.oktaviani"
  // }else if(categSlug==="intim"){
  //   writerName = "Kevin Chandra"
  //   writerLink = "kevin.chandra"
  // }else if(categSlug==="quotes" || categSlug==="lagu"){
  //   writerName = "Michelle Lim"
  //   writerLink = "michelle.lim"
  // }else if(categSlug==="hiburan" || categSlug==="telko"){
  //   writerName = "Fitria Handayani"
  //   writerLink = "fitria.handayani"
  // }else if(categSlug==="gaming" || categSlug==="finansial"){
  //   writerName = "Puput Hadiningrat"
  //   writerLink = "puput.hadiningrat"
  // }else if(categSlug==="ragam" || categSlug==="iptek"){
  //   writerName = "Muhammad Reza"
  //   writerLink = "muhammad.reza"
  // }else if(categSlug==="sport" || categSlug==="ntfs"){
  //   writerName = "Asep Budiawan"
  //   writerLink = "asep.budiawan"
  // }else if(categSlug==="kendaraan-listrik" || categSlug==="otomotif"){
  //   writerName = "Bayu Setiawan"
  //   writerLink = "bayu.setiawan"
  // }else if(categSlug==="web3"){
  //   writerName = "Rizki Ramadhan"
  //   writerLink = "rizki.ramadhan"
  // }else if(categSlug==="healthtech"){
  //   writerName = "Dwi Yulianti"
  //   writerLink = "dwi.yulianti"
  // }
  return (
    <div className="article-top-info__wrapper">
      <Breadcrumbs
        categName={categName}
        articleSlug={articleSlug}
        categSlug={categSlug}
        className="article-top-info__breadcrumbs"
        breadcrumbDropdownInfo={breadcrumbDropdownMenuArticle}
        hasDropdown
        primaryTag={primaryTag}
        primaryTagLink={`/tags/`}
      />

      <h1 className="article-top-info__title" 
        style={{ fontFamily: 'Helvetica', fontSize: '32px', fontWeight: 700, lineHeight: 'normal', color: '#212936', marginBottom: '8px' }}>{title}</h1>
      <PostInfo
        categoryName={writerName}
        categoryUrl={categSlug}
        date={date}
        absoluteDate={true}
        writer={true} 
        authorLink={writerLink}
      />
    </div>
  );
}

export default ArticleTopInfo;
