import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Layout from "@components/Layout/Layout";
import ScrollToTop from "@components/ScrollToTop/ScrollToTop";
import routes from '../../shared/routes';
import ErrorPage from "@pages/ErrorPage";
import ArticlePage from "../../pages/ArticlePage";

export default function AppRouter() {
  const [isSubdomain, setIsSubdomain] = useState(false);

  useEffect(() => {
    // This code will only run on the client side
    const hostname = window.location.hostname.replace("www.", "");
    const parts = hostname.split('.');
    setIsSubdomain(parts.length > 2);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Switch>
        {routes.map((route, index) => (
          <Route key={index} {...route}>
            {
              route.redirect ?
              (<Redirect to={route.redirect} />) :
              (<Layout InnerComponent={isSubdomain ? ArticlePage : route.component } />)
            }
          </Route>
        ))}
        <Route path="*" render={() => <ErrorPage />} status={404} />
      </Switch>
    </>
  );
}
