import React, { useEffect, useState } from "react";
import "../assets/css/videoCategoryPage.css";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import VideoTopBlock from "../components/VideoTopBlock/VideoTopBlock";
import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import VideoSectionItem from "../components/VideoSectionItem/VideoSectionItem";
import BannerMobile from "@components/Banners/BannerMobile/BannerMobile";
import Filter from "../components/common/Filter/Filter";
// import BannerMobileSquare from "@components/Banners/BannerMobileSquare/BannerMobileSquare";
import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import { breadcrumbDropdownMenuVideo, getVideoCategoryMeta } from "../constants";
import LoadingMore from "../components/common/LoadingMore/LoadingMore";
import InfiniteScroll from "react-infinite-scroller";
import SubTitle from "@components/common/Titles/SubTitle";
import Error from  '../components/Error/Error';
import MetaTag from '../components/MetaTag/MetaTag';
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";

import {
  getAllVideoCategoryPageDataThunk,
  getSearchVideoListThunk,
  getFilteredVideoListThunk,
  getInfiniteActiveCategoryVideoDataThunk,
  getInfiniteAllCategoryVideoDataThunk,
  addFilteredVideoListThunk,
  clearHasMore,
} from "../store/ducks/videoCategoryPageReducer";
import {
  categories,
  categoriesQueryName,
  categoryNameObj,
  categoryUrlObj,
} from "../constants";
import useSearch from "../hooks/useSearch";
import {googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody} from '../helpers'
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';

let isBusy = false;

function VideoCategoryPage() {
  // const [infiniteArray, setInfiniteArray] = useState([]);
  // const [hasMoreValue, setHasMoreValue] = useState(false);
  const [page, setPage] = useState(1);

  const [timeSelectValue, setTimeSelectValue] = useState("");
  const [popularitySelectValue, setPopularitySelectValue] = useState("");
  const dispatch = useDispatch();
  // const location = useLocation();
  const activeCategoryVideoList = useSelector(
    (state) => state.videoCategoryPageReducer.activeCategoryVideoList || []
  );
  const allCategoryVideoList = useSelector(
    (state) => state.videoCategoryPageReducer.allCategoryVideoList
  );

  const filteredVideoList = useSelector(
    (state) => state.videoCategoryPageReducer.filteredVideoList
  );

  const videoFilterPopularity = {
    Popular: "visited",
    Newest: "recent",
  };

  const videoFilterTime = {
    "Today": "today",
    "Last Week": "week",
    "Last Month": "month",
  };
  
  const { onClickSearchIcon, onKeyDownCallback, params } = useSearch({
    category: "video",
    searchDataThunk: getSearchVideoListThunk,
  });

  const hasMoreFromSelector = useSelector(
    (state) => state.videoCategoryPageReducer.activeCategoryHasMore
  );

  const filteredHasMore = useSelector(
    (state) => state.videoCategoryPageReducer.filteredHasMore
  );

  const addMorePosts = async () => {
    try {
      if (!popularitySelectValue) {
        if (params?.categoryName !== "all") {
          await dispatch(
            getInfiniteActiveCategoryVideoDataThunk(
              page,
              categoriesQueryName[params?.categoryName]
            )
          );
        }
        if (params?.categoryName === "all") {
          await dispatch(getInfiniteAllCategoryVideoDataThunk(page + 1));
        }
      } else {
        const popularityFilter =
          videoFilterPopularity[popularitySelectValue] || "visited";
        let timeFilter = "";
        if (popularityFilter !== "recent" && timeSelectValue) {
          timeFilter = `-${videoFilterTime[timeSelectValue]}`;
        }

        const query = `${popularityFilter}${timeFilter}`;
        await dispatch(
          addFilteredVideoListThunk(params?.categoryName, query, page)
        );
      }
      setPage(page + 1);
    } catch (e) {
      // console.log("error", e);
    }
  };

  const trottle = () => {
    if (isBusy || !hasMoreFromSelector) {
      return;
    }
    isBusy = true;
    setTimeout(async () => {
      await addMorePosts();
      isBusy = false;
    }, 1000);
  };

  const popularitySelectHandler = (e) => {
    setPopularitySelectValue(e.value);
  };

  const timeSelectHandler = (e) => {
    setTimeSelectValue(e.value);
  };

  useEffect(() => {
    dispatch(clearHasMore());
    setPopularitySelectValue(null);
    // video by category
    dispatch(getAllVideoCategoryPageDataThunk(params));
    setPage(2);
    // setInfiniteArray([]);
    googleAnalytics()
    loadAds()
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (!popularitySelectValue) {
      return;
    }
    const popularityFilter =
      videoFilterPopularity[popularitySelectValue] || "visited";
    let timeFilter = "";
    if (popularityFilter !== "recent" && timeSelectValue) {
      timeFilter = `-${videoFilterTime[timeSelectValue]}`;
    }

    const query = `${popularityFilter}${timeFilter}`;
    dispatch(getFilteredVideoListThunk(params.categoryName, query, 1));
    // eslint-disable-next-line
  }, [timeSelectValue, popularitySelectValue]);

  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`videoCategoryPage`);

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

    let slots = []
    if(isMobile){
        // available slot in this page
        slots = [{
            slot: "JT_320x100", // define slot
            id: "adSlotMobTop-category",
            wrapperId: "JT_320x100",
            defaultSize: "320x100",
        },
        {
            slot: "JT_320x100", // define slot
            id: "adSlotMobSticky-category",
            wrapperId: "JT_320x100-sticky-bottom",
            defaultSize: "320x100",
        },
        {
            slot: "JT_300x250", // define slot
            id: "adSlotMobBelowRelatedArticle",
            wrapperId: "JT_300x250_between_videos",
            defaultSize: "300x250"
        },
      ]
    }else{
        // available slot in this page
        slots = [{
          slot: "JT_970x90/JT_970x90_top",
          id: "adSlotDesktop0",
          wrapperId: "JT_970x90_top",
          defaultSize: "970x90"
        }]
    }
    // create ad unit tag head
    for (let i = 0; i < slots.length; i++) {
      _scriptTagHead.text += generateAdUnitTagHead(slots[i])
    }
    $currpage && $currpage.appendChild(_scriptTagHead)    
    
    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
        generateAdUnitTagBody(slots[i])
    }
  }

  return (
    <main className="container-common__wrapper" id="videoCategoryPage">
      {/* Left vertical banner */}
      <div className="container-common__vertical-banner">
        <VerticalBanner />
      </div>

      {/* Main content */}
      <div className="container-common">
        {categories[params.categoryName]===undefined || activeCategoryVideoList?.error ? (
            <Error code={categories[params.categoryName]===undefined ? '404' : '503'}></Error>
        ) : (
        <>
        <div className="video-category__top-wrapper">
          <MetaTag {...getVideoCategoryMeta(params.categoryName)}/>
          <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{minHeight:'100px'}} />
          <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{minHeight:'100px'}} />
          <div className="video-category__breadcrumbs">
            <Breadcrumbs
              categName="Video"
              articleSlug={categories[params.categoryName]}
              categSlug="video"
              className="article-top-info__breadcrumbs"
              hasDropdown
              breadcrumbDropdownInfo={breadcrumbDropdownMenuVideo}
            />
          </div>
          <Filter
            page="video"
            dataList={activeCategoryVideoList}
            onKeyDownCallback={onKeyDownCallback}
            onClickSearchIcon={onClickSearchIcon}
            onChangeCallbackLeft={popularitySelectHandler}
            onChangeCallbackRight={timeSelectHandler}
            selected={params?.categoryName}
          />
          {activeCategoryVideoList && (
            <VideoTopBlock
              isLowercase
              videoTopBlockTitle={
                params.categoryName && categories[params.categoryName]
              }
              videoList={(popularitySelectValue
                ? filteredVideoList
                : activeCategoryVideoList
              ).slice(0, 2)}
            />
          )}
        </div>
        <DesktopHorizontalBanner />
        <div className="ads_slot_mobile_300">
          <div id="JT_300x250_between_videos"></div>
        </div>
        {!popularitySelectValue && allCategoryVideoList && (
          <SubTitle isLowercase subTitleText="All" />
        )}
        <InfiniteScroll
          initialLoad={false}
          loadMore={trottle}
          className="infinite-scroll-block"
          loader={<LoadingMore key={0} />}
          hasMore={
            popularitySelectValue ? filteredHasMore : hasMoreFromSelector
          }
        >
          <div className="video-category__bottom-wrapper">
            {(popularitySelectValue
              ? filteredVideoList
              : activeCategoryVideoList
            )
              .slice(2, 12)
              .map((item, index) => {
                return (
                  <VideoSectionItem
                    key={item.id}
                    videoId={item.id}
                    title={item.title}
                    imgUrl={item.thumbnail_url}
                    date={item.created_time}
                    itemUrl={item.url}
                    categoryName={categoryNameObj[item["channel.id"]]}
                    categoryUrl={`category/${
                      categoryUrlObj[item["channel.id"]]
                    }/`}
                    videoDetailPageLink={`video/${
                      categoryUrlObj[item["channel.id"]]
                    }/${item.id}/${encodeURIComponent(item.title)}/`}
                  />
                );
              })}              
            {(popularitySelectValue
              ? filteredVideoList
              : activeCategoryVideoList
            )
              .slice(12)
              .map((item, index) => {
                return (
                  <VideoSectionItem
                    key={item.id}
                    videoId={item.id}
                    title={item.title}
                    imgUrl={item.thumbnail_url}
                    date={item.created_time}
                    itemUrl={item.url}
                    categoryName={categoryNameObj[item["channel.id"]]}
                    categoryUrl={`category/${
                      categoryUrlObj[item["channel.id"]]
                    }/`}
                    videoDetailPageLink={`video/${
                      categoryUrlObj[item["channel.id"]]
                    }/${item.id}/${encodeURIComponent(item.title)}/`}
                  />
                );
              })}
          </div>
        </InfiniteScroll>
        <BannerMobile />
        </>
        )}
      </div>

      {/* Right vertical banner */}
      <div className="container-common__vertical-banner">
        <VerticalBanner />
      </div>

      <MobileStickyBottom />
    </main>
  );
}
export default VideoCategoryPage;
