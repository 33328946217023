import React from "react";
import { useLocation, Redirect } from 'react-router-dom'
import '../assets/css/partnershipPage.css'
import Logo from "@components/common/Logo/Logo";
import partnershipImg from '../assets/images/partnership-image.png'
import partnershipBrand from '../assets/images/partnership-brand.png'
import partnershipImgLg from '../assets/images/partnership-image-lg.png'
import backgroundPartnershipRed from '../assets/images/background-partnership-red.png'
import mouseIcon from '../assets/images/icons/mouse.svg'
import samsung from '../assets/images/brand/samsung.png'
import asus from '../assets/images/brand/asus.png'
import huawei from '../assets/images/brand/huawei.png'
import lenovo from '../assets/images/brand/lenovo.png'
import evercross from '../assets/images/brand/evercross.png'
import meizu from '../assets/images/brand/meizu.png'
import vivo from '../assets/images/brand/vivo.png'
import advan from '../assets/images/brand/advan.png'

import {PartnershipPageService} from "../http"
import MetaTag from '../components/MetaTag/MetaTag';
import { useEffect } from "react";
import { googleAnalytics } from "../helpers";

function PartnershipPage() {
    const isValidForm = () => {
        const $name = document.getElementById("name");
        const $nameError = document.getElementById("name-error");
        const $email = document.getElementById("email");
        const $emailError = document.getElementById("email-error");
        const $content = document.getElementById("content");
        const $contentError = document.getElementById("content-error");
        const name = $name.value;
        const email = $email.value;
        const content = $content.value;
    
        let isError = false;
    
        if (name.trim() === "") {
            $nameError.style.display = "block";
            isError = true;
        } else $nameError.style.display = "none";
    
        if (email.trim() === "") {
            $emailError.innerHTML ="<strong> Email harus diisi </strong>";
            $emailError.style.display = "block";
            isError = true;
        } else {
            if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)) {
                $emailError.innerHTML ="<strong> Format email tidak valid </strong>";
                $emailError.style.display = "block";
                isError = true;
            }else $emailError.style.display = "none";
        }
    
        if (content.trim() === "") {
            $contentError.style.display = "block";
            isError = true;
        } else $contentError.style.display = "none";
    
        return (!isError)
    }
    
    const submitForm = async (e) => {
        e.preventDefault()
        if(isValidForm()){
            const $type = document.getElementById("type");
            const $name = document.getElementById("name");
            const $email = document.getElementById("email");
            const $content = document.getElementById("content");
            const type = $type.value;
            const name = $name.value;
            const email = $email.value;
            const content = $content.value;
            
            var bodyFormData = new FormData()
            bodyFormData.append('type', type);
            bodyFormData.append('name', name);
            bodyFormData.append('email', email);
            bodyFormData.append('content', content);
            $name.value ='';
            $name.disabled = true;
            $email.value = '';
            $email.disabled = true;
            $content.value = '';
            $content.disabled = true;
    
            try {
                const response = await PartnershipPageService.sendForm(bodyFormData);
                if(response.status === 200){
                    alert('Pesan anda telah dikirim, kami akan segera membalas pesan anda.')
                }
            } catch (error) {
                // console.log(error);
            }
    
            $name.disabled = false;
            $email.disabled = false;
            $content.disabled = false;
        }
    }
    const location = useLocation();
    const currentPathName = location.pathname;

    useEffect(() => {
        googleAnalytics('Halaman Partnership | JalanTikus')
        // eslint-disable-next-line
    },[])

    if(currentPathName.substr(-1) !== "/") {
        return <Redirect to={`${currentPathName}/`} />
    }
    
    const renderMeta = () => {
        const title = 'Halaman Partnership | JalanTikus';
        const ogTitle = title;
        const description = 'Ingin produk anda lebih terkenal dan mendapatkan banyak penjualan? JalanTikus menawarkan kerjasama iklan kepada anda untuk mendapatkan hasil yang optimal.';
        const url = currentPathName.slice(1);
        const props = {title, description, url, ogTitle};
        return <MetaTag {...props} />
    }

    const brands = [
        {icon: samsung},
        {icon: asus},
        {icon: huawei},
        {icon: lenovo},
        {icon: evercross},
        {icon: meizu},
        {icon: vivo},
        {icon: advan},
    ]
    return(
        <main>
            <div className="partnership-top">
                {renderMeta()}
                <div className="partnership-image">
                    <div className="partnership-image__wrapper mobile">
                        <img src={partnershipImg} alt="" width="100%"/>
                    </div>
                    <div className="partnership-image__wrapper desktop">
                        <img src={partnershipImgLg} alt="" width="100%"/>
                    </div>
                </div>
                <div className="container-full-width description">
                    <div className="section-one__wrapper">
                        <div className="partnership-title">
                            Perkenalkan Produk Anda Sekarang, Bersama
                        </div>
                        <div className="partnership-logo__wrapper">
                            <Logo />
                        </div>

                        <div className="partnership-subtitle">
                            Kami berkomitmen untuk memaksimalkan semua produk yang di-iklankan kepada kami. Dengan memberikan pelayanan yang total dengan kualitas layanan terbaik, diharapkan produk yang di-iklankan bisa mendapatkan hasil yang optimal. Tertarik?
                        </div>

                        <a href="#container-full-width-statistic" className="partnership-red-btn">
                            Pelajari Selengkapnya
                        </a>
                        
                    </div>
                </div>
                <div className="container-full-width scroll">
                    <div className="partnership-scroll__wrapper">
                        <div className="partnership-icon__wrapper">
                            <img src={mouseIcon} width="32" height="32" alt="" />
                        </div>
                        Scroll to discover
                    </div>
                </div>
            </div>

            <div className="partnership-red-bg" id="container-full-width-statistic">
                <div className="partnership-bg-img desktop">
                    <img src={backgroundPartnershipRed} alt="" width="100%" height="100%"/>
                </div>
                <div className="container-full-width statistic" >
                    <div className="statistic__container">
                        <div className="statistic__wrapper">
                            <div className="statistic-title">
                                Avg. Pageviews/Month
                            </div>
                            <div className="statistic-number">
                                35M
                            </div>
                        </div>
                        <div className="statistic__wrapper">
                            <div className="statistic-title">
                                Daily Active Users
                            </div>
                            <div className="statistic-number">
                                600K
                            </div>
                        </div>
                        <div className="statistic__wrapper">
                            <div className="statistic-title">
                                Monthly Active Users
                            </div>
                            <div className="statistic-number">
                                20M
                            </div>
                        </div>
                        <div className="statistic__wrapper">
                            <div className="statistic-title">
                                Active Users/Minute
                            </div>
                            <div className="statistic-number">
                                4K
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        
                <div className="container-full-width brand">
                    <div className="partnership-brand__image-wrapper">
                        <img src={partnershipBrand} alt="" />
                    </div>
                    <div className="brand-description__container">
                        <div className="brand-section__title">
                            Creative Solutions And Results That Grow Brands.
                        </div>
                        <div className="brand-section__subtitle">
                            We believe our work speaks for itself. Browse our most recent projects 
                            below and enjoy our handmade work with love for every detail.
                        </div>

                        <div className="brand__wrapper">
                            {brands.map(brand => (
                            <a className="brand__icon" href="/#" key={brand.icon}>
                                <img src={brand.icon} alt="" />
                            </a>
                            ))}
                        </div>
                    </div>
                </div>
            
            <div className="partnership-grey-bg">
                <div className="container-full-width work-together">
                    <div className="work-together__wrapper">
                        <div className="work-together__item">
                            <div className="work-together__title">
                                Let's Work Together.
                            </div>
                            <div className="work-together__subtitle">
                                Let’s find out how to work together and create something meaningful or valuable.
                            </div>
                            <div className="work-together__social">
                                <a className="work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-social-fb"></span>
                                </a>
                                <a className="work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-instagram"></span>
                                </a>
                                <a className="work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-youtube"></span>
                                </a>
                                <a className="work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-twitter"></span>
                                </a>
                                <a className="work-together__social-wrapper" target="_blank" href="/#">
                                    <span className="icon-social-email white"></span>
                                </a>
                            </div>
                            <a href="mailto:info@jalantikus.com" className="work-together__email-wrapper">
                                info@jalantikus.com
                            </a>
                        </div>
                        <div className="work-together__item">
                            <form className="partnership-form__wrapper" id="formPartnership">
                                <div className="partnership-input__wrapper">
                                    <label className="jt-form-label">
                                        YOUR NAME
                                    </label>
                                    <input type="hidden" name="type" id="type" value="1" />
                                    <input type="text" name="name" id="name" className="jt-form"/>
                                    <div className="input-info-error" id="name-error" style={{display: 'none'}}><strong>Nama harus diisi</strong></div>
                                </div>
                                <div className="partnership-input__wrapper">
                                    <label className="jt-form-label">
                                        YOUR EMAIL
                                    </label>
                                    <input type="email" name="email" id="email" className="jt-form"/>
                                    <div className="input-info-error" id="email-error" style={{display: 'none'}}><strong>Email harus diisi</strong></div>
                                </div>
                                {/* <div className="partnership-input__wrapper">
                                    <label className="jt-form-label">
                                        YOUR SUBJECT
                                    </label>
                                    <input type="text" name="subject" className="jt-form"/>
                                </div> */}
                                <div className="partnership-input__wrapper">
                                    <label className="jt-form-label">
                                        YOUR MESSAGE
                                    </label>
                                    <input type="text" name="content" id="content" className="jt-form"/>
                                    <div className="input-info-error" id="content-error" style={{display: 'none'}}><strong>Pesan harus diisi</strong></div>
                                </div>
                                <div className="parntership-input__wrapper">
                                    <button className="btn-send-msg" onClick={(e) => submitForm(e)}>
                                        SEND MESSAGE
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default PartnershipPage;