import React from "react";
import Carousel from "react-multi-carousel";
import "../AppsHeadlineSlider/multi-carousel/styles.css";
import "./DAppsHeadlineSlider.css";
import DAppsHeadlineSliderItem from "./DAppsHeadlineSliderItem";

function DAppsHeadlineSlider({ articles }) {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1025,
      },
      items: 3,
      partialVisibilityGutter: 10,
    },
    mobile: {
      breakpoint: {
        max: 768,
        min: 1,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 769,
      },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  return (
    <div className="dApps-headline__container">
      <div className="dApps-headline__wrapper">
        <div className="dApps-headline__left-blur"></div>
        <Carousel
          additionalTransfrom={-55}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={true}
          containerClass="container"
          dotListClass="dapps-dot-list"
          draggable
          focusOnSelect
          infinite
          itemClass="card-item"
          keyBoardControl
          minimumTouchDrag={100}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          showDots={true}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {articles && articles.length > 1
            ? articles.map((item, index) => {
                return <DAppsHeadlineSliderItem article={item} key={index} />;
              })
            : [...Array(3)].map((e, i) => {
                return <DAppsHeadlineSliderItem key={i} />;
              })}
        </Carousel>{" "}
        <div className="dApps-headline__right-blur"></div>
      </div>
    </div>
  );
}

export default DAppsHeadlineSlider;
