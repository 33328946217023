import { javantApi } from "../constants";

export const ArticlePageService = {
  getArticleData(categName, articleSlug) {
    return javantApi.get(`content/article/${categName}/detail/${articleSlug}`);
  },
  getArticlePreviewData(categName, articleSlug) {
    return javantApi.get(`content/article/${categName}/detail/${articleSlug}/preview`);
  },
  getSearchArticleData(keyword) {
    return javantApi.get(`search?q=${keyword}`);
  },
  getInfiniteArticleData(page, keyword) {
    return javantApi.get(`search&page=${page}?q=${keyword}`);
  },
  getSuggestionData(keyword) {
    return javantApi.get(`/search/suggest?q=${keyword}`);
  },
  getAdsData(){
    return javantApi.get(`component/article/fetchParts/content?pid=1&page=1&type=regular`);
  }
};
