import React, {
  // useEffect,
  useMemo,
  // useRef,
  // useState,
  // useStateuseRef,
} from "react";
import "./VideoSectionItem.css";
import "moment/locale/id";
import PostInfo from "@components/common/PostInfo/PostInfo";
import useCustomLazy from "../../hooks/useCustomLazy";
import LazyLoader from "@components/LazyLoader/LazyLoader";

function VideoSectionItem({
  title,
  imgUrl,
  date,
  isNumbered,
  index,
  categoryName,
  categoryUrl,
  videoDetailPageLink,
  videoId,
}) {
  const { imageRef, loaded, hasError } = useCustomLazy();
  const postImageUrl = useMemo(() => {
    if (hasError) return "https://via.placeholder.com/150x100";
    return imgUrl;
    // eslint-disable-next-line
  }, [hasError]); 
  return (
    <>
      <div className="video-section__item">
        {!loaded && <LazyLoader />}
        <div
          className={`${
            !loaded && "video-section__item-outer_hidden"
          } video-section__item-outer`}
        >
          <div className="video-section__item-outer">
            <a href={`/${videoDetailPageLink}`}>
            <div className={`video-section__item-wrapper `}>
              <div className="video-section__item-img-link" >
                {isNumbered && (
                  <div className="video-section__item-number-wrapper">
                    <p className="video-section__item-number">{index + 1}</p>
                  </div>
                )}

                <img
                  ref={imageRef}
                  className="video-section__item-img"
                  src={postImageUrl}
                  alt=""
                />
              </div>
            </div>
            </a>
            <a href={`/${videoDetailPageLink}`}
              className="video-section__item-title-wrapper"
            >
              <p className="video-section__item-title category-small">
                {title}
              </p>
            </a>

            <PostInfo
              categoryName={categoryName}
              categoryUrl={categoryUrl}
              date={date * 1000}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoSectionItem;
