import { HomePageService, TagsPageService } from "../../http";

/*ACTION TYPES*/
const GET_HOMEPAGE_DATA = "GET_HOMEPAGE_DATA";
const GET_OTHER_HOMEPAGE_DATA = "GET_OTHER_HOMEPAGE_DATA";
// const GET_WATCH_NOW_DATA = "GET_WATCH_NOW_DATA";
const GET_POPULER_DATA = "GET_POPULER_DATA";
const GET_NEWS_ARTICLE_DATA = "GET_NEWS_ARTICLE_DATA";
const GET_EV_ARTICLE_DATA = "GET_EV_ARTICLE_DATA";
const GET_FINTECH_ARTICLE_DATA = "GET_FINTECH_ARTICLE_DATA";
const GET_FINANCE_ARTICLE_DATA = "GET_FINANCE_ARTICLE_DATA";
const GET_TECHHACK_ARTICLE_DATA = "GET_TECHHACK_ARTICLE_DATA";
const GET_APPS_ARTICLE_DATA = "GET_APPS_ARTICLE_DATA";
const GET_AFFILIATE_DATA = "GET_AFFILIATE_DATA";

/*ACTION CREATORS*/
export const getHomepageData = (payload) => ({
  type: GET_HOMEPAGE_DATA,
  payload: payload,
});

export const getOther = (payload) => ({
  type: GET_OTHER_HOMEPAGE_DATA,
  payload: payload,
});

// export const getWatchNowData = (payload) => ({
//   type: GET_WATCH_NOW_DATA,
//   payload: payload,
// }); 

export const getPopulerData = (payload) => ({
  type: GET_POPULER_DATA,
  payload: payload,
});

export const getNewsArticleData = (payload) => ({
  type: GET_NEWS_ARTICLE_DATA,
  payload: payload,
});

export const getEvArticleData = (payload) => ({
  type: GET_EV_ARTICLE_DATA,
  payload: payload,
});

export const getFintechArticleData = (payload) => ({
  type: GET_FINTECH_ARTICLE_DATA,
  payload: payload
})

export const getFinanceArticleData = (payload) => ({
  type: GET_FINANCE_ARTICLE_DATA,
  payload: payload,
});

export const getTechHackArticleData = (payload) => ({
  type: GET_TECHHACK_ARTICLE_DATA,
  payload: payload,
});

export const getAppsArticleData = (payload) => ({
  type: GET_APPS_ARTICLE_DATA,
  payload: payload,
});

export const getAffiliatesData = (payload) => ({
  type: GET_AFFILIATE_DATA,
  payload: payload,
});

/*THUNK*/
export const getHomepageDataThunk = () => async (dispatch) => {
  try {
    const response = await HomePageService.getData();
    dispatch(getHomepageData(response.data));
  } catch (error) {
    dispatch(getHomepageData(error));
  }
};

export const getOtherDataThunk = () => async (dispatch) => {
  try {
    const response = await HomePageService.getOtherData();
    dispatch(getOther(response.data));
  } catch (error) {
  }
};

// export const getWatchNowDataThunk = () => async (dispatch) => {
//   try {
//     const response = await HomePageService.getWatchNowData();
//     dispatch(getWatchNowData(response.data));
//   } catch (error) {
//   }
// };

export const getPopulerDataThunk = () => async (dispatch) => {
  try {
    const response = await HomePageService.getPopulerData();
    dispatch(getPopulerData(response.data));
  } catch (error) {
  }
};

export const getNewsArticles = () => async (dispatch) => {
  try {
    const response = await HomePageService.getNewsArticle();
    dispatch(getNewsArticleData(response.data));
  } catch (error) {
    dispatch(getNewsArticleData(error));
  }
};

export const getEvArticles = () => async (dispatch) => {
  try {
    const response = await HomePageService.getEvArticle();
    dispatch(getEvArticleData(response.data));
  } catch (error) {
    dispatch(getEvArticleData(error));
  }
};

export const getFintechArticles = () => async (dispatch) => {
  try {
    const response = await TagsPageService.getData('penghasil-uang');
    dispatch(getFintechArticleData(response.data));
  } catch (error) {
    dispatch(getFintechArticleData(error));
  }
};

export const getFinanceArticles = () => async (dispatch) => {
  try {
    const response = await HomePageService.getFinanceArticle();
    dispatch(getFinanceArticleData(response.data));
  } catch (error) {
    dispatch(getFinanceArticleData(error));
  }
};

export const getTechHackArticles = () => async (dispatch) => {
  try {
    const response = await HomePageService.getTechHackArticle();
    dispatch(getTechHackArticleData(response.data));
  } catch (error) {
    dispatch(getTechHackArticleData(error));
  }
};

export const getAppsArticles = () => async (dispatch) => {
  try {
    const response = await HomePageService.getAppsArticle();
    dispatch(getAppsArticleData(response.data));
  } catch (error) {
    dispatch(getAppsArticleData(error));
  }
};

export const getAllDataHomeThunk = () => async (dispatch) => {
  try {
    await dispatch(getHomepageDataThunk());
    await dispatch(getOtherDataThunk());
    // await dispatch(getWatchNowDataThunk());
    await dispatch(getPopulerDataThunk());
  } catch (error) {
  }
};

export const getAffiliates = () => async (dispatch) => {
  try {
    const response = await HomePageService.getAffiliateData();
    dispatch(getAffiliatesData(response.data));
  } catch (error) {
    dispatch(getAffiliatesData(error));
  }
};

/*INITIAL STATE*/
const initialState = {
  homepageData: null,
  other: null,
  // watchNowData: null,
  populerData: null,
  newsArticleData: null,
  evArticleData: null,
  fintechArticleData: null,
  financeArticleData: null,
  techHackArticleData: null,
  appsArticleData: null,
  getAffiliates: null
};

/*REDUCER*/
function homepageReducer(state = initialState, action) {
  switch (action.type) {
    /*Get data for Homepage*/
    case GET_HOMEPAGE_DATA:
      return {
        ...state,
        homepageData: action.payload,
      };
    case GET_OTHER_HOMEPAGE_DATA:
      return {
        ...state,
        other: action.payload,
      };

    // case GET_WATCH_NOW_DATA:
    //   return {
    //     ...state,
    //     watchNowData: action.payload,
    //   };
    case GET_POPULER_DATA:
      return {
        ...state,
        populerData: action.payload,
      };
    case GET_NEWS_ARTICLE_DATA:
      return {
        ...state,
        newsArticleData: action.payload,
      };
    case GET_EV_ARTICLE_DATA:
      return {
        ...state,
        evArticleData: action.payload,
      };
    case  GET_FINTECH_ARTICLE_DATA:
      return {
        ...state,
        fintechArticleData: action.payload,
      };
    case GET_FINANCE_ARTICLE_DATA:
      return {
        ...state,
        financeArticleData: action.payload,
      };
    case GET_TECHHACK_ARTICLE_DATA:
      return {
        ...state,
        techHackArticleData: action.payload,
      };
    case GET_APPS_ARTICLE_DATA:
      return {
        ...state,
        appsArticleData: action.payload,
      };
    case GET_AFFILIATE_DATA:
      return {
        ...state,
        getAffiliates: action.payload,
      };
    default:
      return state;
  }
}

export default homepageReducer;
