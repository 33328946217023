import React from "react";
import slugify from 'react-slugify';
import "./VideoTopBlock.css";
import SubTitle from "../common/Titles/SubTitle";
import VideoSectionItem from "@components/VideoSectionItem/VideoSectionItem";
import { categoryNameObj, categoryUrlObj } from "../../constants/index";

function VideoTopBlock({ videoTopBlockTitle, videoList, isLowercase }) {
  return (
    <div className="video-top-block">
      <SubTitle isLowercase={isLowercase} subTitleText={videoTopBlockTitle} />
      <div className="video-top-block__wrapper">
        {videoList.map((item, index) => {
          return (
            <VideoSectionItem
              key={item.id}
              videoId={item.id}
              title={item.title}
              imgUrl={item.thumbnail_url}
              date={item.created_time}
              itemUrl={item.url}
              categoryName={categoryNameObj[item["channel.id"]]}
              categoryUrl={`category/${categoryUrlObj[item["channel.id"]]}/`}
              videoDetailPageLink={`video/${
                categoryUrlObj[item["channel.id"]]
              }/${item.id}/${slugify(item.title)}/`}
            />
          );
        })}
      </div>
    </div>
  );
}

export default VideoTopBlock;
