import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect, useHistory } from "react-router-dom";
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AlertCopyClip from "../components/ShareSocials/AlertCopyClip";
import { googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody, imgSrc } from "../helpers";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';
import { getVehicleDetailDataThunk, getVehicleSuggestionDataThunk } from "../store/ducks/electricVehiclePageReducer";
import MetaTag from "../components/MetaTag/MetaTag";
import useSearch from "../hooks/useSearch";
import { breadcrumbDropdownMenuVehicles } from "../constants";
import Filter from "../components/common/Filter/Filter";
import Error from  '../components/Error/Error';
import EVTopInfo from "../components/EVTopInfo/EVTopInfo";
import EVFullSpecs from "../components/common/EVFullSpecs/EVFullSpecs";
import EVRecommendation from "../components/EVRecommendation/EVRecommendation";

function EVDetailPage() {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleDetailData = useSelector((state) => state.eletricVehiclePageReducer.vehicleDetail);
  const vehicleType = params.vehicle_type;

  useEffect(() => {
    dispatch(getVehicleDetailDataThunk(params));
    googleAnalytics()
    loadAds()
    // eslint-disable-next-line
  }, [params]);

  const renderMeta = (vehicleDetailData) => {
    const url = vehicleDetailData.response.links.detail.replace(/\/?$/, '/');
    const title = (vehicleDetailData.response.meta_title) ? vehicleDetailData.response.meta_title : vehicleDetailData.response.title + ' | JalanTikus';
    const ogTitle = title;
    const description = vehicleDetailData.response.summary;
    const image = imgSrc(vehicleDetailData.response && vehicleDetailData.response?.image, 769, 330);
    const props = { url, title, description, image, ogTitle };
    return <MetaTag {...props} />
  }

  const vehicleData = vehicleDetailData && vehicleDetailData.code === 200 && vehicleDetailData.response

  const { onClickSearchIcon, onKeyDownCallback } = useSearch({
    category: vehicleType,
    suggestionDataThunk: getVehicleSuggestionDataThunk,
  });

  const onClickSuggestion = (value, url) => {
    let escapedValue = value.replace(/\+/g,"%20")
    history.push(`/search/${vehicleType}/${escapedValue}`);
  };

  const suggestionData = useSelector(
    (state) => state.eletricVehiclePageReducer.vehicleSuggestionData
  );

  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`EVDetailPage`);

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

    let slots = []
    if (isMobile) {
      // available slot in this page
      slots = [{
        slot: "JT_320x100", // define slot
        id: "adSlotMobTop-category",
        wrapperId: "JT_320x100",
        defaultSize: "320x100"
      }, {
        slot: "JT_320x100", // define slot
        id: "adSlotMobSticky-category",
        wrapperId: "JT_320x100-sticky-bottom",
        defaultSize: "320x100"
      }]
    } else {
      slots = [{
        slot: "JT_970x90/JT_970x90_top", // define slot
        id: "adSlotDesktop0", // whatever id
        wrapperId: "JT_970x90_top", // must match with id in html
        defaultSize: "970x90"
      }]
    }
    // create ad unit tag head
    for (let i = 0; i < slots.length; i++) {
      _scriptTagHead.text += generateAdUnitTagHead(slots[i])
    }
    $currpage && $currpage.appendChild(_scriptTagHead)

    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
      generateAdUnitTagBody(slots[i])
    }
  }

  return (
    <>
      <main className="container-common__wrapper" id="EVDetailPage">
        {vehicleDetailData && vehicleDetailData.code === 200 && renderMeta(vehicleDetailData)}

        {/* Left vertical banner */}
        <div className="container-common__vertical-banner">
          <VerticalBanner />
        </div>

        <div className="container-common">
          <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{ minHeight: '100px' }} />
          <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{ minHeight: '100px' }} />

          <div className="video-category__breadcrumbs">
            <Breadcrumbs
              categName="Kendaraan Listrik"
              categSlug='kendaraan-listrik'
              articleSlug={vehicleData && vehicleData.title}
              className="article-top-info__breadcrumbs"
              breadcrumbDropdownInfo={breadcrumbDropdownMenuVehicles}
              hasDropdown
            />
          </div>

          <Filter
            page="vehicle"
            type={vehicleType}
            noSelect
            inputPlaceholder="Search Vehicle.."
            onKeyDownCallback={onKeyDownCallback}
            selected={params.brand}
            suggestionList={suggestionData}
            onClickSuggestion={onClickSuggestion}
            onClickSearchIcon={onClickSearchIcon}
            categoriesData={[]}
          />
          {vehicleDetailData && vehicleDetailData.code !== 200 ? (
            vehicleDetailData.code === 301 ? <Redirect to={`/${vehicleDetailData.response.redirect}`} /> :
              <div className="container-common">
                <Error code={vehicleDetailData.code ? vehicleDetailData.code : '503'} />
              </div>
          ) : (
            <>
              {vehicleDetailData &&
                <>
                  <EVTopInfo vehicle={vehicleDetailData.response} />
                  <EVFullSpecs vehicle={vehicleDetailData.response} />
                  <EVRecommendation title={vehicleType} items={vehicleDetailData.response.rekomendasiLainnya} />
                </>
              }
            </>
          )}
        </div>

        {/* Right vertical banner */}
        <div className="container-common__vertical-banner">
          <VerticalBanner />
        </div>

        <AlertCopyClip />
        <MobileStickyBottom />
      </main>
    </>
  )
}

export default EVDetailPage