import { dailymotionApi } from "../constants";

export const VideoPageService = {
  getLatestVideoList() {
    return dailymotionApi.get(
      "/videos?owners=x2hdhi4&sort=recent&fields=id,title,thumbnail_url,created_time,url,channel.id&limit=2"
    );
  },
  getTechnoVideoList() {
    return dailymotionApi.get(
      "/videos?owners=x2hdhi4&channel=tech&fields=id,title,thumbnail_url,created_time,url,channel.id&limit=4"
    );
  },
  getGamingVideoList() {
    return dailymotionApi.get(
      "/videos?owners=x2hdhi4&channel=videogames&fields=id,title,thumbnail_url,created_time,url,channel.id&limit=4"
    );
  },
  getNewsVideoList() {
    return dailymotionApi.get(
      "/videos?owners=x2hdhi4&channel=news&fields=id,title,thumbnail_url,created_time,url,channel.id&limit=4"
    );
  },
  getHiburanVideoList() {
    return dailymotionApi.get(
      "videos?owners=x2hdhi4&channel=lifestyle&fields=id,title,thumbnail_url,created_time,url,channel.id&limit=4"
    );
  },
  getPopularVideoList() {
    return dailymotionApi.get(
      "/videos?owners=x2hdhi4&sort=visited&fields=id,title,thumbnail_url,created_time,url,channel.id&limit=5"
    );
  },
  getVideoByCategory(category, limit=4){
    return dailymotionApi.get(
      `/videos?owners=x2hdhi4&channel=${category}&fields=id,title,thumbnail_url&limit=${limit}`
    );
  }
};
