const defaultMeta = {
  'title': 'Solusi Lengkap Download, Informasi Teknologi, Lifestyle dan Gadget',
  'ogTitle': 'Solusi Lengkap Download, Informasi Teknologi, Lifestyle dan Gadget',
  'description': 'JalanTikus adalah website teknologi berbahasa Indonesia yang menyajikan informasi gadget, game Android, iOS, film, anime dan informasi teknologi lainnya.',
  'url': '',
  // 'image': 'https://assets.jalantikus.com/assets/cache/0/0/userfiles/2019/12/11/jalantikus-sharer-thumbnail-image-7cb8d.jpg'
}

const getFBPropertyMetas = (meta) => {
  return {
    'og:site_name': 'JalanTikus',
    'og:url': `${ process.env.REACT_APP_WEB_URL }${ meta.url.replace(/\/?$/, '/') }`,
    'og:type': 'article',
    'og:title': meta.ogTitle,
    'og:description': meta.description,
    'og:image': meta.image,
    'og:image:width': 600,
    'og:image:height': 315,
  }
}

const getTwitterNameMetas = (meta) => {
  return {
    'twitter:card': 'summary_large_image',
    'twitter:creator': 'jalantikuscom',
    'twitter:title': meta.title,
    'twitter:description': meta.description,
    // 'twitter:image:src': meta.image,
  }
}

const getAllMetaTags = (meta = {}) => {
  meta = {...defaultMeta, ...meta};
  
  // title
  let metaTags = `<title>${ meta.title??'JalanTikus' }</title>`;

  // meta
  metaTags += `<meta name="description" content="${ meta.description }" />`;
  if (typeof meta.robots !== 'undefined') {
    metaTags += `<meta name="robots" content="${ meta.robots }" />`;
  }

  // append fb tags
  const properties = getFBPropertyMetas(meta);
  for (const name in properties) {
    metaTags += `<meta property="${ name }" content="${ properties[name] }" />`;
  }

  // append twitter tags
  const names = getTwitterNameMetas(meta);
  for (const name in names) {
    metaTags += `<meta name="${ name }" content="${ names[name] }" />`;
  }

  // link
  // metaTags += `<link rel="preload" as="image" href="${meta.image}">`;
  if (meta.url.endsWith('/')){
    if (meta.url==="https://jalantikus.com/"){
      metaTags += `<link rel="canonical" href="${ process.env.REACT_APP_WEB_URL }" />`;
    }else{
      metaTags += `<link rel="canonical" href="${ process.env.REACT_APP_WEB_URL }${ meta.url }" />`;
    }
    if (typeof meta.amphtmlUrl !== 'undefined') {
      metaTags += `<link rel="amphtml" href="${ process.env.REACT_APP_WEB_URL }${ meta.amphtmlUrl }" />`;
    }
  }else{
    if (meta.url===""){
      metaTags += `<link rel="canonical" href="${ process.env.REACT_APP_WEB_URL }/" />`;
    }else{
      if (meta.url==="https://jalantikus.com"){
        metaTags += `<link rel="canonical" href="${ process.env.REACT_APP_WEB_URL }/" />`;
      }else{
        metaTags += `<link rel="canonical" href="${ process.env.REACT_APP_WEB_URL }/${ meta.url }/" />`;
      }
    }
    if (typeof meta.amphtmlUrl !== 'undefined') {
      metaTags += `<link rel="amphtml" href="${ process.env.REACT_APP_WEB_URL }${ meta.amphtmlUrl }/" />`;
    }
  }
  return metaTags;
}

export { getAllMetaTags, defaultMeta };