export const faqGeneral = [
    { 
        question: "Apa itu JalanTikus?", 
        answer: "<p>JalanTikus adalah sebuah portal teknologi online yang memberikan layanan download aplikasi dan games gratis, tidak hanya itu, JalanTikus juga menyajikan konten-konten menarik yang mengedukasi masyarakat dan juga memberikan hiburan yang unik.</p>" 
    },
    { 
        question: "Saya ingin mengajak JalanTikus untuk bekerjasama, bagaimana caranya?", 
        answer: '<p>Kami dengan senang hati menerima setiap tawaran kerjasama yang masuk, Silahkan kirim ajuan kerjasama kamu ke <a href="mailto:info@jalantikus.com">info@jalantikus.com</a></p>'
    },
    { 
        question: "Apakah saya bisa memasang iklan di JalanTikus?", 
        answer: 'Tentu saja. Kami akan mengirimkan kamu daftar harga (price list) iklan yang ada di JalanTikus. Kirim permohonan pemasangan iklan ke email kami <a href="mailto:info@jalantikus.com">info@jalantikus.com</a>' 
    },
    { 
        question: "JalanTikus itu perorangan atau organisasi?", 
        answer: "JalanTikus memiliki tim, yang terdiri dari beberapa divisi, sehingga kami lebih dikenal sebagai sebuah organisasi dan bukan perorangan." 
    },
    { 
        question: "Apakah saya bisa bergabung menjadi Tim JalanTikus?", 
        answer: '<p>Tentu saja, Kami sangat senang jika kamu bersemangat menjadi anggota tim JalanTikus. Silahkan kirimkan CV &amp; Lamaran kamu ke <a href="mailto:info@jalantikus.com">info@jalantikus.com</a></p>' 
    },
];

export const faqDownload = [
    { 
        question: "Apakah semua aplikasi & games yang ada di JalanTikus dibagikan secara gratis dan legal?", 
        answer: "Ya, JalanTikus hanya membagikan aplikasi & games yang gratis (Freeware) dan legal, dalam artian tidak (bajakan) hasil crack, patch atau semacamnya." 
    },
    { 
        question: "Apakah semua aplikasi & games yang Saya download terbebas dari Virus?", 
        answer: "Ya, JalanTikus selalu melakukan scanning dengan 3 jenis Antivirus (Kaspersky, AVG & Avast) sebelum menerbitkan suatu aplikasi atau games, sehingga bisa dijamin 100% terbebas dari virus." 
    },
    { 
        question: "Aplikasi & Games yang Saya download meminta registrasi serial number setelah 30 hari?", 
        answer: 'Meskipun dibagikan secara gratis, namun ada beberapa aplikasi & games yang dibagikan secara Shareware, dalam arti hanya bisa digunakan dalam jangka waktu tertentu dan jika ingin lanjut menggunakannya kamu harus membeli lisensi aslinya.' 
    },
    { 
        question: "Saya mempunyai aplikasi / games buatan sendiri, apa bisa diterbitkan di JalanTikus?", 
        answer: '<p>Tentu saja bisa. Silahkan kirim email ke <a href="mailto:info@jalantikus.com">info@jalantikus.com</a> dengan menyertakan Nama Aplikasi/Games, Deskripsi serta Lampiran File instalasi / (APK) jika Android</p>'
    },
    { 
        question: "Kenapa aplikasi / games di JalanTikus tidak selalu update?", 
        answer: '<p>Demi menjaga kualitas aplikasi dan games yang ada di JalanTikus, hingga saat ini kita masih melakukan upload-download secara manual, sehingga kuota sebesar ribuan aplikasi & games tidak dapat tercapai dalam waktu yang singkat.</p>' 
    },
    { 
        question: "Saya tidak menemukan pertanyaan yang tepat, di mana saya bisa bertanya?", 
        answer: '<p>Kami dengan senang hati menjawab setiap pertanyaan yang masuk. Kirim pertanyaan kamu ke <a href="mailto:info@jalantikus.com">info@jalantikus.com</a></p>' 
    },
];

export const faqArticle = [
    { 
        question: "Artikel apa saja yang ada di JalanTikus?", 
        answer: "Hingga saat ini, JalanTikus membahas berbagai macam artikel, seperti edukasi dan pemecahan masalah yang biasa dikenal sebagai Tips, serta membahas hal-hal unik dan menarik yang bisa kamu temui di Gokil. Selain itu ada juga ulasan menarik serta review tentang Gadget hingga berita-berita paling baru yang bisa kamu temui di News." 
    }
];

export const faqDApp = [
    {
      question: "FAQ: Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      answer:
        "<p class='mb-20'>Ut tortor nibh, euismod vitae blandit id, elementum non tellus. Sed viverra velit vitae ligula consequat, non tristique elit porttitor. Morbi finibus nisl nunc, tempor gravida mauris feugiat sit amet. Curabitur lobortis, nulla ut vulputate dapibus, justo elit aliquet ante, ut pellentesque odio urna sed turpis. Mauris eu ex et libero vehicula suscipit eu vel magna. Duis eget odio nibh. Aliquam pretium dui ut aliquet dapibus. Nulla tempor leo elit, in scelerisque mi eleifend vel. Cras lorem risus, vehicula consequat tempus in, ullamcorper malesuada dolor. Vestibulum rhoncus leo vitae magna aliquet pellentesque. Maecenas facilisis elementum neque sed vehicula. Pellentesque in rhoncus odio, id fermentum ligula.</p>" +
        "<p class='mb-10'>Donec in porta arcu, ac semper leo. Nullam ex justo, varius a nunc in, ultricies sagittis risus. Curabitur fermentum vitae nunc id tincidunt. Ut finibus suscipit neque et lacinia. Duis finibus mollis risus in luctus. Suspendisse consequat justo a dolor accumsan pharetra. Etiam ut iaculis nisl. Nulla commodo luctus sapien, vel maximus nisi sagittis porta. Morbi sed dui in dui porttitor suscipit. Suspendisse eget ex molestie, rhoncus lacus vel, fermentum elit. Quisque bibendum felis non blandit pretium. Morbi sollicitudin odio a nibh dignissim, vitae lobortis tortor fermentum. Nam vulputate nunc at sapien ullamcorper ultrices. Morbi ut mattis urna. Curabitur hendrerit eget tellus nec rutrum.</p>",
    },
    {
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      answer:
        "<p class='mb-20'>Ut tortor nibh, euismod vitae blandit id, elementum non tellus. Sed viverra velit vitae ligula consequat, non tristique elit porttitor. Morbi finibus nisl nunc, tempor gravida mauris feugiat sit amet. Curabitur lobortis, nulla ut vulputate dapibus, justo elit aliquet ante, ut pellentesque odio urna sed turpis. Mauris eu ex et libero vehicula suscipit eu vel magna. Duis eget odio nibh. Aliquam pretium dui ut aliquet dapibus. Nulla tempor leo elit, in scelerisque mi eleifend vel. Cras lorem risus, vehicula consequat tempus in, ullamcorper malesuada dolor. Vestibulum rhoncus leo vitae magna aliquet pellentesque. Maecenas facilisis elementum neque sed vehicula. Pellentesque in rhoncus odio, id fermentum ligula.</p>" +
        "<p>1. Lorem ipsum dolor sit amet, consectetur adipiscing </p>" +
        "<p>2. Donec auctor metus nec massa faucibus porttitor.</p>" +
        "<p>3. Nam scelerisque diam sollicitudin, interdum justo id, vestibulum lacus.</p>" +
        "<p>4. n eget leo eu orci molestie consequat.</p>" +
        "<p>5. Maecenas viverra leo ut ex sodales, sed gravida ex lobortis.</p>",
    },
  ];
