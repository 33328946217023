import React from "react";
import "@components/CreateAccount/CreateAccount.css";
import "./FollowUs.css";

function FollowUs() {
  return (
    <>
      <div className="sidebar-widget__container follow-us-widget__container">
        <div className="follow-us-title title-medium">FOLLOW US</div>
        <div className="follow-us-social-icons__wrapper">
          <a
            className="follow-us-social-icons__link facebook"
            href="/handler/social/?u=https://www.facebook.com/JalanTikus/"
          >
            <span className="icon-social-fb"></span>
          </a>
          <a
            className="follow-us-social-icons__link twitter"
            href="/handler/social/?u=https://twitter.com/jalantikuscom"
          >
            <span className="icon-twitter"></span>
          </a>
          <a
            className="follow-us-social-icons__link youtube"
            href="/handler/social/?u=https://www.youtube.com/user/JalanTikusOfficial"
          >
            <span className="icon-youtube"></span>
          </a>
          <a
            className="follow-us-social-icons__link instagram"
            href="/handler/social/?u=https://www.instagram.com/jalantikusid/"
          >
            <span className="icon-social-ig"></span>
          </a>
        </div>
      </div>
    </>
  );
}

export default FollowUs;
