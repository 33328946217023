import React from 'react';
import { imgSrc} from "../../helpers";

const DefaultImage = () => {
  return (
    <img src={imgSrc(null, 0, 0)} alt="Default" />
  )
}

export default DefaultImage
