import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FollowUs from "@components/FollowUs/FollowUs";
// import CreateAccount from "@components/CreateAccount/CreateAccount";
import PopularToday from "@components/PopularToday/PopularToday";
import RecentPosts from "../components/RecentPosts/RecentPosts";
import ArticleTopInfo from "../components/ArticleTopInfo/ArticleTopInfo";
import ArticleMainContent from "../components/ArticleMainContent/ArticleMainContent";
import { getArticlePageDataThunk } from "../store/ducks/articlePageReducer";
import { useParams, useLocation } from "react-router-dom";
import ShareSocials from "@components/ShareSocials/ShareSocials";
import "../assets/css/articlePage.css";
import "../assets/css/articleContent.css";
import BottomPostsBlock from "../components/BottomPostsBlock/BottomPostsBlock";
import Error from  '../components/Error/Error'
import {
  getOtherDataThunk,
  getHomepageDataThunk,
  getAffiliates
} from "../store/ducks/homepageReducer";
import MetaTag from '../components/MetaTag/MetaTag';
import { imgSrc } from "../helpers";
import { Redirect } from "react-router-dom";
import {googleAnalytics, shareSocial, generateAdUnitTagBody, generateAdUnitTagHeadWithTargeting} from '../helpers'
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';
import AlertCopyClip from "../components/ShareSocials/AlertCopyClip";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Affiliate from "../components/Affiliate/Affiliate";

function ArticlePage() {
  const wordsToRemove = [
    "domino",
    "higgs",
    "mod",
    "hack",
    "xnxx",
    "bokeh",
    "museum",
    "aplikasi dewasa",
    "yandex",
    "judi",
    "slot",
    "dewasa",
    "sadap",
    "bobol",
    "cheat",
    "lacak",
    "open bo",
    "pinjol",
    "pinjaman online",
    "cara pinjam",
    "simontok",
    "xnxubd",
    "uncensored",
    "doodstream",
    "dood",
    "18+",
    "xnx",
    "japanese",
    "vcs",
    "barbar",
    "coolmex",
    "colmek",
    "live bar bar",
    "nekopoi",
    "kucing pink",
    "penghapus baju",
    "tembus pandang"
  ];
  const history = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const { width } = useWindowDimensions();

  const articlePageData = useSelector(
    (state) => state.articlePageReducer.articlePageData
  );

  const homepageData = useSelector(
    (state) => state.homepageReducer.homepageData
  );
  const otherData = useSelector((state) => state.homepageReducer.other);

  // const affiliateData = useSelector(
  //   (state) => state.homepageReducer.getAffiliates
  // );
  
  const renderMeta = (articlePageData) => {
    const authorName = articlePageData.response.user.name;
    const authorImage = articlePageData.response.user.profile_picture.file;
    const authorImageID = authorImage.split('/').pop().replace(".jpeg","");
    let authorLink = "https://jalantikus.com/" + articlePageData.response.user.links.profile;
    authorLink = authorLink.replace("user", "author");
    const dateModified = articlePageData.response.published.replace(/\s+/g, "T") + "+07:00";
    const datePublished = dateModified;
    // const mySrc = 
    // {
    //   "@type":"ImageObject",
    //   "url": imgSrc(articlePageData.response && articlePageData.response?.banner_image, 769, 330),
    //   "height": 330,
    //   "width": 769
    // }

    const url = articlePageData.response.links.detail.replace(/\/?$/, '/');
    const imageUrl = articlePageData.response.banner_image.file;
    const title = `${articlePageData.response.meta_title} | JalanTikus`;
    const description = articlePageData.response.summary;
    const image = imgSrc(articlePageData.response && articlePageData.response?.banner_image, 769, 330);
    const thumbnail = imgSrc(articlePageData.response && articlePageData.response?.banner_image, 298, 170);
    const imageCaption = imageUrl.split('/').pop().replace(".jpg","");
    const amphtmlUrl = `amp/${url}`;
    const robots = articlePageData.response.robots;
    const ogTitle = articlePageData.response.title;
    const category = articlePageData.response.category.title;
    const primaryTag = articlePageData.response.tags.primary.title;
    let currentYear = new Date().getFullYear();

    // function stripHTML(html) {
    //   let tempDiv = document.createElement("div");
    //   tempDiv.innerHTML = html;
      
    //   return tempDiv.textContent || tempDiv.innerText || "";
    // }

    // function wordCount(html) {
    //     let text = stripHTML(html);
    //     let words = text.trim().split(/\s+/);
        
    //     return words.length;
    // }

    // const wordCounted = wordCount(articlePageData.response.content);
    const schemaContent = [{
      "@type":"NewsArticle",
      "@id": "https://jalantikus.com/" + url+"#article",
      "isPartOf":{
        "@id":"https://jalantikus.com/" + url
      },
      "author": {
        "@id": authorLink
      },
      "headline": ogTitle,
      "datePublished": datePublished,
      "dateModified": dateModified,
      "mainEntityOfPage":{"@id": url },
      // "wordCount":wordCounted,
      "publisher": {
        "@id":"https://jalantikus.com/#organization",
      },
      "image": {
        "@id": "https://jalantikus.com/" + url + "#primaryimage"
      },
      "thumbnailUrl": thumbnail,
      "articleSelection":[category,primaryTag],
      "inLanguage":"id-ID",
      "speakable":{
        "@type":"SpeakableSpecification",
        "cssSelector":["speakable-summary"]
      },
      "copyrightYear": currentYear,
      "copyrightHolder": {
        "@id":"https://jalantikus.com/#organization"
      }
    },{
      "@type":"ImageObject",
      "inLanguage":"id-ID",
      "@id":"https://jalantikus.com/" + url+"#primaryimage",
      "url":thumbnail,
      "contentUrl":thumbnail,
      "width":"298",
      "height":"170",
      "caption":imageCaption
    }, {
      "@type":"WebPage",
      "@id":"https://jalantikus.com/" + url,
      "url":"https://jalantikus.com/" + url,
      "name":title
    },{
      "@type":"Person",
      "@id":"https://jalantikus.com/#/schema/person/image/"+authorImageID,
      "name": authorName,
      "image":{
        "@type":"ImageObject",
        "inLanguage":"id-ID",
        "@id": "https://jalantikus.com/#/schema/person/image/"+authorImageID,
        "url": "https://assets.jalantikus.com/assets/cache/200/200/"+authorImage,
        "contentUrl": "https://assets.jalantikus.com/assets/cache/200/200/"+authorImage,
        "caption": authorName,
      },
      "url": authorLink
    }];
    const props = {url, title, description, image, amphtmlUrl, robots, schemaContent, ogTitle}; 
    return <MetaTag {...props} />
  }

  useEffect(() => {
    if (articlePageData && articlePageData.code === 200) {
      var recentPosts = document.querySelector(".related-article.news-content");
      var recentPostseWapper = document.querySelector(
        ".article-main-content__relate-topics"
      );
      if (recentPosts) recentPostseWapper.insertAdjacentElement("afterbegin", recentPosts);

      setTimeout(() => {
        loadAds();
      }, 500);
      // send to google analytics
      googleAnalytics(articlePageData?.response?.meta_title + ' | JalanTikus')
    }
    // eslint-disable-next-line
  }, [articlePageData]);

  useEffect(() => {
    const pathData = window.location.hostname.replace("www.", "").split(".");
    if(pathData.length > 2)
    {
      history.slug = pathData[0];
    }
    dispatch(getArticlePageDataThunk(history));
    // eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    if (width > 768) {
      dispatch(getOtherDataThunk());
    }
    dispatch(getHomepageDataThunk());
    dispatch(getAffiliates());
    // eslint-disable-next-line
  }, [dispatch]);

  if(pathname.substr(-1) !== "/" && !history.page) {
    return <Redirect to={`${pathname}/`} />
  }

  
  const loadAds = () => {
    if(articlePageData){
      var articleTagID = articlePageData.response.tags.primary.id;
      var articleCategoryID = articlePageData.response.category.id;
    }

    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`articlepage`)

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`
    
    let slots = []

    // define variable related for targeting
    const data = articlePageData.response;
    const published = data.published;
    // const adsType = isMobile ? 'mobile' : 'desktop'
    // define writerID_date
    const writerID_date = data.user.wid ? data.user.wid + published.split("-")[1] + published.split("-")[0].substring(4, 2) : "undefined"
    // define tagID
    let tagID = articleTagID;
    // let categoryCurr = data?.tags?.primary?.slug;
    // if (categoryCurr && categoryArr[adsType]["tags"][categoryCurr]) tagID = categoryArr[adsType]["tags"][categoryCurr];
    // define contentID
    const contentID = data.id;
    // define categoryID
    const categoryID = articleCategoryID;
    // define seo_tag
    let seoTag = false;
    if(data.tags.all){
      seoTag = data.tags.all.filter((obj) => { return obj.id === "2707"; }).length > 0;
    }
    if (data.tags.primary && data.tags.primary.id === "2707") seoTag = true
    let mySeo = isMobile ? "0702" : "0701";
    if (seoTag) mySeo = isMobile ? "0602" : "0601";

    // define slot
    if(isMobile){
      slots = [{
        slot: "JT_320x100", // adunit path
        id: "adSlotMob0-articlepage", // whatever
        wrapperId: "JT_320x100", //match with existing HTML elmt
        defaultSize: "320x100"
      },{
        slot: "JT_320x100", // adunit path
        id: "adSlotMobSticky-articlepage", // whatever
        wrapperId: "JT_320x100-sticky-bottom",
        defaultSize: "320x100"
      },{
        slot: "JT_320x100", // adunit path
        id: "adSlotMobAbove_artikelterkait", // whatever
        wrapperId: "JT_320x100_above_artikelterkait",
        defaultSize: "320x100"
      },{
        slot: "JT_300x250", // adunit path
        id: "adSlotMobbelow_artikelterkait", // whatever
        wrapperId: "JT_300x250_below_artikelterkait",
        defaultSize: "300x250"
      },{
        slot: "JT_300x250", // adunit path
        id: "adSlotMobscroll1_artikelterbaru", // whatever
        wrapperId: "JT_300x250_scroll1_artikelterbaru-mobile",
        defaultSize: "300x250"
      },{
        slot: "JT_320x100_top", // adunit path
        id: "JT_320x100_top_inner", // whatever
        wrapperId: "JT_320x100_top",
        defaultSize: "320x100"
      }]
    }else{
      slots = [{
        slot: "JT_970x90/JT_970x90_top", // adunit path
        id: "adSlotDesktop1", // whatever
        wrapperId: "JT_970x90_top", //match with existing HTML elmt
        defaultSize: "970x90"
      },{
        slot: "JT_300x600/JT_300x600_above_popular", // adunit path
        id: "adSlotDesktop2", // whatever
        wrapperId: "JT_300x600_above_popular", //match with existing HTML elmt
        defaultSize: "300x600"
      },{
        slot: "JT_300x250/JT_300x250_below_popular", // adunit path
        id: "adSlotDesktop3", // whatever
        wrapperId: "JT_300x250_below_popular", //match with existing HTML elmt
        defaultSize: "300x250"
      },{
        slot: "JT_300x600/JT_300x600_below_popular", // adunit path
        id: "adSlotDesktop4", // whatever
        wrapperId: "JT_300x600_below_popular",
        defaultSize: "300x600"
      },{
        slot: "JT_728x90", // adunit path
        id: "adSlotDesktop5", // whatever
        wrapperId: "JT_728x90_below_relatedTags",
        defaultSize: "728x90"
      },{
        slot: "JT_728x90/JT_728x90_below_artikelterkait", // adunit path
        id: "adSlotDesktop6", // whatever
        wrapperId: "JT_728x90_below_artikelterkait",
        defaultSize: "728x90"
      },{
        slot: "JT_300x600/JT_300x600_right_artikelterbaru", // adunit path
        id: "adSlotDesktop7", // whatever
        wrapperId: "JT_300x600_right_artikelterbaru",
        defaultSize: "300x600"
      },{
        slot: "JT_300x250/JT_300x250_right_artikelterbaru", // adunit path
        id: "adSlotDesktop8", // whatever
        wrapperId: "JT_300x250_right_artikelterbaru",
        defaultSize: "300x250"
      },{
        slot: "JT_970x90/JT_970x90_scroll1_artikelterbaru", // adunit path
        id: "adSlotDesktop9", // whatever
        wrapperId: "JT_970x90_scroll1_artikelterbaru",
        defaultSize: "970x90"
      },{
        slot: "JT_300x600/JT_300x600_scroll1_artikelterbaru", // adunit path
        id: "adSlotDesktop10", // whatever
        wrapperId: "JT_300x600_scroll1_artikelterbaru",
        defaultSize: "300x600"
      },{
        slot: "JT_300x250/JT_300x250_scroll1_artikelterbaru", // adunit path
        id: "adSlotDesktop11", // whatever
        wrapperId: "JT_300x250_scroll1_artikelterbaru",
        defaultSize: "300x250"
      },{
        slot: "JT_in_article/JT_in_article_01",
        id: "JT_in_article1",
        wrapperId: "JT_in_article_01",
        defaultSize: "750x300"
      },{
        slot: "JT_in_article/JT_in_article_02",
        id: "JT_in_article2",
        wrapperId: "JT_in_article_02",
        defaultSize: "750x300"
      },{
        slot: "JT_in_article/JT_in_article_03",
        id: "JT_in_article3",
        wrapperId: "JT_in_article_03",
        defaultSize: "750x300"
      },{
        slot: "JT_in_article/JT_under_article",
        id: "div-gpt-ad-1680583049808-0",
        wrapperId: "JT_under_article",
        defaultSize: "480x320"
      }]
    }
    
    // create ad unit tag head
    for (var i = 0; i < slots.length; i++) {
      var targeting = { ...slots[i],
        WriterID_date: writerID_date,
        tag_ID: tagID,
        seo_tag: mySeo,
        content_id: contentID,
        categoryID: categoryID
      }
      _scriptTagHead.text += generateAdUnitTagHeadWithTargeting(targeting)
    }
    $currpage && $currpage.appendChild(_scriptTagHead)
    
    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
      generateAdUnitTagBody(slots[i])
    }
  }

  return (
    <main className="main-container main-container__article-page" id="articlepage">
      { articlePageData && articlePageData.code === 200 && renderMeta(articlePageData) }
      <div className="container-full-width">
        <AdWrapper id={`JT_970x90_top`} cls={`homepage desktop`} customStyle={{minHeight:'100px'}} />
        <AdWrapper id={`JT_320x100`} cls={`homepage mobile`} customStyle={{minHeight:'100px'}} />
      </div>
      {/* end of container-full-width */}

      { (articlePageData && articlePageData.code !== 200) ? (
          // redirect page
          articlePageData.code === 301 ? <Redirect to={`/${articlePageData.response.redirect}`} /> :
          // not found / server error 
          <div className="container-common">
            <Error code={articlePageData.code ? articlePageData.code : '503'} />
          </div>
      ) : (
        <>
        <div className="container-divided container-divided__article-page">
          <section className="container-divided_section" style={{minHeight:'100vh'}}>
            {articlePageData && (
              <ArticleTopInfo
                title={articlePageData.response.title}
                categName={articlePageData.response.category.title}
                categSlug={articlePageData.response.category.slug}
                articleSlug={articlePageData.response?.meta_title}
                ownerScreenname={articlePageData.response.user.name}
                ownerUrl={articlePageData.response.user.links.profile}
                date={articlePageData.response.published}
                primaryTag={articlePageData.response.tags?.primary}
                authorLink={articlePageData.response.user.links.profile.replace("user","")}
              />
            )}
            {articlePageData && (
              <ShareSocials
              title="Share"
              facebookLink={shareSocial({
                platform: "facebook",
                link: articlePageData.response.links.detail,
              })}
              twitterLink={shareSocial({
                platform: "twitter",
                link: articlePageData.response.links.detail,
                title: articlePageData.response.title,
              })}
              whatsAppLink={shareSocial({
                platform: "whatsapp",
                link: articlePageData.response.links.detail,
                title: articlePageData.response.title,
              })}
              lineLink={shareSocial({
                platform: "line",
                link: articlePageData.response.links.detail,
                title: articlePageData.response.title,
              })}
              isMobile
              />
            )}
            {articlePageData && (
              <ArticleMainContent data={articlePageData.response} />
            )}
            <AdWrapper id={`JT_728x90_below_artikelterkait`} cls={`desktop`} />
            <AdWrapper id={`JT_300x250_below_artikelterkait`} cls={`mobile`} />
          </section>
          {width > 768 && (
          <aside className="container-divided_aside">
            {/*(affiliateData) && (
                <Affiliate data={affiliateData.response.hits} title="Rekomendasi JT" />
              )*/}
            {/* <CreateAccount /> */}
            <FollowUs />
            <AdWrapper id={`JT_300x600_above_popular`}/>

            <div className="article-page__popular-today">
              <PopularToday
                className="article-page__popular-today"
                title="artikel terpopuler"
                articles={
                  otherData && otherData.response.populerHariIni.filter(item => {
                    return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
                  }).slice(5, 10)
                }
              />
            </div>
            <AdWrapper id={`JT_300x250_below_popular`} />
            <AdWrapper id={`JT_300x600_below_popular`} />
          </aside>
          )}
        </div>
        {/* end of container-divided */}

        <div className="container-divided container-divided__article-page">
          <section className="container-divided_section">
            <div className="article-page__recent-posts">
              {homepageData && (
                <RecentPosts
                  hasBreadcrumbs
                  containerData={homepageData.response.masihHangat}
                  title="artikel terbaru"
                  wordsToRemove={wordsToRemove}
                />
              )}
            </div>
          </section>
          <aside className="container-divided_aside">
            <AdWrapper id={`JT_300x600_right_artikelterbaru`} />
            <AdWrapper id={`JT_300x250_right_artikelterbaru`} />
          </aside>
        </div>
        {/* end of container-divided */}

        <div className="container-full-width" style={{marginTop:'0px'}}>
          <AdWrapper id={`JT_970x90_scroll1_artikelterbaru`} customStyle={{marginTop:'8px'}} />
          <AdWrapper id={`JT_300x250_scroll1_artikelterbaru-mobile`} cls={`mobile`} customStyle={{marginTop:'8px'}} />
        </div>
        {/* end of container-full-width */}

        <div className="container-divided container-divided__article-page">
          <section className="container-divided_section">
            <BottomPostsBlock wordsToRemove={wordsToRemove}/>
          </section>
          <aside className="container-divided_aside">
            <AdWrapper id={`JT_300x600_scroll1_artikelterbaru`} />
            <AdWrapper id={`JT_300x250_scroll1_artikelterbaru`} />
          </aside>
        </div>
        <AlertCopyClip />
        </>
      )}
      <MobileStickyBottom />
    </main>
  );
}
export default ArticlePage;
