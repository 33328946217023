import React from "react";
import "./RelatedArticle.css"
import SubTitle from "@components/common/Titles/SubTitle";
import EditorsPickItem from "../EditorsPick/EditorsPickItem";

const RelatedArticle = ({ title, articles }) => {
  return (
    <div className="related-article__container">
      <SubTitle subTitleText={title} />
      <div className="related-article__wrapper">
        {articles &&
          articles.map((item, index) => (
            <EditorsPickItem key={`editorpick-${index}`} article={item} />
          ))}
      </div>
    </div>
  );
};

export default RelatedArticle;
