import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import "./EVPagination.css";
import EVTab from "./EVTab";

function EVPagination({ data }) {
  const history = useParams()
  // eslint-disable-next-line
  const [type, setType] = useState('Electric Mobil');
  const [selectedTab, setSelectedTab] = useState(0);
  const vehicleType = history.vehicle_type;
  const brand = history.brand !== undefined ? `${history.brand}/`: '';

  useEffect(() => {
    if(vehicleType ==="mobil"){
      setType("Electric Mobil")
      setSelectedTab(0)
    }
    else if(vehicleType ==="motor"){
      setType("Electric Motor")
      setSelectedTab(1)
    }
    // eslint-disable-next-line
  }, [history])

  return (
    <div className="ev-pagination">
      <Tabs selectedIndex={selectedTab}>
        <TabList className="mb-4">
          <Tab>
            <a href={`/kendaraan-listrik/mobil/${brand}`}>
              Electric Mobil
            </a>
          </Tab>
          <Tab>
            <a href={`/kendaraan-listrik/motor/${brand}`}>
              Electric Motor
            </a>
          </Tab>
        </TabList>
        <TabPanel>
          <EVTab items={data} />
        </TabPanel>
        <TabPanel>
          <EVTab items={data} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default EVPagination;