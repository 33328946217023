import React from "react";
import "./EditorsPick.css";
import EditorsPickItem from "../../components/EditorsPick/EditorsPickItem";
import SubTitle from "@components/common/Titles/SubTitle";


function EditorsPick({ articles }) {

  return (
    <div className="editors-pick__container">
      <SubTitle subTitleText={`PILIHAN EDITOR`} />
      <div className="editors-pick__wrapper">
        {articles && articles.map((item, index) => 
          <EditorsPickItem
            key={`editorpick-${index}`}
            article={item} 
          />
        )}
      </div>
    </div>
  );
}

export default EditorsPick;
