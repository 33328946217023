import React from "react";
import './EVTopInfo.css'
import { EVInfoService, imgVehicleSrc, writePrice } from "../../helpers";
import { imgSrc } from "../../helpers";
import ShareWithFriends from '../common/ShareWithFriends/ShareWithFriends';
import { useState } from "react";
import { useEffect } from "react";

function EVTopInfo({ vehicle }) {
  const getSummary = () => {
    if (vehicle) {
      const content = vehicle.content;
      // cut string until finds first </p>
      const summary = content.substring(3, content.indexOf("</p>"));
      return summary;
    }
    return false;
  }

  const openLink = (link) => {
    if (!link) return;
    link = `https://jalantikus.com/handler/article?u=${link}`
    window.open(link, '_blank');
  }

  const [specs, setSpecs] = useState([]);

  useEffect(() => {
    if (!vehicle || !vehicle.type) return;
    if (vehicle.type === 'mobil') {
      setSpecs([
        {
          prop: "Announced",
          value: vehicle?.specification?.mobil_announced || '-',
          icon: "icon-vehicle-announce"
        },
        {
          prop: "Power",
          value: EVInfoService.formatFn.mobilPower(
            vehicle?.specification?.mobil_power, vehicle?.specification?.mobil_drive_system),
          icon: "icon-vehicle-power"
        },
        {
          prop: "Max Speed",
          value: EVInfoService.formatFn.mobilMaxSpeed(
            vehicle?.specification?.mobil_max_speed
          ),
          icon: "icon-vehicle-speed"
        },
        {
          prop: "Battery",
          value: EVInfoService.formatFn.mobilCapacity(
            vehicle?.specification?.mobil_capacity
          ),
          icon: "icon-vehicle-battery"
        },
        {
          prop: "Range",
          value: EVInfoService.formatFn.mobilRange(
            vehicle?.specification?.mobil_range
          ),
          icon: "icon-vehicle-road"
        },
        {
          prop: "Type",
          value: vehicle?.specification?.mobil_body_type || '-',
          icon: "icon-vehicle-type"
        },
      ]);
    } else if (vehicle.type === 'motor') {
      setSpecs([
        {
          prop: "Power",
          value: EVInfoService.formatFn.motorPower(
            vehicle?.specification?.motor_power
          ),
          icon: "icon-vehicle-power"
        },
        {
          prop: "Max Speed",
          value: EVInfoService.formatFn.motorMaxSpeed(
            vehicle?.specification?.motor_max_speed
          ),
          icon: "icon-vehicle-speed"
        },
        {
          prop: "Battery",
          value: EVInfoService.formatFn.motorBattery(
            vehicle?.specification?.motor_battery
          ),
          icon: "icon-vehicle-battery"
        },
        {
          prop: "Charging time",
          value: EVInfoService.formatFn.motorChargingTime(
            vehicle?.specification?.motor_charging_time
          ),
          icon: "icon-vehicle-charge"
        },
        {
          prop: "Transmission",
          value: vehicle?.specification?.motor_transmission || '-',
          icon: "icon-vehicle-link"
        },
      ]);
    }
    // eslint-disable-next-line
  }, vehicle)


  return (
    <>
      <div className="vehicle-wrapper">
        <div className="vehicle-wrapper__image-wrapper">
          <picture>
            <source
              type="image/webp"
              data-srcset={imgVehicleSrc(vehicle.image, 1, 1)}
              srcSet={imgVehicleSrc(vehicle.image, 1, 1)}
            />
            <img
              src={imgSrc(vehicle.image, 1, 1)}
              data-src={imgSrc(vehicle.image, 1, 1)}
              alt={vehicle.title}
            />
          </picture>
          <div className="vehicle-image-title my-2">check the official website {vehicle.company.title}</div>
          <div className="vehicle-info-box d-flex justify-content-between">
            <div>
              <h1>{vehicle.title}</h1>
              {/* <div className="vehicle-info-box__brand-link">{vehicle.links.external_url}</div> */}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="vehicle-info-box__vehicle-btn-link d-flex justify-content-center align-items-center"
                onClick={() => openLink(vehicle.links.external_url)}
              >
                Cek Website
              </div>
            </div>
          </div>
        </div>
        <div className="vehicle-info__wrapper">
          <div className="vehicle-title">
            {vehicle.title}
          </div>
          <div className="vehicle-info">
            Price: <span className="vehicle-info__price-tag">{writePrice(vehicle.price)}</span> Brand: <span className="vehicle-info__brand-name">{vehicle?.company?.title}</span>
          </div>
          <div className="vehicle-summary" dangerouslySetInnerHTML={{ __html: getSummary() }}></div>
          <ul className="spec-wrapper mb-3">
            {
              specs.map((item, idx) => (
                <li className="spec-wrapper__spec-item" key={idx}>
                  <span className="spec-wrapper__spec-item__icon-wrapper">
                    <span className={item.icon}></span>
                  </span>
                  <span className="spec-wrapper__spec-item-prop">{item.prop}:</span>
                  {
                    item.prop === 'URL' ? 
                    <a className="spec-wrapper__spec-item-information" target="_blank" rel="noreferrer"
                      href={`https://jalantikus.com/handler/article?u=${item.value}`}>{item.value}</a> :
                    <span className="spec-wrapper__spec-item-information">{item.value}</span>
                  }
                </li>
              ))
            }
          </ul>
          <ShareWithFriends link={vehicle.links.detail} title={vehicle.title} />
        </div>
      </div>
    </>
  )
}

export default EVTopInfo;