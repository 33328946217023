import axios from "axios";
import https from 'https';

export const javantApi = axios.create({
  baseURL: `https://api.jalantikus.com/v1/`,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
});


export const javantApiStaging = axios.create({
  baseURL: `http://dev-api.jalantikus.com/v1/`,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
});

export const dailymotionApi = axios.create({
  baseURL: `https://api.dailymotion.com/`,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
  crossDomain: true,
});

export const assetsUrl = `https://assets.jalantikus.com/`;

// export const api = axios.create({
//   baseURL: `https://api.jalantikus.com/v1/`,
// });
