import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import AppsSection from '../components/AppsSection/AppsSection';
import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import AppsArticle from '../components/AppsArticle/AppsArticle'
import AppsArticleSquare from '../components/AppsArticleSquare/AppsArticleSquare'
import AppsHeadlineSlider from '../components/common/Sliders/AppsHeadlineSlider/AppsHeadlineSlider'
import AppsFAQ from '../components/FAQ/FAQ'
import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
import Error from  '../components/Error/Error';
import {
    getAllDownloadPageDataThunk
} from "../store/ducks/downloadPageReducer";
import { getDownloadMeta } from "../constants";
import MetaTag from '../components/MetaTag/MetaTag';
import {googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody} from '../helpers'
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';

function DownloadPage(){
    const wordsToRemove = [
        "domino",
        "higgs",
        "mod",
        "hack",
        "xnxx",
        "bokeh",
        "museum",
        "aplikasi dewasa",
        "yandex",
        "judi",
        "slot",
        "dewasa",
        "sadap",
        "bobol",
        "cheat",
        "lacak",
        "open bo",
        "pinjol",
        "pinjaman online",
        "cara pinjam",
        "simontok",
        "xnxubd",
        "uncensored",
        "doodstream",
        "dood",
        "18+"
      ];
    const dispatch = useDispatch();

    const appsData = useSelector((state) => state.downloadPageReducer.appsData );

    // browser
    const browserWindows = useSelector((state) => state.downloadPageReducer.browserWindows);
    const browserAndroid = useSelector((state) => state.downloadPageReducer.browserAndroid);
    const browserArticle = useSelector((state) => state.downloadPageReducer.browserArticle);
    // video-audio
    const videoAudioWindows = useSelector((state) => state.downloadPageReducer.videoAudioWindows);
    const videoAudioAndroid = useSelector((state) => state.downloadPageReducer.videoAudioAndroid);
    const videoAudioArticle = useSelector((state) => state.downloadPageReducer.videoAudioArticle);
    // photo-imaging
    const photoImagingWindows = useSelector((state) => state.downloadPageReducer.photoImagingWindows);
    const photoImagingAndroid = useSelector((state) => state.downloadPageReducer.photoImagingAndroid);
    const photoImagingArticle = useSelector((state) => state.downloadPageReducer.photoImagingArticle);
    // games
    const bestGamesWindows = useSelector((state) => state.downloadPageReducer.bestGamesWindows);
    const bestGamesAndroid = useSelector((state) => state.downloadPageReducer.bestGamesAndroid);
    const bestGamesArticle = useSelector((state) => state.downloadPageReducer.bestGamesArticle);
    // antivirus
    const antiVirusWindows = useSelector((state) => state.downloadPageReducer.antiVirusWindows);
    const antiVirusAndroid = useSelector((state) => state.downloadPageReducer.antiVirusAndroid);
    const antiVirusArticle = useSelector((state) => state.downloadPageReducer.antiVirusArticle);
    // utilities
    const utilitiesWindows = useSelector((state) => state.downloadPageReducer.utilitiesWindows);
    const utilitiesAndroid = useSelector((state) => state.downloadPageReducer.utilitiesAndroid);
    const utilitiesArticle = useSelector((state) => state.downloadPageReducer.utilitiesArticle);

    useEffect(() => {
        const getData = () => {
            dispatch(getAllDownloadPageDataThunk());
        }
        getData();
        googleAnalytics();
        loadAds()
        // eslint-disable-next-line
    }, []);

    const sliderArticles = appsData && appsData.code===200 && appsData.response.slider.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })
    const lastArticles = appsData && appsData.code===200 && appsData.response.artikelTerbaru.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })

    const appsVideoAudioWindows = videoAudioWindows && videoAudioWindows.code===200 && videoAudioWindows.response.appList.hits
    const appsVideoAudioAndroid = videoAudioAndroid && videoAudioAndroid.code===200 && videoAudioAndroid.response.appList.hits
    const appsVideoAudioArticle = videoAudioArticle && videoAudioArticle.code===200 && videoAudioArticle.response.artikelTerbaru.hits.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })
    
    const appsBrowserWindows = browserWindows && browserWindows.code===200 && browserWindows.response.appList.hits
    const appsBrowserAndroid = browserAndroid && browserAndroid.code===200 && browserAndroid.response.appList.hits
    const appsBrowserArticle = browserArticle && browserArticle.code===200 && browserArticle.response.artikelTerbaru.hits.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })
    
    const appsPhotoImagingWindows = photoImagingWindows && photoImagingWindows.code===200 && photoImagingWindows.response.appList.hits
    const appsPhotoImagingAndroid = photoImagingAndroid && photoImagingAndroid.code===200 && photoImagingAndroid.response.appList.hits
    const appsPhotoImagingArticle = photoImagingArticle && photoImagingArticle.code===200 && photoImagingArticle.response.artikelTerbaru.hits.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })
    
    const gamesBestGamesWindows = bestGamesWindows && bestGamesWindows.code===200 && bestGamesWindows.response.appList.hits
    const gamesBestGamesAndroid = bestGamesAndroid && bestGamesAndroid.code===200 && bestGamesAndroid.response.appList.hits
    const gamesBestGamesArticle = bestGamesArticle && bestGamesArticle.code===200 && bestGamesArticle.response.artikelTerbaru.hits.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })
    
    const appsAntiVirusWindows = antiVirusWindows && antiVirusWindows.code===200 && antiVirusWindows.response.appList.hits
    const appsAntiVirusAndroid = antiVirusAndroid && antiVirusAndroid.code===200 && antiVirusAndroid.response.appList.hits
    const appsAntiVirusArticle = antiVirusArticle && antiVirusArticle.code===200 && antiVirusArticle.response.artikelTerbaru.hits.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })
    
    const appsUtilitiesWindows = utilitiesWindows && utilitiesWindows.code===200 && utilitiesWindows.response.appList.hits
    const appsUtilitiesAndroid = utilitiesAndroid && utilitiesAndroid.code===200 && utilitiesAndroid.response.appList.hits
    const appsUtilitiesArticle = utilitiesArticle && utilitiesArticle.code===200 && utilitiesArticle.response.artikelTerbaru.hits.filter(item => {
        return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
      })

    const pathname = useLocation().pathname;
    const pathArray = pathname.split("/");
    const pageType = pathArray[1];
    if(pathname.substr(-1) !== "/") {
        return <Redirect to={`${pathname}/`} />
    }

    const loadAds = () => {
        const isMobile = window.innerWidth <= 768 ? true : false;
        const $currpage = document.getElementById(`downloadPage`);
    
        var _scriptTagHead = document.createElement("script");
        _scriptTagHead.async = true;
        _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

        let slots = []
        if(isMobile){
            // available slot in this page
            slots = [{
                    slot: "JT_320x100", // define slot
                    id: "adSlotMobTop-category",
                    wrapperId: "JT_320x100",
                    defaultSize: "320x100"
                },{
                    slot: "JT_320x100", // define slot
                    id: "adSlotMobSticky-category",
                    wrapperId: "JT_320x100-sticky-bottom",
                    defaultSize: "320x100"
                },{
                    slot: "JT_300x250", // define slot
                    id: "adSlotMobBelowVideoAudio",
                    wrapperId: "JT_300x250_below_video_audio",
                    defaultSize: "300x250"
                },{
                    slot: "JT_320x100", // define slot
                    id: "adSlotMobBelowBrowser",
                    wrapperId: "JT_300x100_below_browser",
                    defaultSize: "320x100"
                },{
                    slot: "JT_320x100", // define slot
                    id: "adSlotMobBelowPhotoImaging",
                    wrapperId: "JT_300x100_below_photo_imaging",
                    defaultSize: "320x100"
                },{
                    slot: "JT_320x100", // define slot
                    id: "adSlotMobBelowBestGames",
                    wrapperId: "JT_300x100_below_best_games",
                    defaultSize: "320x100"
                },{
                    slot: "JT_320x100", // define slot
                    id: "adSlotMobBelowFaq",
                    wrapperId: "JT_300x100_below_faq",
                    defaultSize: "320x100"
                },
            ]
        }else{
            // available slot in this page
            slots = [{
                slot: "JT_970x90/JT_970x90_top", // define slot
                id: "adSlotDesktop0", // whatever id
                wrapperId: "JT_970x90_top", // must match with id in html
                defaultSize: "970x90"
            }]
        }
        // create ad unit tag head
        for (let i = 0; i < slots.length; i++) {
            _scriptTagHead.text += generateAdUnitTagHead(slots[i])
        }
        $currpage && $currpage.appendChild(_scriptTagHead)    
        
        // create ad unit tag body
        for (let i = 0; i < slots.length; i++) {
            generateAdUnitTagBody(slots[i])
        }
    }

    return(
        <>
            <main className="container-common__wrapper" id="downloadPage">
                <MetaTag {...getDownloadMeta(pageType, pathname.slice(1))}/>
                {/* Left vertical banner */}
                <div className="container-common__vertical-banner">
                    <VerticalBanner />
                </div>

                <div className="container-common">
                    <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{minHeight:'100px'}} />
                    <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{minHeight:'100px'}} />
                    {appsData && appsData.code !== 200 ? (
                        appsData.code === 301 ? <Redirect to={`/${appsData.response.redirect}`} /> :
                            <Error code={appsData.code ? appsData.code : '503'} />
                    ) : (
                    <>
                    <AppsHeadlineSlider articles={sliderArticles} />
                    <AppsArticleSquare articles={lastArticles && lastArticles.slice(0,3)} />

                    {/* video-audio section */}
                    {appsVideoAudioWindows && appsVideoAudioAndroid &&
                    <AppsSection
                        tab
                        description="Download and try our best video and sound editing apps below. You can record, edit, mix, export, and share your video or music using these best apps on [OS]! "
                        windowApps={appsVideoAudioWindows && appsVideoAudioWindows.slice(0,6)}
                        androidApps={appsVideoAudioAndroid && appsVideoAudioAndroid.slice(0,6)}
                        title="Best Video & Sound Apps"
                        seeAllUrl = '/apps/category/video-audio/'
                    />
                    }
                    {appsVideoAudioArticle &&
                        <AppsArticle articles={appsVideoAudioArticle} /> }
                    <div className="desktop-horizontal-banner1">
                        <DesktopHorizontalBanner />
                    </div>
                    <div className="ads_slot_mobile_300">
                        <div id="JT_300x250_below_video_audio"></div>
                    </div>

                    {/* browser section */}
                    {appsBrowserWindows && appsBrowserAndroid &&
                    <AppsSection
                        tab
                        description="Browse, search, and explore the internet using these fast and lightweight browser. Download now and use it in your [OS]!"
                        windowApps={appsBrowserWindows && appsBrowserWindows.slice(0,6)}
                        androidApps={appsBrowserAndroid && appsBrowserAndroid.slice(0,6)}
                        title="Best Browsers Apps"
                        seeAllUrl = '/apps/category/browser/'
                    />
                    }
                    {appsBrowserArticle && 
                    <AppsArticle articles={appsBrowserArticle} /> }
                    <div className="ads_slot_mobile_300">
                        <div id="JT_300x100_below_browser"></div>
                    </div>

                    {/* photo imaging section */}
                    {appsPhotoImagingWindows && appsPhotoImagingAndroid && 
                    <AppsSection
                        tab
                        description="Export, edit, and give a magical touch to your photo using our best photo editing app below! Download and install your favorite photo editing app and edit your photo on your [OS]"
                        windowApps={appsPhotoImagingWindows && appsPhotoImagingWindows.slice(0,6)} 
                        androidApps={appsPhotoImagingAndroid && appsPhotoImagingAndroid.slice(0,6)} 
                        title="Best Photo Editing Apps"
                        seeAllUrl = '/apps/category/photo-imaging/'
                    />
                    }
                    {appsPhotoImagingArticle && 
                    <AppsArticle articles={appsPhotoImagingArticle} /> }
                    <div className="ads_slot_mobile_300">
                        <div id="JT_300x100_below_photo_imaging"></div>
                    </div>

                    {/* best game section */}
                    {gamesBestGamesWindows && gamesBestGamesAndroid &&
                    <AppsSection
                        tab
                        description="Excel your gaming experience and play with these game applications and cheats. Download and install it on your [OS] and win every battle on your favorite games NOW!"
                        windowApps={gamesBestGamesWindows && gamesBestGamesWindows.slice(0,6)} 
                        androidApps={gamesBestGamesAndroid && gamesBestGamesAndroid.slice(0,6)} 
                        title="Game Terbaik"
                        seeAllUrl = '/games/category/'
                    />
                    }
                    {gamesBestGamesArticle && 
                        <AppsArticle articles={gamesBestGamesArticle} /> }
                    <div className="desktop-horizontal-banner1">
                        <DesktopHorizontalBanner />
                    </div>
                    <div className="ads_slot_mobile_300">
                        <div id="JT_300x100_below_best_games"></div>
                    </div>

                    {/* anti virus section */}
                    {appsAntiVirusWindows && appsAntiVirusAndroid &&
                    <AppsSection
                        tab
                        description="Play your favorite movies, music videos, or clips on your [OS] using this best video player. Download and enjoy your video now! "
                        windowApps={appsAntiVirusWindows && appsAntiVirusWindows.slice(0,6)} 
                        androidApps={appsAntiVirusAndroid && appsAntiVirusAndroid.slice(0,6)} 
                        title="Best Anti Virus & Security Apps"
                        seeAllUrl = '/apps/category/antivirus-security/'
                    />
                    }
                    {appsAntiVirusArticle && 
                    <AppsArticle articles={appsAntiVirusArticle} /> }

                    {/* utility section */}
                    {appsUtilitiesWindows && appsUtilitiesAndroid && 
                    <AppsSection 
                        tab
                        description="The System Utilities directory includes a variety of PC utilities for managing and optimizing your computer system, including the Registry, start-up applications, desktop, and other settings."
                        windowApps={appsUtilitiesWindows && appsUtilitiesWindows.slice(0,6)} 
                        androidApps={appsUtilitiesAndroid && appsUtilitiesAndroid.slice(0,6)} 
                        title="Best Utility Apps"
                        seeAllUrl = '/apps/category/utilities/'
                    />
                    }

                    {appsUtilitiesArticle && 
                    <AppsArticle articles={appsUtilitiesArticle} /> }
                    </>
                    )}
                    <AppsFAQ />
                    <div className="ads_slot_mobile_300">
                        <div id="JT_300x100_below_faq"></div>
                    </div>
                </div>

                {/* Right vertical banner */}
                <div className="container-common__vertical-banner">
                    <VerticalBanner />
                </div>
                
                <MobileStickyBottom />
            </main>
        </>
    )
}

export default DownloadPage