import React from "react";
import Carousel from "react-multi-carousel";
import './multi-carousel/styles.css';
import './AppsHeadlineSlider.css';
import SubTitle from '../../Titles/SubTitle';
import AppsHeadlineSliderItem from './AppsHeadlineSliderItem'

function AppsHeadlineSlider({articles}) {
    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 1,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 10
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 1,
            partialVisibilityGutter: 10
        }
    };

    return (
        <div className="apps-headline__container">
            <div className="apps-section__title">
                <SubTitle subTitleText="Download Aplikasi Terbaru"/>
            </div>
            <div className="apps-section__title">
            Baca artikel terbaru mengenai aplikasi Windows dan Android. Kalian juga bisa download aplikasi terbaru yang aman, gratis, dan tentunya legal.
            </div>
            <div className="apps-headline__wrapper">
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode
                    containerClass="container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    showDots={true}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {articles && articles.length > 1 ? (
                        articles.map((item,index) => {
                            return (
                                <AppsHeadlineSliderItem article={item} key={index} />
                            )
                        })
                    ) : (
                        [...Array(3)].map((e,i) => {
                            return (
                                <AppsHeadlineSliderItem key={i}/>
                            )
                        })
                    )}

                </Carousel>
            </div>
        </div>
    )
}

export default AppsHeadlineSlider