import React from 'react';
import AppRouter from "@components/AppRouter/AppRouter";
import { DFPSlotsProvider } from 'react-dfp';
// import TagManager from 'react-gtm-module'
// import {analytics} from './constants';
import withRobotsTag from './components/RobotTags/RobotTags';

// const tagManagerArgs = {
//   gtmId: analytics.googleTagManagerId
// }

// if (process.browser) 
//   TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <DFPSlotsProvider dfpNetworkId="21702367101">
      <AppRouter />
    </DFPSlotsProvider>
  );
}

export default withRobotsTag(App);
