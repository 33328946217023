import { TagsPageService } from "../../http";

/*ACTION TYPES*/
const GET_TAGSPAGE_DATA = "GET_TAGSPAGE_DATA";
const GET_DATA_BY_CATEGORY = "GET_DATA_BY_CATEGORY";
const GET_SELECTED_TAG_META = "GET_SELECTED_TAG_META";

/*ACTION CREATORS*/
export const getTagsPageData = (payload) => ({
  type: GET_TAGSPAGE_DATA,
  payload: payload,
});

export const getDataByCategory = (payload) => ({
  type: GET_DATA_BY_CATEGORY,
  payload: payload,
});

export const getSelectedTagMeta = (payload) => ({
  type: GET_SELECTED_TAG_META,
  payload: payload,
});

/*THUNK*/
export const getTagsPageDataThunk = (tag_name) => async (dispatch) => {
  try {
    const response = await TagsPageService.getData(tag_name);
    dispatch(getTagsPageData(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getTagsPageData(error));
  }
};

export const getDataByCategoryThunk = (category_name, tag_name) => async (
  dispatch
) => {
  try {
    const response = await TagsPageService.getDataByCategory(
      category_name,
      tag_name
    );
    dispatch(getDataByCategory(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getDataByCategory(error));
  }
};

export const getSelectedTagMetaThunk = (tag_name) => async (dispatch) => {
  try{
    const response = await TagsPageService.getMetaByTag(tag_name);
    dispatch(getSelectedTagMeta(response.data));
  }catch(error){
    // console.log(error)
  }
}


export const getAllTagsPageDataThunk = (params) => async (dispatch) => {
  const { category_name, tag_name } = params;
  category_name
    ? await dispatch(getDataByCategoryThunk(category_name, tag_name))
    : await dispatch(getTagsPageDataThunk(tag_name));
  await dispatch(getSelectedTagMetaThunk(tag_name));
};

/*INITIAL STATE*/
const initialState = {
  tagsPageData: null,
  dataByCategory: null,
  selectedTagMeta: null
};

/*REDUCER*/
function tagsPageReducer(state = initialState, action) {
  switch (action.type) {
    /*Get data for tags page*/
    case GET_TAGSPAGE_DATA:
      return {
        ...state,
        tagsPageData: action.payload,
      };
    // Get data by category
    case GET_DATA_BY_CATEGORY:
      return {
        ...state,
        dataByCategory: action.payload,
      };
    case GET_SELECTED_TAG_META:
      return{
        ...state,
        selectedTagMeta: action.payload,
      }
    default:
      return state;
  }
}

export default tagsPageReducer;
