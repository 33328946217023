import React from "react";
import "./PopularFinanceItem.css";
// import { Link } from "react-router-dom";

function PopularFinanceItem({ article, num }) {
  const category = article.category
    ? article.category.slug
    : article.links.detail.split("/")[0];
  return (
    <>
      <div className="popular-Finance__item">
        <div className="popular-Finance__item-num">{num}</div>
        <a href={`/${category}/${article.slug}/`}
          className="popular-Finance__item-ttl"
        >
          {article.title}
        </a>
      </div>
    </>
  );
}

export default PopularFinanceItem;
