import React from "react";
import "./MainPost.css";
import PostInfo from "@components/common/PostInfo/PostInfo";
// import { Link } from "react-router-dom";
import { imgSrc, onErrorSrc} from "../../helpers";

function MainPost({ article }) {
  const category = article && article?.category
    ? article?.category?.slug
    : article?.links?.detail.split("/")[0];

  const articleUrl = `/${article?.links?.detail}/`;
  const articleImgUrl = imgSrc(article && article?.banner_image, 0, 400);
  const articleAlt = article && article.title ? article.title : "Default Image";
  const articleTitle = article && article?.title;
  const articleDate = article &&  article?.published;
  
  return (
    <>
      <div className="main-post__wrapper">
        <a href={articleUrl} className="main-post__image-wrapper" >
          <picture>
            <source 
              type="image/webp" 
              srcSet={article && articleImgUrl + ".webp"} 
              data-srcset={article && articleImgUrl + ".webp"} 
            />
            <img
              onError={onErrorSrc}
              src={articleImgUrl}
              data-src={articleImgUrl}
              className=""
              height="400"
              alt={articleAlt}
            />
          </picture>
        </a>
        <div className="main-post__info-wrapper">
          <h3 className="main-post__info-title">
            <a href={`${articleUrl}`} className="main-post__info-title-link">{articleTitle}</a>
          </h3>
          <PostInfo
            subTitleText=""
            categoryName={category}
            categoryUrl={category}
            date={articleDate}
            categoryLink="#"
          />
        </div>
      </div>
    </>
  );
}

// MainPost.propTypes = {
//   article: PropTypes.object.isRequired,
// };

export default MainPost;
