import React from "react";
import "./Privacy.css";
import StaticSidebar from "@components/Static/Sidebar/StaticSidebar";

function Privacy() {
   return (
      <>
         <div className="privacy-section__container">
            <div className="static-sidebar">
               <StaticSidebar cls="show-desktop has-title" id="" active="eula" />
            </div>
            <div className="static-content">
               <div className="title">
                  <h1>KEBIJAKAN PRIVASI</h1>
                  <span>We Value Our Readers Privacy</span>
               </div>
               <div className="content">
                  <h3>Tinjauan</h3>
                  
                  <p>Kami menyadari bahwa privasi di World Wide Web merupakan hal penting bagi Anda. Kami menggunakan informasi untuk memahami kebutuhan Anda dengan lebih baik dan meningkatkan pengalaman Anda pada website kami.</p>

                  <p>Dalam kebijakan privasi ini, kami menyatakan beberapa praktek yang kami pakai untuk memanfaatkan dan menjelaskan informasi yang diperoleh dari website kami.</p>

                  <p>Informasi yang kami minta, seperti nama, alamat pengiriman surat dan alamat email akan dipakai untuk memenuhi permintaan Anda atas informasi dan bantuan dalam layanan yang kami berikan. Jika Anda ingin menerima update, kami akan mengirim informasi kepada Anda secara berkala mengenai produk dan layanan JalanTikus lainnya yang kami rasa menarik bagi Anda.</p>

                  <p>Kami tidak menjual atau memberikan informasi yang ada kepada perusahaan lain yang ingin memberitahukan Anda mengenai produk mereka tanpa persetujuan Anda. Selama kebijakan privasi berlaku, kami akan terus memberikan versi terbaru pada website ini.</p>

                  <p>Pernyataan privasi mencakup website-website yang dioperasikan oleh JalanTikus yang meliputi, tapi tidak terbatas pada, situs-situs domain dan sub domain berikut: <a href="https://jalantikus.com">https://jalantikus.com</a></p>

                  <p>Kebijakan Privasi ini hanya mencakup informasi yang diperoleh melalui website ini dan tidak mencakup informasi yang kemungkinan diperoleh melalui peranti lunak yang diunduh dari situs atau yang diperoleh dari situs-situs lainnya yang ditautkan ke pada situs ini.</p>

                  <h3>Informasi yang Kami Kumpulkan</h3>
                  
                  <p>Kami menyimpan informasi dari pendaftaran, cookies, log files, clear gif dan/atau pihak ketiga untuk menciptakan profil pengguna kami.</p>

                  <p>Data Pribadi bisa termasuk nama, alamat, nomor telepon, atau alamat e-mail Anda. Kami juga mengumpulkan informasi yang tak dapat diidentifikasi secara pribadi, yang mungkin tertaut pada Data Pribadi Anda, termasuk alamat IP Anda, serta preferensi pencarian terkait pada pencarian spesifik.</p>

                  <p>Kami menggunakan informasi ini untuk menghubungi Anda mengenai layanan di situs kami untuk mengetahui ketertarikan Anda. Umumnya, pengguna memiliki opsi untuk memberikan informasi, identitas unik (seperti, nama pengguna dan kata sandi), tapi untuk mendukungnya kami dapat memberikan pengalaman yang lebih pribadi pada situs kami.</p>

                  <h3 className="color black"><strong>Keamanan</strong></h3>
                  
                  <p>JalanTikus akan selalu berusaha melindungi informasi pengguna. Ketika pengguna memberikan informasi rahasia via situs Web, informasi mereka dilindungi secara online maupun offline.</p>

                  <h3 className="color black"><strong>Layanan Pelanggan</strong></h3>
                  
                  <p>Kami berhubungan dengan pengguna secara teratur untuk menyajikan layanan yang diperlukan dan mengenai persoalan yang berkaitan dengan akun mereka dan kami membalasnya via email atau telepon, sesuai dengan keinginan pengguna.</p>

                  <h3 className="color black"><strong>Berbagi Data Pribadi Anda</strong></h3>
                  
                  <p>Ini adalah contoh dimana kami akan membagi informasi pribadi pengguna:</p>
                  <ul>
                     <li>
                        JalanTikus dapat membagikan Data Pribadi Anda dengan pihak ketiga yang kami pekerjakan untuk melakukan layanan atas nama kami, seperti layanan hosting web. Pihak ketiga tersebut juga memiliki kewajiban untuk menjaga keamanan dan kerahasiaan Data Pribadi dan untuk memproses Data Pribadi hanya sesuai dengan instruksi kami.
                     </li>
                     <li>
                        JalanTikus mungkin menyingkapkan Data Pribadi untuk melindungi diri kami terhadap tuntutan, untuk menanggapi panggilan menjadi saksi, proses peradilan, permohonan yang sah dari petugas penegak hukum, atau sebagaimana yang diperlukan untuk mematuhi hukum negara dan undang-undang yang berlaku. Kami juga mungkin menyingkapkan Data Pribadi untuk menegakkan atau mengaplikasikan syarat dan ketentuan yang berlaku bagi produk dan layanan kami, atau untuk melindungi hak, properti atau keselamatan JalanTikus, pengguna kami, atau lainnya.
                     </li>
                  </ul>

                  <h3 className="color black"><strong>Tindakan Hukum</strong></h3>
                  
                  <p>Meskipun kami berusaha maksimal untuk menjaga privasi pengguna, kami juga perlu memberikan informasi pribadi jika diperlukan oleh hukum dimana kami yakin bahwa tindakan seperti ini perlu dilakukan untuk memenuhi current judicial process (proses pengadilan), ketetapan pengadilan atau proses hukum yang berlaku di JalanTikus.</p>
                  
                  <p>Kami memberikan informasi demografi keseluruhan kepada mitra dan pengiklan kami. Sebagai contoh, kami dapat secara berkala memberikan informasi seperti jumlah pengunjung, area ketertarikan, hubungan dengan JalanTikus, dll. Ini tidak ada kaitannya dengan informasi yang secara personal tidak dapat teridentifikasi.</p>
                  
                  <p>Jika ada yang menggunakan nama JalanTikus.Com untuk tujuan mengambil keuntungan sendiri diluar apa yang JalanTikus jual secara resmi maka akan dilakukan tindakan hukum sebagaimana tindak pencurian dan atau perusakan nama baik. JalanTikus.Com tidak akan segan-segan untuk mengadukan pihak(-pihak) tak bertanggung jawab tersebut kepada Polisi untuk diproses keputusan hukumnya sesuai yang berlaku di Negara Republik Indonesia!</p>
                  
                  <p>Jika ada yang menemukan orang yang menjual dan atau menggunakan nama JalanTikus.com secara sepihak tanpa persetujuan resmi dari JalanTikus.Com, Abda bisa melaporkan langsung kepada kami lewat <a href="mailto:info@jalantikus.com">info@jalantikus.com</a></p>

                  <h3 className="color black"><strong>Tautan</strong></h3>
                  
                  <p>Website ini dapat memiliki tautan ke situs lain. Perlu Anda ketahui bahwa kami, JalanTikus, tidak bertanggungjawab atas konten atau praktek privasi situs lainnya. Kami membedakan situs-situs web pihak ketiga ini dengan metode seperti membukanya pada jendela browser terpisah atau dalam suatu bingkai yang mengindikasikan area yang bukan bagian dari situs kami. Dalam hal ini, kebijakan privasi website JalanTikus tidak berlaku. Kami harap pengguna berhati-hati ketika meninggalkan situs kami dan membaca pernyataan privasi masing-masing dan setiap Website yang Anda kunjungi. Pernyataan privasi berlaku sepenuhnya terhadap informasi yang diperoleh website ini.</p>

                  <h3 className="color black"><strong>Pemberitahuan Perubahan</strong></h3>
                  
                  <p>Jika kami memutuskan untuk mengubah kebijakan privasi kami, kami akan mengirim perubahan tersebut pada pernyataan privasi ini, yang dapat diakses dari halaman utama <a href="https://jalantikus.com">https://jalantikus.com</a>, atau tempat lain yang kami anggap tepat.</p>
                  
                  <p>Kami akan berusaha semaksimal mungkin untuk menggunakan informasi sesuai dengan kebijakan privasi dimana informasi diperoleh. Terkadang, kami juga mengirim email kepada pengguna yang telah memilih menerima komunikasi dari kami, yang memberitahukan mereka mengenai perubahan privasi kami. Meskipun demikian, jika pengguna telah meng-opt-out semua komunikasi dengan situs, atau menghapus/menonaktifkan akun mereka, maka mereka tidak akan dihubungi lagi.</p>

                  <h3 className="color black"><strong>Pemberitahuan Perubahan</strong></h3>
                  
                  <p>Dengan menggunakan website kami, dengan ini Anda setuju pada pengumpulan dan pemakaian informasi ini sebagaimana ditetapkan dalam kebijakan privasi ini.</p>

                  <h3 className="color black"><strong>Gambaran Umum</strong></h3>
                  
                  <p>Tidak ada sesuatu apapun dalam perjanjian ini yang merupakan usaha kami untuk menjaga kerahasiaan informasi apapun yang diberikan kepada kami dalam hal pemakaian Website kami.</p>

                  <h3 className="color black"><strong>Informasi Kontak</strong></h3>
                  
                  <p>Jika pengguna memiliki pertanyaan atau saran yang berkaitan dengan kebijakan privasi, silahkan kirimkan email ke <a href="mailto:info@jalantikus.com">info@jalantikus.com</a>.</p>
               </div>
            </div>
         </div>
      </>
   );
}

export default Privacy;
