import { adManager } from '../constants'

export function generateAdUnitTagHead(obj) {
  const _sizes = (obj.defaultSize) ? getDefaultSize(obj.defaultSize) : obj.sizes;

  return `googletag.cmd.push(function() {
        googletag.defineSlot(
          '/${adManager.networkCode}/${obj.slot}', 
          ${_sizes},
          '${obj.id}'
        ).addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
    });`
}

export function generateAdUnitTagHeadWithTargeting(obj) {
  const _sizes = (obj.defaultSize) ? getDefaultSize(obj.defaultSize) : obj.sizes;

  if(obj.content_id <=53900){
    return `googletag.cmd.push(function() {
          googletag.defineSlot(
            '/${adManager.networkCode}/${obj.slot}',
            ${_sizes},
            '${obj.id}'
          ).addService(googletag.pubads())
          .setTargeting('WriterID_date', ['${obj.WriterID_date}'])
          .setTargeting('tag_ID', ['${obj.tag_ID}'])
          .setTargeting('seo_tag', ['${obj.seo_tag}'])
          .setTargeting('content_id', ['${obj.content_id}'])
          .setTargeting('categoryID', ['${obj.categoryID}']);
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
      });`
  } else if (obj.content_id > 53900 && obj.content_id<=154000){
    return `googletag.cmd.push(function() {
        googletag.defineSlot(
          '/${adManager.networkCode}/${obj.slot}',
          ${_sizes},
          '${obj.id}'
        ).addService(googletag.pubads())
        .setTargeting('WriterID_date', ['${obj.WriterID_date}'])
        .setTargeting('tag_ID', ['${obj.tag_ID}'])
        .setTargeting('seo_tag', ['${obj.seo_tag}'])
        .setTargeting('content_id_02', ['${obj.content_id}'])
        .setTargeting('categoryID', ['${obj.categoryID}']);
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
    });`
  } else if (obj.content_id > 154000){
    return `googletag.cmd.push(function() {
        googletag.defineSlot(
          '/${adManager.networkCode}/${obj.slot}',
          ${_sizes},
          '${obj.id}'
        ).addService(googletag.pubads())
        .setTargeting('WriterID_date', ['${obj.WriterID_date}'])
        .setTargeting('tag_ID', ['${obj.tag_ID}'])
        .setTargeting('seo_tag', ['${obj.seo_tag}'])
        .setTargeting('content_id_03', ['${obj.content_id}'])
        .setTargeting('categoryID', ['${obj.categoryID}']);
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
    });`
  }
}

export function generateAdUnitTagBody(obj) {
  var $wrapperId = document.getElementById(`${obj.wrapperId}`)

  var _string = `<div id='${obj.id}'></div>`;
  var _script = document.createElement("script");
  _script.type = "text/javascript";
  _script.text = `googletag.cmd.push(function() { googletag.display('${obj.id}'); });`;
  $wrapperId && $wrapperId.insertAdjacentHTML('afterbegin', _string);
  $wrapperId && $wrapperId.appendChild(_script);
}

export const categoryArr = {
  desktop: {
    tags: {
      apps: "0111",
      games: "0112",
      "tech-hack": "0113",
      android: "0114",
      "android-ios": "0114",
      gadget: "0115",
      fintech: "0116",
      viral: "0117",
      "out-of-tech": "0118",
    },
    category: {
      apps: "0101",
      games: "0102",
      gadgets: "0103",
      news: "0104",
      gokil: "0105",
      tips: "0106",
    },
  },
  mobile: {
    tags: {
      apps: "0211",
      games: "0212",
      "tech-hack": "0213",
      android: "0214",
      "android-ios": "0214",
      gadget: "0215",
      fintech: "0216",
      viral: "0217",
      "out-of-tech": "0218",
    },
    category: {
      apps: "0201",
      games: "0202",
      gadgets: "0203",
      news: "0204",
      gokil: "0205",
      tips: "0206",
    },
  },
};

export const getDefaultSize = (parent) => {
  let result = '';

  switch (parent) {
    case '970x250':
      result = "[[970, 66], [970, 250], [970, 90], 'fluid']";
      break;
    case '970x90':
      result = "['fluid', [960, 90], [970, 66], [728, 90], [950, 90], [970, 90]]";
      break;
    case '728x90':
      result = "['fluid', [728, 90]]";
      break;
    case '300x600':
      result = "['fluid', [300, 300], [300, 600], [300, 250]]";
      break;
    case '300x250':
      result = "[[200, 200], [300, 50], [300, 250], [300, 31], [300, 75], [300, 200], 'fluid', [300, 100]]";
      break;
    case '320x100':
      result = "[[300, 100], [320, 100], [300, 75], [216, 54], [234, 60], [168, 28], 'fluid', [300, 50], [168, 42], [320, 50], [216, 36], [220, 90]]";
      break;
    case '750x300':
      result = "['fluid', [750, 300], [750, 200]]";
      break;
    case '480x320':
      result = "[[480, 320], [750, 250], 'fluid', [750, 300]]";
      break;
    default:
      result = "[[200, 200], [300, 50], [300, 250], [300, 31], [300, 75], [300, 200], 'fluid', [300, 100]]";
      break;
  }

  return result;
}