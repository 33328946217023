import { NFTPageService } from "../../http";
import { getBestGamesThunk } from "./downloadPageReducer";

/* ACTION TYPES */
const GET_NFT_ARTICLE_DATA = "GET_NFT_ARTICLE_DATA";
const GET_NFT_GAME_APP_DATA = "GET_NFT_GAME_APP_DATA";
const GET_DAPP_APP_DATA = "GET_DAPP_APP_DATA";
const GET_SELECTED_CATEGORY_META = "GET_SELECTED_CATEGORY_META";
const GET_WEB3_ARTICLE_DATA = "GET_WEB3_ARTICLE_DATA";

/*ACTION CREATORS*/
export const getCategoryPageData = (payload) => ({
  type: GET_NFT_ARTICLE_DATA,
  payload: payload,
});

export const getNFTAppsByNFTGamesCategory = (payload) => ({
  type: GET_NFT_GAME_APP_DATA,
  payload: payload,
});

export const getNFTAppsByDAppCategory = (payload) => ({
  type: GET_DAPP_APP_DATA,
  payload: payload,
});

export const getSelectedCategoryMeta = (payload) => ({
  type: GET_SELECTED_CATEGORY_META,
  payload: payload,
});

export const getWeb3ArticleData = (payload) => ({
  type: GET_WEB3_ARTICLE_DATA,
  payload: payload,
});

/*THUNK*/
export const getCategoryNFTPageDataThunk = () => async (dispatch) => {
  try {
    const response = await NFTPageService.getArticle();
    dispatch(getCategoryPageData(response.data));
  } catch (error) {
    dispatch(getCategoryPageData(error));
  }
};

export const getNFTArticles = () => async (dispatch) => {
  try {
    const response = await NFTPageService.getArticle();
    dispatch(getCategoryPageData(response.data));
  } catch (error) {
    dispatch(getCategoryPageData(error));
  }
};

export const getAppsByCategoryThunk =
  () => async (dispatch) => {
    try {
      const nftGameApps = await NFTPageService.getAppsByNFTGameCategory();
      const nftDAppApps = await NFTPageService.getAppsByDAppCategory();
      dispatch(getNFTAppsByNFTGamesCategory(nftGameApps.data));
      dispatch(getNFTAppsByDAppCategory(nftDAppApps.data));
    } catch (error) {
      dispatch(getNFTAppsByNFTGamesCategory(error));
      dispatch(getNFTAppsByDAppCategory(error));
    }
  };

export const getSelectedCategoryMetaThunk =
  (type, category) => async (dispatch) => {
    try {
      const response = await NFTPageService.getMetaByCategory(type, category);
      dispatch(getSelectedCategoryMeta(response.data));
    } catch (error) {}
  };

export const getAllNFTPageDataThunk = () => async (dispatch) => {
  try {
    await dispatch(getNFTArticles());
    // await dispatch(getAppsByCategoryThunk("nft-games"));
    // await dispatch(getAppsByCategoryThunk("dapp"));
    // await dispatch(getSelectedCategoryMetaThunk("apps", "antivirus-security"));
    await dispatch(getBestGamesThunk('android'));
    await dispatch(getBestGamesThunk('windows'));
  } catch (error) {}
};

export const getWeb3Articles = () => async (dispatch) => {
  try {
    const response = await NFTPageService.getWeb3Article(); 
    dispatch(getWeb3ArticleData(response.data));
  } catch (error) {
    dispatch(getWeb3ArticleData(error));
  }
};

/*INITIAL STATE*/
const initialState = {
  nftCategoryData: null,
  nftGameApps: null,
  nftDAppApps: null,
  selectedCategoryMeta: null,
  web3ArticleData: null
};

/*REDUCER*/
function nftPageReducer(state = initialState, action) {
  switch (action.type) {
    /*Get data for category page*/
    case GET_NFT_ARTICLE_DATA:
      return {
        ...state,
        nftCategoryData: action.payload,
      };
    case GET_NFT_GAME_APP_DATA:
      return {
        ...state,
        nftGameApps: action.payload,
      };
    case GET_DAPP_APP_DATA:
      return {
        ...state,
        nftDAppApps: action.payload,
      };
    case GET_SELECTED_CATEGORY_META:
      return {
        ...state,
        selectedCategoryMeta: action.payload,
      };
    case GET_WEB3_ARTICLE_DATA:
      return {
        ...state,
        web3ArticleData: action.payload,
      };
    default:
      return state;
  }
}

export default nftPageReducer;
