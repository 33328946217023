import React from "react";
import "./TopComponent.css";
import EditorsPickItem from "../../components/EditorsPick/EditorsPickItem";
import MainPost from "../../components/MainPost/MainPost";
// import { useSelector } from "react-redux";

function TopComponent({ category, articles }) {
  // const homepageData = useSelector(
  //   (state) => state.homepageReducer.homepageData
  // );

  return (
    <div className="top-component__wrapper">
      { category
        && <h2 style={{fontSize: "0"}}>Artikel {category} Terpopuler</h2>}
      <div className="top-component__left">
        <MainPost article={articles && articles[0]} />
      </div>
      <div className="top-component__right">
        {articles 
        ? articles.slice(1, 3).map((item) => <EditorsPickItem key={item.id} article={item} />)
        : [...Array(2)].map((item, index) => <EditorsPickItem key={index}/>)}
      </div>
    </div>
  );
}

export default TopComponent;
