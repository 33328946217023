import React from "react";
// import email from "@assets/images/social/email.svg";
// import facebook from "@assets/images/social/facebook.svg";
// import instagram from "@assets/images/social/instagram.svg";
// import twitter from "@assets/images/social/twitter.svg";
// import youtube from "@assets/images/social/youtube.svg";
import "./FooterSocialIcons.css";

function FooterSocialIcons() {
    return (
        <>
            <div className="footer-social-icons__wrapper">
                <a
                    className="footer-social-icons__link"
                    href="/handler/social/?u=https://www.facebook.com/JalanTikus/"
                >
                    <span className="icon-social-fb default"></span>
                </a>
                <a
                    className="footer-social-icons__link"
                    href="/handler/social/?u=https://www.instagram.com/jalantikusid/">
                    <span className="icon-instagram colorized" />
                </a>
                <a
                    className="footer-social-icons__link"
                    href="/handler/social/?u=https://www.youtube.com/user/JalanTikusOfficial">
                    <span className="icon-youtube red" />
                </a>
                <a
                    className="footer-social-icons__link"
                    href="/handler/social/?u=https://twitter.com/jalantikuscom">
                    <span className="icon-social-twitter"></span>
                </a>
                <a className="footer-social-icons__link" href="mailto:info@jalantikus.com">
                    <span className="icon-social-email"></span>
                </a>
            </div>
        </>
    );
}

export default FooterSocialIcons;
