import React from "react";
import logoBlackSmall from "@assets/images/jalantikus-logo-black-small.png";
// import { Link } from "react-router-dom";

function LogoBlackSmall() {
  return (
    <>
      <a href="/">
        <img src={logoBlackSmall} alt="logo" />
      </a>
    </>
  );
}

export default LogoBlackSmall;
