import { VideoDetailPageService } from "../../http";
import { getPopulerDataThunk } from './homepageReducer';

/*ACTION TYPES*/
const GET_ACTIVE_DETAIL_VIDEO = "GET_ACTIVE_DETAIL_VIDEO";
const GET_SLIDER_DATA = "GET_SLIDER_DATA";

/*ACTION CREATORS*/
export const getActiveDetailVideo = (payload) => ({
  type: GET_ACTIVE_DETAIL_VIDEO,
  payload: payload,
});

export const getSliderData = (payload) => ({
  type: GET_SLIDER_DATA,
  payload: payload,
});

/*THUNK*/
export const getActiveDetailVideoThunk = (videoId) => async (dispatch) => {
  try {
    const response = await VideoDetailPageService.getActiveDetailVideo(videoId);
    dispatch(getActiveDetailVideo(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getActiveDetailVideo(error.response.data));
  }
};

export const getSliderDataThunk = (videoID, category) => async (dispatch) => {
  try {
    const response = await VideoDetailPageService.getSliderData(
      videoID,
      category
    );
    dispatch(getSliderData(response.data));
  } catch (error) {
    // console.log(error);
  }
};

export const getAllVideoDetailPageDataThunk = (params) => async (dispatch) => {
  try {
    const { category, videoId } = params;
    await dispatch(getPopulerDataThunk());
    await dispatch(getActiveDetailVideoThunk(videoId));
    await dispatch(getSliderDataThunk(videoId, category));
  } catch (error) {
    // console.log(error);
  }
};

/*INITIAL STATE*/
const initialState = {
  activeDetailVideo: null,
  sliderData: null,
};

/*REDUCER*/
function videoDetailPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_DETAIL_VIDEO:
      return {
        ...state,
        activeDetailVideo: action.payload,
      };
    case GET_SLIDER_DATA:
      return {
        ...state,
        sliderData: action.payload,
      };
    default:
      return state;
  }
}

export default videoDetailPageReducer;
