import React from 'react';
// import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';
import ReactHtmlParser from 'react-html-parser';
import { getAllMetaTags } from '../../constants';

const MetaTag = (props) => {
  const schemas = props && props.schemaContent ? props.schemaContent.map((schema, index) => <script key={`meta-${index}`} type="application/ld+json">{JSON.stringify(schema)}</script>) : ""

  return (
    <Helmet>
      { ReactHtmlParser(getAllMetaTags(props)) }
      { schemas }
    </Helmet>
  )
}

export default MetaTag
