import React from "react";
import "./PopularFinance.css";
// import { useSelector } from "react-redux";
import PopularFinanceItem from "../PopularFinance/PopularFinanceItem";
import { LazyLoadImage } from "react-lazy-load-image-component";

function PopularFinance({ title, articles }) {
  // const otherData = useSelector((state) => state.homepageReducer.other);

  return (
    <>
      <div className="popular-finance__container">
        <div className="popular-finance__title">{title}</div>
        <div className="popular-finance__list">
          {articles &&
            articles.map((item, index) => (
              <PopularFinanceItem key={index} num={index + 1} article={item} />
            ))}
        </div>
        <LazyLoadImage className="finance-image" effect="blur" src={`/assets/images/popularFinance.png`} />
      </div>
    </>
  );
}

export default PopularFinance;
