import React from "react";
import "./LoadingMore.css";

function LoadingMore() {
  return (
    <div className="loading-more__wrapper">
      <p className="loading-more-inner category-small ">Loading More..</p>
    </div>
  );
}
export default LoadingMore;
