import { ArticlePageService } from "../../http";
import { clearSearchVideoList, getInfiniteVideoDataThunk } from './videoCategoryPageReducer';
import { clearSearchGadgetData, getInfiniteGadgetDataThunk } from "./gadgetsPageReducer";
import { getInfiniteVehicleDataThunk } from "./electricVehiclePageReducer";

/*ACTION TYPES*/
const GET_ARTICLE_PAGE_DATA = "GET_ARTICLE_PAGE_DATA";
const GET_ARTICLE_PREVIEW_PAGE_DATA = "GET_ARTICLE_PREVIEW_PAGE_DATA";
const GET_SEARCH_ARTICLE_DATA = "GET_SEARCH_ARTICLE_DATA";
const CLEAR_SEARCH_ARTICLE_DATA = "CLEAR_SEARCH_ARTICLE_DATA";
const GET_INFINITE_ARTICLE_DATA = "GET_INFINITE_ARTICLE_DATA";
const GET_SUGGESTION_DATA = "GET_SUGGESTION_DATA";
const CLEAR_SUGGESTION_DATA = "CLEAR_SUGGESTION_DATA";

const CLEAR_SEARCH_APPS_DATA = "CLEAR_SEARCH_APPS_DATA";
const GET_INFINITE_APPS_DATA = "GET_INFINITE_APPS_DATA";
const GET_ARTICLE_ADS_DATA = "GET_ARTICLE_ADS_DATA";

/*ACTION CREATORS*/
export const getArticlePageData = (payload) => ({
  type: GET_ARTICLE_PAGE_DATA,
  payload: payload,
});

export const getArticlePreviewPageData = (payload) => ({
  type: GET_ARTICLE_PREVIEW_PAGE_DATA,
  payload: payload,
});

export const getSearchArticleData = (payload) => ({
  type: GET_SEARCH_ARTICLE_DATA,
  payload: payload,
});

export const clearSearchArticleData = (payload) => ({
  type: CLEAR_SEARCH_ARTICLE_DATA,
  payload: payload,
});

export const getInfiniteArticleData = (payload) => ({
  type: GET_INFINITE_ARTICLE_DATA,
  payload: payload,
});

export const getSuggestionData = (payload) => ({
  type: GET_SUGGESTION_DATA,
  payload: payload,
});

export const clearSuggestionData = (payload) => ({
  type: CLEAR_SUGGESTION_DATA,
  payload: payload,
});


export const clearSearchAppsData = (payload) => ({
  type: CLEAR_SEARCH_APPS_DATA,
  payload: payload,
});

export const getInfiniteAppsData = (payload) => ({
  type: GET_INFINITE_APPS_DATA,
  payload: payload,
});

export const getArticleAdsData = (payload) => ({
  type: GET_ARTICLE_ADS_DATA,
  payload: payload,
});

/*THUNK*/
export const getArticlePageDataThunk = (params) => async (dispatch) => {
  try {
    const { category, slug } = params;
    const response = await ArticlePageService.getArticleData(category, slug);
    dispatch(getArticlePageData(response.data));
  } catch (error) {
    dispatch(getArticlePageData(error));
  }
};

export const getArticlePreviewPageDataThunk = (params) => async (dispatch) => {
  try {
    const { category, slug } = params;
    const response = await ArticlePageService.getArticlePreviewData(category, slug);
    dispatch(getArticlePreviewPageData(response.data));
  } catch (error) {
    dispatch(getArticlePreviewPageData(error));
  }
};

export const getSearchArticleDataThunk = (keyword) => async (dispatch) => {
  try {
    const response = await ArticlePageService.getSearchArticleData(keyword);
    dispatch(getSearchArticleData(response.data.response.article.data));
  } catch (error) {
  }
};

export const getInfiniteArticleDataThunk =
  (page, keyword) => async (dispatch) => {
    try {
      const response = await ArticlePageService.getInfiniteArticleData(
        page,
        keyword
      );
      dispatch(
        getInfiniteArticleData({
          list: response.data.response.article.data,
          total: response.data.response.article.total,
          hasMore: (response.data.response.article.page.curr < response.data.response.article.page.last) ? true : false 
        })
      );
      dispatch(getInfiniteAppsData({
        android:{
          list: response.data.response.android.data,
          total: response.data.response.android.total,
          hasMore: (response.data.response.android.page.curr < response.data.response.android.page.last) ? true : false 
        },
        windows:{
          list: response.data.response.windows.data,
          total: response.data.response.windows.total,
          hasMore: (response.data.response.windows.page.curr < response.data.response.windows.page.last) ? true : false 
        }
      }))
    } catch (error) {
    }
  };

export const getArticleSuggestionDataThunk = (keyword) => async (dispatch) => {
  try {
    const response = await ArticlePageService.getSuggestionData(keyword);
    dispatch(getSuggestionData(response.data));
  } catch (error) {
  }
};

export const getArticleAdsDataThunk = () => async (dispatch) => {
  try{
    const response = await ArticlePageService.getAdsData();
    dispatch(getArticleAdsData(response.data));
  }catch (error) {
    // console.log(error)
  }
}

export const getSearchPageDataThunk = (params) => async (dispatch) => {
  try {
    const { page, query, category } = params;
    switch (category) {
      case "video":
        await dispatch(getInfiniteVideoDataThunk(page, query));
        dispatch(clearSearchArticleData());
        dispatch(clearSearchGadgetData());
        break;
      case "gadget":
        await dispatch(getInfiniteGadgetDataThunk('smartphone', page, query));
        dispatch(clearSearchArticleData());
        dispatch(clearSearchVideoList());
        break;
      case "mobil":
      case "motor":
        await dispatch(getInfiniteVehicleDataThunk(category, page, query));
        break;
      case "articles":
      case "download":
      default:
          await dispatch(getInfiniteArticleDataThunk(page, query));
          dispatch(clearSearchVideoList());
          dispatch(clearSearchGadgetData());
        break;
    }
  } catch (error) {
  }
}

/*INITIAL STATE*/
const initialState = {
  articlePageData: null,
  articlePreviewPageData: null,
  searchArticleData: null,
  searchAppsData: {
    android:null,
    windows:null
  },
  searchAppsDataAndroid:null,
  searchAppsDataWindows:null,
  suggestionData: null,
  total: null,
  appsDataTotal: null,
  articleAdsData: null,
  isHasMoreArticle: false,
  isHasMoreWindows: false,
  isHasMoreAndroid: false,
};

/*REDUCER*/
function articlePageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLE_PAGE_DATA:
      return {
        ...state,
        articlePageData: action.payload,
      };

    case GET_ARTICLE_PREVIEW_PAGE_DATA:
      return {
        ...state,
        articlePreviewPageData: action.payload,
      };

    case GET_SEARCH_ARTICLE_DATA:
      return {
        ...state,
        searchArticleData: action.payload,
      };

    case CLEAR_SEARCH_ARTICLE_DATA:
      return {
        ...state,
        searchArticleData: [],
        searchAppsDataAndroid: [],
        searchAppsDataWindows: []
      };

    case GET_INFINITE_ARTICLE_DATA:
      return {
        ...state,
        searchArticleData: [
          ...(state.searchArticleData || []),
          ...(action.payload.list || []),
        ],
        total: action.payload.total,
        isHasMoreArticle:action.payload.hasMore,
      };

    case GET_SUGGESTION_DATA:
      return {
        ...state,
        suggestionData: action.payload,
      };

    case CLEAR_SUGGESTION_DATA:
      return {
        ...state,
        suggestionData: null,
      };

    case CLEAR_SEARCH_APPS_DATA:
      return {
        ...state,
        searchAppsData: [],
      };

    case GET_INFINITE_APPS_DATA:
      return {
        ...state,
        isHasMoreAndroid: action.payload.android.hasMore,
        isHasMoreWindows: action.payload.windows.hasMore,
        searchAppsDataAndroid:[
          ...(state.searchAppsDataAndroid || []),
          ...(action.payload.android.list || []),
        ],
        searchAppsDataWindows:[
          ...(state.searchAppsDataWindows || []),
          ...(action.payload.windows.list || []),
        ],
        appsDataTotal: action.payload.android.total + action.payload.windows.total,
      };
      case GET_ARTICLE_ADS_DATA:
        return {
          ...state,
          articleAdsData: action.payload,
        };
    default:
      return state;
  }
}

export default articlePageReducer;
