import React, { useState, useEffect } from "react";
import loadable from '@loadable/component'
import "./Header.css";
const HeaderMobile = loadable(() => import('../Header/HeaderMobile/HeaderMobile'))
const HeaderDesktop = loadable(() => import('../Header/HeaderDesktop/HeaderDesktop'))

function Header() {
    const isMoblie = useIsMobile();
    return (
        <header>
            {isMoblie ? <HeaderMobile /> : <HeaderDesktop />}
        </header>
    );
}


export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleMobileResize = () => {
            const mobile = typeof window === undefined ? false : window.innerWidth <= 768;
            setIsMobile(mobile);
        }

        handleMobileResize();
        window.addEventListener("resize", handleMobileResize);
    
        return () => {
            window.removeEventListener("resize", handleMobileResize);
        }
    }, []);
    return isMobile;
}

export default Header;
