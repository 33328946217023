import { createStore, combineReducers, applyMiddleware } from "redux";
import autoSuggestionReducer from "./ducks/autoSuggestionReducer";
import homepageReducer from "./ducks/homepageReducer";
import categoryPageReducer from "./ducks/categoryPageReducer";
import authorPageReducer from "./ducks/authorPageReducer";
import articlePageReducer from "./ducks/articlePageReducer";
import tagsPageReducer from "./ducks/tagsPageReducer";
import gadgetsPageReducer from "./ducks/gadgetsPageReducer";
import videoPageReducer from "./ducks/videoPageReducer";
import videoCategoryPageReducer from "./ducks/videoCategoryPageReducer";
import downloadPageReducer from "./ducks/downloadPageReducer";
import downloadCategoryPageReducer from "./ducks/downloadCategoryPageReducer";
// import searchResultsReducer from "./ducks/searchResultsReducer";
import videoDetailPageReducer from "./ducks/videoDetailPageReducer";
import downloadDetailPageReducer from "./ducks/downloadDetailPageReducer";
import megaMenuReducer from "./ducks/megaMenuReducer";
import nftPageReducer from "./ducks/nftPageReducer";
import dappPageReducer from "./ducks/dappPageReducer";
import gameItemPageReducer from "./ducks/gameItemPageReducer";
import eletricVehiclePageReducer from "./ducks/electricVehiclePageReducer";
// import worldCupReducer from "./ducks/worldCupReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
const rootReducer = combineReducers({
  autoSuggestionReducer,
  homepageReducer,
  categoryPageReducer,
  articlePageReducer,
  tagsPageReducer,
  gadgetsPageReducer,
  videoPageReducer,
  videoCategoryPageReducer,
  downloadPageReducer,
  // searchResultsReducer,
  videoDetailPageReducer,
  downloadCategoryPageReducer,
  downloadDetailPageReducer,
  megaMenuReducer,
  nftPageReducer,
  dappPageReducer,
  gameItemPageReducer,
  authorPageReducer,
  eletricVehiclePageReducer,
  // worldCupReducer
});

let enhancer = applyMiddleware(thunk);

if (process.env.REACT_APP_ENV !== 'production') {
  enhancer = composeWithDevTools(enhancer);
}

const store = createStore(
  rootReducer,
  enhancer
);

export default store;
