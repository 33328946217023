import React from "react";
import './AppsArticleSquare.css'
import ArticleSquareItem from '../common/ArticleSquare/ArticleSquareItem';

function AppsArticleSquare({articles}){
    return(
        <div className="article-square__container">
            <div className="article-square__wrapper">
                {articles && articles.length > 1 ? (
                    articles.map((item, i) => <ArticleSquareItem article={item} key={i}/>)
                ) : (
                    [...Array(3)].map((item, i) => <ArticleSquareItem key={i}/> )
                )}
            </div>
        </div>
    )
}

export default AppsArticleSquare