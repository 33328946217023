import React from "react";
import "./StaticSidebar.css";
import {useLocation} from "react-router-dom"
// import PrivacyPolicyImg from "@assets/images/static/privacy.svg";
// import ContactUsImg from "@assets/images/static/contact.svg";
// import TermsConditionsImg from "@assets/images/static/accept.svg";
// import FaqImg from "@assets/images/static/help.svg";
// import ArrowImg from "@assets/images/static/arrow.svg";

function StaticSidebar() {
   const location = useLocation();
   const pathname = location.pathname;
   const pathArray = pathname.split("/");
   const pageType = pathArray[1];

   return (
      <>
         <div className="sidebar-wrapper">
            <div className={`sidebar-item__container ${pageType==='tentang-kami' && 'active'}`}>
               <a href={`/tentang-kami/`} className="eula__wrapper">
               <span className="icon-team-icon"></span>
                  <div className="title-text">
                     <h3>About Us</h3>
                     <span>Get to know our mission</span>
                  </div>
                  <span className="icon-icon-arrow-sm"></span>
               </a>
            </div>
            <div className={`sidebar-item__container ${pageType==='privacy' && 'active'}`}>
               <a href={`/privacy/`} className="eula__wrapper">
               <span className="icon-shield"></span>
                  <div className="title-text">
                     <h3>Privacy Policy</h3>
                     <span>We Value Our Readers Privacy</span>
                  </div>
                  <span className="icon-icon-arrow-sm"></span>
               </a>
            </div>
            <div className={`sidebar-item__container ${pageType==='contact' && 'active'}`}>
               <a href={`/contact/`} className="contact__wrapper">
               <span className="icon-social-email-default"></span>
                  <div className="title-text">
                     <h3>Contact Us</h3>
                     <span>We'd love to hear from you</span>
                  </div>
                  <span className="icon-icon-arrow-sm"></span>
               </a>
            </div>
            <div className={`sidebar-item__container ${pageType==='eula' && 'active'}`}>
               <a href={`/eula/`} className="terms-conditions__wrapper">
                  <span className="icon-file-text2"></span>
                  <div className="title-text">
                     <h3>Terms & Condition</h3>
                     <span>We Value Our Readers Privacy</span>
                  </div>
                  <span className="icon-icon-arrow-sm"></span>
               </a>
            </div>
            <div className={`sidebar-item__container ${pageType==='faq' && 'active'}`}>
               <a href={`/faq/`} className="faq__wrapper">
                  <span className="icon-bubbles"></span>
                  <div className="title-text">
                     <h3>FAQ</h3>
                     <span>Ask Anything About Us</span>
                  </div>
                  <span className="icon-icon-arrow-sm"></span>
               </a>
            </div>
            <div className={`sidebar-item__container ${pageType==='disclaimer' && 'active'}`}>
               <a href={`/disclaimer/`} className="disclaimer__wrapper">
                  <span className="icon-icon-article"></span>
                  <div className="title-text">
                     <h3>Disclaimer</h3>
                     <span>Our Disclaimer & Terms</span>
                  </div>
                  <span className="icon-icon-arrow-sm"></span>
               </a>
            </div>
            <div className={`sidebar-item__container ${pageType==='pedoman' && 'active'}`}>
               <a href={`/pedoman/`} className="pedoman__wrapper">
                  <span className="icon-archive-data-document"></span>
                  <div className="title-text">
                     <h3>Pedoman</h3>
                     <span>Pedoman Media Siber</span>
                  </div>
                  <span className="icon-icon-arrow-sm"></span>
               </a>
            </div>
         </div>
      </>
   );
}

export default StaticSidebar;
