import React from "react";
import "./Contact.css";
import StaticSidebar from "@components/Static/Sidebar/StaticSidebar";
// import {Link} from 'react-router-dom';

function Contact() {
   return (
      <>
         <div className="contact-section__container">
            <div className="static-sidebar">
               <StaticSidebar cls="show-desktop has-title" id="" active="eula" />
            </div>
            <div className="static-content">
               <div className="title">
                  <h1>Contact Us</h1>
                  <span>We'd Love to Hear From You</span>
               </div>
               <div className="content">
                  <p>Silahkan pilih bantuan yang Anda butuhkan.</p>
                  <div className="contact-us__wrapper">
                     <a href={`/partnership/`} className="contact-us__item partnership">
                        <div className="contact-us-icon-partnership"></div>
                        <div className="contact-us__title">
                           Partnership
                        </div>
                     </a>
                     <a href={`/partnership/`} className="contact-us__item advertisement">
                        <div className="contact-us-icon-advertisement"></div>
                        <div className="contact-us__title">
                           Advertising
                        </div>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Contact;
