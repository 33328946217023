import { javantApi } from "../constants";

export const GadgetsPageService = {
    getHomeData(){
        return javantApi.get(`component/gadget/home/`);
    },
    getDetailData(gadgetSlug){
        // https://api.jalantikus.com/v1/content/gadget/detail/asus-zenfone-max-pro-m2-zb631kl
        return javantApi.get(`content/gadget/detail/${gadgetSlug}`);
    },
    getGadgetSearch(type, merk="", q=""){
        return javantApi.get(`component/gadget/${type}?merek=${merk}&q=${q}&page=1&pmin=500000&pmax=150000000`);
    },
    getSuggestionData(q="", item="", type="gadget"){
        return javantApi.get(`search/suggest?q=${q}&item=${item}&type=${type}`);
    },

    getInfiniteGadgetData(type, page, keyword) {
        return javantApi.get(`component/gadget/${type}?q=${keyword}&page=${page}&pmin=500000&pmax=150000000`);
    },
    getInfiniteGadgetDataByMerk(type, merk, page){
        return javantApi.get(`component/gadget/${type}?merek=${merk}&page=${page}&pmin=500000&pmax=150000000`);
    }
}