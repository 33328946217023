import React from "react";
import "./DesktopHorizontalBanner.css";
// import desktopBanner from "../../../assets/images/banners/desktop-banner1.svg";
import { AdSlot } from 'react-dfp';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function DesktopHorizontalBanner() {
  const { width } = useWindowDimensions();
  const isMobile = width <= 768 ? true : false 

  return (
    <>
    { !isMobile &&
      <div className="desktop-banner__wrapper" style={{minWidth:'320px',minHeight: '66px'}}>
        <AdSlot 
          dfpNetworkId="21702367101" 
          adUnit="JT_728x90" 
          sizes={[[728, 90], 'fluid', [320, 100], [950, 90], [960, 90], [970, 66], [750, 100], [980, 90], [970, 90]]}
          targetingArguments={{ jt_ads: ['revamp_test'] }}
        />
      </div>
    }
    </>
  );
}

export default DesktopHorizontalBanner;
