import React from "react"

function AlertCopyClip(){
    const closeAlert = () => {
        const el = document.getElementsByClassName(`alert-copy-clip`)[0];
        el.classList.contains(`show`) && el.classList.remove(`show`)
    }

    return (
        <div className="alert-copy-clip">
            {`Tautan berhasil disalin`}
            <span className='close-btn' onClick={() => {closeAlert()}}>X</span>
        </div>
    )
}

export default AlertCopyClip