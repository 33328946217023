export const analytics = {
    // https://analytics.google.com/
    googleTrackingId: (process.env.NODE_ENV !== 'production') ? 'UA-56475281-6' : 'UA-56475281-2', // UA-XXXXX-X,
    googleTagManagerId: (process.env.NODE_ENV !== 'production') ? 'GTM-MC7C782' : 'GTM-WVNL8MB' //GTM-XXXXXXX

};

export const adManager = {
    networkCode: '21702367101'
}
