import axios from "axios";
import https from 'https';

export const wcApiKey = {
    apiKey: 'd4e1cbd37340e5e8fe9673e95a590a0a'
};

export const worldCupApi = axios.create({
    baseURL: 'https://api.statorium.com/api/v1/',
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    }),
  });