import React from "react"
import { useEffect } from "react"
import './ExternalPopup.css'

function ExternalPopup() {

    useEffect(() => {
        
        document.querySelectorAll(".external-popup-container .popup-overlay")[0].addEventListener("click", (e) => {
            document.querySelectorAll(".external-popup-container")[0].style.display = 'none';
        });
        document.querySelectorAll(".external-popup-container .popup-close")[0].addEventListener("click", (e) => {
            document.querySelectorAll(".external-popup-container")[0].style.display = 'none';
        });
        document.getElementsByClassName("external-reject")[0].addEventListener("click", (e) => {
            document.querySelectorAll(".external-popup-container")[0].style.display = 'none';
        });
        document.getElementsByClassName("external-accept")[0].addEventListener("click", (e) => {
            let _href = document.getElementById("external").getAttribute("data-href")
            window.open(_href, "_blank");

            document.querySelectorAll(".external-popup-container")[0].style.display = 'none';
        });
        
    }, [])

    return (
        <div className="external-popup-container">
            <div className="popup-overlay black"></div>
            <div className="external-popup">
                <div className="popup-close icon-close">X</div>
                <p className="external-popup-title">Keluar dari JalanTikus</p>
                <img className="lazyload" data-src="/assets/images/external-bg.jpg" alt="Popup External Background JalanTikus" />
                <p>Apakah anda yakin untuk meninggalkan website JalanTikus?</p>
                <div id="external"></div>
                <div className="external-select">
                    <div className="external-accept">Ya</div>
                    <div className="external-reject">Batal</div>
                </div>
            </div>
        </div>
    )
}

export default ExternalPopup