export const getDownloadMeta = (page, pathname) => {
   switch (page) {
      case "apps":
         return {
            title: 'Download Aplikasi Android / Windows Terbaru Gratis | JalanTikus',
            description: 'Kumpulan artikel tentang aplikasi serta kamu juga bisa Download aplikasi (APK) Android dan software Windows terbaru gratis yang aman dan legal.',
            url: `${ pathname }`,
            ogTitle: 'Download Aplikasi Android / Windows Terbaru Gratis | JalanTikus'
         }
      case "games":
         return {
            title: 'Download Game Android & PC Terbaru Gratis | JalanTikus',
            description: 'Kumpulan informasi lengkap tentang game, cheat game serta kamu juga bisa Download game Android dan PC (windows) terbaru gratis yang aman dan legal.',
            url: `${ pathname }`,
            ogTitle: 'Download Game Android & PC Terbaru Gratis | JalanTikus'
         }
      case "download":
      default:
         return {
            title: 'Download Aplikasi Android dan Windows gratis JalanTikus',
            description: 'Download Aplikasi Android (APK) dan Windows di JalanTikus Gratis dan Terbukti Aman',
            url: `${ pathname }`,
            ogTitle: 'Download Aplikasi Android dan Windows gratis JalanTikus'
         }
   }
};