import React from "react";
import { useLocation } from "react-router-dom";
import "./ShareSocials.css";
import share from "@assets/images/share-icon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

function ShareSocials({
  facebookLink,
  twitterLink,
  whatsAppLink,
  lineLink,
  isDesktop,
  isMobile,
  title,
}) {
  const location = useLocation();
  const currentUrl = `${process.env.REACT_APP_WEB_URL.slice(0, -1)}${location.pathname}`;
  
  const handleCopy = () => {
    const el = document.getElementsByClassName(`alert-copy-clip`)[0];
    if(el){
      !el.classList.contains(`show`) && el.classList.add(`show`)
      setTimeout(() => el.classList.contains(`show`) && el.classList.remove(`show`), 3000);
    }
  }

  return (
    <>
      {isDesktop && (
        <div className="share-desk__container">
          <div className="share-desk__icon">
            <img src={share} alt="" />
          </div>
          <div className="share-desk__social-outer-wrapper">
            <div className="share-desk__social-wrapper">
              <a
                href={facebookLink}
                target="_blank"
                className="share-desk__social-item"
                rel="noreferrer"
              >
                {/* <img src={facebook} alt="" /> */}
                <span className="icon-social-fb default"></span>
              </a>
              <a
                href={twitterLink}
                target="_blank"
                className="share-desk__social-item"
                rel="noreferrer"
              >
                {/* <img src={twitter} alt="" /> */}
                <span className="icon-social-twitter"></span>
              </a>
              <a
                href={whatsAppLink}
                target="_blank"
                className="share-desk__social-item"
                rel="noreferrer"
              >
                {/* <img src={whatsapp} alt="" /> */}
                <span className="icon-social-whatsapp"></span>
              </a>
              <a
                href={lineLink}
                target="_blank"
                className="share-desk__social-item"
                rel="noreferrer"
              >
                {/* <img src={line} alt="" /> */}
                <span className="icon-social-line"></span>
              </a>
              <CopyToClipboard text={currentUrl} onCopy={() => handleCopy()}>
                <span className="share-desk__social-item">
                  {/* <img src={urlDesk} alt="" /> */}
                  <span className="icon-social-url"></span>
                </span>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="share-social__container">
          <div className="share-social__title">{title}</div>
          <div className="share-social__social-wrapper">
            <a href={facebookLink} className="share-social__social-item">
              {/* <img src={facebook} alt="" /> */}
              <span className="icon-social-fb default"></span>
            </a>
            <a href={twitterLink} className="share-social__social-item">
              {/* <img src={twitter} alt="" /> */}
              <span className="icon-social-twitter"></span>
            </a>
            <a href={whatsAppLink} className="share-social__social-item">
              {/* <img src={whatsapp} alt="" /> */}
              <span className="icon-social-whatsapp"></span>
            </a>
            <a href={lineLink} className="share-social__social-item">
              {/* <img src={line} alt="" /> */}
              <span className="icon-social-line"></span>
            </a>
            <CopyToClipboard text={currentUrl} onCopy={() => handleCopy()}>
              <span href="#" className="share-social__social-item">
                {/* <img src={urlMob} alt="" /> */}
                <span className="icon-social-url"></span>
              </span>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </>
  );
}

export default ShareSocials;
