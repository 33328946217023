import React, { useEffect, useState } from "react";
import './EVFullSpecs.css';
import { EVInfoService, writePrice } from "../../../helpers";

const EVFullSpecs = ({ vehicle }) => {

  const [fullSpecs, setFullSpecs] = useState([]);
  const fmSpecs = EVInfoService.formatFn;

  useEffect(() => {
    if (!vehicle || !vehicle.type) return;
    switch (vehicle.type) {
      case "mobil":
        setFullSpecs(parseCarSpecs(vehicle));
        break;
      case "motor":
        setFullSpecs(parseBikeSpecs(vehicle));
        break;
      default:
      // do nothing
    }
  // eslint-disable-next-line
  }, [vehicle]);

  const parseCarSpecs = (vehicle) => {
    return [
      {
        title: "Title",
        collapse: false,
        props: [
          { text: "Brand", value: vehicle.company.title || "-" },
          { text: "Model", value: vehicle.model || "-" },
        ],
      },
      {
        title: "Launch",
        collapse: false,
        props: [
          { text: "Price", value: writePrice(vehicle.price) || "-" },
          { text: "Announced", value: vehicle.specification.mobil_announced || "-" },
        ]
      },
      {
        title: "Performance",
        collapse: false,
        props: [
          { text: "Power", value: fmSpecs.mobilPower(vehicle?.specification?.mobil_power,
            vehicle?.specification?.mobil_drive_system) },
          { text: "Torque", value: fmSpecs.mobilTorque(vehicle?.specification?.mobil_torque) },
          { text: "Acceleration", value: fmSpecs.mobilAcceleration(vehicle?.specification?.mobil_acceleration) },
          { text: "Max speed", value: fmSpecs.mobilMaxSpeed(vehicle?.specification?.mobil_max_speed) },
        ]
      },
      {
        title: "Battery",
        collapse: false,
        props: [
          { text: "Capacity", value: fmSpecs.mobilCapacity(vehicle?.specification?.mobil_capacity) },
          { text: "Tech", value: vehicle.specification.mobil_tech || "-" },
          { text: "Range", value: fmSpecs.mobilRange(vehicle?.specification?.mobil_range) },
          { text: "Consumption", value: vehicle.specification.mobil_consumption || "-" },
          { text: "Recuperation", value: vehicle.specification.mobil_recuperation || "-" },
          { text: "Heat pump", value: vehicle.specification.mobil_heat_pump || "-" },
          { text: "AC Charging", value: vehicle.specification.mobil_ac_charging || "-" },
          { text: "DC Charging", value: vehicle.specification.mobil_dc_charging || "-" },
        ]
      },
      {
        title: "Body",
        collapse: false,
        props: [
          { text: "Type", value: vehicle.specification.mobil_body_type || "-" },
          { text: "Dimensions", value: fmSpecs.mobilDimensions(
            vehicle?.specification?.mobil_length,
            vehicle?.specification?.mobil_width,
            vehicle?.specification?.mobil_height ) },
          { text: "Wheelbase", value: fmSpecs.mobilWheelbase(vehicle?.specification?.mobil_wheelbase) },
          { text: "Clearance", value: vehicle.specification.mobil_clearance || "-" },
          { text: "Weight", value: vehicle.specification.mobil_weight || "-" },
          { text: "Suspension", value: vehicle.specification.mobil_suspension || "-" },
          { text: "Wheels", value: vehicle.specification.mobil_wheels || "-" },
          { text: "Trunk", value: vehicle.specification.mobil_trunk || "-" },
          { text: "Frunk", value: vehicle.specification.mobil_frunk || "-" },
          { text: "Towing", value: vehicle.specification.mobil_towing || "-" },
        ],
      },
      {
        title: "Displays",
        collapse: false,
        props: [
          { text: "Center", value: vehicle.specification.mobil_display_center || "-" },
          { text: "Driver's", value: vehicle.specification.mobil_display_drivers || "-" },
          { text: "Head-up", value: vehicle.specification.mobil_head_up || "-" },
        ]
      },
      {
        title: "Comfort",
        collapse: false,
        props: [
          { text: "Seats", value: vehicle.specification.mobil_seats || "-" },
          { text: "Roof", value: vehicle.specification.mobil_roof || "-" },
          { text: "Parking aids", value: vehicle.specification.mobil_parking_aids || "-" },
          { text: "Connectivity", value: vehicle.specification.mobil_connectivity || "-" },
        ]
      },
      {
        title: "Safety",
        collapse: false,
        props: [
          { text: "Airbags", value: vehicle.specification.mobil_airbags || "-" },
          { text: "Driving aids", value: vehicle.specification.mobil_driving_aids || "-" },
          { text: "Self driving", value: vehicle.specification.mobil_self_driving || "-" },
          { text: "Crash tests", value: vehicle.specification.mobil_crash_tests || "-" },
        ]
      }
    ]
  }

  const toggleCollapse = (title) => {    
    setFullSpecs(fullSpecs.map(spec => {
      if (spec.title === title) {
        return {
          ...spec,
          collapse: !spec.collapse
        };
      }
      return spec;
    }));
  }

  const parseBikeSpecs = (vehicle) => {
    return [
      {
        title: "Performance",
        collapse: false,
        props: [
          { text: "Brand", value: vehicle.company.title || "-" },
          { text: "Model", value: vehicle.model || "-" },
          { text: "Power", value: fmSpecs.motorPower(vehicle?.specification?.motor_power) },
          { text: "Max Speed", value: fmSpecs.motorMaxSpeed(vehicle?.specification?.motor_max_speed) },
          { text: "Battery", value: fmSpecs.motorBattery(vehicle?.specification?.motor_battery) },
          { text: "Charging time", value: fmSpecs.motorChargingTime(vehicle?.specification?.motor_charging_time) },
          { text: "Capacity", value: fmSpecs.motorCapacity(vehicle?.specification?.motor_capacity) },
          { text: "Transmission", value: vehicle.specification.motor_transmission || "-" },
        ],
      },
      {
        title: "Suspension",
        collapse: false,
        props: [
          { text: "Front suspension", value: vehicle.specification.motor_front_suspension || "-" },
          { text: "Rear suspension", value: vehicle.specification.motor_rear_suspension || "-" },
        ]
      },
      {
        title: "Dimensions",
        collapse: false,
        props: [
          { text: "L x W x H", value: fmSpecs.motorDimension(
            vehicle?.specification?.motor_length,
            vehicle?.specification?.motor_width,
            vehicle?.specification?.motor_height) },
          { text: "Weight", value: vehicle.specification.motor_weight || "-" },
        ]
      },
      {
        title: "Wheel",
        collapse: false,
        props: [
          { text: "Type", value: vehicle.specification.motor_wheel_type || "-" },
          { text: "Front tires", value: vehicle.specification.motor_front_tire || "-" },
          { text: "Rear tires", value: vehicle.specification.motor_rear_tire || "-" },
        ]
      },
      {
        title: "Others",
        collapse: false,
        props: [
          { text: "Digital features", value: vehicle.specification.motor_digital_features || "-" },
          { text: "Safety", value: vehicle.specification.motor_safety || "-" },
        ]
      }
    ]
  }
  return (
    <>
      <div className="vehicle-full-specs-wrapper">
        <h1 className="full-spec__title mb-3">
          <span className="icon-icon-double-arrow mr-2"></span>
          Full Specification
        </h1>
        {
          fullSpecs.map((item, idx) => (
            <>
              <div className="vehicle-sub-item__header d-flex justify-content-between">
                <h2 className="vehicle-subitem__name">{item.title}</h2>
                {
                  item.collapse ?
                    <i onClick={() => toggleCollapse(item.title)} className="icon-icon-arrow-fill-sm-margin"></i> :
                    <i onClick={() => toggleCollapse(item.title)} className="icon-icon-arrow-up-fill-sm-margin"></i>
                }
              </div>
              {
                item.collapse ||
                <div className="vehicle-subitem-container">
                  {item.props.map(subitem => (
                    <div className="vehicle-subitem-container__info d-flex">
                      <div className="vehicle-subitem__text">
                        {subitem.text}
                      </div>
                      <div className="vehicle-subitem__value">
                        {subitem.value}
                      </div>
                    </div>
                  ))}
                </div>
              }
              <hr />
            </>
          ))
        }
      </div>
    </>
  )
}

export default EVFullSpecs;
