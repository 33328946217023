import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// import AppsRanking from "../components/AppsRanking/AppsRanking";
import AdWrapper from "../components/Banners/AdWrapper/AdWrapper";
// import NFTBoard from "../components/NFTBoard/NFTBoard";
import "../assets/css/NFTPage.css";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import DAppsHeadlineSlider from "../components/common/Sliders/DAppsHeadlineSlider/DAppsHeadlineSlider";
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import {
  getAllNFTPageDataThunk,
  // getAppsByCategoryThunk,
  getCategoryNFTPageDataThunk,
} from "../store/ducks/nftPageReducer";
import Error from "../components/Error/Error";
import RelatedArticle from "../components/RelatedArticle/RelatedArticle";
import BottomPostsBlock from "../components/BottomPostsBlock/BottomPostsBlock";
import {
  googleAnalytics,
  generateAdUnitTagHead,
  generateAdUnitTagBody,
} from "../helpers";
import AppsSection from "../components/AppsSection/AppsSection";
import MetaTag from '../components/MetaTag/MetaTag';
import RecentPosts from "../components/RecentPosts/RecentPosts";

// const rankingDatas = [
//   {
//     title: "Trending Games",
//     items: [
//       {
//         name: "Rainbow Token",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//       {
//         name: "MetaPets",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//       {
//         name: "ShibElon",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//     ],
//   },
//   {
//     title: "Naik Terbesar",
//     items: [
//       {
//         name: "Euro Shiba Inu",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//       {
//         name: "Txbit Token",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//       {
//         name: "ElonFlokilnu",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//     ],
//   },
//   {
//     title: "Latest Games",
//     items: [
//       {
//         name: "Devia8",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//       {
//         name: "Umami Finance",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//       {
//         name: "Big Fund Capital DAO",
//         percent: "1.55",
//         icon: "icon-social-fb default",
//       },
//     ],
//   },
// ];


const NFTPage = () => {
  const wordsToRemove = [
    "domino",
    "higgs",
    "mod",
    "hack",
    "xnxx",
    "bokeh",
    "museum",
    "aplikasi dewasa",
    "yandex",
    "judi",
    "slot",
    "dewasa",
    "sadap",
    "bobol",
    "cheat",
    "lacak",
    "open bo",
    "pinjol",
    "pinjaman online",
    "cara pinjam",
    "simontok",
    "xnxubd",
    "uncensored",
    "doodstream",
    "dood",
    "18+"
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = () => {
      dispatch(getCategoryNFTPageDataThunk());
      // dispatch(getAppsByCategoryThunk("antivirus-security", "windows"));
      dispatch(getAllNFTPageDataThunk());
    };
    getData();
    googleAnalytics();
    loadAds()
    // eslint-disable-next-line
  }, []);

  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`homepage`);

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`;

    let slots = [];

    if (isMobile) {
      // available slot in this component
      slots = [
        {
          slot: "JT_320x100", // adunit path (parent and child)
          id: "adSlotMobTop-homepage", // whatever
          wrapperId: "JT_320x100", // match with existing HTML elmt
          defaultSize: "320x100", // use the default size
        },
        {
          slot: "JT_300x250",
          id: "adSlotMobBelowWatchNow-homepage",
          wrapperId: "JT_300x250_below_watch_now",
          defaultSize: "300x250",
        },
        {
          slot: "JT_300x250",
          id: "adSlotMobBelowEditorsPick-homepage",
          wrapperId: "JT_300x250_below_editors_pick",
          defaultSize: "300x250",
        },
        {
          slot: "JT_320x100",
          id: "adSlotMobSticky-homepage",
          wrapperId: "JT_320x100-sticky-bottom",
          defaultSize: "320x100",
        },
      ];
    } else {
      slots = [
        {
          slot: "JT_970x250/JT_970x250_top", // adunit path (parent and child)
          id: "adSlotDesktop1", // whatever
          wrapperId: "JT_970x250_top", // match with existing HTML elmt
          defaultSize: "970x250", // use the default size
        },
        {
          slot: "JT_300x250/JT_300x250_after_popular",
          id: "adSlotDesktop2",
          wrapperId: "JT_300x250_after_popular",
          defaultSize: "300x250",
        },
        {
          slot: "JT_970x90/JT_970x90_below_palingdicari",
          id: "adSlotDesktop3",
          wrapperId: "JT_970x90_below_palingdicari",
          defaultSize: "970x90",
        },
        {
          slot: "JT_300x250/JT_300x250_after_trending",
          id: "adSlotDesktop4",
          wrapperId: "JT_300x250_after_trending",
          defaultSize: "300x250",
        },
        {
          slot: "JT_970x90/JT_970x90_below_pilihaneditor",
          id: "adSlotDesktop5",
          wrapperId: "JT_970x90_below_pilihaneditor",
          defaultSize: "970x90",
        },
        {
          slot: "JT_300x600/JT_300x600_right_artikelterbaru",
          id: "adSlotDesktop6",
          wrapperId: "JT_300x600_right_artikelterbaru",
          defaultSize: "300x600",
        },
        {
          slot: "JT_300x250/JT_300x250_right_artikelterbaru",
          id: "adSlotDesktop7",
          wrapperId: "JT_300x250_right_artikelterbaru",
          defaultSize: "300x250",
        },
        {
          slot: "JT_970x90/JT_970x90_scroll1_artikelterbaru",
          id: "adSlotDesktop8",
          wrapperId: "JT_970x90_scroll1_artikelterbaru",
          defaultSize: "970x90",
        },
        {
          slot: "JT_300x600/JT_300x600_scroll1_artikelterbaru",
          id: "adSlotDesktop9",
          wrapperId: "JT_300x600_scroll1_artikelterbaru",
          defaultSize: "300x600",
        },
        {
          slot: "JT_300x250/JT_300x250_scroll1_artikelterbaru",
          id: "adSlotDesktop10",
          wrapperId: "JT_300x250_scroll1_artikelterbaru",
          defaultSize: "300x250",
        },
      ];
    }

    // create ad unit tag head
    for (let i = 0; i < slots.length; i++) {
      _scriptTagHead.text += generateAdUnitTagHead(slots[i]);
    }
    $currpage && $currpage.appendChild(_scriptTagHead);

    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
      generateAdUnitTagBody(slots[i]);
    }
  };

  const nftArticles = useSelector((state) => state.nftPageReducer?.nftCategoryData);
  const sliderArticles =
  nftArticles &&
  nftArticles.code === 200 &&
  nftArticles.response.artikelTerbaru &&
  nftArticles.response.artikelTerbaru.hits.filter(item => {
    return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
  }).slice(0, 5);
 
  const nftGameApps = useSelector((state) => state.nftPageReducer.nftGameApps);
  const nftDAppApps = useSelector((state) => state.nftPageReducer.nftDAppApps);
  const appsForNFTGamesTab =
    nftGameApps &&
    nftGameApps.code === 200 &&
    nftGameApps.response.appList.hits.filter(item => {
      return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
    }).slice(0, 6);
  const appsForDAppTab =
    nftDAppApps &&
    nftDAppApps.code === 200 &&
    nftDAppApps.response.appList.hits.filter(item => {
      return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
    }).slice(0, 6);
  const defaultNftMeta = {
      'title': 'Daftar NFT games, Art, Project, dan Metaverse Terbaik Paling Baru',
      'ogTitle': 'Daftar NFT games, Art, Project, dan Metaverse Terbaik Paling Baru',
      'description': 'Kumpulan ulasan NFT games, NFT art, Project, dan metaverse terakurat serta berita kripto paling baru dari JalanTikus',
      'url': 'nft/'
    }

  return (
    <main className="nft-container">
      <MetaTag {...defaultNftMeta} />
      {nftArticles && nftArticles.code !== 200 ? (
        // redirect page
        nftArticles.code === 301 ? (
          <Redirect to={`/${nftArticles.response.redirect}`} />
        ) : (
          // not found / server error
          <div className="container-common">
            <Error code={nftArticles.code ? nftArticles.code : "503"} />
          </div>
        )
      ) : (
        <div className="nft-container-common">
          <div className="container-full-width-gadget">
            <AdWrapper
              id={`JT_970x250_top`}
              cls={`homepage desktop`}
              customStyle={{ minHeight: "100px" }}
            />
            <AdWrapper
              id={`JT_320x100`}
              cls={`homepage mobile`}
              customStyle={{ minHeight: "100px" }}
            />
          </div>
          <div className="category-page__breadcrumbs">
            <Breadcrumbs
              categName="NFT"
              categSlug="/nft"
              className="article-top-info__breadcrumbs"
            />
          </div>
          <DAppsHeadlineSlider articles={sliderArticles} />
          {/* <AppsRanking
              title="NFT Game Ranking"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dolor est, vehicula ac pulvinar id, lacinia non purus. Maecenas at aliquet quam, at fringilla nisi"
              data={rankingDatas}
            ></AppsRanking> */}

          {/* <AppsRanking
            title="Koleksi NFT Game Terpopuler dan Termahal Hari Ini"
            description="Tracking user statistics of top blockchain game build on the diffirent blockchains including Ethereum, Binance Smart Chain, Tron, EOS, etc. View app all top blockchain games in the list form and sort by different methods including social signal, user amount, transaction amount, volumn."
          ></AppsRanking> */}

          {/* <div
            style={{
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #212936",
            }}
          > */}
          {/* NFT board */}
            {nftArticles && nftArticles.code !== 200 ? (
              nftArticles.code === 301 ? (
                <Redirect to={`/${nftArticles.response.redirect}`} />
              ) : (
                <div className="container-common">
                  <Error code={nftArticles.code} />
                </div>
              )
            ) : (
              <AppsSection
                tab
                innerHtmlDesc
                windowApps={appsForNFTGamesTab}
                androidApps={appsForDAppTab}
                title="Best NFT & DApps"
                tabList={["NFT Game", "DApps"]}
                infiniteScroll={false}
              />
            )}
            {/* <NFTBoard></NFTBoard> */}
          {/* </div> */}
          {/* <div className="nft-container-common">
            <AppsSection
              title="NFT dan DApps Terpopuler Sepekan Ini"
              description={`Tracking user statistics of top blockchain game build on the diffirent blockchains including Ethereum, 
              Binance Smart Chain, Tron, EOS, etc. View app all top blockchain games in the list form and sort`}
            ></AppsSection>
          </div> */}
          {/* <div style={{ background: "#212936", margin: "0 auto" }}>
            <div className="nft-container-common">
              {nftArticles &&
                nftArticles.response.populerMingguIni.length > 0 && (
                  <DAppsArticle
                    page="categoryPage"
                    title="NFT dan DApps Artikel Terkait Axie Infinity"
                    arrow={false}
                    slideNumber={5}
                    articles={nftArticles.response.populerMingguIni.slice(0, 5)}
                  />
                )}
            </div>
          </div> */}
          {nftArticles && (
            <div className="related-acticle-divided">
              <RelatedArticle
                title="Related Article"
                articles={nftArticles.response.pilihanEditor.slice(0, 6)}
              ></RelatedArticle>
            </div>
          )}
          {nftArticles && (
            <div className="recent-acticle-divided">
              <RecentPosts
                hasBreadcrumbs
                containerData={nftArticles.response.artikelTerbaru}
                wordsToRemove={wordsToRemove}
                title="artikel terbaru"
              />
            </div>
          )}
          <div className="container-divided">
            <section className="container-divided_section">
              <BottomPostsBlock category_name="nfts" wordsToRemove={wordsToRemove}/>
            </section>
            {/* <aside className="container-divided_aside">
                <SidebarBanner />
              </aside> */}
          </div>
          <div className="container-full-width-gadget">
            <AdWrapper
              id={`JT_970x250_top`}
              cls={`homepage desktop`}
              customStyle={{ minHeight: "100px" }}
            />
            <AdWrapper
              id={`JT_320x100`}
              cls={`homepage mobile`}
              customStyle={{ minHeight: "100px" }}
            />
          </div>
        </div>
      )}
    </main>
  );
};

export default NFTPage;