import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation, Redirect } from 'react-router-dom';
import { googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody } from "../helpers";
import useSearch from "../hooks/useSearch";
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
import AdWrapper from "../components/Banners/AdWrapper/AdWrapper";
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import EVPagination from "../components/EVPagination/EVPagination";
import { getVehicleSearchDataThunk, getVehicleSuggestionDataThunk } from "../store/ducks/electricVehiclePageReducer";
import Filter from "../components/common/Filter/Filter";
import Error from  '../components/Error/Error';
import { breadcrumbDropdownMenuVehicles } from "../constants";

function EVCategoryPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const searchParams = new URLSearchParams(useLocation().search);
  const query = searchParams.get("q");
  const vehicleType = params.vehicle_type;
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [fetching, setFetching] = useState(false);

  const vehicleSearchData = useSelector(
    (state) => state.eletricVehiclePageReducer.searchVehicleData
  );

  const suggestionData = useSelector(
    (state) => state.eletricVehiclePageReducer.vehicleSuggestionData
  );

  useEffect(() => {
    const parameters = { type: params.vehicle_type, merk: params.brand, query }
    getData(parameters)
    // dispatch(getVehicleSearchDataThunk(parameters));
    loadAds()
    googleAnalytics()
    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    let brands = (vehicleSearchData?.response?.vehicleList?.merek ?? []).map(item => ({
      linkName: item.title,
      alias: item.title.toLowerCase(),
       // eslint-disable-next-line
      url: params.brand === item.title.toLowerCase() ? 'javascript:void(0)' :`/kendaraan-listrik/${vehicleType}/${item.title.toLowerCase()}/`
    }));
    brands = [
      {
        linkName: "All",
        alias: "all",
         // eslint-disable-next-line
        url: !params.brand ? 'javascript:void(0)' : `/kendaraan-listrik/${vehicleType}/`
      }, ...brands
    ];
    setVehicleBrands(brands);
     // eslint-disable-next-line
  }, [vehicleSearchData])

  const getData = async (parameters) => {
    setFetching(true);
    try {
      await dispatch(getVehicleSearchDataThunk(parameters));
    } catch (e) {
    } finally {
      setFetching(false);
    }
  }

  const { onClickSearchIcon, onKeyDownCallback } = useSearch({
    category: vehicleType,
    suggestionDataThunk: getVehicleSuggestionDataThunk,
  });

  const onClickSuggestion = (value, url) => {
    let escapedValue = value.replace(/\+/g, "%20")
    history.push(`/search/${vehicleType}/${escapedValue}`);
  };

  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`evCategoryPage`);

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

    let slots = []
    if (isMobile) {
      // available slot in this page
      slots = [{
        slot: "JT_320x100", // define slot
        sizes: "[[300, 100], [320, 100], [300, 75], [216, 54], [234, 60], [168, 28], 'fluid', [468, 60], [300, 50], [168, 42], [320, 50], [216, 36], [220, 90]]",
        id: "adSlotMobTop-category",
        wrapperId: "JT_320x100"
      }, {
        slot: "JT_320x100", // define slot
        sizes: "[[300, 100], [320, 100], [300, 75], [216, 54], [234, 60], [168, 28], 'fluid', [468, 60], [300, 50], [168, 42], [320, 50], [216, 36], [220, 90]]",
        id: "adSlotMobSticky-category",
        wrapperId: "JT_320x100-sticky-bottom"
      }]
    } else {
      slots = [{
        slot: "JT_970x90/JT_970x90_top", // define slot
        id: "adSlotDesktop0", // whatever id
        wrapperId: "JT_970x90_top", // must match with id in html
        defaultSize: "970x90"
      }]
    }
    // create ad unit tag head
    for (let i = 0; i < slots.length; i++) {
      _scriptTagHead.text += generateAdUnitTagHead(slots[i])
    }
    $currpage && $currpage.appendChild(_scriptTagHead)

    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
      generateAdUnitTagBody(slots[i])
    }
  }

  return (<>
    <main className="container-common__wrapper" id="evCategoryPage">
      {/* Left vertical banner */}
      <div className="container-common__vertical-banner">
        <VerticalBanner />
      </div>

      <div className="container-common">
        <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{ minHeight: '100px' }} />
        <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{ minHeight: '100px' }} />
        <div className="video-category__breadcrumbs">
          <Breadcrumbs
            categName="Kendaraan Listrik"
            categSlug='kendaraan-listrik'
            className="article-top-info__breadcrumbs"
            breadcrumbDropdownInfo={breadcrumbDropdownMenuVehicles}
            hasDropdown
          />
        </div>
        <h1 className="page-title">
          Jalantikus Kendaraan Listrik
          <div className="page-subtitle">
            Kumpulan Artikel & Berita Kendaraan Listrik Terakurat Hari Ini
          </div>
        </h1>

        {
          !fetching && 
          <Filter
            page="vehicle"
            type={vehicleType}
            noSelect
            inputPlaceholder="Search Vehicle.."
            selected={params.brand ? params.brand : "all"}
            suggestionList={suggestionData}
            onKeyDownCallback={onKeyDownCallback}
            onClickSuggestion={onClickSuggestion}
            onClickSearchIcon={onClickSearchIcon}
            categoriesData={vehicleBrands}
          />
        }

        {vehicleSearchData && (
          vehicleSearchData.code !== 200 ? (
            vehicleSearchData.code === 301 ? <Redirect to={`/${vehicleSearchData.response.redirect}`} /> :
              <div className="container-common">
                <Error code={vehicleSearchData.code ? vehicleSearchData.code : '503'} />
              </div>
          ) :
            (
              <EVPagination
                data={vehicleSearchData.response.vehicleList.vehicleList.hits}
              />
            )
        )}

      </div>

      {/* Right vertical banner */}
      <div className="container-common__vertical-banner">
        <VerticalBanner />
      </div>
      <MobileStickyBottom />
    </main>
  </>)
}

export default EVCategoryPage;