import React from "react";
import "./EULA.css";
import StaticSidebar from "@components/Static/Sidebar/StaticSidebar";

function EULA() {
   return (
      <>
         <div className="eula-section__container">
            <div className="static-sidebar">
               <StaticSidebar cls="show-desktop has-title" id="" active="eula" />
            </div>
            <div className="static-content">
               <div className="title">
                  <h1>End-User License Agreement (EULA)</h1>
                  <span>We Value Our Readers Privacy</span>
               </div>
               <div className="content">
                  <h3>Tinjauan</h3>
                  <p>
                     This End-User License Agreement (EULA) is a legal agreement between you (either an individual or a single entity) and the mentioned author (JalanTikus.com) of this Software for the software product identified above, which includes computer software and may include associated media, printed materials, and "online" or electronic documentation ("SOFTWARE PRODUCT").
                  </p>
                  <p>
                     By installing, copying, or otherwise using the SOFTWARE
                     PRODUCT, you agree to be bounded by the terms of this
                     EULA.
                  </p>
                  <p>
                     If you do not agree to the terms of this EULA, do not
                     install or use the SOFTWARE PRODUCT.
                  </p>
                  <h3 className="color black">
                     <strong>SOFTWARE PRODUCT LICENSE</strong>
                  </h3>
                  <p>
                     SOFTWARE is being distributed as Freeware for personal,
                     commercial use, non-profit organization, educational
                     purpose. It may be included with CD-ROM/DVD-ROM
                     distributions. You are NOT allowed to make a charge for
                     distributing this Software (either for profit or merely
                     to recover your media and distribution costs) whether as
                     a stand-alone product, or as part of a compilation or
                     anthology, nor to use it for supporting your business or
                     customers. It may be distributed freely on any website
                     or through any other distribution mechanism, as long as
                     no part of it is changed in any way.
                  </p>
                  <ol>
                     <li>
                        <h3 className="color black">
                           <strong>GRANT OF LICENSE</strong>
                        </h3>
                        <p>
                           This EULA grants you the following rights:
                           Installation and Use. You may install and use an
                           unlimited number of copies of the SOFTWARE PRODUCT.{" "}
                        </p>
                        <p>
                           Reproduction and Distribution. You may reproduce and
                           distribute an unlimited number of copies of the
                           SOFTWARE PRODUCT; provided that each copy shall be a
                           true and complete copy, including all copyright and
                           trademark notices, and shall be accompanied by a
                           copy of this EULA.{" "}
                        </p>
                        <p>
                           Copies of the SOFTWARE PRODUCT may be distributed as
                           a standalone product or included with your own
                           product as long as The SOFTWARE PRODUCT is not sold
                           or included in a product or package that intends to
                           receive benefits through the inclusion of the
                           SOFTWARE PRODUCT.{" "}
                        </p>
                        <p>
                           The SOFTWARE PRODUCT may be included in any free or
                           non-profit packages or products.{" "}
                        </p>
                     </li>
                     <li>
                        <h3 className="color black">
                           <strong>
                              DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS
                           </strong>
                        </h3>
                        <p>
                           Limitations on Reverse Engineering, Decompilation,
                           Disassembly and change (add,delete or modify) the
                           resources in the compiled the assembly. You may not
                           reverse engineer, decompile, or disassemble the
                           SOFTWARE PRODUCT, except and only to the extent that
                           such activity is expressly permitted by applicable
                           law notwithstanding this limitation.
                        </p>
                        <p>Software Transfer </p>
                        <p>
                           You may permanently transfer all of your rights
                           under this EULA, provided the recipient agrees to
                           the terms of this EULA.{" "}
                        </p>
                        <p>Termination</p>
                        <p>
                           Without prejudice to any other rights, the Author of
                           this Software may terminate this EULA if you fail to
                           comply with the terms and conditions of this EULA.
                           In such event, you must destroy all copies of the
                           SOFTWARE PRODUCT and all of its component parts.{" "}
                        </p>
                     </li>
                     <li>
                        <h3 className="color black">
                           <strong>COPYRIGHT</strong>
                        </h3>
                        <p>
                           All title and copyrights in and to the SOFTWARE
                           PRODUCT (including but not limited to any images,
                           photographs, clipart, libraries, and examples
                           incorporated into the SOFTWARE PRODUCT), the
                           accompanying printed materials, and any copies of
                           the SOFTWARE PRODUCT are owned by the Author of this
                           Software. The SOFTWARE PRODUCT is protected by
                           copyright laws and international treaty provisions.
                           Therefore, you must treat the SOFTWARE PRODUCT like
                           any other copyrighted material. The licensed users
                           or licensed company can use all functions, example,
                           templates, clipart, libraries and symbols in the
                           SOFTWARE PRODUCT to create new diagrams and
                           distribute the diagrams.
                        </p>
                     </li>
                  </ol>
                  <h3 className="color black">
                     <strong>LIMITED WARRANTY</strong>
                  </h3>
                  <h3 className="color black">
                     <strong>NO WARRANTY</strong>
                  </h3>
                  <p>
                     SOFTWARE is being distributed as Freeware for personal,
                     commercial use, non-profit organization, educational
                     purpose. It may be included with CD-ROM/DVD-ROM
                     distributions. You are NOT allowed to make a charge for
                     distributing this Software (either for profit or merely
                     to recover your media and distribution costs) whether as
                     a stand-alone product, or as part of a compilation or
                     anthology, nor to use it for supporting your business or
                     customers. It may be distributed freely on any website
                     or through any other distribution mechanism, as long as
                     no part of it is changed in any way.
                  </p>
                  <h3 className="color black">
                     <strong>NO LIABILITY FOR DAMAGES</strong>
                  </h3>
                  <p>
                     In no event shall the author of this Software be liable
                     for any special, consequential, incidental or indirect
                     damages whatsoever (including, without limitation,
                     damages for loss of business profits, business
                     interruption, loss of business information, or any other
                     pecuniary loss) arising out of the use of or inability
                     to use this product, even if the Author of this Software
                     is aware of the possibility of such damages and known
                     defects.
                  </p>
                  <h3>ADVERTISING</h3>
                  <p>
                     We may use{" "}
                     <a
                     href="http://d2yfll6jgszl1w.cloudfront.net/pp.html"
                     rel="noreferrer"
                     target="_blank"
                     >
                        third-party advertising companies
                     </a>{" "}
                     to serve ads when you visit our website
                  </p>
               </div>
            </div>
         </div>
      </>
   );
}

export default EULA;
