import React, { useEffect, useState } from "react";
import "./Breadcrumbs.css";
import LogoBlackSmall from "@components/common/Logo/LogoBlackSmall";
import BreadcrumbDropdown from "@components/common/BreadcrumbDropdown/BreadcrumbDropdown";
import { categories } from "../../../constants";
import { useParams } from "react-router-dom";

function Breadcrumbs({
  categName,
  categSlug,
  articleSlug,
  hasDropdown,
  breadcrumbDropdownInfo,
  hasSecondCategory,
  secondCategoryName,
  secondCategoryLink,
  primaryTag,
  primaryTagLink
}) {
  const history = useParams();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const breadcrumbArrowHandler = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setShowDropdown(true);
  };
  const [showDropdown, setShowDropdown] = useState(true);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      // if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    setIsDropdownOpen(false);
    setShowDropdown(true);
  }, [history])

  return (
    <>
      <div className="breadcrumbs-wrapper" style={{ fontFamily: 'Helvetica' }}>
        <div className="breadcrumbs-logo_wrapper">
          <LogoBlackSmall />
        </div>
        <ul className="breadcrumbs category-small" itemScope itemType="http://schema.org/BreadcrumbList">
          {hasDropdown ? (
            <div>
              <li className="breadcrumb-with-dropdown" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                <a itemProp="item" href={`/${categSlug}/`}><span itemProp="name">{categName.length >= 50 ? categName.slice(0,50) + '...' : categName}</span></a>
                <span
                  onClick={breadcrumbArrowHandler}
                  className={`breadcrumb-with-dropdown_arrow ${
                    isDropdownOpen ? "active" : ""
                  }`} 
                  itemProp="name"
                ></span>
                <BreadcrumbDropdown
                  showDropdown={showDropdown}
                  useOutsideAlerter={useOutsideAlerter}
                  isDropdownOpen={isDropdownOpen}
                  breadcrumbDropdownInfo={breadcrumbDropdownInfo}
                />
                <meta itemProp="position" content="1" />
              </li>
            </div>
          ) : (
            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
              <a itemProp="item" href={categSlug}><span itemProp="name">{categName}</span></a>
              <meta itemProp="position" content="1" />
            </li>
          )}
          {hasSecondCategory ? (
            <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
              <a itemProp="item" href={categories[secondCategoryName]
                  ? `/category/${secondCategoryLink}/`
                  : `${secondCategoryLink}`} >
                {categories[secondCategoryName]
                  ? <span itemProp="name">{categories[secondCategoryName]}</span>
                  : <span itemProp="name">{secondCategoryName}</span>
                }
              </a>
              <meta itemProp="position" content="2" />
            </li>
          ) : null}
          {
            primaryTag && 
            <li className="breadcrumb-current" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
              <a href={`${primaryTagLink}${primaryTag.slug}/`} style={{textTransform:'capitalize'}} itemProp="item">
                <span itemProp="name">{primaryTag.title?.length >= 50 ? primaryTag.title?.length.slice(0,50) + '...' : primaryTag.title}</span>
              </a>
              {
                hasSecondCategory ? (
                  <meta itemProp="position" content="3" />
                ) : (
                  <meta itemProp="position" content="2" />
                )
              }
              
            </li>
          }
          <li className="breadcrumb-current" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            <span itemProp="name">{articleSlug}</span>
            {
              hasSecondCategory ? (
                <meta itemProp="position" content="4" />
              ) : (
                <meta itemProp="position" content="3" />
              )
            }
            
          </li>
        </ul>
      </div>
    </>
  );
}

export default Breadcrumbs;
