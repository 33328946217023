import React from "react";
import Slider from "react-slick";
import "./ButtonSlider.css";

function ButtonSlider({ items }){
    const settings = {
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 315,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 475,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                },
            },
        ],  
    };

    return (
        <div className="button-slider__wrapper">
            <Slider {...settings}>
                {items.map((item,index) => {
                    return(
                        <a href={item.url} key={`btn-red-${index}`}>
                            <div className="btn-red">
                            {item.linkName}
                            </div>
                        </a>
                    )
                })}
            </Slider>
        </div>
    )
}

export default ButtonSlider;