import React from "react"
import { useEffect } from "react"
import AdWrapper from '../AdWrapper/AdWrapper'

function DeskstopBetweenPosts({idName, parentId, currentAdIndex}){
    const uniqueId = 'adSlotscroll'+currentAdIndex
    
    useEffect(() => {
        loadAds()
        // eslint-disable-next-line
    },[])

    const loadAds = () => {
        const isMobile = window.innerWidth <= 768 ? true : false;
        const $parent = document.getElementById(parentId)

        if(!isMobile){
            // create ad unit tag head
            var _scriptTagHead = document.createElement("script");
            _scriptTagHead.async = true;
            _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};
            googletag.cmd.push(function() {
            googletag.defineSlot(
                '/JT_728x90/JT_728x90_scroll${currentAdIndex}_artikelterbaru', 
                ['fluid', [728, 90]], 
                '${uniqueId}'
                ).addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
            });`
            $parent && $parent.appendChild(_scriptTagHead)

            // create ad unit tag body
            var $adSlotElmt = document.getElementById(`JT_728x90_scroll${currentAdIndex}_artikelterbaru`)
            var _string = `<div id='${uniqueId}'></div>`;
            var _script = document.createElement("script");
            _script.type = "text/javascript";
            _script.text = `googletag.cmd.push(function() { googletag.display('${uniqueId}'); });`;
            $adSlotElmt && $adSlotElmt.insertAdjacentHTML( 'afterbegin', _string );
            $adSlotElmt && $adSlotElmt.appendChild(_script );
        }
    }

    return(
        <AdWrapper id={`JT_728x90_scroll${currentAdIndex}_artikelterbaru`} cls={`desktop`}/>
    )
}

export default DeskstopBetweenPosts