import { assetsUrl } from "../constants";
import ReactGA from 'react-ga';
import {analytics} from '../constants';
import {defaultMeta} from '../constants'
export function imgSrc(url, width = "0", height = "0") {
    const placeholder =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABWQAAAJYAQMAAADfXH2qAAAABlBMVEXu7u7Nzc0qm56VAAAE5klEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABm54xtJgehIAxyQEgJlOJWrhPo4Er4W3Ep7uAcbrDynG0BBtK7YEbiC1Ybjt7OY7yGx2QymUwmk8lkMplMJpPJZPJv/DJC2I8RYvkaITyMECs2owNwGBksINRmDjiNDAFAMipEALtRARBqswUXMvngcSGTDysuZPIBEDKuxYVMPjhcyORDwIVMPkRcyOQDINRmCy5k8sHjQiYfVlzI5EMEdNrM4kImHxxufkTyITw6nUg+rI8HrEib5f6KEvmwZAsEiXzwub2cRD6EvHRZiTaLJRaiQD68JQ0C+eBqKjiBfPC1olagzdbXrZE/H5qVINDnw9KY1dHng2sWAkvfZqFdZCN7PnQCA3k+9D++I8+HpdNnydvM97995M6Hte+rwJ0Pw5rlqPPBjm1F3WZuXLIicz74MQ48c5v5sZSOXW1vU3a1fdKu5GpNR+BWew5RzK12yALLrXYfs5harRnwzGq/ZmBhVnuYAcusdjcjK7HaZEYCsdrdjERitR8p355mwDGrRRptS612NG6kVvuRek4YjOu41SIpPd8Oxo3kajvjWvb/ZVr/eTvjBnq1uzEaLxk9XuPyb5l5tMZl30f36I0bBNS+xo0CaqtxLfeJO49+T0dBbTVukFBb9yIl1JZ9XvKToh6tcR292nU8n4A/xGp/Yzj7gd/Eah2GczUn74Skh32Nm4Uzq02xPw+GnVlt0Vhtm5jf2qVqXIsH4slej9JbxbZfbrUG7flbHNRqi8o923ajfttcn3GzbZkn0j3KSewz25ZbbSrG/cHNwa02b5sXdu5dkufjJXHXNt3GrZzU9z54DFNF/GrbiS3y3b306Cskgdq6alvu+0o8unG4D7va1Br3YN+V7malN/badiOy5PfsZCfkfPjSn0/o7k+gr213NwW92tQYl/+kSnunCvttVh7tCWx+tam5C0jFCY9xE39tmzus6G9h80hV7Vfg9Fr+smjU1ry1FVCbcm2lnCDSZcW3Uk6Ya8J/xyNJrQlNbQWcoFpbBbWlthpOUK2tglqtNWGTeqrZXyfoTGfctdWZzrh9KzCxhdcJAtNw2KoTFCYNj1pbnVmS27c6syS3E3RmSaoTPLnardRWYg79KL7VmXy5nUA/+fK3vTu4iRgGogCaVQ45poSUklboZCPRGL3QAAWgDXFiWzlQwIz2PQnOlpnRB7HyLzv1uk/CFPkljXE/bLe7nUM/r7Mfvm5zu0Z+Aaadrk9C7Nd1zp98nYToS1a3qt5t9CWrl7n10y6RnwJq/3rqk7DGfs3svM2ffrfRX4qrazZe32Mv2XXAV5uEKXQ2FPthq3e7hM6GoS1WPe0aOhv6mp2TEH/J6qxevyeMsbOh58N1t1PwbGj5sJ1zuwTPhr5m52mf4Zes/m1WJiH0u2v3fPgdy1f4bGj5UE47h8+Gtmafx2kzLNmVD9/HaZ/hs+HeVBQ/G/I1fQx7phaVZA01udp/cjUrPTItWbZGsFxta7ma7MZMY5usgTFZu2Wu5tD4rRl3j0xLlq1NOFdT85wmG4ox0ZJla29P1owf99OL/5kSLdmRD3myofgYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgLfwB371bVaqc5ojAAAAAElFTkSuQmCC";

    if (!url) return placeholder;
    const base_url = url.base_url + width + "/" + height + "/";
    const file = url.file;
    const imgSrc = assetsUrl + base_url + file;

    return imgSrc;
}

const defaultFallbackDim = [
    '184/94',
    '0/200',
    '0/400',
    '0/0',
    '195/195'
]

/**
 * If the image fails to load, it will try to load the next image in the srcset.
 * @param e - the event object
 * @returns the value of the last expression evaluated.
 */
export function onErrorSrc(e, fallback) {
    const fallbackDim = fallback ?? defaultFallbackDim
    let target = e.target.parentNode.children[0]
    if (target.srcset.endsWith('.webp')) {
        target.srcset = e.target.src
        return
    }
    let reg = /assets\/cache\/(\d+\/\d+).+/
    let curDim = target.srcset.match(reg)
    if (!curDim) return
    curDim = curDim[1]
    let indexDim = fallbackDim.indexOf(curDim)
    let setDimIndex = indexDim === -1 ? 0 : indexDim + 1
    if (setDimIndex === fallbackDim.length) return
    target.srcset = target.srcset.replace(curDim, fallbackDim[setDimIndex])
}

// use for img tag
export function onErrorSrcImg(e, fallback) {
    const fallbackDim = fallback ?? defaultFallbackDim

    let target = e.target
    let reg = /assets\/cache\/(\d+\/\d+).+/
    let curDim = target.src.match(reg)
    if (!curDim) {
        target.src = target.src.replace('/assets/cache', '/assets/cache/184/94')
        return
    }
    curDim = curDim[1]
    let indexDim = fallbackDim.indexOf(curDim)
    let setDimIndex = indexDim === -1 ? 0 : indexDim + 1
    if (setDimIndex === fallbackDim.length) return
    target.src = target.src.replace(curDim, fallbackDim[setDimIndex])
}

export function writePrice(price){
    let result = price.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0
    });
    return result;
}

export function shareSocial({platform, link, title}){
    const currentUrl = `${process.env.REACT_APP_WEB_URL}`
    switch(platform){
        case "facebook":
            return `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}${link}/`;
        case "twitter":
            return `https://twitter.com/intent/tweet?url=${currentUrl}${link}/&text=${encodeURI(title)}`;
        case "whatsapp":
            return `https://api.whatsapp.com/send?text=${encodeURI(title)} ${currentUrl}${link}/`;
        case "line":
            return `line://msg/text/${encodeURI(title)} ${currentUrl}${link}/`;
        default:
            return false;
    }
}

export function insertParam(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split('&');
    let i=0;

    for(; i<kvp.length; i++){
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }

    if(i >= kvp.length){
        kvp[kvp.length] = [key,value].join('=');
    }

    // can return this or...
    let params = kvp.join('&');

    // reload page with new params
    // document.location.search = params;
    // history.push({ search: params});
    return params
}

export function googleAnalytics(title = defaultMeta.title){
    // send to google analytics
    const _hostname = window.location.hostname;
    const _pathname = window.location.pathname;
    const _location = _hostname + _pathname.slice(1)
    ReactGA.initialize(analytics.googleTrackingId);
    var ga = ReactGA.ga();
    ga('set', 'hostname', _hostname);
    ga('set', 'page', _pathname);
    ga('set', 'title', title);
    ga('set', 'location', _location);
    ga('send', { hitType: 'pageview', page:_pathname});
}

export function fetchTimeout(url, options = {}, s = 20) {
    // start fetch
    const fetch = require("node-fetch");
    return fetch(url, options)
        .then(function (res) {
            return !res ? res : res.json();
        })
        .catch(function (err) {
            return false;
        });
}

export function generateCamelCase(string) {
    return string.replace(/['"]+/g, "").trim().toLowerCase();
}

export function generateURL(url, base_url="") {
    var _baseUrl = 'https://jalantikus.com/'
    return _baseUrl + url;
}

export function imgSrcOld(url, width = "0", height = "0") {
    var assetsUrl = 'https://assets.jalantikus.com/'

    var baseAssetsUrl = assetsUrl;
    var base_url = url.base_url + width + "/" + height + "/";
    var file = url.file ? url.file : "placeholder.png";
    if (!file.match(/.(jpg|jpeg|png|gif)$/i)) {
        file = "placeholder.png";
    }
    assetsUrl = baseAssetsUrl + base_url + file;

    return assetsUrl;
}

export function formatRupiah(angka, prefix) {
    var number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
}

export const EVInfoService = {
    formatFn: {
        mobilPower: (power, driveSystem) => {
            if (!power) {
                return '-';
            }

            let res = driveSystem ? `${driveSystem.toUpperCase()} ` : '';
            res = `${res}${power} kW`;
            return res;
        },
        mobilTorque: (torque) => {
            if (!torque) return '-';
            return `${torque} Nm`;
        },
        mobilAcceleration: (acc) => {
            if (!acc) return '-';
            return `${acc} sec 0-100 km/h`
        },
        mobilMaxSpeed: (speed) => {
            if (!speed) return '-';
            return `${speed} km/h` || '-';
        },
        mobilCapacity: (capacity) => {
            if (!capacity) return '-';
            return `${capacity} kWh`
        },
        mobilRange: (range) => {
            if (!range) return '-';
            return `${range} km WLTP`;
        },
        mobilDimensions: (l, w, h) => {
            if (!l || !w || !h) return '-';
            return `${l} x ${w} x ${h} mm (l x w x h)`
        },
        mobilWheelbase: (wheelbase) => {
            if (!wheelbase) return '-';
            return `${wheelbase} mm`;
        },
        motorPower: (power) => {
            if (!power) return '-';
            return `${power} kW`;
        },
        motorMaxSpeed: (speed) => {
            if (!speed) return '-';
            return `${speed} km/h` || '-';
        },
        motorBattery: (capacity) => {
            if (!capacity) return '-';
            return `${capacity} kWh`;
        },
        motorChargingTime: (time) => {
            if (!time) return '-';
            return `${time} hour(s)`
        },
        motorCapacity: (capacity) => {
            if (!capacity) return '-';
            return `${capacity} passenger(s)`;
        },
        motorDimension: (l, w, h) => {
            if (!l || !w || !h) return '-';
            return `${l} x ${w} x ${h} mm (l x w x h)`;
        }
    }
}

export const imgVehicleSrc = (img, width = '0', height = '0') => {
    if (!img.file) return imgSrc();
    return imgSrc(img, width, height) + '.webp';
}
