export const appCategories = [
    {
        linkName: "All",
        alias: "all",
        url: "/apps/category/",
    },
    {
        linkName: "Antivirus",
        alias: "antivirus-security",
        url: "/apps/category/antivirus-security/",
    },
    {
        linkName: "Benchmarking",
        alias: "benchmarking",
        url: "/apps/category/benchmarking/",
    },
    {
        linkName: "Browser",
        alias: "browser",
        url: "/apps/category/browser/",
    },
    {
        linkName: "Cleaning",
        alias: "cleaning-tweaking",
        url: "/apps/category/cleaning-tweaking/",
    },
    {
        linkName: "Compression",
        alias: "compression-backup",
        url: "/apps/category/compression-backup/",
    },
    {
        linkName: "Enhancement",
        alias: 'desktop-enhancement',
        url: "/apps/category/desktop-enhancement/",
    },
    {
        linkName: "Developer",
        alias: 'developer-tools',
        url: "/apps/category/developer-tools/",
    },
    {
        linkName: "Downloader",
        alias: 'downloader-plugin',
        url: "/apps/category/downloader-plugin/",
    },
    {
        linkName: "Drivers",
        alias: 'drivers-smartphone',
        url: "/apps/category/drivers-smartphone/",
    },
    {
        linkName: "File Transfer",
        alias: 'file-transfer',
        url: "/apps/category/file-transfer/",
    },
    {
        linkName: "Networking",
        alias: 'networking',
        url: "/apps/category/networking/",
    },
    {
        linkName: "Office",
        alias: 'office-business-tools',
        url: "/apps/category/office-business-tools/",
    },
    {
        linkName: "Photo Imaging",
        alias: 'photo-imaging',
        url: "/apps/category/photo-imaging/",
    },
    {
        linkName: "Productivity",
        alias: 'productivity',
        url: "/apps/category/productivity/",
    },
    {
        linkName: "Socials",
        alias: 'social-messaging',
        url: "/apps/category/social-messaging/",
    },
    {
        linkName: "System Tuning",
        alias: '',
        url: "/apps/category/system-tuning/",
    },
    {
        linkName: "Utilities",
        alias: 'utilities',
        url: "/apps/category/utilities/",
    },
    {
        linkName: "Video & Audio",
        alias: 'video-audio',
        url: "/apps/category/video-audio/",
    },
];

export const gamesCategories = [
    {
        linkName: "All",
        alias: 'all',
        url: "/games/category/",
    },
    {
        linkName: "NFT Games",
        alias: 'nft-games',
        url: "/games/category/nft-games/",
    },
    {
        linkName: "Arkade",
        alias: 'arcade',
        url: "/games/category/arcade/",
    },
    {
        linkName: "Balapan",
        alias: 'racing',
        url: "/games/category/racing/",
    },
    {
        linkName: "Kartu",
        alias: 'kartu',
        url: "/games/category/kartu/",
    },
    {
        linkName: "Kasino",
        alias: 'kasino',
        url: "/games/category/kasino/",
    },
    {
        linkName: "Kata-kata",
        alias: 'kata-kata',
        url: "/games/category/kata-kata/",
    },
    {
        linkName: "Musik",
        alias: 'music',
        url: "/games/category/music/",
    },
    {
        linkName: "Olahraga",
        alias: 'sports',
        url: "/games/category/sports/",
    },
    {
        linkName: "Pendidikan",
        alias: 'pendidikan',
        url: "/games/category/pendidikan/",
    },
    {
        linkName: "Petualangan",
        alias: 'adventure',
        url: "/games/category/adventure/",
    },
    {
        linkName: "RPG",
        alias: 'rpg',
        url: "/games/category/rpg/",
    },
    {
        linkName: "Shooting",
        alias: 'shooting',
        url: "/games/category/shooting/",
    },
    {
        linkName: "Simulasi",
        alias: 'simulasi',
        url: "/games/category/simulasi/",
    },
    {
        linkName: "Strategie",
        alias: 'strategi',
        url: "/games/category/strategi/",
    },
    {
        linkName: "Teka-teki",
        alias: 'teka-teki',
        url: "/games/category/teka-teki/",
    },
    {
        linkName: "Trivia",
        alias: 'trivia',
        url: "/games/category/trivia/",
    },
];