import React from "react";
import "./Tags.css";
import OneTag from "@components/common/Tags/OneTag";

function Tags({ tags }) {
  // Check if some visible tags present in tags array(item.is_hidden === 0)
  const isVisibleTags = tags.some((item) => item.is_hidden === 0);
  return (
    // Show Tags block if it contains visible tags
    isVisibleTags && (
      <div className="tags-wrapper">
        <span className="tags-wrapper__title">Tags Terkait: </span>
        {tags && tags.length > 0  && tags
          .filter(tag => !tag.is_hidden)
          .map(tag => <OneTag key={tag.id} link="" title={tag.title} />)
        }
      </div>
    )
  );
}

export default Tags;
