import React,{ useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Tags from "@components/common/Tags/Tags";
import "./ArticleMainContent.css";
import ShareSocials from "../../components/ShareSocials/ShareSocials";
import { assetsUrl } from "../../constants/api";
import { shareSocial } from "../../helpers";
import 'lazysizes';
import { getArticleAdsDataThunk } from "../../store/ducks/articlePageReducer";
import Ads from '../Ads/Ads'
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AdWrapper from '@components/Banners/AdWrapper/AdWrapper';
import DefaultImage from "../common/DefaultImage";

function ArticleMainContent({ data }) {
  const history = useParams();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch()
  const articleAdsData = useSelector(
    (state) => state.articlePageReducer.articleAdsData
    );
  const published = data.published;
  const isMobile = width <= 1024 ? true : false
  const adsType = isMobile ? 'mobile' : 'desktop'
  // define categoryID
  const categoryID = data.category.id
    // define tagID
  let tagID = data.tags.primary.id
  // let categoryCurr = data?.tags?.primary?.slug;
  // if (categoryCurr && categoryArr[adsType]["tags"][categoryCurr]) tagID = categoryArr[adsType]["tags"][categoryCurr];
  // define seo_tag
  let seoTag = false;
  if(data.tags.all){
    seoTag = data.tags.all.filter((obj) => { return obj.id === "2707"; }).length > 0;
  }
  if (data.tags.primary && data.tags.primary.id === "2707") seoTag = true
  let mySeo = isMobile ? "0702" : "0701";
  if (seoTag) mySeo = isMobile ? "0602" : "0601";

  let bannerImageSrc = '';
  let bannerImageHeight = 0;
  if (width < 768) {
    bannerImageSrc = `${assetsUrl}${data.banner_image.base_url}0/200/${data.banner_image.file}`;
    bannerImageHeight = 150;
  } else {
    bannerImageSrc = `${assetsUrl}${data.banner_image.base_url}769/330/${data.banner_image.file}`;
    bannerImageHeight = 260;
  }

  useEffect(() => {
    const elements = document.querySelectorAll(".daftar-isi-content .li-wrapper a");
    for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", (e) => {
          e.preventDefault()
          const dataId = e.target.getAttribute("data-id")
          if(dataId){
            const elmnt = document.getElementById(dataId);
            elmnt.scrollIntoView({behavior: 'smooth'});
          }
        });
    }
    let isAdv = /-adv\/?$/.test(window.location.pathname)
      if ( ! isAdv) {
        const externalLinkElements = document.querySelectorAll("a.link-external");
        for (let i = 0; i < externalLinkElements.length; i++) {
        externalLinkElements[i].addEventListener("click", (e) => {
          e.preventDefault()
          document.querySelectorAll(".external-popup-container")[0].style.display = 'block';

          const _href =  externalLinkElements[i].getAttribute("href")
          const _datahref =  externalLinkElements[i].getAttribute("data-href")

          if (_href !== "" && _href !== "#") {
            document.getElementById("external").innerHTML = _href
            document.getElementById("external").setAttribute("data-href", _datahref);
          }
        });
      }
    }
  },[history]);

  useEffect(()=> {
    dispatch(getArticleAdsDataThunk());
    // eslint-disable-next-line
  },[history])

  const getAds = (param) => {
    if(width <= 1024){
      return (param.response.ads_slot.mobile)
    }else{
      return (param.response.ads_slot.desktop)
    }
  }

  // const allPages = data.content_pages.length ? data.content_pages : [data.content]

  const [content, setContent] = useState(data.content);

  useEffect(() => {
    // Parse the article content as an HTML string and insert the ads slot
    const parser = new DOMParser();
    const parsedContent = parser.parseFromString(data.content, 'text/html');
    const paragraphs = parsedContent.querySelectorAll('p');
    const numParagraphs = paragraphs.length;
    const numAdSlots = 3;
    const adIndexStep = Math.floor(numParagraphs / (numAdSlots + 1)); // Calculate the step size for the ad slots
    let adIndex = adIndexStep;
    let adCount = 1;
    const adSlotTemplate = '<div class="ad_wrapper desktop"> <div id="JT_in_article_0{count}"></div></div>';
    for (let i = 0; i < numAdSlots; i++) {
      const adSlot = adSlotTemplate.replace('{count}', adCount);
      paragraphs[adIndex].insertAdjacentHTML('afterend', adSlot);
      adIndex += adIndexStep;
      adCount++;
    }

    // Sanitize the parsed and modified HTML content using DOMPurify
    // const sanitizedContent = DOMPurify.sanitize(parsedContent.documentElement.innerHTML);

    // Set the sanitized content as the state
    setContent(parsedContent.documentElement.innerHTML);
  }, [data.content]);

  return (
    <div className="article-main-content__wrapper article-content" id={data.slug}>
      <div className="article-main-content__padding">
        <ShareSocials
          facebookLink={shareSocial({
            platform: "facebook",
            link: data.links.detail,
          })}
          twitterLink={shareSocial({
            platform: "twitter",
            link: data.links.detail,
            title: data.title,
          })}
          whatsAppLink={shareSocial({
            platform: "whatsapp",
            link: data.links.detail,
            title: data.title,
          })}
          lineLink={shareSocial({
            platform: "line",
            link: data.links.detail,
            title: data.title,
          })}
          isDesktop
        />
        {/* <TableOfContent /> */}
        <div className="article-main-content__banner" style={{ borderRadius: '8px' }}>
          <LazyLoadImage 
            style={{  maxWidth: '100%',
            marginBottom: '32px',
            borderRadius: '8px' }}
            effect="blur"
            alt={data.title}
            placeholder={<DefaultImage/>}
            height={bannerImageHeight}
            src={bannerImageSrc} />
        </div>
        {/* <BannerCustom width={300} id="articlepage-belowbanner" cls={"inlined belowSummary page-1"} parentId={data.slug} target={getTargeting()} show={'desktop'}/> */}
        <div style={{ fontFamily: 'Helvetica' }}
          className="article-main-content__inner"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <Tags tags={data.tags.all} />
        <div className="article-main-content__tags-banner">
          <AdWrapper id={`JT_728x90_below_relatedTags`} cls={`desktop`} />
        </div>
        <AdWrapper id={`JT_under_article`} cls={`desktop homepage`}/>
      </div>

      <div className="article-main-content__soc-and-banner">
        <ShareSocials
          title="Share"
          facebookLink={shareSocial({
            platform: "facebook",
            link: data.links.detail,
          })}
          twitterLink={shareSocial({
            platform: "twitter",
            link: data.links.detail,
            title: data.title,
          })}
          whatsAppLink={shareSocial({
            platform: "whatsapp",
            link: data.links.detail,
            title: data.title,
          })}
          lineLink={shareSocial({
            platform: "line",
            link: data.links.detail,
            title: data.title,
          })}
          isMobile
        />
        
        <AdWrapper id={`JT_320x100_above_artikelterkait`} cls={`mobile`} />
        
        {articleAdsData && articleAdsData.code === 200 && 
          <Ads 
            data={getAds(articleAdsData)}
            writerID={data.user.wid ? data.user.wid + published.split("-")[1] + published.split("-")[0].substring(4, 2) : "undefined"}
            categoryID={categoryID}
            tagID={tagID}
            contentID={data.id}
            seoTag={mySeo}
            size={adsType}
            adsIndex={1}
            adsSelector={"article_content"}
            slug={data.slug}
          />
        }
      </div>
      <div className="article-main-content__relate-topics"></div>
      {/* <CreateAccount /> */}
      {/* <DesktopHorizontalBanner /> */}
    </div>
  );
}
export default ArticleMainContent;
