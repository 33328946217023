export const categoryMenu = [
  {
    category: "Hiburan",
    url: "/hiburan/",
    subCategory: [
      {
        linkName: "Actress & Actors",
        url: "/tags/artis/",
      },
      {
        linkName: "Film & Animations",
        url: "/tags/film/",
      },
      {
        linkName: "Music & Songs",
        url: "/tags/musik/",
      },
      {
        linkName: "K-pop & K-Drama",
        url: "/tags/out-of-tech/",
      },
    ],
  },
  {
    category: "Tech Hack & Lifestyle",
    url: "/tags/tech-hack/",
    subCategory: [
      {
        linkName: "Finance",
        url: "/tags/finansial/",
      },
      {
        linkName: "Tech Hack",
        url: "/tags/tech-hack/",
      },
      {
        linkName: "App Reviews",
        url: "/tags/apps-scl/",
      },
      {
        linkName: "Games Tips & Tricks",
        url: "/tags/games/",
      },
    ],
  },
  {
    category: "Gaming and e-Sports",
    url: "/gaming/",
    subCategory: [
      {
        linkName: "Game Reviews",
        url: "/tags/games/",
      },
      {
        linkName: "Cheats and Gaming Tips",
        url: "/tags/games/",
      },
      {
        linkName: "e-Sports News",
        url: "/tags/esports/",
      },
      {
        linkName: "Tournaments",
        url: "/tags/esports/",
      },
    ],
  },
  {
    category: "Finance",
    url: "/finansial/",
    subCategory: [
      {
        linkName: "Financial Apps",
        url: "/tags/finansial/",
      },
      {
        linkName: "Finance Articles",
        url: "/tags/finansial/",
      },
      {
        linkName: "Investment Articles",
        url: "/tags/investasi/",
      },
      {
        linkName: "Others",
        url: "/tags/finansial/",
      },
    ],
  },
  {
    category: "Ragam",
    url: "/ragam/",
    subCategory: [
      {
        linkName: "Viral",
        url: "/tags/viral/",
      },
      {
        linkName: "Unik",
        url: "/tags/fakta-unik/",
      },
      {
        linkName: "Others",
        url: "/ragam/",
      },
    ],
  },
  {
    category: "Kendaraan Listrik",
    url: "/kendaraan-listrik/",
    subCategory: [
    ],
  },
  {
    category: "Web3",
    url: "/web3/",
    subCategory: [
    ],
  },
  {
    category: "Health Tech",
    url: "/healthtech/",
    subCategory: [
    ],
  },
  {
    category: "Otomotif",
    url: "/otomotif/",
    subCategory: [
    ],
  },
  {
    category: "Others",
    url: "/tags/",
    subCategory: [
      {
        linkName: "Quotes & Captions",
        url: "/tags/quotes",
      },
      {
        linkName: "Song and Chords",
        url: "/tags/lagu/",
      },
    ],
  },
];

export const gadgetMegaMenu = [
  {
    category: "Smartphone",
    url: "/gadgets/smartphone/samsung",
    subCategory: [
      {
        linkName: "Samsung",
        url: "/gadgets/smartphone/samsung/",
      },
      {
        linkName: "Apple",
        url: "/gadgets/smartphone/apple/",
      },
      {
        linkName: "Oppo",
        url: "/gadgets/smartphone/oppo/",
      },
      {
        linkName: "Vivo",
        url: "/gadgets/smartphone/vivo/",
      },
    ],
  },
  {
    category: "Tablet",
    url: "/gadgets/tablet/samsung",
    subCategory: [
      {
        linkName: "Samsung",
        url: "/gadgets/tablet/samsung/",
      },
      {
        linkName: "Apple",
        url: "/gadgets/tablet/apple/",
      },
      {
        linkName: "Oppo",
        url: "/gadgets/tablet/oppo/",
      },
      {
        linkName: "Vivo",
        url: "/gadgets/tablet/vivo/",
      },
    ],
  },
];

export const categoryAndTags = {
  tips: {
    category: "tips",
    url: "/tips/",
    tags: [
      {
        linkName: "Tech Hack",
        url: "/tags/tech-hack/",
      },
      {
        linkName: "Apps",
        url: "/tags/apps-scl/",
      },
      {
        linkName: "Fintech",
        url: "/tags/fintech/",
      },
      {
        linkName: "Gadget",
        url: "/tags/gadgets/",
      },
      {
        linkName: "Games",
        url: "/tags/games/",
      },
      {
        linkName: "Out Of Tech",
        url: "/tips/out-of-tech/",
      },
      {
        linkName: "Telekomunikasi",
        url: "/tags/telekomunikasi/",
      },
      {
        linkName: "Finansial",
        url: "/tags/finansial/",
      },
    ],
  },
  gadgets: {
    category: "gadgets",
    url: "/gadgets/",
    tags: [
      {
        linkName: "Gadgets",
        url: "/tags/gadgets/",
      },
      {
        linkName: "Smartphone",
        url: "/tags/smartphone/",
      },
    ],
  },
  gaming: {
    category: "Gaming",
    url: "/gaming/",
    tags: [
      {
        linkName: "Esports",
        url: "/tags/esports/",
      },
      {
        linkName: "Games",
        url: "/tags/games/",
      },
    ],
  },
  finansial: {
    category: "Finansial",
    url: "/finansial/",
    tags: [
      {
        linkName: "Fintech",
        url: "/tags/fintech/",
      },
      {
        linkName: "Kerja",
        url: "/tags/kerja/",
      },
      {
        linkName: "E-commerce",
        url: "/tags/e-commerce/",
      },
      {
        linkName: "Finansial",
        url: "/tags/finansial/",
      },
      {
        linkName: "Apps",
        url: "/tags/apps-scl/",
      },
      {
        linkName: "Tech Hack",
        url: "/tags/tech-hack/",
      },
      {
        linkName: "Investasi",
        url: "/tags/investasi/",
      },
      {
        linkName: "Cryptocurrency",
        url: "/tags/cryptocurrency/",
      },
    ],
  },
  hiburan: {
    category: "Hiburan",
    url: "/hiburan/",
    tags: [
      {
        linkName: "Film Korea",
        url: "/hiburan/out-of-tech/",
      },
      {
        linkName: "Film Indonesia",
        url: "/tags/film-indonesia/",
      },
      {
        linkName: "Film Hollywood",
        url: "/tags/film/",
      },
      {
        linkName: "Artis Thailand",
        url: "/hiburan/out-of-tech/",
      },
      {
        linkName: "Artis Indonesia",
        url: "/tags/artis/",
      },
      {
        linkName: "Artis Hollywood",
        url: "/hiburan/out-of-tech/",
      },
      {
        linkName: "Artis Bollywood",
        url: "/tags/hiburan/",
      },
      {
        linkName: "Film & Animasi",
        url: "/hiburan/out-of-tech/",
      },
      {
        linkName: "Anime & Manga",
        url: "/hiburan/out-of-tech/",
      },
      {
        linkName: "Hiburan",
        url: "/tags/hiburan/",
      },
      {
        linkName: "Out of Tech",
        url: "/hiburan/out-of-tech/",
      },
    ],
  },
  news: {
    category: "news",
    url: "/news/",
    tags: [
      {
        linkName: "Berita Teknologi",
        url: "/tags/tech-news/",
      },
      {
        linkName: "Berita",
        url: "/tags/berita/",
      },
      {
        linkName: "Berita Terkini",
        url: "/tags/berita-terkini/",
      },
    ],
  },
  iptek: {
    category: "iptek",
    url: "/iptek/",
    tags: [
      {
        linkName: "Teknologi",
        url: "/tags/teknologi/",
      },
      {
        linkName: "Sejarah",
        url: "/tags/sejarah/",
      },
      {
        linkName: "Sains",
        url: "/tags/sains/",
      },
      {
        linkName: "Luar Angkasa",
        url: "/tags/luar-angkasa/",
      },
      {
        linkName: "Fakta Unik",
        url: "/tags/fakta-unik/",
      },
      {
        linkName: "Out Of Tech",
        url: "/iptek/out-of-tech/",
      },
      {
        linkName: "Viral",
        url: "/tags/viral/",
      },
    ],
  },
  telko: {
    category: "telko",
    url: "/telko/",
    tags: [
      {
        linkName: "Telekomunikasi",
        url: "/telko/",
      },
      {
        linkName: "Provider & Internet",
        url: "/tags/tech-hack/",
      },
    ],
  },
  quotes: {
    category: "quotes",
    url: "/quotes/",
    tags: [
      {
        linkName: "Out of Tech",
        url: "/quotes/out-of-tech/",
      },
      {
        linkName: "Quotes",
        url: "/tags/quotes/",
      },
      {
        linkName: "Ramadhan",
        url: "/tags/ramadhan/",
      },
    ],
  },
  lagu: {
    category: "lagu",
    url: "/lagu/",
    tags: [
      {
        linkName: "Out of Tech",
        url: "/lagu/out-of-tech/",
      },
      {
        linkName: "Musik",
        url: "/tags/musik/",
      },
      {
        linkName: "Lagu",
        url: "/tags/lagu/",
      },
    ],
  },
  ragam: {
    category: "ragam",
    url: "/ragam/",
    tags: [
      {
        linkName: "Gambar",
        url: "/tags/gambar/",
      },
      {
        linkName: "Fakta Unik",
        url: "/tags/fakta-unik/",
      },
      {
        linkName: "Viral",
        url: "/tags/viral/",
      },
      {
        linkName: "Ramadhan",
        url: "/tags/ramadhan/",
      },
      {
        linkName: "Sains",
        url: "/tags/sains/",
      },
      {
        linkName: "Out of Tech",
        url: "/ragam/out-of-tech/",
      },
    ],
  },
  sport: {
    category: "sport",
    url: "/sport/",
    tags: [
      {
        linkName: "Sport",
        url: "/tags/sport/",
      },
      {
        linkName: "Olahraga",
        url: "/tags/olahraga/",
      },
      {
        linkName: "Sepakbola",
        url: "/tags/sepakbola/",
      },
      {
        linkName: "NBA",
        url: "/tags/nba/",
      },
      {
        linkName: "Badminton",
        url: "/tags/badminton/",
      },
    ],
  },
  nfts: {
    category: "nfts",
    url: "/nfts/",
    tags: [
      {
        linkName: "NFT",
        url: "/tags/nft/",
      },
    ],
  },
  'kendaraan-listrik': {
    category: "kendaraan listrik",
    url: "/kendaraan-listrik/",
    tags: [
      {
        linkName: "Mobil Listrik",
        url: "/tags/mobil/",
      },
      {
        linkName: "Motor Listrik",
        url: "/tags/motor/",
      },
    ]
  },
  web3: {
    category: "web3",
    url: "/web3/",
    tags: []
  },
  healthtech: {
    category: "healthtech",
    url: "/healthtech/",
    tags: []
  },
  otomotif: {
    category: "otomotif",
    url: "/otomotif/",
    tags: []
  }
};

export const footerLinks = [
  [
    {
      linkName: "Tentang Kami",
      url: "/tentang-kami/",
    },
    {
      linkName: "Redaksi",
      url: "/tentang-kami#redaksi",
    },
    {
      linkName: "Pedoman Media Siber",
      url: "/pedoman/",
    },
    {
      linkName: "Proses Penulisan Artikel",
      url: "/disclaimer#proses-penulisan",
    },
    {
      linkName: "Saran & Kritik",
      url: "/contact/",
    },
  ],

  [
    {
      linkName: "Tech Hack",
      url: "/tags/tech-hack/",
    },
    {
      linkName: "Games",
      url: "/games/",
    },
    {
      linkName: "Apps",
      url: "/apps/",
    },
    {
      linkName: "Hiburan",
      url: "/hiburan/",
    },
    {
      linkName: "Finansial",
      url: "/finansial/",
    },
    {
      linkName: "Penghasil Uang",
      url: "/tags/penghasil-uang/",
    },
    // {
    //   linkName: "Kendaraan Listrik",
    //   url: "/kendaraan-listrik/",
    // },
  ],
];

export const breadcrumbDropdownMenuArticle = [
  { linkName: "Tips", url: "/tips/" },
  { linkName: "Gaming", url: "/gaming/" },
  { linkName: "Gadgets", url: "/gadgets/" },
  { linkName: "Quotes", url: "/quotes/" },
  { linkName: "Lagu", url: "/lagu/" },
  { linkName: "Finansial", url: "/finansial/" },
  { linkName: "Hiburan", url: "/hiburan/" },
  { linkName: "News", url: "/news/" },
  { linkName: "IPTEK", url: "/iptek/" },
  { linkName: "Telko", url: "/telko/" },
  { linkName: "Sport", url: "/sport/" },
  { linkName: "NFTs", url: "/nfts/" },
];

export const breadcrumbDropdownMenuVideo = [
  { linkName: "JTTechno", url: "/category/techno/" },
  { linkName: "JTGaming", url: "/category/gaming/" },
  { linkName: "JTHiburan", url: "/category/hiburan/" },
  { linkName: "JTNews", url: "/category/news/" },
];

export const breadcrumbDropdownMenuDownload = [
  { linkName: "Application", url: "/apps/category/" },
  { linkName: "Games", url: "/games/category/" },
];

export const breadcrumbDropdownMenuGadgets = [
  { linkName: "Phones", url: "/gadgets/smartphone/" },
  { linkName: "Tablets", url: "/gadgets/tablet/" },
];

export const breadcrumbDropdownMenuVehicles = [
  { linkName: "Mobil", url: "/kendaraan-listrik/mobil/" },
  { linkName: "Motor", url: "/kendaraan-listrik/motor/" },
];

export const placeholder = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABWQAAAJYAQMAAADfXH2qAAAABlBMVEXu7u7Nzc0qm56VAAAE5klEQVR42uzBgQAAAACAoP2pF6kCAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABm54xtJgehIAxyQEgJlOJWrhPo4Er4W3Ep7uAcbrDynG0BBtK7YEbiC1Ybjt7OY7yGx2QymUwmk8lkMplMJpPJZPJv/DJC2I8RYvkaITyMECs2owNwGBksINRmDjiNDAFAMipEALtRARBqswUXMvngcSGTDysuZPIBEDKuxYVMPjhcyORDwIVMPkRcyOQDINRmCy5k8sHjQiYfVlzI5EMEdNrM4kImHxxufkTyITw6nUg+rI8HrEib5f6KEvmwZAsEiXzwub2cRD6EvHRZiTaLJRaiQD68JQ0C+eBqKjiBfPC1olagzdbXrZE/H5qVINDnw9KY1dHng2sWAkvfZqFdZCN7PnQCA3k+9D++I8+HpdNnydvM97995M6Hte+rwJ0Pw5rlqPPBjm1F3WZuXLIicz74MQ48c5v5sZSOXW1vU3a1fdKu5GpNR+BWew5RzK12yALLrXYfs5harRnwzGq/ZmBhVnuYAcusdjcjK7HaZEYCsdrdjERitR8p355mwDGrRRptS612NG6kVvuRek4YjOu41SIpPd8Oxo3kajvjWvb/ZVr/eTvjBnq1uzEaLxk9XuPyb5l5tMZl30f36I0bBNS+xo0CaqtxLfeJO49+T0dBbTVukFBb9yIl1JZ9XvKToh6tcR292nU8n4A/xGp/Yzj7gd/Eah2GczUn74Skh32Nm4Uzq02xPw+GnVlt0Vhtm5jf2qVqXIsH4slej9JbxbZfbrUG7flbHNRqi8o923ajfttcn3GzbZkn0j3KSewz25ZbbSrG/cHNwa02b5sXdu5dkufjJXHXNt3GrZzU9z54DFNF/GrbiS3y3b306Cskgdq6alvu+0o8unG4D7va1Br3YN+V7malN/badiOy5PfsZCfkfPjSn0/o7k+gr213NwW92tQYl/+kSnunCvttVh7tCWx+tam5C0jFCY9xE39tmzus6G9h80hV7Vfg9Fr+smjU1ry1FVCbcm2lnCDSZcW3Uk6Ya8J/xyNJrQlNbQWcoFpbBbWlthpOUK2tglqtNWGTeqrZXyfoTGfctdWZzrh9KzCxhdcJAtNw2KoTFCYNj1pbnVmS27c6syS3E3RmSaoTPLnardRWYg79KL7VmXy5nUA/+fK3vTu4iRgGogCaVQ45poSUklboZCPRGL3QAAWgDXFiWzlQwIz2PQnOlpnRB7HyLzv1uk/CFPkljXE/bLe7nUM/r7Mfvm5zu0Z+Aaadrk9C7Nd1zp98nYToS1a3qt5t9CWrl7n10y6RnwJq/3rqk7DGfs3svM2ffrfRX4qrazZe32Mv2XXAV5uEKXQ2FPthq3e7hM6GoS1WPe0aOhv6mp2TEH/J6qxevyeMsbOh58N1t1PwbGj5sJ1zuwTPhr5m52mf4Zes/m1WJiH0u2v3fPgdy1f4bGj5UE47h8+Gtmafx2kzLNmVD9/HaZ/hs+HeVBQ/G/I1fQx7phaVZA01udp/cjUrPTItWbZGsFxta7ma7MZMY5usgTFZu2Wu5tD4rRl3j0xLlq1NOFdT85wmG4ox0ZJla29P1owf99OL/5kSLdmRD3myofgYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgLfwB371bVaqc5ojAAAAAElFTkSuQmCC";