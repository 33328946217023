import React from "react";
import "./Disclaimer.css";
import StaticSidebar from "@components/Static/Sidebar/StaticSidebar";

function Disclaimer() {
   return (
      <>
         <div className="disclaimer-section__container">
            <div className="static-sidebar">
               <StaticSidebar cls="show-desktop has-title" id="" active="disclaimer" />
            </div>
            <div className="static-content">
               <div className="title">
                  <h1>Disclaimer:</h1>
                  <span>Dengan mengakses Jalantikus.com, Anda memahami dan menyetujui:</span>
               </div>
               <div className="content">
                  <h3 className="color black">Informasi:</h3>
                  <ul>
                     <li>
                        Data dan informasi di Jalantikus.com hanya sebagai rujukan, bukan untuk perdagangan saham, transaksi keuangan/bisnis, maupun transaksi lainnya.
                     </li>
                     <li>
                        Tim redaksi Jalantikus.com berusaha menampilkan data dan informasi yang akurat, namun tidak bertanggung jawab atas kesalahan dan keterlambatan memperbarui informasi.
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black">
                     Tautan:
                  </h3>
                  <ul>
                     <li>
                        Jalantikus.com menyediakan tautan ke situs lain, namun tidak menandakan persetujuan Jalantikus.com terhadap situs tersebut.
                     </li>
                     <li>
                        Anda bertanggung jawab atas isi dan materi pada situs pihak lain.
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black">Aplikasi Ilegal:</h3>
                  <ul>
                     <li>
                        Jalantikus.com tidak merekomendasikan penggunaan aplikasi ilegal.
                     </li>
                     <li>
                        Jalantikus.com tidak bertanggung jawab atas risiko virus, malware, pencurian data, dan kerugian lainnya akibat penggunaan aplikasi ilegal.
                     </li>
                  </ul>
                  <p>
                     Selalu periksa kembali informasi dan lakukan riset mandiri sebelum mengambil keputusan.
                     Jalantikus.com berkomitmen untuk memberikan informasi yang bermanfaat dan terpercaya.
                  </p>
                  <br />
                  <h3 className="color black">File Log</h3>
                  <p>
                     Jalantikus.com mengikuti prosedur standar penggunaan file log. File-file ini mencatat pengunjung ketika mengunjungi situs web. Semua perusahaan hosting melakukan hal ini dan merupakan bagian dari layanan analitik hosting. Informasi yang dikumpulkan oleh file log mencakup alamat protokol internet (IP), jenis browser, Internet Service Provider (ISP), waktu dan tanggal, halaman referensi/keluar, dan mungkin jumlah klik.
                  </p>
                  <p>
                     Informasi tersebut tidak terhubung dengan informasi yang dapat diidentifikasi secara pribadi. Tujuan dari informasi tersebut adalah untuk menganalisis tren, mengelola situs, melacak pergerakan pengguna di situs web, dan mengumpulkan informasi demografis.
                  </p>
                  <br />
                  <h3 className="color black">Online Privacy Policy</h3>
                  <p>Kebijakan Privasi ini hanya berlaku untuk kegiatan online Jalantikus.com dan berlaku untuk pengunjung situs web Jalantikus.com mengenai informasi yang mereka bagikan dan/atau kumpulkan di Jalantikus.com. Kebijakan ini tidak berlaku untuk informasi yang dikumpulkan secara offline atau melalui saluran lain selain situs web ini.</p>

                  <br />
                  <h3 className="color black">Kebijakan Pihak Ketiga</h3>
                  <ul>
                     <li>
                        Beberapa pengiklan di situs Jalantikus.com mungkin menggunakan cookie dan web beacon. Setiap mitra periklanan Jalantikus.com memiliki Kebijakan Privasi sendiri terkait data pengguna.
                     </li>
                     <li>
                        Setiap perjanjian dan transaksi antara Anda dan pengiklan yang ada di Jalantikus.com adalah antara Anda dan pengiklan.
                     </li>
                     <li>
                        Anda mengetahui dan setuju bahwa Jalantikus.com tidak bertanggung jawab atas segala bentuk kehilangan atau klaim yang mungkin timbul dari perjanjian atau transaksi antara Anda dengan pengiklan.
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black">Kebijakan Konten Dewasa</h3>
                  <p>Kebijakan ini mengatur semua konten dewasa yang terbit di Jalantikus.com. Artikel yang mengandung konten dewasa adalah artikel yang mengandung unsur seksualitas. Konten dewasa di Jalantikus.com hanya diperuntukkan bagi pembaca berusia 18 tahun ke atas dengan memahami catatan berikut ini:</p>
                  <ul>
                     <li>
                        Pembaca harus berusia 18 tahun ke atas.
                     </li>
                     <li>
                        Artikel hanya ditujukan untuk mengedukasi agar pembaca tidak menggunakan, mengakses, maupun menonton konten dewasa.
                     </li>
                     <li>
                        Jalantikus.com tidak bertanggung jawab atas risiko penggunaan aplikasi, kecanduan konten dewasa, maupun risiko lainnya.
                     </li>
                  </ul>
                  <br />
                  <h3 className="color black" id="proses-penulisan">
                     Bagaimana Proses Penulisan Artikel di JalanTikus.com?
                  </h3>
                  <p>
                     Jalantikus.com bertujuan untuk menyediakan informasi obyektif tentang topik yang kami bahas. Tim redaksi Jalantikus.com berkomitmen untuk melakukan penelitian mendalam, menyampaikan fakta, dan memberikan bukti-bukti yang membimbing dalam mengambil keputusan Dengan informasi dan fakta yang Jalantikus.com sampaikan, kami ingin pembaca merasa yakin dan teredukasi saat membuat keputusan untuk menggunakan maupun tidak menggunakan suatu produk atau layanan.
                  </p>
                  <p>
                     Untuk menghasilkan keuntungan dan membayar seluruh tim Jalantikus.com, kami menerima iklan dari sejumlah mitra, tetapi kami selalu merekomendasikan merek terbaik dan kami hanya bekerja sama dengan merek yang kami percayai untuk memberikan manfaat bagi pengguna.
                  </p>
                  <p>
                     Untuk mendapatkan kepercayaan Anda sebagai pembaca, Jalantikus.com pertimbangkan nilai terlebih dahulu dan memverifikasi semua mitra afiliasi untuk memastikan kepuasan Anda saat mengambil keputusan.
                  </p>
                  <p>
                     Jalantikus.com memastikan objektivitas dengan menjaga pemisahan yang jelas dan ketat antara tim editorial dan pengiklan. Kami tidak pernah membiarkan keinginan pengiklan untuk mempengaruhi apa yang kami tulis dan bagaimana kami menulis.
                  </p>
                  <ul>
                     <li>
                        Penulis Jalantikus.com adalah profesional dalam topik yang disampaikan. Kami merekrut mereka berdasarkan kualitas tulisan mereka dan kami mengarahkan mereka untuk selalu memberikan pandangan yang tidak bias.
                     </li>
                     <li>
                        Tim editorial dan pengecek fakta Jalantikus.com memiliki pengalaman bertahun-tahun dalam memastikan bahwa setiap fakta disajikan secara jelas dan mudah dipahami.
                     </li>
                     <li>
                        Jalantikus.com berpegang teguh pada prinsip untuk memberikan informasi yang akurat dan inklusif. Konten Jalantikus.com adalah untuk semua orang yang mencari jawaban atas pertanyaan dari berbagai topik yang tersedia di situs Jalantikus.com.
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </>
   );
}

export default Disclaimer;
