import { DownloadDetailPageService } from "../../http";

// ACTION TYPES
const GET_DETAIL_ID_VERSION = "GET_DETAIL_ID_VERSION"; 

// ACTION CREATORS
export const getDetailIdVersion = (payload) => ({
    type: GET_DETAIL_ID_VERSION,
    payload: payload,
})

// THUNK
export const getDetailIdVersionThunk = (type, id, version) => async (dispatch) => {
    try {
        const response = await DownloadDetailPageService.getDataByIdVersion(type, id, version);
        dispatch(getDetailIdVersion(response.data));
    }catch(error){
        // console.log(error)
        dispatch(getDetailIdVersion(error));
    }
  }

// INITIAL STATE
const initialState = {
    detailIdVersion: null,
}

// REDUCER
function downloadDetailPageReducer(state = initialState, action){
    switch(action.type){
        case GET_DETAIL_ID_VERSION:
            return{
                ...state,
                detailIdVersion: action.payload,
            }
        default:
            return state;
    }
}

export default downloadDetailPageReducer;