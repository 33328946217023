import React from "react";
import { imgSrc} from "../../../helpers";
import { placeholder } from '../../../constants/links'
import './ArticleSquareItem.css'

function ArticleSquareItem({article}){
  const url = article ? article.links && article.links.detail && `/${article.links.detail}/` : `#`;
  const img = (article && article.banner_image) ? imgSrc(article.banner_image,0,400) : placeholder;
  const alt = (article && article.title) ? article.title : "Default Image";
  const category = (article) && (article.category ? article.category.slug : article.links.detail.split("/")[0]);
  const title =  (article && article.title)

  return (
      <div className="article-square__item">
        <a href={url}>
          <div className="article-square__image-wrapper">
              <picture>
                {(article && article.banner_image) &&
                <source
                  type="image/webp" 
                  srcSet={img + ".webp"}
                  data-srcset={img + ".webp"}
                />  
                }
                <img
                  src= {img} 
                  data-src={img}
                  className=""
                  alt={alt}  
                />
              </picture>
          </div>
        </a>
        <div className="article-square__info-wrapper">
          <h3 className="article-square__info-title">
            <a href={url}>
              <div className="article-square__info-title-link">{title}</div>
            </a>
          </h3>
          <div className="article-square__info-category">
            <a href={`/${category}/`}>
              <div className="article-square__info-category-link">{category}</div>
            </a>
          </div>
        </div>
      </div>
    );
}

export default ArticleSquareItem