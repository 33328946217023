export const gadgetMerk = (type) => [
    {
        linkName: "All",
        alias: "all",
        url: `/gadgets/${type}/`
    },
    {
        linkName: "Samsung",
        alias: "samsung",
        url: `/gadgets/${type}/samsung/`
    },
    {
        linkName: "Xiaomi",
        alias: "xiaomi",
        url: `/gadgets/${type}/xiaomi/`
    },
    {
        linkName: "Oppo",
        alias: "oppo",
        url: `/gadgets/${type}/oppo/`
    },
    {
        linkName: "Vivo",
        alias: "vivo",
        url: `/gadgets/${type}/vivo/`
    },
    {
        linkName: "Realme",
        alias: "realme",
        url: `/gadgets/${type}/realme/`
    },
    {
        linkName: "Apple",
        alias: "apple",
        url: `/gadgets/${type}/apple/`
    },
    {
        linkName: "Huawei",
        alias: "huawei",
        url: `/gadgets/${type}/huawei/`
    },
    {
        linkName: "Asus",
        alias: "asus",
        url: `/gadgets/${type}/asus/`
    },
    {
        linkName: "Nokia",
        alias: "nokia",
        url: `/gadgets/${type}/nokia/`
    },
    {
        linkName: "Advan",
        alias: "advan",
        url: `/gadgets/${type}/advan/`
    },
    {
        linkName: "Sony",
        alias: "sony",
        url: `/gadgets/${type}/sony/`
    },
    {
        linkName: "Infinix",
        alias: "infinix",
        url: `/gadgets/${type}/infinix/`
    },
    {
        linkName: "Honor",
        alias: "honor",
        url: `/gadgets/${type}/honor/`
    },
    {
        linkName: "LG",
        alias: "lg",
        url: `/gadgets/${type}/lg/`
    },
    {
        linkName: "Meizu",
        alias: "meizu",
        url: `/gadgets/${type}/meizu/`
    },
    {
        linkName: "Lenovo",
        alias: "lenovo",
        url: `/gadgets/${type}/lenovo/`
    },
    {
        linkName: "Motorola",
        alias: "motorola",
        url: `/gadgets/${type}/motorola/`
    },
    {
        linkName: "BlackBerry",
        alias: "blackberry",
        url: `/gadgets/${type}/blackberry/`
    },
    {
        linkName: "OnePlus",
        alias: "oneplus",
        url: `/gadgets/${type}/oneplus/`
    },
];