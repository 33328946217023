import React, { useEffect } from "react";
import FollowUs from "@components/FollowUs/FollowUs";
import MostReadPosts from "../components/MostReadPosts/MostReadPosts";
import { useDispatch, useSelector } from "react-redux";
import PopularToday from "@components/PopularToday/PopularToday";
import PopularFinance from "../components/PopularFinance/PopularFinance";
import TopComponent from "../components/TopComponent/TopComponent";
import RecentPosts from "../components/RecentPosts/RecentPosts";
import CategoryBlock from "../components/CategoryBlock/CategoryBlock";
import CategoryBlockB from "../components/CategoryBlock/CategoryBlockB";
import { defaultMeta } from "../constants";
import {
  googleAnalytics,
  generateAdUnitTagHead,
  generateAdUnitTagBody,
} from "../helpers";
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import { getAllDataHomeThunk, getOtherDataThunk, getNewsArticles, getFinanceArticles, getAppsArticles, getFintechArticles, getAffiliates } from "../store/ducks/homepageReducer";
import Error from "../components/Error/Error";
import AdWrapper from "../components/Banners/AdWrapper/AdWrapper";

// TRIAL FOR INFINITE SCROLL
import { HomePageService } from "../http/HomePageService";
import InfiniteScroll from "react-infinite-scroller";
import PostBlockWithBreadcrumb from "../components/PostBlockWithBreadcrumb/PostBlockWithBreadcrumb";
import { useState } from "react";
import MobileBetweenPosts from "../components/Banners/MobileBetweenPosts/MobileBetweenPosts";
import DeskstopBetweenPosts from "../components/Banners/DesktopBetweenPosts/DesktopBetweenPosts";
import Affiliate from "../components/Affiliate/Affiliate";
import MetaTag from '../components/MetaTag/MetaTag';

let page = 2;
let isBusy = false;
const maxAds = 10;
const limit = 10;
const halfSize = Math.round(limit / 2);
function Homepage() {
  const dispatch = useDispatch();

  const homepageData = useSelector(
    (state) => state.homepageReducer.homepageData
  );

  const otherData = useSelector((state) => state.homepageReducer.other);

  const wordsToRemove = [
    "domino",
    "higgs",
    "mod",
    "hack",
    "xnxx",
    "bokeh",
    "museum",
    "aplikasi dewasa",
    "yandex",
    "judi",
    "slot",
    "dewasa",
    "sadap",
    "bobol",
    "cheat",
    "lacak",
    "open bo",
    "pinjol",
    "pinjaman online",
    "cara pinjam",
    "simontok",
    "xnxubd",
    "uncensored",
    "doodstream",
    "dood",
    "18+",
    "xnx",
    "japanese",
    "vcs",
    "barbar",
    "coolmex",
    "colmek",
    "live bar bar",
    "nekopoi",
    "kucing pink",
    "penghapus baju",
    "tembus pandang"
  ];

  const wordCombinationsToRemove = [
    ["video", "bokeh", "link"], // List of words that should appear together for an article to be discarded
  ];

  const newsArticleData = useSelector(
    (state) => state.homepageReducer.newsArticleData
  );

  const newestArticles =
    homepageData &&
    homepageData.code === 200 &&
    homepageData.response.masihHangat.hits.filter(item => {
      const title = item.title.toLowerCase();
      const containsSingleWord = wordsToRemove.some(word => title.includes(word));
      const containsCombination = wordCombinationsToRemove.some(combo =>
          combo.every(word => title.includes(word))
      );
    
      return !containsSingleWord && !containsCombination;
    }).slice(0, 10);

  const newsArticles =
    newsArticleData &&
    newsArticleData.code === 200 &&
    newsArticleData.response.artikelTerbaru.hits.filter(item => {
      const title = item.title.toLowerCase();
      const containsSingleWord = wordsToRemove.some(word => title.includes(word));
      const containsCombination = wordCombinationsToRemove.some(combo =>
          combo.every(word => title.includes(word))
      );
    
      return !containsSingleWord && !containsCombination;
    }).slice(0, 10);

  const fintechArticleData = useSelector(
    state => state.homepageReducer.fintechArticleData
  );

  const financeArticleData = useSelector(
    (state) => state.homepageReducer.financeArticleData
  );

  const appsArticleData = useSelector(
    (state) => state.homepageReducer.appsArticleData
  );

  // const affiliateData = useSelector(
  //   (state) => state.homepageReducer.getAffiliates
  // );

  // handling infinite scroll
  const [cacheArray, setCacheArray] = useState([]);
  const [infiniteArray, setInfiniteArray] = useState([]);

  const renderMeta = () => {
    const url = "https://jalantikus.com"
    const title = "Solusi Lengkap Download, Informasi Teknologi, Lifestyle dan Gadget";
    const description = "JalanTikus adalah website teknologi berbahasa Indonesia yang menyajikan informasi gadget, game Android, iOS, film, anime dan informasi teknologi lainnya.";
    const image = "https://jalantikus.com/assets/images/logo.png"
    const amphtmlUrl = "";
    const robots = "index, follow";
    const ogTitle = "";

    const schemaContent = [
      { 
        "@type": "CollectionPage", 
        "@id": "https://jalantikus.com/", 
        "url": "https://jalantikus.com/", 
        "name": "Solusi Lengkap Download, Informasi Teknologi, Lifestyle dan Gadget", 
        "isPartOf": { 
          "@id": "https://jalantikus.com/#website" 
        }, 
        "about": { 
          "@id": "https://jalantikus.com/#organization" 
        }, 
        "description": "JalanTikus adalah website teknologi berbahasa Indonesia yang menyajikan informasi gadget, game Android, iOS, film, anime dan informasi teknologi lainnya.", 
        "breadcrumb": { 
          "@id": "https://jalantikus.com/#breadcrumb" 
        }, 
        "inLanguage": "id-ID" 
      },
      {
        "@type":"BreadcrumbList",
        "@id":"https://jalantikus.com/#breadcrumb",
        "itemListElement":""
      }
    ]
    const props = { url, title, description, image, amphtmlUrl, robots, schemaContent, ogTitle };
    return <MetaTag {...props} />
  }

  const addMorePost = async () => {
    try {
      if (cacheArray.length) {
        setInfiniteArray((prev) => [...prev, ...cacheArray])
        setCacheArray([])
        return;
      }
      let response = await HomePageService.getRecentArticles(page);
      let hits = response.data.response.masihHangat.hits
      hits = hits.filter(item => {
        // const title = item.title.toLowerCase();
      const containsSingleWord = wordsToRemove.some(word => title.includes(word));
      const containsCombination = wordCombinationsToRemove.some(combo =>
          combo.every(word => title.includes(word))
      );
    
      return !containsSingleWord && !containsCombination;
        const title = item.title.toLowerCase();

        // Check if the title contains any forbidden words
        const containsForbiddenWords = wordsToRemove.some(word => title.includes(word));

        // Check if the title has fewer than 5 words
        const wordCount = title.split(/\s+/).length;
        const hasFewerThan5Words = wordCount <= 5;

        // Keep the item if it does not meet either condition
        return !(containsForbiddenWords || hasFewerThan5Words);
      });
      if (hits) {
        // split half to cache
        setInfiniteArray((prev) => [...prev, ...hits.splice(0, halfSize)])
        setCacheArray(hits.splice(halfSize));
      }
      page++
    } catch (e) {
      console.log(e)
    }
  }

  const throttle = () => {
    if (isBusy) {
      return;
    }
    isBusy = true;
    setTimeout(async () => {
      await addMorePost();
      isBusy = false;
    }, 100);
  }

  useEffect(() => {
    const getData = () => {
      dispatch(getAllDataHomeThunk());
      dispatch(getNewsArticles());
      dispatch(getFintechArticles());
      dispatch(getFinanceArticles());
      dispatch(getAppsArticles());
      dispatch(getOtherDataThunk()); //infinite scroll
      dispatch(getAffiliates());
    };
    getData();
    // send to google analytics
    googleAnalytics(defaultMeta.title.replace(/['"]+/g, ""));
    loadAds();
    // eslint-disable-next-line
  }, []);

  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`homepage`);

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`;

    let slots = [];

    if (isMobile) {
      // available slot in this component
      slots = [
        {
          slot: "JT_320x100", // adunit path (parent and child)
          id: "adSlotMobTop-homepage", // whatever
          wrapperId: "JT_320x100", // match with existing HTML elmt
          defaultSize: "320x100", // use the default size
        },
        {
          slot: "JT_300x250",
          id: "adSlotMobBelowWatchNow-homepage",
          wrapperId: "JT_300x250_below_watch_now",
          defaultSize: "300x250",
        },
        {
          slot: "JT_300x250",
          id: "adSlotMobBelowEditorsPick-homepage",
          wrapperId: "JT_300x250_below_editors_pick",
          defaultSize: "300x250",
        },
        {
          slot: "JT_320x100",
          id: "adSlotMobSticky-homepage",
          wrapperId: "JT_320x100-sticky-bottom",
          defaultSize: "320x100",
        },
        {
          slot: "JT_320x250",
          id: "adSlotMobAfterFinance-homepage",
          wrapperId: "JT_300x250_after_finance",
          defaultSize: "320x250",
        },
        // {
        //   slot: "JT_320x250",
        //   id: "adSlotMobAfterTechHack-homepage",
        //   wrapperId: "JT_300x250_after_techhack",
        //   defaultSize: "320x250",
        // },
        {
          slot: "JT_320x250",
          id: "adSlotMobAfterApps-homepage",
          wrapperId: "JT_300x250_after_apps",
          defaultSize: "320x250",
        }, {
          slot: "JT_320x100_top", // adunit path
          id: "JT_320x100_top_inner", // whatever
          wrapperId: "JT_320x100_top",
          defaultSize: "320x100"
        }
      ];
    } else {
      slots = [
        {
          slot: "JT_970x250/JT_970x250_top", // adunit path (parent and child)
          id: "adSlotDesktop1", // whatever
          wrapperId: "JT_970x250_top", // match with existing HTML elmt
          defaultSize: "970x250", // use the default size
        },
        {
          slot: "JT_300x250/JT_300x250_after_popular",
          id: "adSlotDesktop2",
          wrapperId: "JT_300x250_after_popular",
          defaultSize: "300x250",
        },
        {
          slot: "JT_970x90/JT_970x90_below_palingdicari",
          id: "adSlotDesktop3",
          wrapperId: "JT_970x90_below_palingdicari",
          defaultSize: "970x90",
        },
        {
          slot: "JT_300x250/JT_300x250_after_trending",
          id: "adSlotDesktop4",
          wrapperId: "JT_300x250_after_trending",
          defaultSize: "300x250",
        },
        {
          slot: "JT_970x90/JT_970x90_below_pilihaneditor",
          id: "adSlotDesktop5",
          wrapperId: "JT_970x90_below_pilihaneditor",
          defaultSize: "970x90",
        },
        {
          slot: "JT_300x600/JT_300x600_right_artikelterbaru",
          id: "adSlotDesktop6",
          wrapperId: "JT_300x600_right_artikelterbaru",
          defaultSize: "300x600",
        },
        {
          slot: "JT_300x250/JT_300x250_right_artikelterbaru",
          id: "adSlotDesktop7",
          wrapperId: "JT_300x250_right_artikelterbaru",
          defaultSize: "300x250",
        },
        {
          slot: "JT_970x90/JT_970x90_scroll1_artikelterbaru",
          id: "adSlotDesktop8",
          wrapperId: "JT_970x90_scroll1_artikelterbaru",
          defaultSize: "970x90",
        },
        {
          slot: "JT_300x600/JT_300x600_scroll1_artikelterbaru",
          id: "adSlotDesktop9",
          wrapperId: "JT_300x600_scroll1_artikelterbaru",
          defaultSize: "300x600",
        },
        {
          slot: "JT_300x250/JT_300x250_scroll1_artikelterbaru",
          id: "adSlotDesktop10",
          wrapperId: "JT_300x250_scroll1_artikelterbaru",
          defaultSize: "300x250",
        },
        // {
        //   slot: "JT_300x250/JT_300x250_right_finance",
        //   id: "adSlotDesktop11",
        //   wrapperId: "JT_300x250_right_finance",
        //   defaultSize: "300x250",
        // },
        // {
        //   slot: "JT_970x90/JT_970x90_below_finance",
        //   id: "adSlotDesktop12",
        //   wrapperId: "JT_970x90_below_finance",
        //   defaultSize: "970x90",
        // },
        {
          slot: "JT_300x250/JT_300x250_right_techhack",
          id: "adSlotDesktop13",
          wrapperId: "JT_300x250_right_techhack",
          defaultSize: "300x250",
        },
        {
          slot: "JT_970x90/JT_970x90_below_techhack",
          id: "adSlotDesktop14",
          wrapperId: "JT_970x90_below_techhack",
          defaultSize: "970x90",
        },
        {
          slot: "JT_300x250/JT_300x250_right_apps",
          id: "adSlotDesktop15",
          wrapperId: "JT_300x250_right_apps",
          defaultSize: "300x250",
        },
        {
          slot: "JT_970x90/JT_970x90_below_apps",
          id: "adSlotDesktop16",
          wrapperId: "JT_970x90_below_apps",
          defaultSize: "970x90",
        },
      ];
    }

    // create ad unit tag head
    for (let i = 0; i < slots.length; i++) {
      _scriptTagHead.text += generateAdUnitTagHead(slots[i]);
    }
    $currpage && $currpage.appendChild(_scriptTagHead);

    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
      generateAdUnitTagBody(slots[i]);
    }
  };

  return (
    <main className="main-container" id="homepage">
      {homepageData && homepageData.code === 200 && renderMeta()}
      <h1 style={{ fontSize: "0" }}>Solusi Lengkap Download, Informasi Teknologi, Lifestyle dan Gadget</h1>
      <div className="container-full-width">
        <AdWrapper
          id={`JT_970x250_top`}
          cls={`homepage desktop`}
          customStyle={{ minHeight: "100px" }}
        />
        <AdWrapper
          id={`JT_320x100`}
          cls={`homepage mobile`}
          customStyle={{ minHeight: "100px" }}
        />
      </div>
      {homepageData && homepageData.code !== 200 ? (
        <div className="container-common">
          <Error code={homepageData.code ? homepageData.code : `503`} />
        </div>
      ) : (
        <>
          {/* START OF TOP COMPONENT */}
          <div className="container-divided">
            <section className="container-divided_section">
              {homepageData ? (
                <TopComponent
                  articles={homepageData.response.slider.slice(0, 3)}
                />
              ) : (
                <TopComponent />
              )}

              {(newsArticles) && (
                <MostReadPosts
                  page="homepage"
                  isLowercase
                  title="Artikel Terbaru Hari Ini"
                  articles={newestArticles}
                />
              )}
            </section>
            <aside className="container-divided_aside-special">
              { /*(affiliateData) && (
                <Affiliate data={affiliateData.response.hits} title="Rekomendasi JT" />
              ) */}
              <AdWrapper id={`JT_300x250_affiliate`} />
              <PopularToday
                title="Artikel Terpopuler"
                articles={
                  otherData && otherData.response.populerHariIni.filter(item => {
                    const title = item.title.toLowerCase();
      const containsSingleWord = wordsToRemove.some(word => title.includes(word));
      const containsCombination = wordCombinationsToRemove.some(combo =>
          combo.every(word => title.includes(word))
      );
    
      return !containsSingleWord && !containsCombination;
                  }).slice(0, 10)
                }
              />
              <AdWrapper id={`JT_300x250_after_popular`} />
            </aside>
          </div>
          {/* END OF TOP COMPONENT */}

          <div className="container-full-width">
            <AdWrapper id={`JT_970x90_below_palingdicari`} />
          </div>

          {/* START OF FINTECH COMPONENT */}
          <div className="container-divided">
            <section className="container-divided_section">
              <AdWrapper id={`JT_300x250_below_watch_now`} cls={`mobile`} />
              {(fintechArticleData) && (
                <CategoryBlock
                  hasBreadcrumbs
                  containerData={fintechArticleData.response.artikelTerbaru}
                  title="Fintech"
                  wordsToRemove={wordsToRemove}
                  buttonLink="/tags/penghasil-uang/"
                />
              )}

              <AdWrapper id={`JT_300x250_below_editors_pick`} cls={`mobile`} />
            </section>
            <aside className="container-divided_aside-special">
              <AdWrapper id={`JT_300x250_after_trending`} />
            </aside>
          </div>
          {/* END OF FINTECH COMPONENT */}
          {/* START OF ELECTRIC VEHICLE COMPONENT */}
          {/* <div className="container-divided">
            <section className="container-divided_section">
              <AdWrapper id={`JT_300x250_below_watch_now`} cls={`mobile`} />
              {(evArticleData) && (
                <CategoryBlock
                  hasBreadcrumbs
                  containerData={evArticleData.response.artikelTerbaru}
                  title="Kendaraan Listrik"
                  wordsToRemove={wordsToRemove}
                  buttonLink="/kendaraan-listrik"
                />
              )}

              <AdWrapper id={`JT_300x250_below_editors_pick`} cls={`mobile`} />
            </section>
            <aside className="container-divided_aside-special">
              <PopularEV
                title="Artikel Kendaraan Listrik Terpopuler"
                articles={
                  evArticleData &&
                  evArticleData.response.populerMingguIni.filter(item => {
                    const title = item.title.toLowerCase();
      const containsSingleWord = wordsToRemove.some(word => title.includes(word));
      const containsCombination = wordCombinationsToRemove.some(combo =>
          combo.every(word => title.includes(word))
      );
  
      return !containsSingleWord && !containsCombination;
                  }).slice(0, 5)
                }
              />
              <AdWrapper id={`JT_300x250_after_trending`} />
            </aside>
          </div> */}
          {/* END OF ELECTRIC VEHICLE COMPONENT */}

          <div className="container-full-width" >
            <AdWrapper id={`JT_970x90_below_pilihaneditor`} />
          </div>

          {/* START OF FINANCE COMPONENT */}
          <div className="container-divided">
            <section className="container-divided_section">
              {financeArticleData && (
                <CategoryBlockB
                  hasBreadcrumbs
                  containerData={financeArticleData.response.artikelTerbaru}
                  title="Finansial"
                  wordsToRemove={wordsToRemove}
                  category_name={"finansial"}
                />
              )}
              <AdWrapper id={`JT_300x250_after_finance`} cls={`mobile`} />
            </section>
            <aside className="container-divided_aside-special">
              <PopularFinance
                title="Artikel Finansial Terpopuler"
                articles={
                  financeArticleData &&
                  financeArticleData.response.populerMingguIni.filter(item => {
                    const title = item.title.toLowerCase();
      const containsSingleWord = wordsToRemove.some(word => title.includes(word));
      const containsCombination = wordCombinationsToRemove.some(combo =>
          combo.every(word => title.includes(word))
      );
    
      return !containsSingleWord && !containsCombination;
                  }).slice(0, 5)
                }
              />
              <AdWrapper id={`JT_300x250_right_finance`} />
            </aside>
          </div>
          {/* END OF FINANCE COMPONENT */}

          <div className="container-full-width" style={{ marginTop: "0px" }}>
            <AdWrapper id={`JT_970x90_below_finance`} />
          </div>

          {/* START OF TECH HACK COMPONENT */}
          {/* <div className="container-divided">
            <section className="container-divided_section">
              {(techHackArticleData) && (
                <CategoryBlock
                  hasBreadcrumbs
                  containerData={techHackArticleData.response.artikelTerbaru}
                  title="Tech Hack"
                  wordsToRemove={wordsToRemove}
                  buttonLink="/tags/tech-hack"
                />
              )}
              <AdWrapper id={`JT_300x250_after_techhack`} cls={`mobile`} customStyle={{ marginTop: "25px" }}/>
            </section>
            <aside className="container-divided_aside">
              <AdWrapper id={`JT_300x250_right_techhack`} />
            </aside>
          </div> */}
          {/* END OF TECH HACK COMPONENT */}

          {/* <div className="container-full-width">
            <AdWrapper id={`JT_970x90_below_techhack`} />
          </div> */}

          {/* START OF APPS COMPONENT */}
          <div className="container-divided">
            <section className="container-divided_section">
              {appsArticleData && (
                <CategoryBlockB
                  hasBreadcrumbs
                  containerData={appsArticleData.response.artikelTerbaru}
                  title="Apps"
                  wordsToRemove={wordsToRemove}
                  tag_name={"apps"}
                />
              )}

              <AdWrapper id={`JT_300x250_after_apps`} cls={`mobile`} />
            </section>
            <aside className="container-divided_aside">
              <AdWrapper id={`JT_300x250_right_apps`} />
            </aside>
          </div>

          <div className="container-full-width" style={{ marginTop: "0px" }}>
            <AdWrapper id={`JT_970x90_below_apps`} />
          </div>
          {/* END OF APPS COMPONENT */}

          {/* START OF NEW ARTICLE COMPONENT */}
          <div className="container-divided container-divided__article-page">
            <section className="container-divided_section">
              <div className="article-page__recent-posts">
                {homepageData && (
                  <RecentPosts
                    hasBreadcrumbs
                    containerData={homepageData.response.masihHangat}
                    title="Artikel Terbaru"
                    wordsToRemove={wordsToRemove}
                  />
                )}
              </div>
            </section>
            <aside className="container-divided_aside">
              <FollowUs />
              <AdWrapper id={`JT_300x600_right_artikelterbaru`} />
              <AdWrapper id={`JT_300x250_right_artikelterbaru`} />
            </aside>
          </div>
          {/* END OF NEW ARTICLE COMPONENT */}

          <div className="container-full-width" style={{ marginTop: "0px" }}>
            <AdWrapper
              id={`JT_970x90_scroll1_artikelterbaru`}
              customStyle={{ marginTop: "8px" }}
            />
          </div>

          {/* START OF INFINITE SCROLL COMPONENT */}
          <div className="container-divided">
            <section className="container-divided_section">
              <InfiniteScroll
                pageStart={0}
                loadMore={throttle}
                hasMore={true}
                className="infinite-scroll-block"
                id="infinite-scroll-block"
              >
                {infiniteArray.map((item, index) => {
                  if ((index + 1) % 10 === 0 && index !== 0) {
                    return (
                      <div key={index}>
                        <PostBlockWithBreadcrumb data={item} />
                        {((index + 1) < maxAds * 10) &&
                          <>
                            <MobileBetweenPosts idName={`infinite-recent-${index}`} parentId={`infinite-scroll-block`} currentAdIndex={(index + 1) / 10 + 1} />
                            <DeskstopBetweenPosts idName={`infinite-recent-${index}`} parentId={`infinite-scroll-block`} currentAdIndex={(index + 1) / 10 + 1} />
                          </>
                        }
                      </div>
                    );
                  } else {
                    return <PostBlockWithBreadcrumb key={index} data={item} />;
                  }
                })}
              </InfiniteScroll>
            </section>
            <aside className="container-divided_aside">
              <AdWrapper id={`JT_300x600_scroll1_artikelterbaru`} />
              <AdWrapper id={`JT_300x250_scroll1_artikelterbaru`} />
            </aside>
          </div>
          {/* END OF INFINITE SCROLL COMPONENT */}
        </>
      )}
      <MobileStickyBottom />
    </main>
  );
}
export default Homepage;
