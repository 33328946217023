import React, { useEffect } from "react";
import PostsContainer from "../PostsContainer/PostsContainer";
import { assetsUrl } from "../../constants";
import PostBlockWithCategory from "@components/PostBlockWithCategory/PostBlockWithCategory";
import "./CategoryBlockB.css";
import AdWrapper from '@components/Banners/AdWrapper/AdWrapper';
import BottomPostsBlock from "../BottomPostsBlock/BottomPostsBlock";

import { generateAdUnitTagHead, generateAdUnitTagBody } from '../../helpers'

function RecentPosts({ containerData, title, hasVideoIcon, hasBreadcrumbs, wordsToRemove, tag_name, category_name }) {
  useEffect(() => {
    loadAds();
    // eslint-disable-next-line
  }, [])
  let recentPostsBottomData
  if (wordsToRemove) {
    recentPostsBottomData = containerData.hits.filter(item => {
      return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
    }).slice(4);
  } else {
    recentPostsBottomData = containerData.hits.slice(4);
  }

  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`recent-post`);

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

    if (isMobile) {
      var idSLots = [
        {
          slot: "JT_300x250", // define slot
          id: "adSlotBetweenRecentPost0", // whatever id
          wrapperId: "JT_300x250_between_recent_post_0",
          defaultSize: "300x250"
        }
      ]
      // create ad unit tag head
      for (let i = 0; i < idSLots.length; i++) {
        _scriptTagHead.text += generateAdUnitTagHead(idSLots[i])
      }
      $currpage && $currpage.appendChild(_scriptTagHead)

      // create ad unit tag body
      for (let i = 0; i < idSLots.length; i++) {
        generateAdUnitTagBody(idSLots[i])
      }
    }
  }

  return (
    <div id="recent-post">
      {recentPostsBottomData.length > 0 && (
        <>
        
      <PostsContainer
        containerData={containerData}
        title={title}
        hasVideoIcon={hasVideoIcon}
        hasBreadcrumbs={hasBreadcrumbs}
        wordsToRemove={wordsToRemove}
      />
      <AdWrapper id={`JT_300x250_between_recent_post_0`} />
      <div className="recent-posts__bottom">
        {recentPostsBottomData.slice(0, 3).map((item, index) => {
          // get thumbnail url
          let thumbnail_url =
            assetsUrl + item.banner_image.base_url + '184/94/' + item.banner_image.file;

          // get post date
          let date = item.published;

          // get post url(post img and title are wrapped in link with this url)
          let url = item.links.detail;

          /*Get category name */
          let categoryName = item.category.title;

          /*Get category url */
          let categoryUrl = item.category.slug;

          return (
            <PostBlockWithCategory
              key={index}
              thumbnail_url={thumbnail_url}
              date={date}
              title={item.title}
              url={url}
              categoryUrl={categoryUrl}
              categoryName={categoryName}
            />
          );
        })}
      </div>
      <BottomPostsBlock
        wordsToRemove={wordsToRemove}
        tag_name={tag_name}
        category_name={category_name}
      />
      </>
      )
}
    </div>
  );
}

export default RecentPosts;
