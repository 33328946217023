import React from 'react';
import slugify from 'react-slugify';
import "./PostBlockLarge.css";
import PostInfo from "@components/common/PostInfo/PostInfo";
import { assetsUrl } from "../../constants";
import { categoryUrlObj } from "../../constants/index";
import 'lazysizes'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { onErrorSrcImg } from '../../helpers';

function PostBlockLarge({ firstPostData }) {
  const { width } = useWindowDimensions();
  /*Get category name */
  let categoryName;
  if (firstPostData["owner.screenname"]) {
    categoryName = firstPostData["owner.screenname"];
  } else if (firstPostData.category.title) {
    categoryName = firstPostData.category.title;
  }

  /*Get category url */
  let categoryUrl;
  let thirdPartyResource;
  if (firstPostData["owner.url"]) {
    thirdPartyResource = true;
    categoryUrl = firstPostData["owner.url"];
  } else if (firstPostData.category.slug) {
    thirdPartyResource = false;
    categoryUrl = firstPostData.category.slug;
  }

  /*Get post date */
  let postDate;
  if (firstPostData.created_time) {
    postDate = firstPostData.created_time * 1000;
  } else if (firstPostData.published) {
    postDate = firstPostData.published;
  }
  /*Get post img src */
  let postImgSrc;
  if (firstPostData.thumbnail_url) {
    postImgSrc = firstPostData.thumbnail_url;
  } else if (firstPostData.banner_image) {
    if (width < 768) {
      postImgSrc = `${ assetsUrl }${ firstPostData.banner_image.base_url }0/200/${ firstPostData.banner_image.file }`
    } else {
    postImgSrc =
      assetsUrl +
      firstPostData.banner_image.base_url +
      firstPostData.banner_image.file;
    }
  }

  /*Get post img wrapped inside link */
  let postImageWithLinkWrapper;
  if (firstPostData.url) {
    let videoURL = `/video/${categoryUrlObj[firstPostData["channel.id"]]}/${firstPostData.id}/${slugify(firstPostData.title)}/`;
    postImageWithLinkWrapper = (
      <a href={videoURL}>
        <img className="post-block-large__img lazyload" src={postImgSrc} alt="" />
      </a>
    );
  } else if (firstPostData.links.detail) {
    postImageWithLinkWrapper = (
      <a href={`/${firstPostData.links.detail}/`}>
        {/* <img className="post-block-large__img" src={postImgSrc} alt="" className="lazyload"/> */}
        <LazyLoadImage
          onError={onErrorSrcImg}
          alt={``}
          src={postImgSrc} 
          className="post-block-large__img"
        />
      </a>
    );
  }

  /*Get post title  wrapped inside link */
  let postTitleWithLinkWrapper;
  if (firstPostData.url) {
    let videoURL = `/video/${categoryUrlObj[firstPostData["channel.id"]]}/${firstPostData.id}/${slugify(firstPostData.title)}/`;
    postTitleWithLinkWrapper = (
      <a className="post-block-large__link" href={videoURL}>
        {firstPostData.title}
      </a>
    );
  } else if (firstPostData.links.detail) {
    postTitleWithLinkWrapper = (
      <a href={`/${firstPostData.links.detail}/`}
        className="post-block-large__link"
      >
        {firstPostData.title}
      </a>
    );
  }
  return (
    <>
      <div className="post-block-large__wrapper">
        <div className="post-block-large__img-wrapper">
          {postImageWithLinkWrapper}
        </div>
        <div className="post-block-large__info">
          <p className="post-block-large__title title-small">
            {postTitleWithLinkWrapper}
          </p>

          <PostInfo
            categoryName={categoryName}
            categoryUrl={categoryUrl}
            date={postDate}
            thirdPartyResource={thirdPartyResource}
            subTitleText=""
          />
        </div>
      </div>
    </>
  );
}

export default PostBlockLarge;
