import React from "react";
import './LastestEV.css';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { imgVehicleSrc, imgSrc } from "../../helpers";
import { getVehicleSearchDataThunk } from "../../store/ducks/electricVehiclePageReducer";
function LastestEV() {

  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [fetching, setFetching] = useState(false);
  // eslint-disable-next-line
  const [category, setCategory] = useState('');

  const vehicleSearchData = useSelector(
    (state) => state.eletricVehiclePageReducer.searchVehicleData
  )

  useEffect(() => {
    if (!vehicleSearchData) {
      // let randomCategory = ['mobil', 'motor'][Math.floor(Math.random() * 2)];
      setCategory('mobil');
      getData({
        type: 'mobil',
        merk: '',
        query: ''
      })
    }
    // eslint-disable-next-line
  }, [])

  const getData = async (params) => {
    setFetching(true);
    try {
      await dispatch(getVehicleSearchDataThunk(params));
    } catch (e) {
    } finally {
      setFetching(false);
    }
  }

  const openLink = (link) => {
    window.open(`/${link}`, "_blank");
  }


  return (
    <div className="latest-ev-container">
      <div className="latest-ev-title d-flex justify-content-between mb-3">
        <h5>
          Latest Model
        </h5>
        <a href="/kendaraan-listrik/mobil/" >see all</a>
      </div>
      <div className="line-break mb-3">
      </div>
      {
        vehicleSearchData && vehicleSearchData.code === 200 &&
        (vehicleSearchData?.response?.vehicleList?.vehicleList?.hits ?? []).slice(0, 5).map(item => (
          <div className="latest-ev-item d-flex" key={item.id}>
            <div className="latest-ev-item__img d-flex justify-content-center"
              onClick={() => openLink(item.links.detail)}
            >
              <picture>
                <source
                  type="image/webp" data-srcset={imgVehicleSrc(item.image, 0, 200)}
                  srcSet={imgVehicleSrc(item.image, 0, 200)}
                />
                <img
                  src={imgSrc(item.image, 0, 200)}
                  alt={item.title}
                  data-src={imgSrc(item.image, 0, 200)}
                />
              </picture>
            </div>
            <div className="latest-ev-item__info d-flex flex-column justify-content-center">
              <div>{item?.company?.title}</div>
              <div>{item?.model}</div>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default LastestEV;
