import React, { useMemo } from "react";
import "./PostBlockWithBreadcrumb.css";
// import postImage1 from "../../assets/images/banners/bann";
import PostInfo from "@components/common/PostInfo/PostInfo";
import { assetsUrl } from "../../constants/api";
import useCustomLazy from "../../hooks/useCustomLazy";
import LazyLoader from "@components/LazyLoader/LazyLoader";

function PostBlockWithBreadcrumb({ data }) {
  const { imageRef, loaded, hasError } = useCustomLazy();

  const postImageUrl = useMemo(() => {
    if (hasError) return "https://via.placeholder.com/150";
    return `${assetsUrl}${data.banner_image.base_url}184/94/${data.banner_image.file}`;
  }, [data, hasError]);

  return (
    <>
      {!loaded && <LazyLoader />}
      <div
        className={`${
          !loaded && "post-block-with-breadcrumb_hidden"
        } post-block-with-breadcrumb`}
      >
        <a href={`/${data.links.detail}/`}
          className="post-block-with-breadcrumb__img-wrapper"
        >
          <img
            ref={imageRef}
            className="post-block-with-breadcrumb__img"
            src={postImageUrl}
            alt=""
          />
        </a>
        <div className="post-block-with-breadcrumb__info">
          <p className="post-block-with-breadcrumb__text">
            <a href={`/${data.links.detail}/`}
              className="post-block-with-breadcrumb__link"
            >
              {data.title}
            </a>
          </p>
          <PostInfo
            subTitleText=""
            categoryName={data.category.slug}
            categoryUrl={data.category.slug}
            date={data.published}
          />
        </div>
      </div>
    </>
  );
}

export default PostBlockWithBreadcrumb;
