import React from "react";
import "./Faq.css";
import StaticSidebar from "@components/Static/Sidebar/StaticSidebar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {faqGeneral, faqDownload, faqArticle} from '../../../constants';

function Faq() {
   
   return (
      <>
         <div className="faq-section__container">
            <div className="static-sidebar">
               <StaticSidebar cls="show-desktop has-title" id="" active="eula" />
            </div>
            <div className="static-content">
               <div className="title">
                  <h1>FREQUENTLY ASKED QUESTIONS (FAQ)</h1>
                  <span>Ask Anything About Us</span>
               </div>
               <div className="content">
                  <Tabs>
                     <TabList>
                        <Tab> 
                           General 
                        </Tab>
                        <Tab> 
                           Download 
                        </Tab>
                        <Tab> 
                           Articles 
                        </Tab>
                     </TabList>
                     <TabPanel>
                        {faqGeneral.map((item,index)=>{
                           return(
                              <div key={index} className="faq-item">
                                 <div className="faq-question">
                                    {item.question}
                                 </div>
                                 <div className="faq-answer"  dangerouslySetInnerHTML={{ __html: item.answer }}>
                                 </div>
                              </div>
                           )
                        })}
                     </TabPanel>
                     <TabPanel>
                        {faqDownload.map((item,index)=>{
                           return(
                              <div key={index} className="faq-item">
                                 <div className="faq-question">
                                    {item.question}
                                 </div>
                                 <div className="faq-answer"  dangerouslySetInnerHTML={{ __html: item.answer }}>
                                 </div>
                              </div>
                           )
                        })}
                     </TabPanel>
                     <TabPanel>
                        {faqArticle.map((item,index)=>{
                           return(
                              <div key={index} className="faq-item">
                                 <div className="faq-question">
                                    {item.question}
                                 </div>
                                 <div className="faq-answer"  dangerouslySetInnerHTML={{ __html: item.answer }}>
                                 </div>
                              </div>
                           )
                        })}
                     </TabPanel>
                  </Tabs>
               </div>
            </div>
         </div>
      </>
   );
}

export default Faq;
