import React, { useRef } from "react";
import Slider from "react-slick";
import "./CategorySlider.css";
import { useEffect } from "react";

function CategorySlider({ categories, settings, selected, searchQuery = "" }) {
  const sliderRef = useRef()

  useEffect(() => {
    let activeIdx;
    for (let i = 0; i < categories.length; i++) {
      const element = categories[i];
      if (element.alias === selected) {
        activeIdx = i
      }
    }
    sliderRef.current.slickGoTo(activeIdx,true);
    // eslint-disable-next-line
  },[])

  return (
    <>
      <div className="category-slider__wrapper">
        <Slider {...settings} ref={sliderRef}>
          {categories.map((item, index) => (
            <a href={`${item.url}${searchQuery && `/${searchQuery}`}`} key={index}>
              <div className={`category-slider__item ${
                  item.alias === selected && "active"
                }`}
              >
                {item.linkName}
              </div>
            </a>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default CategorySlider;
