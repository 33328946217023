import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

function useSearch({ searchDataThunk, suggestionDataThunk, category }) {
  // common
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");

  let searchType;
  let searchCategory = params.type;
  switch (category) {
    case "gadget":
      searchType = "gadgets";
      break;
    case "mobil":
    case "motor":
      searchType = "vehicles";
      searchCategory = params.vehicle_type;
      break;
    default:
      searchType = "gadgets";
      break;
  }

  const onKeyDownCallback = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      history.push(`/search/${category}/${userInput}`);
      setUserInput(e.target.value);
    }
    setUserInput(e.target.value);
  };
  const onClickSearchIcon = () => {
    history.push(`/search/${category}/${userInput}`);
  };
  useEffect(() => {
    if (!suggestionDataThunk) {
      return;
    }
    // change gadgets
    dispatch(
      suggestionDataThunk(
        userInput, // search query
        searchCategory, // item: smartphone or tablet
        searchType // type
      )
    );
    // eslint-disable-next-line
  }, [userInput]);

  // change gadgets
  const suggestionCallback = (value) => {
    history.push(`/gadgets/${params.type}/?q=${value}`);
  };

  return {
    onKeyDownCallback,
    suggestionCallback,
    onClickSearchIcon,
    params,
    userInput,
    setUserInput,
  };
}
export default useSearch;
