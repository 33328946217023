import AppsItem from '../common/AppsItem/AppsItem';
import InfiniteScroll from "react-infinite-scroller";
// import LoadingMore from '../common/LoadingMore/LoadingMore';
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DownloadPageService } from '../../http';

function TabPanelContent({items, infiniteScroll, platform, type}){
    const location = useLocation();
    const parameter = new URLSearchParams(location.search);
    const pathname = location.pathname;
    const pathArray = pathname.split("/");
    const params = useParams();
    const order = parameter.get('order') ? parameter.get('order') : 'popular' ; 
    const license = parameter.get('license') ? parameter.get('license') : '' ;
    
    const [pagination, setPagination] = useState({
        page: 1,
        isMore: false,
        appList: []
    }); 

    useEffect(() => {
        setPagination({ page: 1, appList: [], isMore: true });
    }, [params]);
    
    const addMoreItemsNew = async () => {
        try{
            let { page, appList, isMore } = pagination;
            const category = params.categoryName ? params.categoryName : "";
            const type = pathArray[1];
            const response = await DownloadPageService.getDownloadByParam(type, category, platform, order, license, page);
            const apps = response.data.response.appList.hits
            if(apps.length > 0){
                const currPage = response.data.response.appList.page.curr;
                const nextPage = response.data.response.appList.page.next;
                const lastPage = response.data.response.appList.page.last;

                if(currPage === lastPage){
                    isMore = false;
                }else{
                    page = nextPage;
                }
                appList = [
                    ...appList,
                    ...apps
                ]
                setPagination({page, isMore, appList})
            }} catch (e) {
        }
    }
    
    return(
    <>
        {infiniteScroll ? (
            <InfiniteScroll
                pageStart={1}
                loadMore={addMoreItemsNew}
                hasMore={pagination.isMore}
                className="apps-wrapper"
            >
                {pagination.appList && (
                    pagination.appList.length > 0 ?
                    pagination.appList.map((item,index) => {
                        return(
                            <AppsItem app={item} key={`appsItem-${index}`} type={type} />
                        )
                    }) : <div>{`Data Not Found`}</div>
                )}
            </InfiniteScroll>
        ) : (
            <div className="apps-wrapper">
                {items ? (
                    items.map((item, i) => <AppsItem key={`appsItem-${i}`} app={item} type={type} />)
                ):(
                    [...Array(6)].map((x, i) => <AppsItem key={`appsItem-${i}`} type={type} />)  
                )}
            </div>
        )}
    </>
    )
}

export default TabPanelContent