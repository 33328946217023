import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import FollowUs from "@components/FollowUs/FollowUs";
// import CreateAccount from "@components/CreateAccount/CreateAccount";
import PopularToday from "@components/PopularToday/PopularToday";
import BottomPostsBlock from "../components/BottomPostsBlock/BottomPostsBlock";
// import BannerMobile from "@components/Banners/BannerMobile/BannerMobile";
// import FixedBannerMobile from "@components/Banners/FixedBannerMobile/FixedBannerMobile";
import SidebarBanner from "@components/Banners/SidebarBanner/SidebarBanner";
// import DesktopHorizontalBanner from "../components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import RecentPosts from "../components/RecentPosts/RecentPosts";
import {
  getAllTagsPageDataThunk,
} from "../store/ducks/tagsPageReducer";
import Error from  '../components/Error/Error'
import { getOtherDataThunk } from "../store/ducks/homepageReducer";
import { getTagParams } from '../shared/params';
import MetaTag from '../components/MetaTag/MetaTag';
import { googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody } from "../helpers";
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';

function TagsPage() {
  const wordsToRemove = [
    "domino",
    "higgs",
    "mod",
    "hack",
    "xnxx",
    "bokeh",
    "museum",
    "aplikasi dewasa",
    "yandex",
    "judi",
    "slot",
    "dewasa",
    "sadap",
    "bobol",
    "cheat",
    "lacak",
    "open bo",
    "pinjol",
    "pinjaman online",
    "cara pinjam",
    "simontok",
    "xnxubd",
    "uncensored",
    "doodstream",
    "dood",
    "18+",
    "xhamstercom",
    "xhamster"
  ];
  const dispatch = useDispatch();

  const { category_name, tag_name } = getTagParams(useParams());

  var tagsTitle = tag_name.trim().replace(/-/g, " ");
  tagsTitle = tagsTitle.replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });

  const tagsPageData = useSelector(
    (state) => state.tagsPageReducer.tagsPageData
  );
  const dataByCategory = useSelector(
    (state) => state.tagsPageReducer.dataByCategory
  );
  const otherData = useSelector((state) => state.homepageReducer.other);

  const selectedTagMeta = useSelector((state) => state.tagsPageReducer.selectedTagMeta);
  const tagMetaTitle = selectedTagMeta?.response?.menu_data?.meta_title ? selectedTagMeta?.response?.menu_data?.meta_title : `Berita ${ tagsTitle } Terbaru Hari Ini | JalanTikus`;
  const tagMetaH1 = selectedTagMeta?.response?.menu_data?.meta_h1 ? selectedTagMeta?.response?.menu_data?.meta_h1 : `Berita ${ tagsTitle } Terbaru Hari Ini | JalanTikus`;
  const tagMetaDescription = selectedTagMeta?.response?.menu_data?.meta_description ? selectedTagMeta?.response?.menu_data?.meta_description : '';
  const tagMetaTop = selectedTagMeta?.response?.menu_data?.meta_top ? selectedTagMeta?.response?.menu_data?.meta_top : '';

  useEffect(() => {
    dispatch(getAllTagsPageDataThunk({ category_name, tag_name }));
    dispatch(getOtherDataThunk());
    googleAnalytics(tagMetaTitle)
    loadAds()
    // eslint-disable-next-line
  }, []);

  const pathName = useLocation().pathname;
  const renderMeta = () => {
    const title = tagMetaTitle;
    const ogTitle = tagMetaTitle;
    const description = tagMetaDescription;
    const url = pathName.slice(1);
    const artikelTerbaru = tagsPageData ? tagsPageData.response.artikelTerbaru.hits.filter(item => {
    return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
  }) : ""
    const robots = 'no-index, follow' 

    const listElement = [];
    for(var i = 0; i<artikelTerbaru.length; i++){
      let object = {
        "@type": "ListItem",
        "item": {
          "@id": "https://jt-staging.maingames.com/"+artikelTerbaru[i].links.detail+"/",
          "name": artikelTerbaru[i].title
        }
      }
      listElement.push(object);
    }

    const schemaContent = [{
      "@context": "https://schema.org",
      "@type":"CollectionPage",
      "description": description,
      "url": "https://jt-staging.maingames.com/"+url,
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": listElement
      }
    }, 
    {
        "@context": "https://schema.org",
        "@type":"BreadcrumbList",
        "@id": "https://jt-staging.maingames.com/"+url,
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "jt-staging.maingames.com", 
          "item": {
            "@id": "https://jt-staging.maingames.com/"
          }
        }, {
          "@type": "ListItem",
          "position": 2,
          "name": "Tags", 
          "item": {
            "@id": "https://jt-staging.maingames.com/tags/"
          }
        }, {
          "@type": "ListItem",
          "position": 3,
          "name": tagsTitle, 
        }]
    }]

    const props = {title, description, url, schemaContent, robots, ogTitle};
    return <MetaTag {...props} />
  }
  
  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`tagspage`);
    
    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

    let slots = []
    if(isMobile){
      // available slot in this page
      slots = [{
        slot: "JT_320x100", // define slot
        id: "adSlotMobTop-category",
        wrapperId: "JT_320x100",
        defaultSize: "320x100" // use the default size 
      },{
        slot: "JT_320x100", // define slot
        id: "adSlotMobSticky-category",
        wrapperId: "JT_320x100-sticky-bottom",
        defaultSize: "320x100"
      },{
        slot: "JT_300x250", // define slot
        id: "adSlotMobscroll1_artikelterbaruMobile",
        wrapperId: "JT_300x250_scroll1_artikelterbaruMobile",
        defaultSize: "300x250"
      }]
    }else{
      // available slot in this page
      slots = [{
        slot: "JT_728x90/JT_728x90_top", // define slot
        id: "adSlotDesktop0", // whatever id
        wrapperId: "JT_728x90_top", // must match with id in html
        defaultSize: "728x90"
      }]
    }
    
    // create ad unit tag head
    for (let i = 0; i < slots.length; i++) {
      _scriptTagHead.text += generateAdUnitTagHead(slots[i])
    }
    $currpage && $currpage.appendChild(_scriptTagHead)    
    
    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
      generateAdUnitTagBody(slots[i])
    }
  }

  return (
    <>
      <main className="main-container" id="tagspage">
        {renderMeta()}
        <div className="container-full-width">
          <AdWrapper id={`JT_728x90_top`} cls={`desktop`} customStyle={{minHeight:'100px'}} />
          <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{minHeight:'100px'}} />

          {category_name && (
            <Breadcrumbs
              categName={category_name}
              className="article-top-info__breadcrumbs"
              articleSlug={tag_name}
            />
          )}
          {/* <div className="tag-page-title">
            {category_name
              ? dataByCategory &&
                dataByCategory.response &&
                `Result for ${tag_name} on ${category_name}`
              : tagsPageData &&
                tagsPageData.response && 
                `Result for ${tag_name}`}
          </div> */}
          <div className="tag-page-container">
            <h1 className="tag-page-title">{ tagMetaH1 }</h1>
            <p className="tag-page-wording">{ tagMetaTop.replace(/(<([^>]+)>)/gi, "") }</p>
          </div>
        </div>
        {
          category_name && dataByCategory &&
          (dataByCategory.code === 200 ? (
          <div className="container-divided tags-page">
            <section className="container-divided_section">
              <div id="initGlia"></div>
              <RecentPosts hasBreadcrumbs containerData={dataByCategory.response} title="artikel terbaru" wordsToRemove={wordsToRemove}/>
              <div className="">
                <AdWrapper id={`JT_970x90_scroll1_artikelterbaru`} cls={`desktop`} customStyle={{marginTop:'8px', minHeight:'0px'}}/>
                <AdWrapper id={`JT_300x250_scroll1_artikelterbaruMobile`} cls={`mobile`} customStyle={{marginTop:'8px', minHeight:'0px'}}/>
              </div>
              <BottomPostsBlock category_name={category_name} tag_name={tag_name} wordsToRemove={wordsToRemove}/>
            </section>
            <aside className="container-divided_aside">
              <PopularToday
                title="artikel terpopuler"
                articles={
                  otherData && otherData.response.populerHariIni.filter(item => {
                    return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
                  }).slice(0, 5)
                }
              />
              {/* <CreateAccount /> */}
              <FollowUs />
              <SidebarBanner />
            </aside>
          </div>
          ) : (
            <div className="container-common">
              <Error code={dataByCategory.code ? dataByCategory.code : '503'}/>
            </div>
          ))
        }
        {
          !category_name && tagsPageData &&
          ( tagsPageData.code === 200 ? (
            <div className="container-divided tags-page">
              <section className="container-divided_section">
                <RecentPosts hasBreadcrumbs containerData={tagsPageData.response.artikelTerbaru} title="artikel terbaru" wordsToRemove={wordsToRemove} />
                <div className="">
                  <AdWrapper id={`JT_970x90_scroll1_artikelterbaru`} cls={`desktop`} customStyle={{marginTop:'8px', minHeight:'0px'}}/>
                  <AdWrapper id={`JT_300x250_scroll1_artikelterbaruMobile`} cls={`mobile`} customStyle={{marginTop:'8px', minHeight:'0px'}}/>
                </div>
                <BottomPostsBlock tag_name={tag_name} wordsToRemove={wordsToRemove}/>
              </section>
              <aside className="container-divided_aside">
                <PopularToday
                  title="artikel terpopuler"
                  articles={
                    otherData && otherData.response.populerHariIni.slice(0, 5)
                  }
                />
                {/* <CreateAccount /> */}
                <FollowUs />
                <SidebarBanner />
              </aside>
            </div>
          ) : (
            <div className="container-common">
              <Error code={tagsPageData.code ? tagsPageData.code : '503'} />
            </div>
          )
          )
        }
        <MobileStickyBottom />
      </main>
    </>
  );
}

export default TagsPage;