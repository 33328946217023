import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import EVItem from "../common/EVItem/EVItem";
import { ElectricVehiclePageService } from "../../http";
import InfiniteScroll from "react-infinite-scroller";
import LoadingMore from "../common/LoadingMore/LoadingMore";

function EVTab(props) {
  const { items } = props;
  const params = useParams();
  const parameter = params.brand ? params.brand : "";
  const vehicleType = params.vehicle_type;
  const [pagination, setPagination] = useState({
    page: 1,
    isMore: false,
    vehicleList: items
  });

  useEffect(() => {
    setPagination({
      page: 1,
      isMore: true,
      vehicleList: []
    })
  }, [params])

  const addMoreVehicles = async () => {
    try {
      let { page, vehicleList, isMore } = pagination;
      const response = await ElectricVehiclePageService.getInfiniteVehicleDataByMerk(vehicleType, parameter, page);
      const vehicles = response.data.response.vehicleList.vehicleList.hits;
      if (vehicles.length > 0) {
        const currPage = response.data.response.vehicleList.vehicleList.page.curr;
        const nextPage = response.data.response.vehicleList.vehicleList.page.next;
        const lastPage = response.data.response.vehicleList.vehicleList.page.last;
        
        if (currPage === lastPage) {
          isMore = false;
        } else {
          page = nextPage;
        }
        vehicleList = [
          ...vehicleList,
          ...vehicles,
        ];
        setPagination({page, isMore, vehicleList});
      }
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        loadMore={addMoreVehicles}
        hasMore={pagination.isMore}
        className="tab-content__wrapper"
      >
        {pagination.vehicleList && 
          pagination.vehicleList.length > 0 ? 
          (pagination.vehicleList.map((item, index) => {
              return <EVItem item={item} key={`item.links.detail-${index}`} />;
          })) : <div>Tidak ada vehicle yang sesuai filter.</div>
        }
      </InfiniteScroll>
      {pagination.isMore && pagination.vehicleList.length > 0 && <LoadingMore />}
    </>
  )
}

export default EVTab;