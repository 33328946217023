import React from "react";
import './ShareWithFriends.css'
import { shareSocial } from "../../../helpers";

import { CopyToClipboard } from "react-copy-to-clipboard";

function ShareWithFriends({link, title}){
    const handleCopy = () => {
        const el = document.getElementsByClassName(`alert-copy-clip`)[0];
        if(el){
            !el.classList.contains(`show`) && el.classList.add(`show`)
            setTimeout(() => el.classList.contains(`show`) && el.classList.remove(`show`), 3000);
        }
    }

    return(
        <>
        <div className="social-wrapper">
            <p>Share with your friends</p>
            <a 
                href={shareSocial({platform:"facebook", link:link})} 
                target="_blank" 
                className="social-icon__wrapper"
                rel="noreferrer"
            >
              <span className="icon-social-fb default"></span>
            </a>
            <a
                href={shareSocial({platform:"twitter", link:link, title:title})} 
                target="_blank" 
                className="social-icon__wrapper"
                rel="noreferrer"
            >
                <span className="icon-social-twitter"></span>
            </a>
            <a
                href={shareSocial({platform:"whatsapp", link:link, title:title})} 
                target="_blank" 
                className="social-icon__wrapper"
                rel="noreferrer"
            >
                <span className="icon-social-whatsapp"></span>
            </a>
            <a
                href={shareSocial({platform:"line", link:link, title:title})}
                target="_blank"
                className="social-icon__wrapper"
                rel="noreferrer"
            >
                <span className="icon-social-line"></span>
            </a>
            <CopyToClipboard text={`${process.env.REACT_APP_WEB_URL}${link}`} onCopy={() => handleCopy()}>
                <span className="social-icon__wrapper">
                    <span className="icon-social-url"></span>
                </span>
            </CopyToClipboard>
        </div>
        </>
    )
}

export default ShareWithFriends