import React from "react"
import '../FixedBannerMobile/FixedBannerMobile.css'
function MobileStickyBottom() {

    const handleClick = () =>{
        const el = document.getElementsByClassName(`ads-sticky-bottom-mobile__wrapper`)[0]
        el.classList.add('d-none')
    }

    return(
        <div className="ads-sticky-bottom-mobile__wrapper">
          <div className="fixed-banner-mobile__close" onClick={() => handleClick()}>x</div>
          <div id="JT_320x100-sticky-bottom"></div>
        </div>
    )
}

export default MobileStickyBottom