import React from 'react';

const withRobotsTag = (WrappedComponent) => {
  return class extends React.Component {
    componentDidMount() {
      const subdomain = window.location.hostname.split('.')[0];

      if (subdomain !== "jalantikus") { // Replace 'subdomain' with your actual subdomain
        const metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'noindex, nofollow'; // Set your desired robots directive here

        document.head.appendChild(metaTag);
      }
    }

    componentWillUnmount() {
      const subdomain = window.location.hostname.split('.')[0];

      if (subdomain !== "jalantikus") { // Replace 'subdomain' with your actual subdomain
        const metaTag = document.querySelector('meta[name="robots"]');
        if (metaTag) {
          document.head.removeChild(metaTag);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withRobotsTag;
