import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import FollowUs from "@components/FollowUs/FollowUs";
// import CreateAccount from "@components/CreateAccount/CreateAccount";
import MostReadPosts from "../components/MostReadPosts/MostReadPosts";
import BottomPostsBlock from "../components/BottomPostsBlock/BottomPostsBlock";
import EditorsPick from "@components/EditorsPick/EditorsPick";
import PopularToday from "@components/PopularToday/PopularToday";
import SidebarBanner from "@components/Banners/SidebarBanner/SidebarBanner";
import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import TopComponent from "@components/TopComponent/TopComponent";
// import PostsContainer from "@components/PostsContainer/PostsContainer";
import RecentPosts from "../components/RecentPosts/RecentPosts";
// import WatchNow from "../components/WatchNow/WatchNow";
import { categoryAndTags } from "./../constants";
import Breadcrumbs from "@components/common/Breadcrumbs/Breadcrumbs";
import ButtonSlider from "@components/common/Sliders/ButtonSlider/ButtonSlider";
// import SubTitle from "@components/common/Titles/SubTitle";
import { breadcrumbDropdownMenuArticle } from "../constants";
import "../assets/css/categoryPage.css";
// import { getWatchNowDataThunk } from "../store/ducks/homepageReducer";
import { getAllCategoryPageDataThunk } from "../store/ducks/categoryPageReducer";
import Error from  '../components/Error/Error';
import MetaTag from '../components/MetaTag/MetaTag';
import { googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody } from '../helpers'
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';
import LastestEV from "../components/LastestEV/LastestEV";

function CategoryPage() {
  const wordsToRemove = [
    "domino",
    "higgs",
    "mod",
    "hack",
    "xnxx",
    "bokeh",
    "museum",
    "aplikasi dewasa",
    "yandex",
    "judi",
    "slot",
    "dewasa",
    "sadap",
    "bobol",
    "cheat",
    "lacak",
    "open bo",
    "pinjol",
    "pinjaman online",
    "cara pinjam",
    "simontok",
    "xnxubd",
    "uncensored",
    "doodstream",
    "dood",
    "18+"
  ];
  const dispatch = useDispatch();
  const { category } = useParams();

  const categoryPageData = useSelector(
    (state) => state.categoryPageReducer.categoryPageData
  );

  const categoryDetail = useSelector(
    (state) => state.categoryPageReducer.categoryDetail
  );
  
  // const gadgetHomeData = useSelector(
  //   (state) => state.gadgetsPageReducer.homeData
  // );

  useEffect(() => {
    const getData = () => {
      dispatch(getAllCategoryPageDataThunk({ category }));
    }
    getData();
    loadAds()
    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if(categoryDetail && categoryDetail.code === 200){
      // send to google analytics
      googleAnalytics(categoryDetail?.response?.menu_data?.meta_title +  ' | JalanTikus')
    }
    // eslint-disable-next-line
  }, [categoryDetail])

  const renderMeta = (categoryDetail) => {

    const url = categoryDetail.response.menu_data.slug;
    const title = categoryDetail.response.menu_data.meta_title +  ' | JalanTikus';
    const description = categoryDetail.response.menu_data.description;
    const ogTitle = title;

    const artikelTerbaru = categoryPageData.response.artikelTerbaru.hits
    const listElement = [];
    for(var i = 0; i<artikelTerbaru.length; i++){
      let object = {
        "@type": "ListItem",
        "item": {
          "@id": "https://jalantikus.com/"+artikelTerbaru[i].links.detail+"/",
          "name": artikelTerbaru[i].title
        }
      }
      listElement.push(object);
    }
    
    const schemaContent = [{
      "@context": "https://schema.org",
      "@type":"CollectionPage",
      "description": description,
      "url": url,
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": listElement
      }
    }]
    
    const props = {url, title, description, schemaContent, ogTitle};

    
    return <MetaTag {...props} />
  }

  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`categorypage`);    

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

    let slots = []
    if(isMobile){
      // available slot in this page
      slots = [{
          slot: "JT_320x100", // define slot
          id: "adSlotMobTop-category",
          wrapperId: "JT_320x100",
          defaultSize: "320x100"
        },{
          slot: "JT_300x250", // define slot
          id: "adSlotMobBelowWatchNow-category",
          wrapperId: "JT_300x250_below_watch_now",
          defaultSize: "300x250"
        },{
          slot: "JT_300x250", // define slot
          id: "adSlotMobBelowEditorsPick-category",
          wrapperId: "JT_300x250_below_editors_pick",
          defaultSize: "300x250"
        },{
          slot: "JT_320x100", // define slot
          id: "adSlotMobSticky-category",
          wrapperId: "JT_320x100-sticky-bottom",
          defaultSize: "320x100"
        }
      ]
      if(category === 'gadgets') 
        slots.push({
          slot: "JT_320x100", // define slot
          id: "adSlotMobBelowHotGadgets",
          wrapperId: "JT_320x100_below_hot_gadgets",
          defaultSize: "320x100"
        })
    }else{
      // available slot in this page
      slots = [{
        slot: "JT_970x90/JT_970x90_top", // define slot
        id: "adSlotDesktop0", // whatever id
        wrapperId: "JT_970x90_top", // must match with id in html
        defaultSize: "970x90"
      }]
    }
    // create ad unit tag head
    for (let i = 0; i < slots.length; i++) {
      _scriptTagHead.text += generateAdUnitTagHead(slots[i])
    }
    $currpage && $currpage.appendChild(_scriptTagHead)    
    
    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
      generateAdUnitTagBody(slots[i])
    }
  }

  return (
    <>
      <main className="main-container" id="categorypage">
        { categoryDetail && categoryDetail.code === 200 && renderMeta(categoryDetail) }
        {categoryPageData && categoryPageData.code !== 200 ? (
          // redirect page
          categoryPageData.code === 301 ? <Redirect to={`/${categoryPageData.response.redirect}`} /> :
          // not found / server error 
          <div className="container-common">
            <Error code={categoryPageData.code ? categoryPageData.code : '503'} />
          </div>
        ) : (
        <>

        <div className="container-full-width">
          <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{minHeight:'100px'}} />
          <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{minHeight:'100px'}} />

          <div className="category-page__breadcrumbs">
            <Breadcrumbs
              categName={category}
              categSlug={category}
              className="article-top-info__breadcrumbs"
              breadcrumbDropdownInfo={breadcrumbDropdownMenuArticle}
              hasDropdown
            />
          </div>

          <h1 className="page-title">
            JalanTikus {category}
            <div className="page-subtitle">
              {categoryDetail &&
                categoryDetail.response &&
                categoryDetail.response.menu_data.meta_title}
            </div>
          </h1>
        </div>
        {/* end of container-full-width */}

        <div className="container-divided">
          <section className="container-divided_section">
            <ButtonSlider items={categoryAndTags[category].tags} />
            { categoryPageData ? (
                <TopComponent category={category} articles={categoryPageData.response.pilihanEditor.filter(item => {
                  return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
                }).slice(0, 3)} />
                ) : (<TopComponent/>)}
            {category === "gadgets"
              // ? ( <> {gadgetHomeData && <HotGadgets data={gadgetHomeData.response} />}
              ? ( <> 
              <div className="ads_slot_mobile_300">
              <div id="JT_320x100_below_hot_gadgets"></div>
            </div></>)
              : categoryPageData &&
                categoryPageData.response.populerMingguIni.length > 5 && (
                  category !== "kendaraan-listrik" &&
                  <MostReadPosts
                    page="categoryPage"
                    isLowercase
                    title="PALING DICARI"
                    articles={categoryPageData.response.populerMingguIni.filter(item => {
                      return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
                    }).slice(
                      3,
                      10
                    )}
                  />
                )}
          </section>
          <aside className="container-divided_aside">
            {categoryPageData &&
              categoryPageData.response.populerMingguIni.length > 0 && (
                <PopularToday
                  title="artikel terpopuler"
                  articles={categoryPageData.response.populerMingguIni.filter(item => {
                    return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
                  }).slice(
                    0,
                    5
                  )}
                />
              )}
            <SidebarBanner />
          </aside>
        </div>
        {/* end of container-divided */}

        <div className="container-full-width">
          <div className="desktop-horizontal-banner1">
            <DesktopHorizontalBanner />
          </div>
        </div>
        {/* end of container-full-width */}

        <div className="container-divided">
          <section className="container-divided_section">
            {/* {watchNowData && (
              <WatchNow
                containerData={watchNowData}
                hasVideoIcon
                title="jt video"
              />
            )} */}
            <div className="ads_slot_mobile_300">
              <div id="JT_300x250_below_watch_now"></div>
            </div>
            <div id="initGlia"></div>
            {categoryPageData &&
              categoryPageData.response.pilihanEditor.length > 3 && (
                <EditorsPick
                  articles={categoryPageData.response.pilihanEditor.filter(item => {
                    return !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
                  }).slice(0, 6)}
                />
              )}
            <div className="ads_slot_mobile_300">
              <div id="JT_300x250_below_editors_pick"></div>
            </div>
          </section>
          <aside className="container-divided_aside">
            {/* <CreateAccount /> */}
            {
              category === 'kendaraan-listrik' ?
                <LastestEV /> :
                <FollowUs />
            }
            <SidebarBanner />
          </aside>
        </div>
        {/* end of container-divided */}

        <div className="container-full-width">
          <div className="desktop-horizontal-banner1">
            <DesktopHorizontalBanner />
          </div>
        </div>
        {/* end of container-full-width */}

        <div className="container-divided container-divided__article-page">
          <section className="container-divided_section">
            <div className="article-page__recent-posts">
              {categoryPageData && (
                <RecentPosts
                    hasBreadcrumbs
                    containerData={categoryPageData.response.artikelTerbaru}
                    title="Artikel Terbaru"
                    wordsToRemove={wordsToRemove}
                  />
              )}
              <div className="article-page__horizontal-banner1">
                <DesktopHorizontalBanner />
              </div>
            </div>
          </section>
          <aside className="container-divided_aside">
            {
              category === 'kendaraan-listrik' ?
                <FollowUs /> : ''
            }
            <SidebarBanner />
          </aside>
        </div>
        {/* end of container-divided */}

        <div className="container-divided">
          <section className="container-divided_section">
            <BottomPostsBlock category_name={category} wordsToRemove={wordsToRemove} />
          </section>
          <aside className="container-divided_aside">
            <SidebarBanner />
          </aside>
        </div>
        {/* end of container-divided */}

        </>
        )}
        <MobileStickyBottom />
      </main>
    </>
  );
}
export default CategoryPage;
