import React, { useEffect } from "react";
import FollowUs from "@components/FollowUs/FollowUs";
import { getArticlePreviewPageDataThunk } from "../store/ducks/articlePageReducer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArticleTopInfo from "../components/ArticleTopInfo/ArticleTopInfo";
import ArticleMainContent from "../components/ArticleMainContent/ArticleMainContent";
import ShareSocials from "@components/ShareSocials/ShareSocials";
import { shareSocial, imgSrc } from '../helpers'
import MetaTag from '../components/MetaTag/MetaTag';
import LoadingMore from '../components/common/LoadingMore/LoadingMore';

function PreviewPage() {
    const history = useParams();
    const dispatch = useDispatch();

    const articlePageData = useSelector(
        (state) => state.articlePageReducer.articlePreviewPageData
    );

    const renderMeta = (articlePageData) => {
        const url = articlePageData.response.links.detail.replace(/\/?$/, '/');
        const title = '[PREVIEW] ' + articlePageData.response.meta_title + ' | JalanTikus';
        const ogTitle = title;
        const description = articlePageData.response.summary;
        const image = imgSrc(articlePageData.response && articlePageData.response?.banner_image, 769, 330);
        const amphtmlUrl = `amp/${url}`;
        const robots = articlePageData.response.robots;
        const props = { url, title, description, image, amphtmlUrl, robots, ogTitle };
        return <MetaTag {...props} />
    }

    useEffect(() => {
        dispatch(getArticlePreviewPageDataThunk(history));
        // eslint-disable-next-line
    }, [history]);

    useEffect(() => {
        if (articlePageData && articlePageData.code === 200) {
            var recentPosts = document.querySelector(".related-article.news-content");
            var recentPostseWapper = document.querySelector(
                ".article-main-content__relate-topics"
            );
            if (recentPosts) recentPostseWapper.insertAdjacentElement("afterbegin", recentPosts);
        }
        // eslint-disable-next-line
    }, [articlePageData]);

    return (
        <main className="main-container main-container__article-page" id="articlepage">
            {articlePageData && articlePageData.code === 200 && renderMeta(articlePageData)}

            <div className="container-full-width">
            </div>
            <div className="container-divided container-divided__article-page">
                <section className="container-divided_section" style={{ minHeight: '100vh' }}>
                    {!articlePageData && <LoadingMore/>}
                    {articlePageData && (
                        <ArticleTopInfo
                            title={articlePageData.response.title}
                            categName={articlePageData.response.category.title}
                            categSlug={articlePageData.response.category.slug}
                            articleSlug={articlePageData.response?.meta_title}
                            ownerScreenname={articlePageData.response.user.name}
                            ownerUrl={articlePageData.response.user.links.profile}
                            date={articlePageData.response.published}
                            primaryTag={articlePageData.response.tags?.primary}
                        />
                    )}
                    {articlePageData && (
                        <ShareSocials
                            title="Share"
                            facebookLink={shareSocial({
                                platform: "facebook",
                                link: articlePageData.response.links.detail,
                            })}
                            twitterLink={shareSocial({
                                platform: "twitter",
                                link: articlePageData.response.links.detail,
                                title: articlePageData.response.title,
                            })}
                            whatsAppLink={shareSocial({
                                platform: "whatsapp",
                                link: articlePageData.response.links.detail,
                                title: articlePageData.response.title,
                            })}
                            lineLink={shareSocial({
                                platform: "line",
                                link: articlePageData.response.links.detail,
                                title: articlePageData.response.title,
                            })}
                            isMobile
                        />
                    )}
                    {articlePageData && (
                        <ArticleMainContent data={articlePageData.response} />
                    )}
                </section>
                <aside className="container-divided_aside">
                    <FollowUs />
                </aside>
            </div>
        </main>
    )
}

export default PreviewPage