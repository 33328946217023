import React, { useEffect, useState } from "react";
import "./SidebarBanner.css";
// import sidebarBannerDesktop from "@assets/images/banners/sidebar-banner-desktop.svg";
import { AdSlot } from 'react-dfp';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function SidebarBanner() {
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(width < 768)
  }, [width])
  return (
    <>
    {!isMobile && 
    <div className="sidebar-banner__wrapper" style={{minWidth:'120px',minHeight: '240px'}}>
        <AdSlot 
          dfpNetworkId="21702367101" 
          adUnit="JT_300x250" 
          sizes={[[320, 480], 'fluid', [120, 600], [160, 600], [250, 360], [120, 240], [300, 600]]}
          targetingArguments={{ jt_ads: ['revamp_test'] }}
        />
    </div>
    }
    </>
  );
}

export default SidebarBanner;
