export const getVideoCategoryMeta = (page) => {
   switch (page) {
      case "gaming":
         return {
            title: 'JTGaming- Kumpulan video berita, ragam info, & tips gaming dari JalanTikus',
            description: 'Kumpulan video ragam unik, tips dan trik gaming, serta berita games terbaru dari JalanTikus',
            ogTitle: 'JTGaming- Kumpulan video berita, ragam info, & tips gaming dari JalanTikus'
         }
      case "techno":
         return {
            title: 'JTTechno - Ragam video berita, tips & trik, tech hack, & update teknologi terbaru dari JalanTikus',
            description: 'Kumpulan video berita teknologi, tips & trik, tech hack paling update dari JalanTikus',
            ogTitle: 'JTTechno - Ragam video berita, tips & trik, tech hack, & update teknologi terbaru dari JalanTikus'
         }
      case "hiburan":
         return {
            title: 'JTHiburan - Kumpulan video hiburan terkini yang unik dan gokil',
            description: 'Kumpulan video yang merangkum berita gokil dan update terbaru dari dunia hiburan',
            ogTitle: 'JTHiburan - Kumpulan video hiburan terkini yang unik dan gokil'
         }
      case "science":
         return {
            title: 'JTScience - Beragam video pengetahuan sains dan teknologi menarik paling update',
            description: 'Nonton beragam video-video sains dan teknologi menarik dari JalanTikus, paling update, loh!',
            ogTitle: 'JTScience - Beragam video pengetahuan sains dan teknologi menarik paling update'
         }
      case "news":
      default:
         return {
            title: 'JTNews - Ragam video yang merangkum berita teknologi, gadget, dan hiburan terupdate',
            description: 'Ragam video yang merangkum berita teknologi, gadget, dan hiburan terbaru yang terpercaya',
            ogTitle: 'JTNews - Ragam video yang merangkum berita teknologi, gadget, dan hiburan terupdate'
         }
   }
};