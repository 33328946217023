import { VideoPageService } from "../../http";

/*ACTION TYPES*/
const GET_LATEST_VIDEO_LIST = "GET_LATEST_VIDEO_LIST";
const GET_TECHNO_VIDEO_LIST = "GET_TECHNO_VIDEO_LIST";
const GET_GAMING_VIDEO_LIST = "GET_GAMING_VIDEO_LIST";
const GET_HIBURAN_VIDEO_LIST = "GET_HIBURAN_VIDEO_LIST";
const GET_NEWS_VIDEO_LIST = "GET_NEWS_VIDEO_LIST";
const GET_POPULAR_VIDEO_LIST = "GET_POPULAR_VIDEO_LIST";

/*ACTION CREATORS*/
export const getLatestVideoList = (payload) => ({
  type: GET_LATEST_VIDEO_LIST,
  payload: payload,
});

export const getTechnoVideoList = (payload) => ({
  type: GET_TECHNO_VIDEO_LIST,
  payload: payload,
});

export const getGamingVideoList = (payload) => ({
  type: GET_GAMING_VIDEO_LIST,
  payload: payload,
});

export const getHiburanVideoList = (payload) => {
  return {
    type: GET_HIBURAN_VIDEO_LIST,
    payload: payload,
  };
};

export const getNewsVideoList = (payload) => {
  return {
    type: GET_NEWS_VIDEO_LIST,
    payload: payload,
  };
};

export const getPopularVideoList = (payload) => {
  return {
    type: GET_POPULAR_VIDEO_LIST,
    payload: payload,
  };
};

/*THUNK*/
export const getLatestVideoListThunk = () => async (dispatch) => {
  try {
    const response = await VideoPageService.getLatestVideoList();
    dispatch(getLatestVideoList(response.data.list));
  } catch (error) {
    // console.log(error);
    dispatch(getLatestVideoList(error.response.data));
  }
};

export const getTechnoVideoListThunk = () => async (dispatch) => {
  try {
    const response = await VideoPageService.getTechnoVideoList();
    dispatch(getTechnoVideoList(response.data.list));
  } catch (error) {
    // console.log(error);
  }
};

export const getGamingVideoListThunk = () => async (dispatch) => {
  try {
    const response = await VideoPageService.getGamingVideoList();
    dispatch(getGamingVideoList(response.data.list));
  } catch (error) {
    // console.log(error);
  }
};

export const getHiburanVideoListThunk = () => async (dispatch) => {
  try {
    const response = await VideoPageService.getHiburanVideoList();
    dispatch(getHiburanVideoList(response.data.list));
  } catch (error) {
    // console.log(error);
  }
};

export const getNewsVideoListThunk = () => async (dispatch) => {
  try {
    const response = await VideoPageService.getNewsVideoList();
    dispatch(getNewsVideoList(response.data.list));
  } catch (error) {
    // console.log(error);
  }
};

export const getPopularVideoListThunk = () => async (dispatch) => {
  try {
    const response = await VideoPageService.getPopularVideoList();
    dispatch(getPopularVideoList(response.data.list));
  } catch (error) {
    // console.log(error);
  }
};

export const getAllVideoPageDataThunk = () => async (dispatch) => {
  try {
    await dispatch(getLatestVideoListThunk());
    await dispatch(getTechnoVideoListThunk());
    await dispatch(getGamingVideoListThunk());
    await dispatch(getNewsVideoListThunk());
    await dispatch(getPopularVideoListThunk());
  } catch (error) {
    // console.log(error);
  }
};

/*INITIAL STATE*/
const initialState = {
  latestVideoList: null,
  technoVideoList: null,
  gamingVideoList: null,
  hiburanVideoList: null,
  newsVideoList: null,
  popularVideoList: null,
};

/*REDUCER*/
function videoPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LATEST_VIDEO_LIST:
      return {
        ...state,
        latestVideoList: action.payload,
      };
    case GET_TECHNO_VIDEO_LIST:
      return {
        ...state,
        technoVideoList: action.payload,
      };

    case GET_GAMING_VIDEO_LIST:
      return {
        ...state,
        gamingVideoList: action.payload,
      };

    case GET_HIBURAN_VIDEO_LIST:
      return {
        ...state,
        hiburanVideoList: action.payload,
      };

    case GET_NEWS_VIDEO_LIST:
      return {
        ...state,
        newsVideoList: action.payload,
      };

    case GET_POPULAR_VIDEO_LIST:
      return {
        ...state,
        popularVideoList: action.payload,
      };
    default:
      return state;
  }
}

export default videoPageReducer;
