import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from 'react-helmet-async';
import './assets/css/bootstrap.min.css';
import './index.css';
import './assets/icomoon/style.css';
import './assets/css/reset.css';
import App from './App';
import store from './store/store';

const rootElement = <React.StrictMode>
  <HelmetProvider>
    <Provider store={ store }>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </HelmetProvider>
</React.StrictMode>;

if (process.env.REACT_APP_ENV === 'development') {
  ReactDOM.render(rootElement,document.getElementById('root'));
} else {
  ReactDOM.hydrate(rootElement,document.getElementById('root'));
}