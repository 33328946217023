import React from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function TopAdWrapper() {
  const { width } = useWindowDimensions();

  const isMobile = width <= 1024 ? true : false
  return (
    <>
      {isMobile && (
        <div className="ad_wrapper homepage mobile" style={{ minWidth: "300px", minHeight: "31px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div id='JT_320x100_top'>
          </div>
        </div >
      )
      }
    </>
  );
}

export default TopAdWrapper;
