import React from "react";
import "./EditorsPickItem.css";
// import { Link } from "react-router-dom";
import { imgSrc, onErrorSrc} from "../../helpers";
import 'lazysizes';

function EditorsPickItem({article}) {
  const category = article && article?.category 
  ? article?.category?.slug 
  : article?.links?.detail.split("/")[0];

  const articleUrl = `/${article?.links?.detail}/`;
  const articleImgUrl = imgSrc(article && article?.banner_image, 0, 200);
  const articleAlt = article && article.title ? article.title : "Default Image";
  const articleTitle = article && article?.title;

  return (
    <div className="editors-pick__item">
      <a href={articleUrl}>
        <div className="editors-pick__image-wrapper">
            <picture>
              <source 
                type="image/webp" 
                srcSet={article && articleImgUrl + ".webp"} 
                data-srcset={article && articleImgUrl + ".webp"} 
              />  
              <img 
                onError={onErrorSrc}
                data-src={articleImgUrl}
                className="lazyload"
                alt={articleAlt}
                height="200"
                />
            </picture>
        </div>
      </a>
      <div className="editors-pick__info-wrapper">
        <h3 className="editors-pick__info-title">
          <a href={articleUrl}>
            <div className="editors-pick__info-title-link">{articleTitle}</div>
          </a>
        </h3>
        <div className="editors-pick__info-category">
          <a href={`/${category}/`}>
            <div className="editors-pick__info-category-link">{category}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

// EditorsPickItem.propTypes = {
//   article: PropTypes.object.isRequired
// };

export default EditorsPickItem;
