import { 
  categoriesQueryName, 
} from '../constants';

const getArticleParams = (params) => {
  return params;
}

const getCategoryParams = (params) => {
  return params;
}

const getDownloadCategoryParams = (params, url) => {
  const uri = new URL(`http://localhost${url}`);
  const query = new URLSearchParams(uri.search);
  const type = uri.pathname.split('/')[1];
  const order = query.get('order') ? query.get('order') : 'popular' ; 
  const license = query.get('license') ? query.get('license') : '' ;
  const category = params ? params.categoryName : '';
  return { type, category, order, license };
}

const getGadgetCategoryParams = (params, url) => {
  const uri = new URL(`http://localhost${url}`);
  const query = new URLSearchParams(uri.search);
  const q = query.get('q') ? query.get('q') : '';
  return { type: params.device, merk: params.brand, q };
}

const getTagParams = (params) => {
  let category_name = null;
  let tag_name = 'all';
  if (params.category !== undefined) {
    category_name = params.category;
  } else if(params.slug !== undefined) {
    tag_name = params.slug;
  }
  // {category_name: "hiburan", tag_name: "out-of-tech"} /hiburan/category/out-of-tech/
  // {category_name: null, tag_name: "all"} /tags/
  // {category_name: null, tag_name: "film-indonesia"} /tags/file-indo/
  return { category_name, tag_name };
}

const getVideoCategoryParams = (params) => {
  return params;
}

const getVideoDetailParams = (params) => {
  return { category: categoriesQueryName[params.category], videoId: params.videoId };;
}

const getParameters = (route, params, url) => {
  if (route.component === undefined) {
    return null;
  }
  
  switch (route.name) {
    case 'article':
      return params;
    case 'category':
      return params;
    case 'download-category':
      return getDownloadCategoryParams(params, url);
    case 'download-detail':
      return params;
    case 'gadget-category':
      return getGadgetCategoryParams(params, url);
    case 'gadget-detail':
      return params;
    case 'search-category':
      return { page: 1, ...params };
    case 'tag':
    case 'tag-slug':
    case 'tag-category':
      return getTagParams(params);
    case 'video-category':
      return params;
    case 'video-detail':
      return getVideoDetailParams(params);
    default:
  }
  return null;
}

export {
  getParameters,
  getDownloadCategoryParams,
  getArticleParams,
  getCategoryParams,
  getTagParams,
  getVideoCategoryParams,
  getVideoDetailParams,
};