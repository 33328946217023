import { CategoryPageService } from "../../http";
import { getGadgetsDataThunk } from "./gadgetsPageReducer";

/* ACTION TYPES */
const GET_CATEGORY_PAGE_DATA = "GET_CATEGORY_PAGE_DATA";
const GET_CATEGORY_DETAIL = "GET_CATEGORY_DETAIL";

/*ACTION CREATORS*/
export const getCategoryPageData = (payload) => ({
  type: GET_CATEGORY_PAGE_DATA,
  payload: payload,
});

export const getCategoryDetail = (payload) => ({
  type: GET_CATEGORY_DETAIL,
  payload: payload,
});

/*THUNK*/
export const getCategoryPageDataThunk = (category) => async (dispatch) => {
  try {
    const response = await CategoryPageService.getData(category);
    dispatch(getCategoryPageData(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getCategoryPageData(error));
  }
};

export const getCategoryDetailThunk = (category) => async (dispatch) => {
  try {
    const response = await CategoryPageService.getDetail(category);
    dispatch(getCategoryDetail(response.data));
  } catch (error) {
    // console.log(error);
  }
};

export const getAllCategoryPageDataThunk = (params) => async (dispatch) => {
  try {
    const { category } = params;
    await dispatch(getCategoryPageDataThunk(category));
    await dispatch(getCategoryDetailThunk(category));
    category === "gadgets" && await dispatch(getGadgetsDataThunk());
  } catch (error) {
    // console.log(error);
  }
};

/*INITIAL STATE*/
const initialState = {
  categoryPageData: null,
  categoryDetail: null,
};

/*REDUCER*/
function categoryPageReducer(state = initialState, action) {
  switch (action.type) {
    /*Get data for category page*/
    case GET_CATEGORY_PAGE_DATA:
      return {
        ...state,
        categoryPageData: action.payload,
      };
    case GET_CATEGORY_DETAIL:
      return {
        ...state,
        categoryDetail: action.payload,
      };
    default:
      return state;
  }
}

export default categoryPageReducer;
