import React from "react";
import "./DAppsHeadlineSliderItem.css";
import { imgSrc } from "../../../../helpers";
import { placeholder } from "../../../../constants/links";

function DAppsHeadlineSliderItem({ article }) {
  const url = article
    ? article.links.detail && `/${article.links.detail}/`
    : `#`;
  const img =
    article && article.banner_image
      ? imgSrc(article.banner_image, 0, 400)
      : placeholder;
  const alt = article && article.title ? article.title : "Default Image";
  const category =
    article &&
    (article.category
      ? article.category.slug
      : article.links.detail.split("/")[0]);
  const title = article && article.title;

  return (
    <div className="dApps-headline__item">
      <a href={url}>
        <div className="dApps-image__wrapper">
          <picture>
            {article && article.banner_image && (
              <source
                type="image/webp"
                srcSet={img + ".webp"}
                data-srcset={img + ".webp"}
              />
            )}
            <img src={img} data-src={img} className="" alt={alt} />
          </picture>
        </div>
      </a>
      <div className="dApps-headline__info">
        <div className="dApps-headline__title">
          <p className="dApps-caption__title">{title}</p>
        </div>
        <div className="dApps-button__item">
          <a href={`/${category}`} className="dApps-button__tag">
            {category}
          </a>
        </div>
      </div>
    </div>
  );
}

export default DAppsHeadlineSliderItem;
