import React from "react";
import './AppsSection.css';
import SubTitle from '../common/Titles/SubTitle';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState } from 'react';
import TabPanelContent from './TabPanelContent'
import SeeAllButton from "@components/common/Buttons/SeeAllButton/SeeAllButton";

function AppsSection({
    tab, 
    description, 
    apps, 
    windowApps, 
    androidApps, 
    title, 
    innerHtmlDesc,
    infiniteScroll,
    seeAllUrl,
    tabList = ['Android', 'Windows'],
    type
}){
    const [activeTab, setActiveTab] = useState('Windows'); 
    let newDescription = (description && description.includes("[OS]")) ? description.replace("[OS]", activeTab) : description
    
    return(
        <div className="apps-section__wrapper">
            <div className={`apps-section__title ${(tab) && 'p-absolute'}`}>
                <SubTitle subTitleText={title}/>
            </div>
            
            {innerHtmlDesc 
            ?(<div className="apps-section__summary d-mobile" dangerouslySetInnerHTML={{ __html: newDescription }}></div>)
            :(<div className="apps-section__summary d-mobile">
                {newDescription}
            </div>)}

            {tab ? (
                <Tabs onSelect={index => setActiveTab(tabList[index])}>
                    <TabList>
                        {
                            tabList.map((tab, index) => (
                                <Tab key={index}>{tab}</Tab>
                            ))
                        }
                    </TabList>
                    {innerHtmlDesc ? (
                        <div className="apps-section__summary d-desktop" dangerouslySetInnerHTML={{ __html: newDescription }}></div>
                    ):(
                        <div className="apps-section__summary d-desktop">
                            {newDescription}
                        </div>
                    )}
                    <TabPanel>
                        <TabPanelContent type={type} items={windowApps} infiniteScroll={infiniteScroll} platform='windows'/>
                    </TabPanel>
                    <TabPanel>
                        <TabPanelContent type={type} items={androidApps} infiniteScroll={infiniteScroll} platform='android'/>
                    </TabPanel>
                    {seeAllUrl && <SeeAllButton url={seeAllUrl}/>}
                </Tabs>
            ) : (
                <TabPanelContent items={apps}/>
            )}
        </div>
    )
}

export default AppsSection