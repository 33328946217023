import { DownloadPageService, TagsPageService } from "../../http";
// ACTION TYPES
const GET_APPS_DATA = "GET_APPS_DATA";
// video-audio
const GET_VIDEO_AUDIO_WINDOWS = "GET_VIDEO_AUDIO_WINDOWS"; 
const GET_VIDEO_AUDIO_ANDROID = "GET_VIDEO_AUDIO_ANDROID"; 
const GET_VIDEO_AUDIO_ARTICLE = "GET_VIDEO_AUDIO_ARTICLE"; 
// browser
const GET_BROWSER_WINDOWS = "GET_BROWSER_WINDOWS"; 
const GET_BROWSER_ANDROID = "GET_BROWSER_ANDROID"; 
const GET_BROWSER_ARTICLE = "GET_BROWSER_ARTICLE"; 
// photo-imaging
const GET_PHOTO_IMAGING_WINDOWS = "GET_PHOTO_IMAGING_WINDOWS"; 
const GET_PHOTO_IMAGING_ANDROID = "GET_PHOTO_IMAGING_ANDROID"; 
const GET_PHOTO_IMAGING_ARTICLE = "GET_PHOTO_IMAGING_ARTICLE"; 
// best-games
const GET_BESTGAMES_WINDOWS = "GET_BESTGAMES_WINDOWS"
const GET_BESTGAMES_ANDROID = "GET_BESTGAMES_ANDROID"
const GET_BESTGAMES_ARTICLE = "GET_BESTGAMES_ARTICLE"
// antivirus
const GET_ANTIVIRUS_WINDOWS = "GET_ANTIVIRUS_WINDOWS"; 
const GET_ANTIVIRUS_ANDROID = "GET_ANTIVIRUS_ANDROID"; 
const GET_ANTIVIRUS_ARTICLE = "GET_ANTIVIRUS_ARTICLE"; 
// utility
const GET_UTILITIES_WINDOWS = "GET_UTILITIES_WINDOWS"; 
const GET_UTILITIES_ANDROID = "GET_UTILITIES_ANDROID"; 
const GET_UTILITIES_ARTICLE = "GET_UTILITIES_ARTICLE"; 
// single app - detail app
const GET_SINGLEAPP_DATA = "GET_SINGLEAPP_DATA";
const GET_SINGLEAPP_COMPONENT_DATA = "GET_SINGLEAPP_COMPONENT_DATA";

// ACTION CREATORS
export const getAppsData = (payload) => ({
    type: GET_APPS_DATA,
    payload: payload,
})
// action browser
export const getBrowserWindows = (payload) => ({
    type: GET_BROWSER_WINDOWS,
    payload: payload,
})
export const getBrowserAndroid = (payload) => ({
    type: GET_BROWSER_ANDROID,
    payload: payload,
})
export const getBrowserArticle = (payload) => ({
    type: GET_BROWSER_ARTICLE,
    payload: payload,
})
// action video-audio
export const getVideoAudioWindows = (payload) => ({
    type: GET_VIDEO_AUDIO_WINDOWS,
    payload: payload,
})
export const getVideoAudioAndroid = (payload) => ({
    type: GET_VIDEO_AUDIO_ANDROID,
    payload: payload,
})
export const getVideoAudioArticle = (payload) => ({
  type: GET_VIDEO_AUDIO_ARTICLE,
  payload: payload,
})
// photo-imaging
export const getPhotoImagingWindows = (payload) => ({
    type: GET_PHOTO_IMAGING_WINDOWS,
    payload: payload,
})
export const getPhotoImagingAndroid = (payload) => ({
    type: GET_PHOTO_IMAGING_ANDROID,
    payload: payload,
})
export const getPhotoImagingArticle = (payload) => ({
  type: GET_PHOTO_IMAGING_ARTICLE,
  payload: payload,
})
// utility
export const getUtilitiesWindows = (payload) => ({
    type: GET_UTILITIES_WINDOWS,
    payload: payload,
})
export const getUtilitiesAndroid = (payload) => ({
    type: GET_UTILITIES_ANDROID,
    payload: payload,
})
export const getUtilitiesArticle = (payload) => ({
  type: GET_UTILITIES_ARTICLE,
  payload: payload,
})
// anti-virus
export const getAntiVirusWindows = (payload) => ({
    type: GET_ANTIVIRUS_WINDOWS,
    payload: payload,
})
export const getAntiVirusAndroid = (payload) => ({
    type: GET_ANTIVIRUS_ANDROID,
    payload: payload,
})
export const getAntiVirusArticle = (payload) => ({
  type: GET_ANTIVIRUS_ARTICLE,
  payload: payload,
})
// best-games
export const getBestGamesWindows = (payload) => ({
    type: GET_BESTGAMES_WINDOWS,
    payload: payload,
})
export const getBestGamesAndroid = (payload) => ({
    type: GET_BESTGAMES_ANDROID,
    payload: payload,
})
export const getBestGamesArticle = (payload) => ({
  type: GET_BESTGAMES_ARTICLE,
  payload: payload,
})
// single app page
export const getSingleAppData = (payload) => ({
    type: GET_SINGLEAPP_DATA,
    payload: payload,
})
export const getSingleAppComponentData = (payload) => ({
    type: GET_SINGLEAPP_COMPONENT_DATA,
    payload: payload,
})

// THUNK
export const getAppsDataThunk = () => async (dispatch) => {
    try {
      const response = await DownloadPageService.getAppsData();
      dispatch(getAppsData(response.data));
    } catch (error) {
      // console.log(error);
      dispatch(getAppsData(error));
    }
};
export const getAppsByCategoryThunk = (category, platform) => async (dispatch) => {
    try {
      const response = await DownloadPageService.getAppsByCategory(category, platform);
      if(platform === "windows"){
        switch (category) {
          case 'browser':
            dispatch(getBrowserWindows(response.data));
            break;
          case 'video-audio':
            dispatch(getVideoAudioWindows(response.data));
            break;
          case 'photo-imaging':
            dispatch(getPhotoImagingWindows(response.data));
            break;
          case 'utilities':
            dispatch(getUtilitiesWindows(response.data));
            break;
          case 'antivirus-security':
            dispatch(getAntiVirusWindows(response.data));
            break;
          default:
            break;
        }
      } else if(platform === "android"){
        switch (category) {
          case 'browser':
            dispatch(getBrowserAndroid(response.data));
            break;
          case 'video-audio':
            dispatch(getVideoAudioAndroid(response.data));
            break;
          case 'photo-imaging':
            dispatch(getPhotoImagingAndroid(response.data));
            break;
          case 'utilities':
            dispatch(getUtilitiesAndroid(response.data));
            break;
          case 'antivirus-security':
            dispatch(getAntiVirusAndroid(response.data));
            break;
          default:
            break;
        }
      }
    } catch (error) {
      // console.log(error);
    }
};
export const getBestGamesThunk = (platform) => async (dispatch) => {
  try {
    const response = await DownloadPageService.getBestGamesData(platform);
    switch (platform) {
      case "windows":
        dispatch(getBestGamesWindows(response.data));
        break;
      case "android":
        dispatch(getBestGamesAndroid(response.data));
        break;
      default:
        break;
    }
  } catch (error) {
    // console.log(error);
  }
};
export const getSingleAppDataThunk = (params) => async (dispatch) => {
  try {
    const { type, slug } = params;
    const response = await DownloadPageService.getSingleAppData(type, slug);
    dispatch(getSingleAppData(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getSingleAppData(error));
  }
};
export const getArticleThunk = (tag_name) => async (dispatch) => {
  try {
    const response = await TagsPageService.getData(tag_name);
    switch (tag_name) {
      case 'browser':
        dispatch(getBrowserArticle(response.data));
        break;
      case 'video-audio':
        dispatch(getVideoAudioArticle(response.data));
        break;
      case 'photo-imaging':
        dispatch(getPhotoImagingArticle(response.data));
        break;
      case 'utilities':
        dispatch(getUtilitiesArticle(response.data));
        break;
      case 'antivirus-security':
        dispatch(getAntiVirusArticle(response.data));
        break;
      case 'best-games':
        dispatch(getBestGamesArticle(response.data));
        break;
      default:
        break;
    }
  } catch (error) {
    // console.log(error);
  }
};

export const getSingleAppComponentDataThunk = (params) => async (dispatch) => {
  try {
    const { type, slug } = params;
    const response = await DownloadPageService.getSingleAppComponentData(type, slug);
    dispatch(getSingleAppComponentData(response.data));
  } catch (error) {
    // console.log(error);
  }
};

export const getAllDownloadPageDataThunk = () => async (dispatch) => {
  try {
    await dispatch(getAppsDataThunk())

    await dispatch(getAppsByCategoryThunk('browser', 'android')) 
    await dispatch(getAppsByCategoryThunk('browser', 'windows'))
    await dispatch(getArticleThunk('browser'))

    await dispatch(getAppsByCategoryThunk('video-audio', 'android'))
    await dispatch(getAppsByCategoryThunk('video-audio', 'windows'))
    await dispatch(getArticleThunk('video-audio'))

    await dispatch(getAppsByCategoryThunk('photo-imaging', 'android'))
    await dispatch(getAppsByCategoryThunk('photo-imaging', 'windows'))
    await dispatch(getArticleThunk('photo-imaging'))

    await dispatch(getBestGamesThunk('android'))
    await dispatch(getBestGamesThunk('windows'))
    await dispatch(getArticleThunk('best-games'))

    await dispatch(getAppsByCategoryThunk('antivirus-security', 'android'))
    await dispatch(getAppsByCategoryThunk('antivirus-security', 'windows'))
    await dispatch(getArticleThunk('antivirus-security'))

    await dispatch(getAppsByCategoryThunk('utilities', 'android'))
    await dispatch(getAppsByCategoryThunk('utilities', 'windows'))
    await dispatch(getArticleThunk('utilities'));
    
  } catch (error) {
    // console.log(error);
  }
};

// INITIAL STATE
const initialState = {
    appsData: null,
    // video-audio
    videoAudioWindows: null,
    videoAudioAndroid: null,
    videoAudioArticle: null,
    // browser
    browserWindows: null,
    browserAndroid: null,
    browserArticle: null,
    // photo-imaging
    photoImagingWindows: null,
    photoImagingAndroid: null,
    photoImagingArticle: null,
    // best-games
    bestGamesWindows:null,
    bestGamesAndroid:null,
    bestGamesArticle:null,
    // antivirus
    antiVirusWindows:null,
    antiVirusAndroid:null,
    antiVirusArticle:null,
    // utility
    utilitiesWindows: null,
    utilitiesAndroid: null,
    utilitiesArticle: null,
    // single app / detail app
    singleAppData:null,
    singleAppComponentData:null
}

// REDUCER
function downloadPageReducer(state = initialState, action){
    switch(action.type){
        case GET_APPS_DATA:
            return {
                ...state,
                appsData: action.payload,
              };

        case GET_BROWSER_WINDOWS:
            return {
                ...state,
                browserWindows: action.payload,
              };
        case GET_BROWSER_ANDROID:
            return {
                ...state,
                browserAndroid: action.payload,
              };
        case GET_BROWSER_ARTICLE:
          return {
              ...state,
              browserArticle: action.payload,
            };
        
        case GET_VIDEO_AUDIO_WINDOWS:
            return {
                ...state,
                videoAudioWindows: action.payload,
              };
        case GET_VIDEO_AUDIO_ANDROID:
            return {
                ...state,
                videoAudioAndroid: action.payload,
              };
        case GET_VIDEO_AUDIO_ARTICLE:
            return {
                ...state,
                videoAudioArticle: action.payload,
              };
        
        case GET_PHOTO_IMAGING_WINDOWS:
            return {
                ...state,
                photoImagingWindows: action.payload,
              };
        case GET_PHOTO_IMAGING_ANDROID:
            return {
                ...state,
                photoImagingAndroid: action.payload,
              };
        case GET_PHOTO_IMAGING_ARTICLE:
            return {
                ...state,
                photoImagingArticle: action.payload,
              };
        
        case GET_UTILITIES_WINDOWS:
            return {
                ...state,
                utilitiesWindows: action.payload,
              };
        case GET_UTILITIES_ANDROID:
            return {
                ...state,
                utilitiesAndroid: action.payload,
              };
        case GET_UTILITIES_ARTICLE:
            return {
                ...state,
                utilitiesArticle: action.payload,
              };
        
        case GET_ANTIVIRUS_WINDOWS:
            return {
                ...state,
                antiVirusWindows: action.payload,
              };
        case GET_ANTIVIRUS_ANDROID:
            return {
                ...state,
                antiVirusAndroid: action.payload,
              };
        case GET_ANTIVIRUS_ARTICLE:
            return {
                ...state,
                antiVirusArticle: action.payload,
              };
        
        case GET_BESTGAMES_WINDOWS:
            return {
                ...state,
                bestGamesWindows: action.payload,
              };
        case GET_BESTGAMES_ANDROID:
            return {
                ...state,
                bestGamesAndroid: action.payload,
              };
        case GET_BESTGAMES_ARTICLE:
            return {
                ...state,
                bestGamesArticle: action.payload,
              };
        
        case GET_SINGLEAPP_DATA:
            return {
                ...state,
                singleAppData: action.payload,
              };
        case GET_SINGLEAPP_COMPONENT_DATA:
            return {
                ...state,
                singleAppComponentData: action.payload,
              };
        default:
            return state;
    }
}

export default downloadPageReducer;