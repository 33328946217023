import { javantApi } from "../constants";

export const HomePageService = {
  getData() {
    return javantApi.get("component/homepage");
  },
  getOtherData() {
    return javantApi.get("component/homepage?component=1");
  },
  getPopulerData() {
    return javantApi.get("component/homepage/populer?component=1");
  },
  getRecentArticles(page) {
    return javantApi.get(`component/homepage?page=${page}`);
  },
  getNewsArticle() {
    return javantApi.get(`component/article/news`);
  },
  getEvArticle() {
    return javantApi.get(`component/article/kendaraan-listrik`);
  },
  getFinanceArticle() {
    return javantApi.get(`component/article/finansial`);
  },
  getTechHackArticle() {
    return javantApi.get(`component/article/tags/tech-hack`);
  },
  getAppsArticle() {
    return javantApi.get(`component/article/tags/apps`);
  },
  getGamesArticle() {
    return javantApi.get(`component/article/tags/games`);
  },
  getAffiliateData() {
    return javantApi.get(`component/affiliate`);
  }
};
