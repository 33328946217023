import React from "react";
import "./OneTag.css";

function OneTag({ title }) {
  return (
    // <a href={link} className="one-tag category-small">
    //   {title}
    // </a>
    <a href={`/tags/${title}/`} className="one-tag category-small">
      {title}
    </a>
  );
}

export default OneTag;
