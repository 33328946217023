import { dailymotionApi, categoriesQueryName } from "../constants";

export const VideoCategoryPageService = {
  getActiveCategoryVideoList(categoryName) {
    return dailymotionApi.get(
      `/videos?owners=x2hdhi4&channel=${categoriesQueryName[categoryName]}&fields=id,title,thumbnail_url,created_time,url,channel.id&sort=recent&limit=11`
    );
  },

  getAllCategoryVideoList() {
    return dailymotionApi.get(
      `/videos?owners=x2hdhi4&sort=recent&fields=id,title,thumbnail_url,created_time,url,channel.id&limit=9&page=2`
    );
  },
  getSearchVideoList(querySearch) {
    return dailymotionApi.get(
      `/videos?owners=x2hdhi4&fields=id,title,description,thumbnail_url,created_time,url,channel.id&search=${querySearch}`
    );
  },
  getFilteredVideoList(category, sortType, page) {
    return dailymotionApi.get(
      `/videos?page=${page}&fields=id,title,thumbnail_url,created_time,url,channel.id&owners=x2hdhi4&search=${category}&sort=${sortType}`
    );
  },

  getInfiniteActiveCategoryVideoData(page, categoryName) {
    return dailymotionApi.get(
      `/videos?owners=x2hdhi4&channel=${categoryName}&fields=id,title,thumbnail_url,created_time,url,channel.id&sort=recent&page=${page}&limit=11`
    );
  },

  getInfiniteAllCategoryVideoData(page) {
    return dailymotionApi.get(
      `/videos?owners=x2hdhi4&sort=recent&fields=id,title,thumbnail_url,created_time,url,channel.id&limit=9&page=${page}`
    );
  },

  getInfiniteVideoData(page, keyword) {
    return dailymotionApi.get(
      `/videos?owners=x2hdhi4&page=${page}&fields=id,title,description,thumbnail_url,created_time,url,channel.id&search=${keyword}`
    );
  },
};
