import './DownloadStatusBox.css'
import { useLocation, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DownloadDetailPageService } from '../../../http';

function DownloadStatusBox({data}){
    const [timer, setTimer] = useState(5)
    const [restart, setRestart] = useState(false)

    const params = useParams();

    const search = window.location.search;
    const searchParams = new URLSearchParams(search);

    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;
    const pathArray = pathname.split("/");
    const type = pathArray[1];
    const isHandler = pathArray[2]==='handler'

    const [ironsource, setIronsource] = useState(true)
    const [ironsource_uri, setIronsource_uri] = useState(false)

    useEffect(() => {
        (searchParams.get('no-downloader')!=="undefined") 
        ? setIronsource(false)
        : setIronsource(true)

        getIronsource()
        // eslint-disable-next-line
    },[history])

    useEffect(() => {
        const timerDownload=setTimeout(() => {
            if (timer > 1) {
                setTimer(timer - 1);
            }else{
                setRestart(true)
                clearTimeout(timerDownload);
                if(data && data.code === 404){
                    window.location.replace(`/${type}`)
                }
                if(data && data.code === 200){
                    if(isHandler){
                        document.getElementById('DLStart').click();
                    }else{
                        const dataFile = (!ironsource && !ironsource_uri) || data?.response?.platform.id === "5" ? 
                            "http://dde9361.jalantikus.com/" + (data?.response?.version?.links?.uri || data?.response?.version?.links?.external_url)
                            : ironsource_uri
    
                        if (dataFile !== "") {
                            window.location = dataFile;
                        }
                    }
                }
            }
        }, 1000);
          // Clear timeout if the component is unmounted
        return () => clearTimeout(timerDownload);
    })

    const getIronsource = async () => {
        try{
            if (ironsource && data && data.response.platform.id !== "5") {
                const response = await DownloadDetailPageService.getDataByIdVersionIronsource(params.slug, params.version)
                if(response.data.response.ironsource !== "undefined"){
                    setIronsource_uri(response.data.response.ironsource)
                }
            }
        } catch (e) {
            // console.log(e)
        }
    }
    
    const handleOnClick = () => {
        if(restart){
            if(isHandler){
                document.getElementById('DLStart').click();
            }else{
                const dataFile = (!ironsource && !ironsource_uri) || data.response.platform.id === "5" ? 
                    "http://dde9361.jalantikus.com/" + (data.response.version.links.uri || data.response.version.links.external_url)
                    : ironsource_uri

                if (dataFile !== "") {
                    window.location = dataFile;
                }
            }
        }
    }
    
    const notFound = data && data.code === 404 && data.description
    
    return(
        <div className="download-status__container">
            {notFound ? (
                <div className="download-status__text-wrapper">
                    Download link not found. Redirecting you back in {timer}.
                </div>
            ) : (
            <div className="download-status__text-wrapper">
                Your download begin in {timer}. If it doesn’t, <a href="/#" className="download-status__restart-download" onClick={handleOnClick}>restart download</a> 
                {isHandler && <a href={data.response.version.links.external_url}
                            target="_blank"
                            rel="nofollow noreferrer"
                            style={{ display: "none" }} id="DLStart">&npbsp;</a>}
            </div>)}
        </div>
    )
}

export default DownloadStatusBox