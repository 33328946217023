import { useEffect, useRef, useState } from "react";

function useCustomLazy() {
  const imageRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const { current } = imageRef;
    if (current) {
      const listenLoad = () => {
        if (!current) return;
        setLoaded(true);
      };

      const errorHandler = () => {
        setHasError(true);
      };

      current.addEventListener("load", listenLoad);
      current.addEventListener("error", errorHandler);
      return () => {
        if (!current) return;
        current.removeEventListener("load", listenLoad);
        current.removeEventListener("error", errorHandler);
      };
    }
    return undefined;
  }, [imageRef, setLoaded]);
  return {
    imageRef,
    loaded,
    hasError,
  };
}

export default useCustomLazy;
