import "./Filter.css";
import React, { useRef } from "react";
import SelectOption from "../SelectOption/SelectOption";
import CategorySlider from "../Sliders/CategorySlider/CategorySlider";
import TextInputSearch from "../TextInputSearch/TextInputSearch";
import {
  optionsPopular,
  optionsTime,
  optionsLicense,
} from "../../../constants";
import {
  videoCategory,
  gadgetMerk,
  appCategories,
  gamesCategories,
} from "../../../constants";
import {
  settingsMerk,
  settingsVideoCategory,
  settingsDownloadCategory,
} from "../../../constants";

function Filter({
  dataList,
  page,
  inputPlaceholder,
  noSelect,
  type,
  selected,
  onKeyDownCallback,
  onClickSuggestion,
  onClickSearchIcon = () => {},
  suggestionList,
  onChangeCallbackLeft,
  onChangeCallbackRight,
  typeDownload,
  optionLeftDefault,
  optionRightDefault,
  categoriesData=null
}) {
  const rightOption = useRef([]);
  let categories;
  let settings = settingsVideoCategory;

  if (categoriesData !== null) {
    categories = categoriesData;
  } else {
    categories = videoCategory;
  }

  switch (page) {
    case "gadget":
      categories = gadgetMerk(type);
      settings = settingsMerk;
      break;
    case "video":
      categories = videoCategory;
      settings = settingsVideoCategory;
      rightOption.current = optionsTime;
      break;
    case "download":
      categories = typeDownload === "apps" ? appCategories : gamesCategories;
      settings = settingsDownloadCategory;
      rightOption.current = optionsLicense;
      break;
    case "search":
    default:
      break;
  }

  return (
    <>
      <TextInputSearch
        onClickSearchIcon={onClickSearchIcon}
        dataList={dataList}
        page={page}
        placeholder={inputPlaceholder}
        onKeyDownCallback={onKeyDownCallback}
        onClickSuggestion={onClickSuggestion}
        suggestionList={suggestionList}
      />

      <div className="bottom-filter__wrapper">
        <div className={`select__wrapper item-first ${noSelect && "d-none"}`}>
          <SelectOption
            placeholder="Popularity"
            options={optionsPopular}
            onChangeCallback={onChangeCallbackLeft}
            selectedValue={optionLeftDefault}
          />
        </div>

        {
          categories?.length > 0 &&
          <div className={`category-wrapper ${noSelect && "full-width"}`}>
            <CategorySlider
              categories={categories}
              settings={settings}
              selected={selected}
            />
          </div>
        }

        <div className={`select__wrapper item-second ${noSelect && "d-none"}`}>
          <SelectOption
            placeholder={`${page === "download" ? `License` : `Time`}`}
            options={rightOption.current}
            onChangeCallback={onChangeCallbackRight}
            selectedValue={optionRightDefault}
          />
        </div>
      </div>
    </>
  );
}

export default Filter;
