import ArticlePage from "@pages/ArticlePage";
import CategoryPage from "@pages/CategoryPage";
import DownloadPage from "@pages/DownloadPage";
import DownloadCategoryPage from "@pages/DownloadCategoryPage";
import DownloadDetailPage from "@pages/DownloadDetailPage";
import DownloadHandlerPage from "@pages/DownloadHandlerPage";
import ErrorPage from "@pages/ErrorPage";
import FaqPage from "@pages/FaqPage";
import Homepage from "@pages/Homepage";
import PartnershipPage from "@pages/PartnershipPage";
import SearchPage from "@pages/SearchPage";
import TagsPage from "@pages/TagsPage";
import VideoCategoryPage from "@pages/VideoCategoryPage";
import HandlerPage from "@pages/HandlerPage";
import PreviewPage from "@pages/PreviewPage";
import NFTPage from "@pages/NFTPage";
import AuthorPage from "@pages/AuthorPage";
import AdsFormPage from "@pages/AdsFormPage";
// import WorldCupPage from "@pages/WorldCupPage";
// import WorldCupDetailPage from "@pages/WorldCupDetailPage";
// import WorldCupMatchDetailPage from "@pages/WorldCupMatchDetailPage";
// import DAppPage from "@pages/DAppPage";
// import GameItemPage from "@pages/GameItemPage";
import { getArticlePageDataThunk, getSearchPageDataThunk } from "../store/ducks/articlePageReducer";
import { getAllCategoryPageDataThunk } from "../store/ducks/categoryPageReducer";
import { getAllDownloadPageDataThunk } from "../store/ducks/downloadPageReducer";
import { getAllDownloadCategoryPageDataThunk } from "../store/ducks/downloadCategoryPageReducer";
import { getSingleAppDataThunk, getSingleAppComponentDataThunk, getAppsDataThunk } from "../store/ducks/downloadPageReducer";
// import { getAllDataHomeThunk, getOtherDataThunk, getHomepageDataThunk, getWatchNowDataThunk } from '../store/ducks/homepageReducer';
import { getAllDataHomeThunk, getOtherDataThunk, getHomepageDataThunk } from '../store/ducks/homepageReducer';
import { getAllTagsPageDataThunk } from '../store/ducks/tagsPageReducer';
import { clearHasMore, getAllVideoCategoryPageDataThunk } from '../store/ducks/videoCategoryPageReducer';
import { assetsUrl } from "../constants";
import EVCategoryPage from "../pages/EVCategoryPage";
import EVDetailPage from "../pages/EVDetailPage";
import { getVehicleDetailDataThunk, getVehicleSearchDataThunk } from "../store/ducks/electricVehiclePageReducer";


const routes = [
  {
    path: "/",
    component: Homepage,
    exact: true,
    dispatches: [getAllDataHomeThunk],
    name: 'home',
  },
  {
    path: "/partnership/",
    component: PartnershipPage,
    exact: true,
    name: 'partnership',
  },
  {
    path: "/form-enquiry/",
    component: AdsFormPage,
    exact: true,
    name: 'form-enquiry',
  },
  {
    path: ["/eula/", '/privacy/', '/contact/', '/faq/', '/tentang-kami/', '/disclaimer/', '/pedoman/'],
    component: FaqPage,
    exact: true,
    name: 'static',
  },
  {
    path: "/error/",
    component: ErrorPage,
    exact: true,
    name: 'error',
  },
  {
    path: '/category/:categoryName/',
    component: VideoCategoryPage,
    exact: true,
    name: 'video-category',
    dispatches: [clearHasMore, getAllVideoCategoryPageDataThunk],
  },
  {
    path: '/:category(finansial|gadgets|gaming|hiburan|iptek|lagu|news|quotes|ragam|telko|tips|sport|nfts|kendaraan-listrik|web3|healthtech|otomotif)/',
    component: CategoryPage,
    exact: true,
    name: 'category',
    // dispatches: [getWatchNowDataThunk, getAllCategoryPageDataThunk],
    dispatches: [getAllCategoryPageDataThunk],
  },
  {
    path: '/author/:userName/',
    component: AuthorPage,
    exact: true,
    name: 'author',
    dispatches: []
  },
  {
    path: '/search/:category/:query?/',
    component: SearchPage,
    exact: true,
    name: 'search-category',
    dispatches: [getSearchPageDataThunk]
  },
  {
    path: '/search/',
    redirect: '/search/articles/',
    exact: true,
    name: 'search',
  },
  {
    path: ['/tags/:slug?/'],
    component: TagsPage,
    exact: true,
    dispatches: [getAllTagsPageDataThunk, getOtherDataThunk],
    name: 'tag-slug',
  },
  {
    path: '/:category(hiburan|iptek|lagu|quotes|ragam|tips|kendaraan-listrik|web3|healthtech|otomotif)/out-of-tech/',
    component: TagsPage,
    exact: true,
    dispatches: [getAllTagsPageDataThunk, getOtherDataThunk],
    name: 'tag-category',
  },
  // {
  //   path: '/video/:category/:videoId/:videoTitle/',
  //   component: VideoDetailPage,
  //   exact: true,
  //   dispatches: [getAllVideoDetailPageDataThunk],
  //   name: 'video-detail',
  // },
  // {
  //   path: '/video/',
  //   component: VideoPage,
  //   exact: true,
  //   name: 'video',
  //   dispatches: [getAllVideoPageDataThunk],
  // },
  // {
  //   path: '/gadgets/:device(smartphone|tablet)/:brand?',
  //   component: GadgetCategoryPage,
  //   exact: true,
  //   name: 'gadget-category',
  //   dispatches: [getGadgetSearchDataThunk],
  // },
  // {
  //   path: '/gadgets/:device(smartphone|tablet)/:brand/:slug/',
  //   component: GadgetDetailPage,
  //   exact: true,
  //   name: 'gadget-detail',
  //   dispatches: [getGadgetDetailDataThunk]
  // },
  {
    path: '/kendaraan-listrik/:vehicle_type(mobil|motor)/:brand?',
    component: EVCategoryPage,
    exact: true,
    name: 'ev-category',
    dispatches: [getVehicleSearchDataThunk],
  },
  {
    path: '/kendaraan-listrik/:vehicle_type(mobil|motor)/:brand/:slug/',
    component: EVDetailPage,
    exact: true,
    name: 'ev-detail',
    dispatches: [getVehicleDetailDataThunk]
  },
  {
    path: [
      '/download/',
      '/apps/',
      '/games/',
    ],
    component: DownloadPage,
    exact: true,
    name: 'download',
    dispatches: [getAllDownloadPageDataThunk]
  },
  {
    path: '/:type(apps|games)/category/:categoryName?/',
    component: DownloadCategoryPage,
    exact: true,
    name: 'download-category',
    dispatches: [getAllDownloadCategoryPageDataThunk]
  },
  {
    path: '/:type(apps|games)/:slug/',
    component: DownloadDetailPage,
    exact: true,
    name: 'download-detail',
    dispatches: [getAppsDataThunk, getSingleAppDataThunk, getSingleAppComponentDataThunk]
  },
  {
    path: '/:type(apps|games)/:action(handler|download)/:slug/:version?/',
    component: DownloadHandlerPage,
    exact: true,
    name: 'download-handler',
  },
  {
    path: '/:category(finansial|gadgets|gaming|hiburan|iptek|lagu|news|quotes|ragam|telko|tips|gokil|sport|nfts|kendaraan-listrik|web3|healthtech|otomotif)/:id(\\d+)?/:slug/:page?',
    component: ArticlePage,
    exact: true,
    dispatches: [getArticlePageDataThunk, getOtherDataThunk, getHomepageDataThunk],
    name: 'article',
    preload: {
      "image": (state) => {
        let data = state?.articlePageReducer?.articlePageData?.response
        return [
          `${assetsUrl}${data?.banner_image?.base_url}0/200/${data?.banner_image?.file}`,
          `${assetsUrl}${data?.banner_image?.base_url}769/330/${data?.banner_image?.file}`
        ]
      }
    }
  },
  {
    path: '/handler/:slug/',
    component: HandlerPage,
    exact: true,
    name: 'handler',
  },
  {
    path: '/preview/:category(finansial|gadgets|gaming|hiburan|iptek|lagu|news|quotes|ragam|telko|tips|gokil|sport|nfts|kendaraan-listrik|web3|healthtech|otomotif)/:id(\\d+)?/:slug/',
    component: PreviewPage,
    exact: true,
    name: 'preview',
  },
  {
    path: "/nft",
    component: NFTPage,
    exact: true,
    name: "NFT",
  },
  // {
  //   path: "/dapp-page",
  //   component: DAppPage,
  //   exact: true,
  //   name: "dApp",
  // },
  // {
  //   path: "/game-item",
  //   component: GameItemPage,
  //   exact: true,
  //   name: "GameItemPage",
  // },
  // {
  //   path: "/world-cup-2022/",
  //   component: WorldCupPage,
  //   exact: true,
  //   name: 'world-cup-2022',
  // },
  // {
  //   path: "/world-cup-2022/:country(england|spain|brazil|argentina|netherlands|germany|portugal|france)",
  //   component: WorldCupDetailPage,
  //   exact: true,
  //   name: 'world-cup-detail-2022',
  // },
  // {
  //   path: "/world-cup-2022/match/:matchid",
  //   component: WorldCupMatchDetailPage,
  //   exact: true,
  //   name: 'world-cup-match-detail-2022',
  // },
  // {
  //   component: ErrorPage,
  // },
];

export default routes;