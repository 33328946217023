export const optionsPopular = [
  { value: "Popular", label: "Popular" },
  { value: "Newest", label: "Newest" },
];

export const optionsTime = [
  { value: "Today", label: "Today" },
  { value: "Last Week", label: "Last Week" },
  { value: "Last Month", label: "Last Month" },
];

export const optionsLicense = [
  { value: "freeware", label: "Gratis" },
  { value: "shareware", label: "Versi Terbatas" },
  { value: "opensource", label: "Open Source" },
  { value: "beta", label: "Beta" },
  { value: "trial", label: "Versi Percobaan" },
  { value: "paid", label: "Versi Berbayar" },
];
