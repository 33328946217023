import { javantApi } from "../constants";

export const CategoryPageService = {
    getData(category) {
        return javantApi.get(`component/article/${category}`); 
    },
    getDetail(category){
        return javantApi.get(`component/header/data?slug=${category}/`);
    },
    getInfiniteArticles(category_name, page){
        return javantApi.get(`component/article/${category_name}?page=${page}`)
    }
}