import React from "react"

function AdWrapper({id, cls, customStyle}){
    return(
        <>
        <div className={`ad_wrapper ${cls ? cls : ''}`} style={customStyle}>
            <div id={id}></div>
        </div>
        </>
    )
}

export default AdWrapper