import React from "react";
import "./SelectOption.css";
import Select from "react-select";

function SelectOption({
  options,
  placeholder,
  onChangeCallback,
  defaultValue,
  selectedValue
}) {
  const JTStyles = {
    option: (provided, state) => ({
      ...provided,
      background: "none",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#212936",
      width: "150px",
      "&:hover": {
        background: "#D6DEE3",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "150px",
    }),
    indicatorSeparator: (styles) => ({ display: "none" }),
    control: (base, state) => ({
      ...base,
      border: "1px solid #D6DEE3",
      borderRadius: "8px",
      boxShadow: "none",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#212936",
      width: "150px",
      "&:hover": {
        border: "1px solid #D6DEE3",
      },
    }),
  };

  return (
    <>
    {selectedValue==='default' 
    ? (
      <Select
        styles={JTStyles}
        options={options}
        placeholder={placeholder}
        isSearchable={false}
        onChange={onChangeCallback}
        defaultValue={defaultValue}
        value={null}
      />
      ) : (
        <Select
          styles={JTStyles}
          options={options}
          placeholder={placeholder}
          isSearchable={false}
          onChange={onChangeCallback}
          defaultValue={defaultValue}
        />
    )}
    </>
  );
}

export default SelectOption;
