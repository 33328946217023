import { DAppPageService } from "../../http/DAppPageService";

/* ACTION TYPES */
const GET_DAPP_ARTICLE_DATA = "GET_DAPP_ARTICLE_DATA";

/*ACTION CREATORS*/
export const getDAppPageData = (payload) => ({
  type: GET_DAPP_ARTICLE_DATA,
  payload: payload,
});

/*THUNK*/
export const getDAppPageDataThunk = () => async (dispatch) => {
  try {
    const response = await DAppPageService.getArticle();
    dispatch(getDAppPageData(response.data));
  } catch (error) {
    dispatch(getDAppPageData(error));
  }
};

/*INITIAL STATE*/
const initialState = {
  dappPageData: null,
};

/*REDUCER*/
function dappPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DAPP_ARTICLE_DATA:
      return {
        ...state,
        dappPageData: action.payload,
      };
    default:
      return state;
  }
}

export default dappPageReducer;
