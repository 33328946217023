import React from "react";
import "./SeeAllButton.css";
// import { Link } from "react-router-dom";

function SeeAllButton({ videoCategoryLink, url }) {
  const linkTo = videoCategoryLink ? `/category${videoCategoryLink}` : url;
  return (
    <a href={linkTo}>
      <button className="see-all__btn category-small ">See all</button>
    </a>
  );
}
export default SeeAllButton;
