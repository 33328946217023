export const videoCategory = [
  {
    linkName: "All",
    alias:"all",
    url: "/category/all/",
  },
  {
    linkName: "JTTechno",
    alias:"techno",
    url: "/category/techno/",
  },
  {
    linkName: "JTGaming",
    alias:"gaming",
    url: "/category/gaming/",
  },
  {
    linkName: "JTHiburan",
    alias:"hiburan",
    url: "/category/hiburan/",
  },
  {
    linkName: "JTNews",
    alias:"news",
    url: "/category/news/",
  },
  // {
  //   linkName: "JTScience",
  //   alias:"science",
  //   url: "#",
  // },
];
export const categories = {
  techno: "JTTechno",
  gaming: "JTGaming",
  hiburan: "JTHiburan",
  news: "JTNews",
  all: "All",
};

export const categoriesQueryName = {
  techno: "tech",
  gaming: "videogames",
  hiburan: "lifestyle",
  news: "news",
};

export const categoryNameObj = {
  tech: "JTTechno",
  videogames: "JTGaming",
  news: "JTNews",
  lifestyle: "JTHiburan",
};

export const categoryUrlObj = {
  tech: "techno",
  videogames: "gaming",
  news: "news",
  lifestyle: "hiburan",
  school: "science",
};
