import React, { useState } from "react";
import "./BottomPostsBlock.css";
import PostBlockWithBreadcrumb from "../PostBlockWithBreadcrumb/PostBlockWithBreadcrumb";
import MobileBetweenPosts from "../../components/Banners/MobileBetweenPosts/MobileBetweenPosts";
import DesktopBetweenPosts from "../../components/Banners/DesktopBetweenPosts/DesktopBetweenPosts";
import {CategoryPageService, TagsPageService, HomePageService} from '../../http';
// eslint-disable-next-line 
import InfiniteScroll from "react-infinite-scroller"; // needed even the variable not used

let page = 2;
let isBusy = false;
let maxAds = 3;
function BottomPostsBlock({ category_name = null, tag_name = null, wordsToRemove }) {
  // const [preloader, setPreloader] = useState(false);
  const [cacheArray, setCacheArray] = useState([]);
  const [infiniteArray, setInfiniteArray] = useState([]);

  const addMorePosts = async () => {
    try {
      // setPreloader(true);

      if (!cacheArray.length) {
        let hits;
        if (category_name && tag_name) { // category and tag (from page tag)
          const response = await TagsPageService.getInfiniteArticlesByCategoryTag(category_name, page);
          hits = response.data.response.hits.filter(item => {
            return !wordsToRemove || !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
          });
        } else if (category_name) { // category only (from page category)
          const response = await CategoryPageService.getInfiniteArticles(category_name, page);
          hits = response.data.response.artikelTerbaru.hits.filter(item => {
            return !wordsToRemove || !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
          });
        } else if (tag_name) { // tag only (from page tag)
          const response = await TagsPageService.getInfiniteArticles(tag_name, page);
          hits = response.data.response.artikelTerbaru.hits.filter(item => {
            return !wordsToRemove || !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
          });
        } else { // from common page
          const response = await HomePageService.getRecentArticles(page);
          hits = response.data.response.masihHangat.hits.filter(item => {
            return !wordsToRemove || !wordsToRemove.some(word => item.title.toLowerCase().includes(word));
          });
        }

        if (hits !== undefined) {
          setInfiniteArray((prev) => {
            return [...prev, ...hits.splice(0, 5),
            ];
          });
          setCacheArray(hits.splice(0));
          page++;
        }
      } else {
        setInfiniteArray((prev) => {
          return [...prev, ...cacheArray];
        });
        setCacheArray([]);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const trottle = () => {
    if (isBusy) {
      return;
    }
    isBusy = true;
    setTimeout(async () => {
      await addMorePosts();
      isBusy = false;
    }, 100);
  };

  return (
    <>
      {/* <InfiniteScroll
        pageStart={0}
        loadMore={trottle}
        hasMore={true || false}
        className="infinite-scroll-block"
        id="infinite-scroll-block"
      > */}
        {infiniteArray.map((item, index) => {
          if ((index + 1) % 10 === 0 && index !== 0) {
            return (
              <div key={index}>
                <PostBlockWithBreadcrumb data={item} />
                { ((index + 1) < maxAds * 10) && 
                  <>
                  <MobileBetweenPosts idName={`infinite-recent-${index}`} parentId={`infinite-scroll-block`} currentAdIndex={(index + 1) / 10 + 1}/>
                  <DesktopBetweenPosts idName={`infinite-recent-${index}`} parentId={`infinite-scroll-block`} currentAdIndex={(index + 1) / 10 + 1}/>
                  </>
                }
              </div>
            );
          } else {
            return <PostBlockWithBreadcrumb key={index} data={item} />;
          }
        })}
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <button id="load-more-btn" onClick={trottle}>
            Muat Lagi
          </button>
        </div>
      {/* </InfiniteScroll> */}
      {/* <Preloader preloader={preloader} /> */}
    </>
  );
}

export default BottomPostsBlock;
