import React from "react";
import logo from "@assets/images/jalantikus-logo-black.png";
// import { Link } from "react-router-dom";

function Logo(props) {
  const isMobile = props.isMobile;
  return (
    <>
      <a href="/">
        {isMobile ? (
          <img src={logo} alt="logo" />
        ):(
          <img src={logo} alt="logo" style={{height:"51px"}} />
        )}
      </a>
    </>
  );
}

export default Logo;
