import { GadgetsPageService } from "../../http";

/* ACTION TYPES */
const GET_HOME_DATA = "GET_HOME_DATA";
const GET_GADGET_DETAIL_DATA = "GET_GADGET_DETAIL_DATA";
const GET_SEARCH_DATA = "GET_SEARCH_DATA";
const GET_SUGGESSTION_DATA = "GET_SUGGESSTION_DATA";

const GET_INFINITE_GADGET_DATA = "GET_INFINITE_GADGET_DATA";
const CLEAR_SEARCH_GADGET_DATA = "CLEAR_SEARCH_GADGET_DATA";

/*ACTION CREATORS*/
export const getHomeData = (payload) => ({
  type: GET_HOME_DATA,
  payload: payload,
});
export const getDetailData = (payload) => ({
  type: GET_GADGET_DETAIL_DATA,
  payload: payload,
});
export const getSearchData = (payload) => ({
  type: GET_SEARCH_DATA,
  payload: payload,
});
export const getSuggestionData = (payload) => ({
  type: GET_SUGGESSTION_DATA,
  payload: payload,
});

export const getInfiniteGadgetData = (payload) => ({
  type: GET_INFINITE_GADGET_DATA,
  payload: payload,
});

export const clearSearchGadgetData = (payload) => ({
  type: CLEAR_SEARCH_GADGET_DATA,
  payload: payload,
});

/*THUNK*/
export const getGadgetsDataThunk = () => async (dispatch) => {
  try {
    const response = await GadgetsPageService.getHomeData();
    dispatch(getHomeData(response.data));
  } catch (error) {
    // console.log(error);
  }
};
export const getGadgetDetailDataThunk = (params) => async (dispatch) => {
  try {
    const { slug } = params;
    const response = await GadgetsPageService.getDetailData(slug);
    dispatch(getDetailData(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getDetailData(error));
  }
};
export const getGadgetSearchDataThunk = (params) => async (dispatch) => {
  try {
    const { type, merk, q } = params;
    const response = await GadgetsPageService.getGadgetSearch(type, merk, q);
    dispatch(getSearchData(response.data));
  } catch (error) {
    // console.log(error);
    dispatch(getSearchData(error));
  }
};
export const getSuggestionDataThunk = (q, item, type) => async (dispatch) => {
  try {
    const response = await GadgetsPageService.getSuggestionData(q, item, type);
    dispatch(getSuggestionData(response.data));
  } catch (error) {
    // console.log(error);
  }
};

export const getInfiniteGadgetDataThunk =
  (type, page, keyword) => async (dispatch) => {
    try {
      const response = await GadgetsPageService.getInfiniteGadgetData(
        type, 
        page,
        keyword
      );
      dispatch(
        getInfiniteGadgetData({
          list: response.data.response.gadgetList.gadgetList.hits,
          total: response.data.response.gadgetList.gadgetList.size,
          hasMore: (response.data.response.gadgetList.gadgetList.page.curr < response.data.response.gadgetList.gadgetList.page.last) ? true : false 
        })
      );
    } catch (error) {
      // console.log(error);
    }
  };

const initialState = {
  homeData: null,
  gadgetDetail: null,
  searchData: null,
  suggestionData: null,
  searchGadgetData: null,
  totalGadgetData: null,
  isHasMoreGadget: true,
};

/*REDUCER*/
function gadgetsPageReducer(state = initialState, action) {
  switch (action.type) {
    /*Get homedata for gadget*/
    case GET_HOME_DATA:
      return {
        ...state,
        homeData: action.payload,
      };
    /* Get gadget detail data */
    case GET_GADGET_DETAIL_DATA:
      return {
        ...state,
        gadgetDetail: action.payload,
      };
    case GET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      };

    case GET_INFINITE_GADGET_DATA:
      return {
        ...state,
        searchGadgetData: [
          ...(state.searchGadgetData || []),
          ...(action.payload.list || []),
        ],
        totalGadgetData: action.payload.total,
        isHasMoreGadget: action.payload.hasMore
      };
    
    case CLEAR_SEARCH_GADGET_DATA:
      return {
        ...state,
        searchGadgetData: [],
      };

    case GET_SUGGESSTION_DATA:
      return {
        ...state,
        suggestionData: action.payload,
      };
    default:
      return state;
  }
}

export default gadgetsPageReducer;
