import { javantApi } from "../constants";

export const TagsPageService = {
    getData(tags_name) {
        return javantApi.get(`component/article/tags/${tags_name}`);
    },
    getDataByCategory(category_name, tag_name) {
        return javantApi.get(`component/article/${category_name}?category=${tag_name}`); 
    },
    getInfiniteArticles(tag_name, page){
        return javantApi.get(`component/article/tags/${tag_name}?page=${page}`)
    },
    getInfiniteArticlesByCategoryTag(category_name,tag_name, page){
        return javantApi.get(`component/article/${category_name}?category=${tag_name}&page=${page}`)
    },
    getMetaByTag(tag_name){
        return javantApi.get(`component/header/data?slug=tags/${tag_name}/`);
    },
};
