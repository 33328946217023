import React, { useEffect, useState } from "react"

function Ads(
    {data, writerID, categoryID, tagID, contentID, seoTag, size, adsIndex, adsSelector, slug, domainID, page}
){
    var sidebarFilled = false
    // const sidebarOwnerId = false
    const [btmScript, setBtmScript] = useState("");
    const [slotLoaded, setSlotLoaded] = useState(false);
    const [adsLoaded, setAdsLoaded] = useState(false)
    // const [myAdvertisement, setMyAdvertisement] = useState([]);
    const elId = (parseInt(adsIndex,10) === 0 ? parseInt(adsIndex,10) : parseInt(adsIndex,10) -1  )

    // componentDidupdate
    useEffect(()=>{
        setAdsLoaded(false)
        setSlotLoaded(false)
        testing()
        // eslint-disable-next-line
    },[contentID])
    
    // componentDidUpdate
    useEffect(()=>{
        if(!adsLoaded && slotLoaded){ // adsLoaded false and slotLoaded true 
            var _script = document.createElement("script");
            var _page =  (page) ? page : `article`;
            _script.type = "text/javascript";
            _script.id = `adScript_${elId}`;
            _script.text = btmScript;
            document.getElementById(`adScript_${elId}`) && document.getElementById(`adScript_${elId}`).remove();
            if(document.getElementsByClassName(`${_page}-content`)[elId]){
                document.getElementsByClassName(`${_page}-content`)[elId].appendChild(_script);
            }

            // let _el = myAdvertisement;
            // setTimeout(()=> {
                // for(var i=0; i<_el.length; i++){
                    
                // }
            // })
            setAdsLoaded(true)
        }
        // eslint-disable-next-line
    },[adsLoaded, slotLoaded])

    const testing = () => {
        var start = "if (window.googletag && googletag.apiReady) { googletag.cmd.push(function() { " + adsSelector + "[" + (adsIndex - 1) + "] = []; ";
        var slots = [];
        var myAds = [];
        var unikSlots = "";
        var defineSlot = ""
        var increment = 0;

        for (var x = 0; x < data.length; x++) {
            var type = data[x].type;
            type = type.toLowerCase();
            var target = data[x].target;
            var gptad = data[x].gptad;
            var gptkey = data[x].gptkey;
            var descID = data[x].desc;
            var uniqueVar = target.split("#")[1] ? target.split("#")[1] : target;
            var width = data[x].width || false;
            var height = data[x].height || false;
            
            var wrid = "";
            var tgid = "";
            var sgid = "";
            var coid = "";
            var catid = "";
            // var dmid = "";
            var unik = "";
            var showTo = "";
            var uniqueNumber = 999999;
            if (type === "dfp") {
                var $target = document.querySelectorAll(target)
                if ($target.length > 0){
                    let isSidebar = document.querySelectorAll(target)[0].classList.contains('topRightSidebar') || document.querySelectorAll(target)[0].classList.contains('stickyAds')
                    
                    if (isSidebar === false && slug) {
                        document.getElementById(slug).classList.add(`pid${adsIndex - 1}`);
                        var newTarget = "#" + CSS.escape(slug) + ".pid" + (adsIndex - 1) + " " + target;
                        $target = document.querySelectorAll(newTarget)
                    }
                    for (var i = 0; i < $target.length; i++) {
                        var tgt = document.querySelector(target).className
                        tgt = tgt.split(" ")[0] + "-content";
                        unik = uniqueVar.toString().replace(/-/g, "").replace(/\.|#/g, "") + i + (adsIndex - 1);
                        uniqueNumber = Math.floor(Math.random() * 10000) + i + (adsIndex - 1);
                        
                        let unqId = unik + gptad;
                        document.getElementById(unqId) && document.getElementById(unqId).remove()
                        if(!document.getElementById(unqId)){
                            myAds.push(unqId);
                            var _string = `<div id="script-${gptad}${uniqueNumber}" class="${unik}" style="display: none;"></div>
                            <div id="${unqId}" class="${tgt} ${showTo}" style="width:${width}px; height:${height}px; margin:auto;"></div>`;

                            wrid = writerID === "undefined" ? "" : ".setTargeting('WriterID_date', ['" + writerID + "'])";
                            tgid = tagID === "undefined" ? "" : ".setTargeting('tag_ID', ['" + tagID + "'])";
                            sgid = seoTag === "undefined" ? "" : ".setTargeting('seo_tag', ['" + seoTag + "'])";
                            // dmid = domainID === "undefined" ? "" : ".setTargeting('domain_ID', ['" + domainID + "'])";
                            if (contentID <= 53900) {
                                coid = contentID === "undefined" ? "" : ".setTargeting('content_id', ['" + contentID + "'])";
                            } else if (contentID > 53900 && contentID <= 154000) {
                                coid = contentID === "undefined" ? "" : ".setTargeting('content_id_02', ['" + contentID + "'])";
                            } else if (contentID > 154000) {
                                coid = contentID === "undefined" ? "" : ".setTargeting('content_id_03', ['" + contentID + "'])";
                            }
                            catid = categoryID ? `.setTargeting('categoryID', ['${categoryID}'])` : ``
                            let targetDescription = descID ? `.setTargeting('jt_ads', ['${descID}'])` : ``;
                            
                            if ($target.length && gptkey && !(isSidebar && sidebarFilled)) {
                                let tmpAllClass = '.'+$target[i].className.replace(/ /g,'.')
                                document.querySelector(tmpAllClass).insertAdjacentHTML( 'afterbegin', _string );

                                defineSlot += 
                                    `${adsSelector}[${adsIndex - 1}][${increment}] = googletag.defineSlot('${gptkey}', [${width},${height}], '${unqId}').addService(googletag.pubads())${wrid}${tgid}${sgid}${coid}${catid}${targetDescription};`;
                                var _script = document.createElement("script");
                                _script.type = "text/javascript";
                                _script.text = "googletag.cmd.push(function() { googletag.display('" + unik + gptad + "'); });";
                                document.getElementById(unqId).appendChild(_script);

                                slots[increment] = `${adsSelector}[${(adsIndex - 1)}][${increment}]`;
                                unikSlots += "googletag.display('" + unqId + "');";

                                if (isSidebar && !sidebarFilled) {
                                    sidebarFilled = increment;
                                    // sidebarOwnerId = elId;
                                  }
                            }
                        }
                        increment++;
                    }
                }
            }

            slots = slots.filter((v) => v);
            var end = "googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.pubads().collapseEmptyDivs(); googletag.pubads().enableLazyLoad(); googletag.enableServices(); " + unikSlots + " googletag.pubads().refresh([" + slots.join() + "]); }); }";

            setBtmScript(start+defineSlot+end)
            setSlotLoaded(true)
            // sidebarOwnerId
            // sidebarFilled
            // setMyAdvertisement(myAds)
        }
    }
    return(
        <section />
    )
}

export default Ads