import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import VerticalBanner from "@components/Banners/VerticalBanner/VerticalBanner";
// import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import SubTitle from "@components/common/Titles/SubTitle";


import "../assets/css/searchPage.css";
import "../assets/css/videoCategoryPage.css";
import "@components/PostBlockWithCategory/PostBlockWithCategory.css";
import { googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody } from '../helpers'
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';

function SearchPage() {
  const params = useParams();
  useEffect(() => {

    googleAnalytics()
    loadAds()
  }, [params]);

  const loadAds = () => {
    const isMobile = window.innerWidth <= 768 ? true : false;
    const $currpage = document.getElementById(`searchPage`);

    var _scriptTagHead = document.createElement("script");
    _scriptTagHead.async = true;
    _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`

    let slots = []
    if (isMobile) {
      // available slot in this page
      slots = [{
        slot: "JT_320x100", // define slot
        id: "adSlotMobTop-category",
        wrapperId: "JT_320x100",
        defaultSize: "320x100"
      },
      {
        slot: "JT_320x100", // define slot
        id: "adSlotMobSticky-category",
        wrapperId: "JT_320x100-sticky-bottom",
        defaultSize: "320x100"
      }, {
        slot: "JT_320x100_top", // adunit path
        id: "JT_320x100_top_inner", // whatever
        wrapperId: "JT_320x100_top",
        defaultSize: "320x100"
      }
      ]
    } else {
      // available slot in this page
      slots = [{
        slot: "JT_970x90/JT_970x90_top",
        id: "adSlotDesktop0",
        wrapperId: "JT_970x90_top",
        defaultSize: "970x90"
      }]
    }

    // create ad unit tag head
    for (let i = 0; i < slots.length; i++) {
      _scriptTagHead.text += generateAdUnitTagHead(slots[i])
    }
    $currpage && $currpage.appendChild(_scriptTagHead)

    // create ad unit tag body
    for (let i = 0; i < slots.length; i++) {
      generateAdUnitTagBody(slots[i])
    }
  }

  useEffect(() => {
    const script = document.createElement("script");
    document.head.append(script);
    script.src = "https://cse.google.com/cse.js?cx=395c6440013fd52d8";
  }, []);

  return (
    <main className="container-common__wrapper" id="searchPage">
      {/* Meta noindex for search page */}
      <Helmet>
        <meta name="googlebot" content="noindex" />
      </Helmet>

      {/* Left vertical banner */}
      <div className="container-common__vertical-banner">
        <VerticalBanner />
      </div>

      {/* Main content */}
      <div className="container-common">
        <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{ minHeight: '100px' }} />
        <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{ minHeight: '100px' }} />
        <div className="gcse-searchbox-only" data-resultsUrl="/search/articles" data-enableHistory="true" data-autoCompleteMaxCompletions="5"></div>
        <div className="search-page__main-title">
          <SubTitle subTitleText="Hasil Pencarian" isLowercase />
        </div>
        <div className="gcse-searchresults-only"></div>
      </div>

      {/* Right vertical banner */}
      <div className="container-common__vertical-banner">
        <VerticalBanner />
      </div>
      <MobileStickyBottom />
    </main>
  );
}
export default SearchPage;