import React from "react";
import { useLocation } from "react-router-dom";
// import DesktopHorizontalBanner from "@components/Banners/DesktopHorizontalBanner/DesktopHorizontalBanner";
import Faq from "../components/Static/Faq/Faq";
import Privacy from "../components/Static/Privacy/Privacy";
import EULA from "../components/Static/EULA/EULA";
import Contact from "../components/Static/Contact/Contact";
import { getFaqMeta } from "../constants";
import MetaTag from '../components/MetaTag/MetaTag';
import { useEffect } from "react";
import { googleAnalytics, generateAdUnitTagHead, generateAdUnitTagBody } from "../helpers";
import AdWrapper from '../components/Banners/AdWrapper/AdWrapper';
import MobileStickyBottom from "../components/Banners/MobileStickyBottom/MobileStickyBottom";
import AboutUs from "../components/Static/AboutUs/AboutUs";
import Disclaimer from "../components/Static/Disclaimer/Disclaimer";
import Pedoman from "../components/Static/Pedoman/Pedoman";

function FaqPage(){
    // const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;
    const pathArray = pathname.split("/");
    const pageType = pathArray[1];

    const contentType = () => {
        switch (pageType) {
            case 'tentang-kami':
                return <AboutUs />
            case 'privacy':
                return <Privacy /> 
                // break;
            case 'contact':
                return <Contact /> 
                // break;
            case 'eula':
                return <EULA /> 
                // break;
            case 'disclaimer':
                return <Disclaimer /> 
            case 'pedoman':
                return <Pedoman /> 
            case 'faq':
            default:
                return <Faq />
                // break;
        }
    }

    useEffect(() => {
        googleAnalytics(getFaqMeta(pageType).title)
        loadAds();
        // eslint-disable-next-line
    },[pageType])
    
    const loadAds = () => {
        const isMobile = window.innerWidth <= 768 ? true : false;
        const $currpage = document.getElementById(`faqpage`);
    
        var _scriptTagHead = document.createElement("script");
        _scriptTagHead.async = true;
        _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`
    
        let slots = [];
        if(isMobile){
            // available slot in this page
            slots = [{
                slot: "JT_320x100", // define slot
                id: "adSlotMobTop-category",
                wrapperId: "JT_320x100",
                defaultSize: "320x100"
            },
            {
                slot: "JT_320x100", // define slot
                id: "adSlotMobSticky-category",
                wrapperId: "JT_320x100-sticky-bottom",
                defaultSize: "320x100"
            }
            ]
        }else{
            // available slot in this page
            slots = [{
                slot: "JT_970x90/JT_970x90_top",
                id: "adSlotDesktop0",
                wrapperId: "JT_970x90_top",
                defaultSize: "970x90"
            }]
        }
        
        // create ad unit tag head
        for (let i = 0; i < slots.length; i++) {
            _scriptTagHead.text += generateAdUnitTagHead(slots[i])
        }
        $currpage && $currpage.appendChild(_scriptTagHead)    
        
        // create ad unit tag body
        for (let i = 0; i < slots.length; i++) {
            generateAdUnitTagBody(slots[i])
        }
    }

    return(
        <>
            <main className="main-container" id="faqpage">
                <MetaTag {...getFaqMeta(pageType, pathname.slice(1))}/>
                <div className="container-full-width">
                    <AdWrapper id={`JT_970x90_top`} cls={`desktop`} customStyle={{minHeight:'100px'}} />
                    <AdWrapper id={`JT_320x100`} cls={`mobile`} customStyle={{minHeight:'100px'}} />
                    {
                        pageType === 'tentang-kami'
                        ?
                        <h1 className="page-title">
                            Tentang Jalantikus.com
                            <div className="page-subtitle">
                                Mengenal lebih dekat tim dan proses kreatif di balik redaksi JalanTikus.com
                            </div>
                        </h1>
                        :
                        <h1 className="page-title">
                            How We Can Help?
                            <div className="page-subtitle">
                                Answers to common questions, expert advice, and a way to provide feedback
                            </div>
                        </h1>
                    }
                </div>
                {/* end of container-full-width */}

                <div className="container-full-width-gadget">
                    {/* <Filter 
                        page="gadget"
                        type={history.type}
                        noSelect 
                        inputPlaceholder="Search For Answers.."
                    /> */}
                    
                    {contentType()}
                </div>
                <MobileStickyBottom />
            </main>
        </>
    )
}

export default FaqPage