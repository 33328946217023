import { javantApi } from "../constants";

export const NFTPageService = {
    getArticle(){
        return javantApi.get(`component/article/nfts`); 
        
    },
    getAppsByNFTGameCategory() {
        return javantApi.get(`component/app/games?category=nft-games&page=1`);
    },
    getAppsByDAppCategory() {
        return javantApi.get(`component/app/apps?category=dapp&page=1`);
    },
    getMetaByCategory(type, category){
        return javantApi.get(`component/header/data?slug=${type}/category/${category}/`); 
    },
    getWeb3Article(){
        return javantApi.get(`component/article/web3`);
    }
}