export const getFaqMeta = (page, pathname) => {
   switch (page) {
      case "privacy":
         return {
            title: 'Halaman Kebijakan Privasi | JalanTikus',
            description: 'Ketahui kebijakan privasi anda untuk pengalaman surfing informasi di situs JalanTikus.',
            url: `${ pathname }`,
            ogTitle: 'Halaman Kebijakan Privasi | JalanTikus'
         }
      case "contact":
         return {
            title: 'Halaman Contact Us | JalanTikus',
            description: 'Hubungi kami untuk keperluan kerjasama dan beriklan di situs JalanTikus.',
            url: `${ pathname }`,
            ogTitle: 'Halaman Contact Us | JalanTikus'
         }
      case "eula":
         return {
            title: 'Syarat dan Ketentuan | JalanTikus',
            url: `${ pathname }`,
            ogTitle: 'Syarat dan Ketentuan | JalanTikus'
         }
      case "faq":
      default:
         return {
            title: 'Tentang Jalantikus.com',
            description: 'Belum kenalan dengan JalanTikus? Berikut kumpulan pertanyaan umum yang biasa ditanyakan oleh pembaca mengenai situs JalanTikus.',
            url: `${ pathname }`,
            ogTitle: 'Tentang Jalantikus.com'
         }
   }
};