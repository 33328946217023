import React from "react"
import { useEffect } from "react"
import {generateAdUnitTagHead, generateAdUnitTagBody} from '../../../helpers'
import AdWrapper from '../AdWrapper/AdWrapper'

function MobileBetweenPosts({idName, parentId, currentAdIndex}){
    const uniqueId = 'adSlotMobilescroll-'+currentAdIndex
    
    useEffect(() => {
        loadAds()
        // eslint-disable-next-line
    },[])

    const loadAds = () => {
        const isMobile = window.innerWidth <= 768 ? true : false;
        const $parent = document.getElementById(parentId)

        if(isMobile){
            // create ad unit tag head
            let targeting = {
                slot: "JT_300x250", // adunit path
                id: uniqueId, // whatever
                wrapperId: `JT_300x250_scroll-mobile-${currentAdIndex}`, //match with existing HTML elmt
                defaultSize: "300x250"
            }

            var _scriptTagHead = document.createElement("script");
            _scriptTagHead.async = true;
            _scriptTagHead.text = `window.googletag = window.googletag || {cmd: []};`
            _scriptTagHead.text += generateAdUnitTagHead(targeting)
            $parent && $parent.appendChild(_scriptTagHead)

            // create ad unit tag body
            generateAdUnitTagBody(targeting)
        }
    }

    return(
        <AdWrapper id={`JT_300x250_scroll-mobile-${currentAdIndex}`} cls={`mobile`} customStyle={{overflowX:'auto'}} />
    )
}

export default MobileBetweenPosts