import React from "react";
import './Error.css'
import NotFound from '@assets/images/notfound.svg'
import ServerError from '@assets/images/server-error.svg'

function Error({code}){

    const titleContent = [
        "Ooops, Halaman yang kamu kunjungi tidak ditemukan",
        "Ooops, Terjadi kesalahan"
    ]

    const subtitleContent = [
        "Mungkin terdapat kesalahan dalam penulisan link atau halaman sudah dipindah",
        "Silakan mencoba kembali beberapa saat lagi"
    ]

    let imgSrc;
    let errorTitle;
    let errorSubtitle;
    const codeStr = code && code.toString()
    switch (codeStr[0]){
        case '4' :
            imgSrc = NotFound
            errorTitle = titleContent[0]
            errorSubtitle = subtitleContent[0]
        break;
        case '5' :
            imgSrc = ServerError
            errorTitle = titleContent[1]
            errorSubtitle = subtitleContent[1]
        break;
        default:
            imgSrc = ServerError
            errorTitle = titleContent[1]
            errorSubtitle = subtitleContent[1]
            break;
    }

    return(
        <div className="error-container">
            <div className="error-code">
                {code}
            </div>
            <div className="error-img__wrapper">
                <img src={imgSrc} alt="" />
            </div>
            <div className="error-title">
                {errorTitle}
            </div>
            <div className="error-subtitle">
                {errorSubtitle}
            </div>
            {codeStr[0]==='4' && 
                <div className="btn__wrapper">
                    <a href="/" className="red-btn">
                        Kembali ke Beranda
                    </a>
                </div>
            }
        </div>
    )
}

export default Error