import { dailymotionApi } from "../constants";

export const VideoDetailPageService = {
  getActiveDetailVideo(videoId) {
    return dailymotionApi.get(
      `/video/${videoId}&fields=id,title,description,thumbnail_url,created_time,url,embed_url`
    );
  },
  getSliderData(videoID, category) {
    return dailymotionApi.get(
      // `/video/x806mln/related?channel=tech&fields=title,thumbnail_url,created_time,url`
      `/video/${videoID}/related?channel=${category}&fields=title,thumbnail_url,created_time,url,id`
    );
  },
};
