
import { CategoryPageService, HomePageService, NFTPageService } from "../../http";

// ACTION TYPES
const GET_GAMES_ARTICLE_DATA = "GET_GAMES_ARTICLE_DATA";
const GET_APPS_ARTICLE_DATA = "GET_APPS_ARTICLE_DATA";
const GET_TECH_HACK_ARTICLE_DATA = "GET_TECH_HACK_ARTICLE_DATA";
const GET_WEB3_ARTICLE_DATA = "GET_WEB3_ARTICLE_DATA";
const GET_POPULAR_ARTICLE_DATA = "GET_POPULAR_ARTICLE_DATA";
const GET_EV_ARTICLE_DATA = "GET_EV_ARTICLE_DATA";
const GET_FINANSIAL_DATA = "GET_FINANSIAL_DATA";

// ACTION CREATORS
export const getGamesData = (payload) => ({
    type: GET_GAMES_ARTICLE_DATA,
    payload: payload,
});

export const getAppsData = (payload) => ({
    type: GET_APPS_ARTICLE_DATA,
    payload: payload,
});

export const getTechHackData = (payload) => ({
    type: GET_TECH_HACK_ARTICLE_DATA,
    payload: payload,
});

export const getWeb3Data = (payload) => ({
    type: GET_WEB3_ARTICLE_DATA,
    payload: payload,
});

export const getPopularData = (payload) => ({
    type: GET_POPULAR_ARTICLE_DATA,
    payload: payload,
});

export const getEVData = (payload) => ({
    type: GET_EV_ARTICLE_DATA,
    payload: payload,
});

export const getFinansialData = (payload) => ({
    type: GET_FINANSIAL_DATA,
    payload: payload,
});

// THUNK
export const getGamesArticles = () => async (dispatch) => {
    try {
        const response = await HomePageService.getGamesArticle();
        dispatch(getGamesData(response.data));
    } catch (error) {
        dispatch(getGamesData(error));
    }
};

export const getAppsArticles = () => async (dispatch) => {
    try {
        const response = await HomePageService.getAppsArticle();
        dispatch(getAppsData(response.data));
    } catch (error) {
        dispatch(getAppsData(error));
    }
};

export const getTechHackArticles = () => async (dispatch) => {
    try {
        const response = await HomePageService.getTechHackArticle();
        dispatch(getTechHackData(response.data));
    } catch (error) {
        dispatch(getTechHackData(error));
    }
};

export const getWeb3Articles = () => async (dispatch) => {
    try {
        const response = await NFTPageService.getWeb3Article();
        dispatch(getWeb3Data(response.data));
    } catch (error) {
        dispatch(getWeb3Data(error));
    }
};

export const getPopularArticles = () => async (dispatch) => {
    try {
        const response = await HomePageService.getOtherData();
        dispatch(getPopularData(response.data));
    } catch (error) {
        dispatch(getPopularData(error));
    }
};

export const getEVArticles = () => async (dispatch) => {
    try {
        const response = await HomePageService.getEvArticle();
        dispatch(getEVData(response.data));
    } catch (error) {
        dispatch(getEVData(error));
    }
};

export const getFinansialArticles = () => async (dispatch) => {
    try {
        const response = await CategoryPageService.getData('finansial');
        dispatch(getFinansialData(response.data));
    } catch (error) {
        dispatch(getFinansialData(error));
    }
};

/*INITIAL STATE*/
const initialState = {
    GamesData: null,
    AppsData: null,
    TechHackData: null,
    Web3Data: null,
    popularArticleData: null,
    EVData: null,
    finansialData: null,
};

/*REDUCER*/
function megaMenuReducer(state = initialState, action) {
    switch (action.type) {
        /*Get data for category page*/
        case GET_GAMES_ARTICLE_DATA:
            return {
                ...state,
                GamesData: action.payload,
            };
        case GET_APPS_ARTICLE_DATA:
            return {
                ...state,
                AppsData: action.payload,
            };
        case GET_TECH_HACK_ARTICLE_DATA:
            return {
                ...state,
                TechHackData: action.payload,
            };
        case GET_WEB3_ARTICLE_DATA:
            return {
                ...state,
                Web3Data: action.payload,
            };
        case GET_POPULAR_ARTICLE_DATA:
            return {
                ...state,
                popularArticleData: action.payload,
            };
        case GET_EV_ARTICLE_DATA:
            return {
                ...state,
                EVData: action.payload,
            };
        case GET_FINANSIAL_DATA:
            return {
                ...state,
                finansialData: action.payload,
            };
        default:
            return state;
    }
}

export default megaMenuReducer