import React from "react";
import logo from "@assets/images/jalantikus-logo-white.svg";
// import { Link } from "react-router-dom";

function LogoWhite() {
  return (
    <>
      <a href="/">
        <img src={logo} alt="logo" />
      </a>
    </>
  );
}

export default LogoWhite;
